<template>
    <div class="container-formulario">
        <section>
            <!-- Cabeçalho com botão de retorno -->
            <div class="container-cabecalho flex justify-between mb-4">
                <span class="font-bold text-corBase text-xl mb-4">Criar Nova Obra</span>
                <router-link to="/obras" class="text-xl text-corBase font-bold">
                    <i class="fas fa-chevron-left"></i>
                </router-link>
            </div>

            <form @submit.prevent="salvarObra">
                <div class="space-y-4">
                    <!-- Seção 1: Dados da Obra -->
                    <div>
                        <button @click.prevent="toggleSection(1)"
                            class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
                            Obra
                        </button>
                        <div v-if="activeSection === 1"
                            class="p-4 flex flex-col gap-5 border border-corBase/60 transition duration-500">
                            <Obra :obra="obra" />
                        </div>
                    </div>

                    <!-- Seção 2: Stakeholders -->
                    <div>
                        <button @click.prevent="toggleSection(2)"
                            class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
                            Stakeholders
                        </button>
                        <div v-if="activeSection === 2"
                            class="p-4 flex flex-col gap-5 border border-corBase/60 transition duration-500">
                            <ListarStakeholders :obra="obra" @atualizarStakeholders="atualizarStakeholders"
                                @salvarStakeholdersObrigatorios="salvarStakeholdersObrigatorios"
                                @clienteEditado="clienteEditado" />
                        </div>
                    </div>

                    <!-- Seção 3: Endereço -->
                    <div>
                        <button @click.prevent="toggleSection(3)"
                            class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
                            Endereço
                        </button>
                        <div v-if="activeSection === 3"
                            class="p-4 flex flex-col gap-5 border border-corBase/60 transition duration-500">
                            <Endereco :obra="obra" />
                        </div>
                    </div>

                    <!-- Seção 4: Documentos -->
                    <div>
                        <button @click.prevent="toggleSection(4)"
                            class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
                            Documentos
                        </button>
                        <div v-if="activeSection === 4"
                            class="p-4 flex flex-col gap-5 border border-corBase/60 transition duration-500">
                            <Documentos :obra="obra" />
                        </div>
                    </div>

                    <!-- Seção 5: Especificações Gerais da Obra -->
                    <div>
                        <button @click.prevent="toggleSection(5)"
                            class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
                            Especificações Gerais
                        </button>
                        <div v-if="activeSection === 5"
                            class="p-4 flex flex-col gap-5 border border-corBase/60 transition duration-500">
                            <EspecificacoesGerais :obra="obra" />
                        </div>
                    </div>

                    <!-- Seção 6: Financeiro (somente para admins) -->
                    <div v-if="isadmin === true">
                        <button @click.prevent="toggleSection(6)"
                            class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
                            Financeiro
                        </button>
                        <div v-if="activeSection === 6"
                            class="p-4 flex flex-col gap-5 border border-corBase/60 transition duration-500">
                            <Financeiro :obra="obra" />
                        </div>
                    </div>
                </div>

                <!-- Botões para salvar ou cancelar -->
                <div class="flex w-full justify-end itens-center mt-6 gap-2">
                    <button class="btn-cancelar-obra" @click.prevent="$router.push('/obras')">Cancelar</button>
                    <button class="btn-salvar-obra" type="submit">Salvar</button>
                </div>
            </form>
        </section>


        <div v-if="exibirModalNovoCliente">
            <!-- Modal de listagem de erros -->
            <div class="fixed inset-0 bg-black/60 flex items-center justify-center z-[10000]">
                <div
                    class="bg-white rounded-lg shadow-lg p-6 w-[90%] sm:w-[600px] max-h-[80vh] overflow-y-auto transform transition-transform duration-300 ease-out scale-95 sm:scale-100">
                    <!-- Cabeçalho do modal -->
                    <div class="flex justify-between items-center mb-4 border-b pb-2">
                        <h2 class="text-lg font-bold text-yellow-500">Novo Cliente</h2>
                        <button @click.prevent="exibirModalNovoCliente = false"
                            class="text-gray-500 hover:text-gray-800 p-2 rounded-full transition duration-200 ease-in-out hover:bg-gray-100">
                            <i class="fa-solid fa-x"></i>
                        </button>
                    </div>

                    <div>
                        <p>O endereço foi limpo pela alteração em Cliente.</p>
                    </div>
                </div>
            </div>
        </div>


        <SucessoRapido v-if="sucesso === true" :mensagem="sucessoMessage" />
        <ErroRapido v-if="erro === true" :mensagem="erroMessage" />
    </div>
    <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros()" />
</template>

<script>
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue';
import Obra from './EtapasCriacaoObra/Obra.vue';
import Endereco from './EtapasCriacaoObra/Endereco.vue';
import Documentos from './EtapasCriacaoObra/Documentos.vue';
import ListarStakeholders from './EtapasCriacaoObra/ListarStakeholders.vue';
import EspecificacoesGerais from './EtapasCriacaoObra/EspecificacoesGerais.vue';
import Financeiro from './EtapasCriacaoObra/Financeiro.vue';
import ListagemErros from '@/components/toasts/toast_erro/ListagemErros.vue';
import axios from 'axios';

export default {
    name: 'CriarNovaObra',
    components: {
        SucessoRapido,
        ErroRapido,
        Obra,
        Endereco,
        ListarStakeholders,
        EspecificacoesGerais,
        Documentos,
        Financeiro,
        ListagemErros,
    },
    data() {
        return {
            sucesso: false,
            sucessoMessage: '',

            erro: false,
            erroMessage: '',

            exibirErros: false,
            erros: [],

            exibirModalNovoCliente: false,

            activeSection: 1,
            obra: {
                descricao: '',
                cno: '',

                contramarco: false,
                medida_contramarco: '',

                arremate: false,

                lista_cor_componente: [],
                lista_cor_perfil: [],
                lista_vidro: [],
                lista_cor_chapa: [],

                imagem: null,
                imagemFile: null,
                endereco: '',
                numero: '',
                apartamento: '',
                bloco: '',
                complemento: '',
                bairro: '',
                cidade: '',
                estado: '',
                cep: '',
                localizacao: '',
                status_obra: '',
                comissao: 0,
                valor_rt: 0,

                responsavel_tecnico: {},
                contato_responsavel_tecnico: '',

                medidores: [], 

                cliente: {},
                contato_cliente: '',

                patrocinador: {},
                contato_patrocinador: '',

                responsavel: {},
                contato_responsavel: '',

                stakeholders: [],
                arquivos: [],
            },
            id_obra_criada: ''
        };
    },
    computed: {
        isadmin() {
            return this.$store.state.isAdminUser;
        },
    },
    methods: {
        toggleSection(sectionNumber) {
            if (sectionNumber === 3) {
                console.log(this.obra.cliente)
                if (!this.obra.cliente || Object.keys(this.obra.cliente).length === 0) {
                    this.erros = { stakeholders: ["Selecione um Cliente para avançar para endereço."] };
                    this.toggleErros()
                    return
                }
            }
            // Alterna a exibição da seção com base no número do acordeão clicado
            this.activeSection = this.activeSection === sectionNumber ? null : sectionNumber;
        },

        atualizarStakeholders(stakeholdersAtualizados) {
            console.log(stakeholdersAtualizados)
            this.obra.stakeholders = stakeholdersAtualizados;
        },

        salvarStakeholdersObrigatorios(stakeholdersObrigatorios) {
            console.log(stakeholdersObrigatorios)
            this.obra.cliente = stakeholdersObrigatorios.cliente.pessoa;
            this.obra.contato_cliente = stakeholdersObrigatorios.cliente.contato;
            this.obra.patrocinador = stakeholdersObrigatorios.patrocinador.pessoa;
            this.obra.contato_patrocinador = stakeholdersObrigatorios.patrocinador.contato;
            this.obra.responsavel = stakeholdersObrigatorios.responsavel.pessoa;
            this.obra.contato_responsavel = stakeholdersObrigatorios.responsavel.contato;

            console.log(this.obra.cliente)
            console.log(this.obra.contato_cliente)
            console.log(this.obra.patrocinador)
            console.log(this.obra.contato_patrocinador)
            console.log(this.obra.responsavel)
            console.log(this.obra.contato_responsavel)
        },

        clienteEditado() {
            this.obra.cep = ''
            this.obra.endereco = ''
            this.obra.numero = ''
            this.obra.apartamento = ''
            this.obra.bloco = ''
            this.obra.complemento = ''
            this.obra.bairro = ''
            this.obra.estado = ''
            this.obra.cidade = ''

            this.exibirModalNovoCliente = true
        },

        async salvarObra() {
            const payload = {
                descricao: this.obra.descricao,
                cno: this.obra.cno,
                status_obra: this.obra.status_obra,
                ativo: true,

                contramarco: this.obra.contramarco,
                medida_contramarco: this.obra.medida_contramarco,

                arremate: this.obra.arremate,

                lista_cor_componente: this.obra.lista_cor_componente.map(item => item.id) || [],
                lista_cor_perfil: this.obra.lista_cor_perfil.map(item => item.id) || [],
                // lista_cor_vidro: this.obra.lista_cor_vidro.map(item => item.id) || [],
                lista_vidro: this.obra.lista_vidro.map(item => item.id) || [],
                lista_cor_chapa: this.obra.lista_cor_chapa.map(item => item.id) || [],

                endereco: this.obra.endereco,
                numero: this.obra.numero,
                apartamento: this.obra.apartamento,
                bloco: this.obra.bloco,
                complemento: this.obra.complemento,
                bairro: this.obra.bairro,
                cidade: this.obra.cidade,
                estado: this.obra.estado,
                cep: this.obra.cep,
                localizacao: this.obra.localizacao,

                comissao: this.obra.comissao,
                valor_rt: this.obra.valor_rt,

                cliente: this.obra.cliente?.id || null,
                contato_cliente: this.obra.contato_cliente,

                patrocinador: this.obra.patrocinador?.id || null,
                contato_patrocinador: this.obra.contato_patrocinador,

                responsavel: this.obra.responsavel?.id || null,
                contato_responsavel: this.obra.contato_responsavel,

                responsavel_tecnico: this.obra.responsavel_tecnico?.id || null,
                contato_responsavel_tecnico: this.obra.contato_responsavel_tecnico,

                medidores: this.obra.medidores || []
            };

            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');
            this.$store.dispatch("setLoading", true);

            try {
                const response = await axios.post('obra/', payload, {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                });

                // Verifica se tem algum stakeholder além dos 3 obrigatórios
                this.id_obra_criada = response.data.id;
                if (this.obra.stakeholders.length >= 4) {
                    this.salvarPessoasAlocadas(this.id_obra_criada);
                }
                if (this.obra.arquivos.length >= 1) {
                    this.salvarArquivos(this.id_obra_criada);
                }
                this.sucesso = true;
                this.sucessoMessage = 'Obra criada com sucesso.';
                setTimeout(() => {
                    this.sucesso = false;
                    this.sucessoMessage = '';
                    this.$router.push(`/obra/${this.id_obra_criada}`);
                }, 3000);
            } catch (error) {
                console.error('Erro ao criar a obra:', error);

                this.erros = error.response?.data || { geral: ["Ocorreu um erro desconhecido."] };
                this.toggleErros();
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros;
        },

        async salvarPessoasAlocadas(id_obra) {
            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');
            const stakeholders = this.obra.stakeholders.filter(
                (stakeholder) => !['Cliente', 'Patrocinador', 'Responsável de Obra'].includes(stakeholder.funcao)
            );

            this.$store.dispatch("setLoading", true);
            try {
                for (const stakeholder of stakeholders) {
                    await axios.post(
                        `obra/pessoa-alocada/`,
                        {
                            obra: id_obra,
                            pessoa: stakeholder.pessoa.id,
                            responsabilidade: stakeholder.funcao.id,
                            contato: stakeholder.contato,
                        },
                        {
                            headers: {
                                Authorization: `Token ${token}`,
                            },
                        }
                    );
                }
            } catch (error) {
                console.error('Erro ao criar pessoas alocadas:', error);
                this.erro = true;
                this.erroMessage = 'Ocorreu um erro ao alocar pessoas.';
                setTimeout(() => {
                    this.erro = false;
                    this.erroMessage = '';
                }, 3000);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async salvarArquivos(id_obra) {
            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');
            try {
                // console.log(id_obra);

                for (const file of this.obra.arquivos) {
                    console.log(file)
                    const formData = new FormData();
                    formData.append('obra', id_obra);
                    formData.append('ativo', 'True');
                    formData.append('name', `${file.name}`);
                    formData.append('url_documento', file.file);

                    const response = await axios.post(`obra/documentos-obra/`, formData, {
                        headers: {
                            Authorization: `Token ${token}`,
                        },
                    });

                    console.log("Arquivo enviado:", response);
                }
            } catch (error) {
                console.error(error);
            }
        }
    },
};
</script>

<style scoped>
.btn-cancelar-obra {
    @apply px-4 py-1 cursor-pointer text-white bg-inativo border hover:bg-screenCor hover:text-inativo hover:border-inativo
}

.btn-salvar-obra {
    @apply px-4 py-1 cursor-pointer text-white bg-ativo border hover:bg-screenCor hover:text-ativo hover:border-ativo
}

input,
textarea,
select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 5px;
}
</style>
