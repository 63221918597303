<template>

  <details>
    <summary class="mt-4">
      <h2 class="bg-screenCor hover:bg-screenCor border border-corBase hover:border-corBase
  text-corBase hover:text-corBase py-2 px-4 ">Endereço
      </h2>
    </summary>

    <button @click.prevent="adicionarEndereco" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-2 px-4 mt-2 mr-2">Adicionar
    </button>

    <div class="border border-gray-200 p-2 mt-3" v-for="(endereco, index) in dadosEndereco" :key="index">

      <div class=" flex flex-end justify-end">
        <i class="fa-solid fa-x cursor-pointer" @click.prevent="removerEndereco(index)"></i>
      </div>

      <div class="container-campos ">

        <div class="campos-mobile campos-dasktop-select">
          <label class="label-campos whitespace-nowrap">Tipo de Endereço:</label>
          <div class="flex">
            <!-- <select class="input-campos" v-model="endereco.tipo_endereco">
              <option v-for="item in lista_tipo_endereco" :value="item.id" :key="item.id">{{ item.descricao }}
              </option>
            </select> -->
            <v-select v-model="endereco.tipo_endereco" :options="lista_tipo_endereco" :reduce="item => item.id"
              label="descricao" class="bg-white w-full" placeholder="Selecione o tipo de endereço" />
          </div>
        </div>

        <div class="campos campos-mobile campos-dasktop-select">
          <label class="label-campos">CEP:</label>
          <input type="text" placeholder="" class="input-campos" v-mask="'#####-###'" v-model="endereco.cep"
            @input="buscarCepAutomaticamente(endereco)">
        </div>

        <div class="campos campos-mobile">
          <label class="label-campos">Logradouro:</label>
          <input type="text" class="input-campos" placeholder="" v-model="endereco.logadouro">
        </div>

        <div class="campos campos-mobile campos-dasktop-select">
          <label class="label-campos">Número:</label>
          <input type="number" class="input-campos" min="0" placeholder="" v-model="endereco.numero">
        </div>

        <div class="mt-2 flex w-1/2 flex-col">
          <label class="text-base text-corBase font-bold mt-2">Complemento:</label>
          <input type="text" class="input-campos" placeholder="" v-model="endereco.complemento">
        </div>
      </div>


      <div class="w-full flex gap-2 mt-2">

        <div class="w-full">
          <label class="text-base text-corBase  mt-2">Referência:</label>
          <input type="text" class="input-campos" placeholder="" v-model="endereco.referencia">
        </div>

        <div class="w-full">
          <label class="label-campos">Bairro:</label>
          <input type="text" class="input-campos" placeholder="" v-model="endereco.bairro">
        </div>

        <div class="w-full">
          <label class="label-campos">UF:</label>
          <v-select v-model="endereco.uf" :options="estados" :reduce="estado => estado.sigla" label="nome"
            @select="buscarCidades()" class="bg-white" />

        </div>

        <div class="w-full">
          <label class="label-campos">Cidade:</label>
          <v-select v-model="endereco.cidade" :options="endereco.cidades" :reduce="cidade => cidade.nome" label="nome"
            class="bg-white" />
          <!-- <input type="text" class="input-campos" placeholder="" v-model="endereco.cidade"> -->
        </div>
      </div>

    </div>


  </details>


</template>

<script>
import TipoEnderecoModal from "@/components/midais/modaisCadastro/TipoEnderecoModal";
import vSelect from "vue-select";
import debounce from "lodash.debounce";
import axios from "axios";

export default {
  name: 'EnderecosCampos',
  components: { TipoEnderecoModal, vSelect, },
  props: ['dadosEndereco'],

  data() {
    return {
      lista_tipo_endereco: [],

      estados: [],
      cidades: [],

      enderecoAnterior: [],
    }
  },
  computed: {
    isadmin() {
      return this.$store.state.isAdminUser
    },
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
  },

  watch: {
    dadosEndereco: {
      handler(novoValor) {
        novoValor.forEach((endereco, index) => {
          const enderecoAnterior = this.enderecoAnterior[index] || {};
          // Verifica se o 'uf' foi alterado
          if (endereco.uf && endereco.uf !== enderecoAnterior.uf) {
            this.buscarCidades(endereco.uf, endereco);
          }
        });
        // Atualiza o estado anterior
        this.enderecoAnterior = JSON.parse(JSON.stringify(novoValor));
      },
      deep: true,
    },
  },


  created() {
    this.getListaTipoEndereco()
    this.carregarEstados()
  },

  methods: {

    async carregarEstados() {
      try {
        const token = await this.$store.dispatch("getAuthTokenCookie");
        await this.$store.dispatch("fetchURLrequest");
        const response = await axios.get(`obra/estados/`, {
          headers: {
            "Authorization": `Token ${token}`
          }
        });

        this.estados = response.data.map((estado) => ({
          sigla: estado.sigla,
          nome: `${estado.nome} (${estado.sigla})`,
        }))

      } catch (error) {
        console.error("Erro ao carregar estados:", error);
      }
    },

    async buscarCidades(uf, endereco) {
      try {
        const token = await this.$store.dispatch("getAuthTokenCookie");
        await this.$store.dispatch("fetchURLrequest");
        const response = await axios.get(`obra/municipios/?estado=${uf}`, {
          headers: {
            "Authorization": `Token ${token}`
          }
        });
        endereco.cidades = response.data.map((cidade) => ({
          id: cidade.id,
          nome: cidade.nome,
        }));
      } catch (error) {
        console.error("Erro ao carregar cidades:", error);
      }
    },


    adicionarEndereco() {
      this.dadosEndereco.push({
        logadouro: '',
        numero: '',
        complemento: '',
        cep: '',
        bairro: '',
        cidade: '',
        uf: '',
        referencia: '',
        tipo_endereco: '',
        cidades: []
      });
    },
    removerEndereco(index) {
      this.dadosEndereco.splice(index, 1);
    },

    async getListaTipoEndereco() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('pessoas/enderecos/tipo-enderecos', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_tipo_endereco = response.data.results
        })
        .catch(error => {
          console.error(error)
        })
    },

    buscarCepAutomaticamente: debounce(async function (endereco) {
      if (endereco.cep && endereco.cep.length === 9) {
        await this.buscarCep(endereco);
      }
    }, 500),

    async buscarCep(endereco) {
      const cepSemMascara = endereco.cep.replace(/[^0-9]/g, '');
      const token = await this.$store.state.token;

      this.$store.dispatch('fetchURLrequest');
      try {
        const response = await axios.get(`cep/${cepSemMascara}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });
        // console.log('Resposta da busca de CEP:', response.data);
        endereco.logadouro = response.data.logradouro;
        endereco.bairro = response.data.bairro;
        endereco.cidade = response.data.localidade;
        endereco.uf = response.data.uf;
      } catch (error) {
        console.error('Erro ao buscar CEP:', error);
      }
    },

    async buscarCep(endereco) {
      // Fazer a chamada à API de CEP aqui
      // Por exemplo:
      const cepSemMascara = endereco.cep.replace(/[^0-9]/g, '');
      const token = await this.$store.state.token;

      this.$store.dispatch('fetchURLrequest');
      await axios
        .get(`cep/${cepSemMascara}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })
        .then(response => {
          // console.log('Resposta da busca de CEP:', response.data);
          // Processar a resposta da API e atualizar os campos de endereço
          endereco.logadouro = response.data.logradouro;
          endereco.bairro = response.data.bairro;
          endereco.cidade = response.data.localidade;
          endereco.uf = response.data.uf;
        })
        .catch(error => {
          console.error('Erro ao buscar CEP:', error);
        });
    },

  }
};
</script>

<style>
details>summary {
  list-style: none;
}

details summary::-webkit-details-marker {
  display: none;
}
</style>