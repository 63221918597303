<template>


  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Tipos de Interação Online</h1>
      </div>

      <router-link to="/configuracoes-relacionamento" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>
    <div class="flex items-center gap-3 mb-10">

      <div class="flex gap-8">
        <div>
          <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
    text-white hover:text-corBase py-2 px-4" @click="getListaTipoInteracao(1)">Pesquisar
          </button>
        </div>
      </div>
      <div v-if="isadmin === true">
        <button @click="$router.push(`/tipo-interacao/cadastro`)" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
    text-white hover:text-ativo py-2 px-4 ">Adicionar
        </button>
      </div>
    </div>

    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
          <tr>
            <th scope="col" class="p-4">
              <div class="flex items-center">
                <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

                <label class="sr-only">checkbox</label>
              </div>
            </th>
            <th scope="col" class="px-6 py-3">
              #
            </th>
            <th class="px-6 w-full py-3">
              Nome
            </th>
            <th scope="col" class="px-6 py-3">
              Status
            </th>
            <th scope="col" class="px-10 py-3">
              Ações
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(tipo_interacao, index) in lista_tipos_interacao" :key="index" class="escopo-tabela">

            <td class="w-4 p-4">
              <div class="flex items-center">
                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                  v-model="tipo_interacao.selected">
                <label class="sr-only">checkbox</label>
              </div>
            </td>
            <th scope="row" class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
              {{ index + 1 }}
            </th>
            <td class="px-6 py-2 text-xs">
              {{ tipo_interacao.descricao }}
            </td>
            <template v-if="tipo_interacao.ativo === true">
              <td class="px-1 sm:px-6  py-1 sm:py-4 text-center">
                <i class="fa-solid fa-circle-check  text-ativo"></i>
              </td>
            </template>
            <template v-if="tipo_interacao.ativo === false">
              <td class="px-1 sm:px-6  py-1 sm:py-4 text-center">
                <i class="fa-solid fa-circle-xmark text-inativo"></i>
              </td>
            </template>


            <td class="px-6 py-2 text-xs">
              <a @click="selecionarInteracaoAlterar(tipo_interacao)" href="#"
                class="font-medium text-corBase pl-2 hover:underline">
                <i class="fa-solid fa-pen-to-square mr-2"></i>Editar
              </a>
            </td>
          </tr>
        </tbody>
      </table>

      <Pagination v-if="lista_tipos_interacao.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
        @change-page="mudarPagina" />
    </div>
  </div>

  <editar-tipo-interacao-online v-if="exibirModal" :tipo="interacaoSelecionada" @fecharModal="toggleModal"
    @atualizarLista="mudarPagina(paginaAtual)" />
</template>

<script>
import axios from "axios";
import EditarTipoInteracaoOnline from "@/components/midais/Editar/tipo/EditarTipoInteracaoOnline";
import Pagination from "@/utils/Pagination.vue";

export default {
  name: "ListaTipoInteracao",

  components: {
    EditarTipoInteracaoOnline,
    Pagination
  },

  data() {
    return {
      lista_tipos_interacao: [],
      tipo_interacao: '',

      checkAll: false,
      // varival que inicializa o numero de paginas
      paginaAtual: 1,
      countItens: '',

      interacaoSelecionada: [],
      exibirModal: false,

      error: [],
    }
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },

    isadmin() {
      return this.$store.state.isAdminUser
    },
  },


  methods: {

    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },


    async getListaTipoInteracao(pagina) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      this.$store.dispatch("setLoading", true);

      try {
        const response = await axios.get(`pessoas/interacoes/tipo-interacoes/?page=${pagina}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        this.lista_tipos_interacao = response.data.results;
        this.countItens = response.data.count;
        console.log(this.lista_tipos_interacao);

      } catch (error) {
        console.error("Erro ao buscar tipos de interação online:", error);
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    selecionarInteracaoAlterar(interacao){
      this.interacaoSelecionada = interacao
      this.toggleModal()
    },

    toggleModal(){
      this.exibirModal = !this.exibirModal
    },

    mudarPagina(pagina) {
      this.paginaAtual = pagina;
      this.getListaTipoInteracao(pagina);
    },

  }
}
</script>

<style lang="scss"></style>