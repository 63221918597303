<template>


    <div class="container-formulario">
  
      <div class="cabecalho-dashboard">
        <h1>Cadastro de Locais</h1>
  
        <button @click="$router.push(`/cadastros`)" class="text-2xl">
          <i class="fas fa-chevron-left mr-1 "></i>
        </button>
      </div>
  
      <div class="flex flex-wrap">
  
        <!-- <button class="escopo-dashboard-desktop" @click="$router.push(`/local-vao`)">
          <div class="opcao-dashboard">
            <div class="cacebalho-opcao">
              <h2 class="titulo-opcao">Local do Vão</h2>
              <i class="fa-solid fa-person-digging"></i>
            </div>
            <div class="descricao-opcao">
              <p class="mt-6">Cadastre os Locais do Vão presentes em seu sistema!</p>
            </div>
          </div>
        </button> -->
  
        <button @click="$router.push('/local-vidro')" class="escopo-dashboard-desktop">
          <div class="opcao-dashboard">
            <div class="cacebalho-opcao">
              <h2 class="titulo-opcao">Local do Vidro</h2>
              <i class="fa-regular fa-square-full"></i>
            </div>
            <div class="descricao-opcao">
              <p class="mt-6">Cadastre os Locais do Vidro presentes em seu sistema!</p>
            </div>
          </div>
        </button>
  
      </div>

    </div>
  
  
  </template>
  
  <script>
  export default {
    name: "Cadastros",
  }
  </script>
  
  <style lang="scss"></style>