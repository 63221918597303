<template>

  <div class="container-modelo-tabela">
    <table class="container-tabela overflow-auto">
      <thead class="cabecalho-tabela">
        <tr class="border-b">
          <th scope="col" class="px-6 py-2 text-center">#</th>
          <th scope="col" class="px-6 py-2 text-center">
            <div class="flex items-center">
              <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

              <label class="sr-only">checkbox</label>
            </div>
          </th>
          <th scope="col" class="px-3 py-2 text-center">Opções</th>
          <th scope="col" class="px-6 py-2 text-start whitespace-nowrap">Resposta Padrão</th>
          <th scope="col" class="px-12 py-2 text-start">Variável</th>
          <th scope="col" class="px-24 py-2 text-start">Descrição</th>
          <th scope="col" class="px-12 py-2 text-start">Condição</th>
          <th scope="col" class="px-3 py-2 text-start">Minimo</th>
          <th scope="col" class="px-3 py-2 text-start">Máximo</th>
          <th scope="col" class="px-6 py-2 text-start">
            <div class="flex flex-col items-center justify-center">
              <span class="block">Obrigatório</span>
              <input type="checkbox" class="checkbox mt-2" v-model="checkAllObrigatorio" @change="selectAllObrigatorio">
            </div>
          </th>
          <th scope="col" class="px-6 py-2 text-start">
            <div class="flex flex-col items-center justify-center">
              <span class="block whitespace-nowrap">Rel. Orçamento</span>
              <input type="checkbox" class="checkbox mt-2" v-model="checkAllRelOrcamento"
                @change="selectAllRelOrcamento">
            </div>
          </th>
          <th scope="col" class="px-6 py-2 text-start">
            <div class="flex flex-col items-center justify-center">
              <span class="block whitespace-nowrap">Rel. Produção</span>
              <input type="checkbox" class="checkbox mt-2" v-model="checkAllRelProducao" @change="selectAllRelProducao">
            </div>
          </th>
        </tr>
      </thead>
      <draggable :list="lista_variaveis_locais" tag="tbody" handle=".handle" itemKey="id">
        <template v-slot:item="{ element, index }">
          <tr :key="index" class="escopo-tabela">
            <th scope="row" class="px-3 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
              <div class="handle cursor-pointer">
                <span>☰</span> <!-- Handle para arrastar -->
                {{ element.index }}
              </div>
            </th>
            <td class="text-center">
              <div class="selec">
                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                  v-model="element.selected">
                <label class="sr-only">checkbox</label>
              </div>
            </td>
            <td class="px-5 h-10 flex items-center justify-center text-center">
              <i class="fa-solid fa-paperclip cursor-pointer mr-2" @click.prevent="toggleModalOpcoes(element)"></i>
              <i class="fa-solid fa-pen-to-square cursor-pointer" @click.prevent="element.edit = !element.edit"></i>
            </td>
            <td scope="col" class="text-start text-xs">
              <input type="text" class="w-full p-1 text-corBase border border-corBase/40"
                v-model="element.medida_valor">
            </td>
            <td scope="col" class="text-center text-xs" v-if="!element.edit">
              {{ element.variavel }}
            </td>
            <td scope="col" class="text-center text-xs" v-else>
              <input type="text" class="w-full p-1 text-corBase border border-corBase/40" v-model="element.variavel"
                @input="forcarPrefixo(element)" :placeholder="valorPadrao">
            </td>
            <td scope="col" class="text-center text-xs" v-if="!element.edit">
              {{ element.descricao }}
            </td>
            <td scope="col" class="text-center text-xs" v-else>
              <input type="text" class="w-full p-1 text-corBase border border-corBase/40" v-model="element.descricao">
            </td>
            <td scope="col" class="text-start text-xs">
              <input type="text" class="w-full p-1 text-corBase border border-corBase/40" v-model="element.condicao">
            </td>
            <td scope="col" class="text-start text-xs">
              <input type="number" class="w-full p-1 text-corBase border border-corBase/40" v-model="element.valor_min">
            </td>
            <td scope="col" class="text-start text-xs">
              <input type="number" class="w-full p-1 text-corBase border border-corBase/40" v-model="element.valor_max">
            </td>
            <td scope="col" class="text-start text-xs">
              <input type="checkbox" class="w-full p-1 text-corBase border border-corBase/40"
                v-model="element.obrigatorio">
            </td>
            <td scope="col" class="text-start text-xs">
              <input type="checkbox" class="w-full p-1 text-corBase border border-corBase/40" v-model="element.rel_orc">
            </td>
            <td scope="col" class="text-start text-xs">
              <input type="checkbox" class="w-full p-1 text-corBase border border-corBase/40"
                v-model="element.rel_prod">
            </td>

          </tr>
        </template>
      </draggable>

      <tbody>
        <tr v-if="adicionarNovaVariavel" class="escopo-tabela">
          <td class=""></td>
          <td class=""></td>
          <td class=""></td>
          <td class="w-full text-center">
            <input type="number" class="w-full p-1 text-corBase border border-corBase/40"
              v-model="novaVariavel.medida_valor" min="1"
              @input="novaVariavel.medida_valor < 0 ? novaVariavel.medida_valor = '' : novaVariavel.medida_valor = novaVariavel.medida_valor">
          </td>
          <td class="w-full text-center">
            <input type="text" class="w-full p-1 text-corBase border border-corBase/40" v-model="novaVariavel.variavel"
              @input="novaVariavel.variavel = forcarPrefixo($event.target.value)" :placeholder="valorPadrao" />
          </td>
          <td class="w-full text-center">
            <input type="text" class="w-full p-1 text-corBase border border-corBase/40" v-model="novaVariavel.descricao"
              @input="novaVariavel.descricao = novaVariavel.descricao.toUpperCase()">
          </td>
          <td class="w-full text-center">
            <input type="text" class="w-full p-1 text-corBase border border-corBase/40" v-model="novaVariavel.condicao">
          </td>
          <td class="w-full text-center">
            <input type="number" class="w-full p-1 text-corBase border border-corBase/40"
              v-model="novaVariavel.valor_min" min="1"
              @input="novaVariavel.valor_min < 0 ? novaVariavel.valor_min = '' : novaVariavel.valor_min = novaVariavel.valor_min">
          </td>
          <td class="w-full text-center">
            <input type="number" class="w-full p-1 text-corBase border border-corBase/40"
              v-model="novaVariavel.valor_max" min="1"
              @input="novaVariavel.valor_max < 0 ? novaVariavel.valor_max = '' : novaVariavel.valor_max = novaVariavel.valor_max">
          </td>
          <td class="w-full text-center">
            <input type="checkbox" class="w-full p-1 text-corBase border border-corBase/40"
              v-model="novaVariavel.obrigatorio">
          </td>
          <td class="w-full text-center">
            <input type="checkbox" class="w-full p-1 text-corBase border border-corBase/40"
              v-model="novaVariavel.rel_orc">
          </td>
          <td class="w-full text-center">
            <input type="checkbox" class="w-full p-1 text-corBase border border-corBase/40"
              v-model="novaVariavel.rel_prod">
          </td>

        </tr>
      </tbody>
    </table>

    <div class="flex w-full justify-end itens-center mt-6 px-4 mb-5" v-if="adicionarNovaVariavel">
      <button class="btn-cancelar" @click.prevent="toggleVariavel">
        Cancelar
      </button>
      <button class="btn-salvar" @click.prevent="postNovaVariavel">
        Adicionar
      </button>
    </div>


    <div class="flex justify-center" v-else>
      <button class="text-ativo" @click.prevent="adicionarVariavel">
        <i class="fas fa-plus-circle"></i>
      </button>
    </div>
  </div>

  <div class="flex flex-wrap sm:flex-nowrap w-full justify-between items-center mt-6 gap-4">
    <div class="flex flex-wrap sm:flex-nowrap items-center gap-3 w-full sm:w-auto">
      <button class="botoes bg-red-500 w-full sm:w-auto" @click.prevent="verificarExclusao()">
        Excluir Selecionados
      </button>
      <button class="botoes bg-blue-500 w-full sm:w-auto" @click.prevent="importarVariaveisBase">
        Importar Variáveis da Base
      </button>
      <button class="botoes bg-yellow-500 w-full sm:w-auto" @click="importarVariaveisProjeto">
        Importar Variáveis Projeto
      </button>
    </div>

    <input type="file" ref="fileInput" class="hidden" @change="importarPerfis" accept=".csv" />

    <div class="flex items-center w-full sm:w-auto justify-center sm:justify-end">
      <button class="btn-salvar w-full sm:w-auto" @click.prevent="atualizarVariaveis(lista_variaveis_locais)">
        Salvar/Atualizar
      </button>
    </div>
  </div>

  <div v-if="erroModal" class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <div class="bg-white p-6 rounded-lg shadow-lg w-1/3 text-center">
      <p class="text-gray-700">{{ erroMessage }}</p>
      <div class="mt-6">
        <button
          class="bg-red-500 border text-white px-4 py-2 rounded hover:bg-screenCor hover:text-red-500 hover:border-red-500"
          @click="fecharErroModal()">
          Fechar
        </button>
      </div>
    </div>
  </div>

  <ModalOpcoesVariaveis v-if="modal_opcoes" :variavel="variavelAtual" :opcoesVariavel="opcoesVariavel"
    @fechar="fecharModalOpcoes" @getListaOpcoesVariavel="getListaOpcoesVariavel"
    @atualizarOpcoesVariavel="putListaOpcoesVariavel" />

  <MensagemConfirmacaoExclusao mensagem="Deseja mesmo excluir os itens selecionados?" :abrir="modal_confirmacao"
    @fecharModal="fecharModalConfirmacao" @confirmarExclusao="excluirSelecionados" />

  <listagem-erros v-if="exibirErros === true" :erros="erros" @fecharModal="toggleErros()" />

  <ModalVericarDependenciasVariaveis :modal_exibir="modal_exibir_verificacao_dependencias"
    :modal_variavel="modal_variavel" :modal_dependencias="modal_dependencias" @resolvePromise="resolverPromise" />

</template>

<script>
import axios from 'axios'
import ModalOpcoesVariaveis from '@/components/midais/alterar_projeto/ModalOpcoesVariaveis.vue';
import ModalVericarDependenciasVariaveis from '@/components/midais/alterar_projeto/ModalVericarDependenciasVariaveis.vue';
import MensagemConfirmacaoExclusao from '@/components/toasts/toast_notificacao/MensagemConfirmacaoExclusao.vue';
import draggable from 'vuedraggable'
import ListagemErros from '@/components/toasts/toast_erro/ListagemErros.vue';

export default {
  name: "VariaveisProjeto",

  props: ['lista_variaveis_locais', 'projeto_id'],

  emits: ['atualizarVariaveis', 'getListaVariaveisProjeto', 'getListaVariaveisLocais'],

  data() {
    return {
      novasVariaveis: [],
      opcoesVariavel: [],
      adicionarNovaVariavel: false,
      modal_opcoes: false,
      modal_confirmacao: false,
      variavelAtual: {},
      valorPadrao: 'VP',

      novaVariavel: {
        projeto: this.projeto_id,
        valor_min: '',
        valor_max: '',
        variavel: '',
        descricao: '',
        condicao: '',
        obrigatorio: false,
        rel_prod: false,
        rel_orc: false,
        index: this.lista_variaveis_locais.length + 1
      },

      modal_exibir_verificacao_dependencias: null,
      modal_variavel: null,
      modal_dependencias: null,
      modalConfirmacaoResolve: null,

      exibirErros: false,
      erros: {},
      erroModal: false,
      erroMessage: '',

      checkAll: false,
      checkAllObrigatorio: false,
      checkAllRelOrcamento: false,
      checkAllRelProducao: false,
    }
  },

  created() {

  },

  components: {
    ModalOpcoesVariaveis,
    ModalVericarDependenciasVariaveis,
    MensagemConfirmacaoExclusao,
    ListagemErros,
    draggable
  },

  computed: {
    checkSome() {
      return this.lista_variaveis_locais.some(variavel => variavel.selected)
    },

  },

  methods: {

    atualizarVariaveis(variaveisAAtualizar) {
      variaveisAAtualizar.forEach((variavel, index) => {
        variavel.index = index + 1
      })

      this.$emit('atualizarVariaveis', variaveisAAtualizar)
    },

    fechar() {
      this.modal_opcoes = false
    },

    fecharModalConfirmacao() {
      this.modal_confirmacao = false
    },

    selectAll() {
      // Atualiza o estado dos demais checkboxes
      // const checkboxes = document.getElementsByClassName('checkbox');
      // checkboxes.forEach((checkbox) => {
      //   checkbox.checked = this.checkAll;
      // });

      this.lista_variaveis_locais.forEach((variavel) => {
        variavel.selected = this.checkAll;
      });
    },

    selectAllObrigatorio() {
      this.lista_variaveis_locais.forEach((element) => {
        element.obrigatorio = this.checkAllObrigatorio;
      });
    },
    selectAllRelOrcamento() {
      this.lista_variaveis_locais.forEach((element) => {
        element.rel_orc = this.checkAllRelOrcamento;
      });
    },
    selectAllRelProducao() {
      this.lista_variaveis_locais.forEach((element) => {
        element.rel_prod = this.checkAllRelProducao;
      });
    },

    toggleVariavel() {
      this.adicionarNovaVariavel = !this.adicionarNovaVariavel;
      this.limparCampos();
    },

    verificarExclusao() {
      if (this.checkSome || this.checkAll) {
        this.modal_confirmacao = true;
      } else {
        this.erroModal = true;
        this.erroMessage = 'Selecione pelo menos uma Variável para ser excluída.';
      }
    },

    fecharErroModal(){
      this.erroModal = false
    },

    limparCampos() {
      this.novaVariavel.valor_min = ''
      this.novaVariavel.valor_max = ''
      this.novaVariavel.variavel = ''
      this.novaVariavel.descricao = ''
      this.novaVariavel.condicao = ''
      this.novaVariavel.obrigatorio = ''
      this.novaVariavel.rel_prod = ''
      this.novaVariavel.rel_orc = ''
    },


    resolverPromise() {
      if (this.modalConfirmacaoResolve) {
        this.modalConfirmacaoResolve(); // Resolve a Promise
        this.modalConfirmacaoResolve = null; // Limpa o estado
        this.modal_exibir_verificacao_dependencias = false; // Fecha o modal
      }
    },

    async getVariableDepencies(projetoId, variavel) {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get(`projeto/variable-dependencies?projeto_id=${projetoId}&variavel=${variavel}`, {
          headers: {
            Authorization: `Token ${token}`
          }
        })

        return response.data
      } catch (error) {
        console.error(error)
      }
    },

    // async verificarExclusao(listaVariaveisADeletar) {
    //   console.log('lista de variaveis', listaVariaveisADeletar)

    //   for (const variavel of listaVariaveisADeletar) {
    //     console.log('variavel', variavel)
    //     const dependenciasDaVariavel = await this.getVariableDepencies(this.projeto_id, variavel.variavel)

    //     if (dependenciasDaVariavel.perfisProjeto.length > 0 || dependenciasDaVariavel.componentesProjeto.length > 0) {
    //       await this.abrirModalConfirmacao(variavel, dependenciasDaVariavel);
    //     }
    //   }
    // },

    async abrirModalConfirmacao(variavel, dependencias) {
      return new Promise((resolve) => {
        console.log('esta aqui o resolve porr', resolve)
        // Configurar os dados para o modal
        this.modal_variavel = variavel;
        this.modal_dependencias = dependencias;
        this.modal_exibir_verificacao_dependencias = true;

        // Aguardar a interação do usuário
        this.modalConfirmacaoResolve = resolve;
      });
    },

    async excluirSelecionados() {
      if (this.checkAll) {
        // await this.verificarExclusao(this.lista_variaveis_locais)
        await this.deletarVariavel(this.lista_variaveis_locais)
        this.modal_confirmacao = false
        this.checkAll = false
        this.atualizarLista()

        return
      }
      const variaveisADeletar = this.lista_variaveis_locais.filter((variavel) => {
        if (variavel.selected) {
          return variavel
        }
      })

      const variaveisAAtualizar = this.lista_variaveis_locais.filter(variavel =>
        !variaveisADeletar.some(deletar => deletar.id === variavel.id)
      )

      // variaveisADeletar.map( async variavel =>  await this.getVariableDepencies(this.projeto_id, variavel.variavel))

      // await this.getVariableDepencies(this.projeto_id, variaveisADeletar)
      // await this.verificarExclusao(variaveisADeletar)
      await this.deletarVariavel(variaveisADeletar)
      this.modal_confirmacao = false
      this.atualizarVariaveis(variaveisAAtualizar)
    },

    toggleModalOpcoes(variavelSelecionada) {
      this.variavelAtual = variavelSelecionada
      this.getListaOpcoesVariavel()
      this.modal_opcoes = !this.modal_opcoes
    },

    fecharModalOpcoes() {
      this.modal_opcoes = false
    },

    // forcarPrefixo(variavel) {
    //   console.log(variavel)
    //   const prefixo = this.valorPadrao;
    //   if (!variavel.variavel.startsWith(prefixo)) {
    //     variavel.variavel = prefixo + variavel.variavel.slice(prefixo.length);
    //   }
    //   // Converte o restante para maiúsculas
    //   variavel.variavel = variavel.variavel.toUpperCase();
    // },

    forcarPrefixo(valor) {
      const prefixo = this.valorPadrao;

      // Verifica se o valor começa com o prefixo e o ajusta se necessário
      if (!valor.startsWith(prefixo)) {
        return (prefixo + valor.slice(prefixo.length)).toUpperCase();
      }

      // Retorna o valor com o restante convertido para maiúsculas
      return valor.toUpperCase();
    },

    adicionarVariavel() {
      this.adicionarNovaVariavel = !this.adicionarNovaVariavel
    },

    async postNovaVariavel() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');
      this.$store.dispatch("setLoading", true);

      console.log(this.novaVariavel)

      try {

        await axios.post('projeto/variavel-local/', this.novaVariavel, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });

        this.sucesso = true;
        this.sucessoMessage = 'Variável criada com sucesso!';
        setTimeout(() => this.sucesso = false, 3000);

        this.adicionarNovaVariavel = false;
        this.limparCampos();
        this.atualizarLista();
      } catch (error) {
        console.error('Erro ao criar variáveis:', error);
        this.erros = error.response?.data || { geral: ["Ocorreu um erro desconhecido."] };
        this.toggleErros();
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    async deletarVariavel(variaveisADeletar) {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')

        const variaveisADeletarPromisses = []

        for (const variavel of variaveisADeletar) {

          variaveisADeletarPromisses.push(
            axios.patch(`projeto/variavel-local/${variavel.id}/`, { deletado: 'True' }, {
              headers: {
                Authorization: `Token ${token}`
              }
            })
          )
        }

        await Promise.all(variaveisADeletarPromisses)

      } catch (error) {
        console.error(error)
      }
    },

    async putListaOpcoesVariavel(listaOpcoesVariavel) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      const data = listaOpcoesVariavel.map(opcao => {
        opcao.variavel ? opcao.variavel = opcao.variavel.id : ''
        opcao.imagem ? opcao.imagem = opcao.imagem.id : ''

        return opcao
      })

      await axios.put(`projeto/opcao-variavel-local-bulk-update/`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          // console.log(response)
          this.getListaOpcoesVariavel()
          this.sucesso = true
        })
        .catch(error => {
          console.error(error)
        if (error.response && error.response.data) {
          if (typeof error.response.data === 'string' && error.response.data.includes('<!DOCTYPE html>')) {
            this.erros = {
              geral: ["Já existe uma opção com este valor nesta variável."],
            };
          } else if (Array.isArray(error.response.data)) {
            this.erros = { detalhes: error.response.data };
          } else if (typeof error.response.data === 'object') {
            this.erros = error.response.data;
          } else {
            this.erros = { geral: ["Erro desconhecido ao salvar a opção da variável."] };
          }
        } else {
          this.erros = { geral: ["Erro desconhecido ao salvar a opção da variável."] };
        }
        this.toggleErros();
        this.$store.dispatch("setLoading", false);
        })

    },

    async getListaOpcoesVariavel() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')
        this.$store.dispatch("setLoading", true);

        const response = await axios.get(`projeto/opcao-variavel-local/?variavel=${this.variavelAtual.id}`, {
          headers: {
            Authorization: `Token ${token}`
          }
        })

        this.opcoesVariavel = response.data.results
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    toggleErros() {
      this.exibirErros = !this.exibirErros;
    },

    atualizarLista() {
      this.$emit('getListaVariaveisLocais');
    },

    importarVariaveisBase(){
      alert('importar variáveis base')
    },

    importarVariaveisProjeto(){
      alert('importar variáveis projeto')
    }
  }
}
</script>

<style scoped lang="scss">
/* .btn-salvar {
  position: relative;
  transition: opacity 0.3s ease, cursor 0.3s ease;

  &:disabled {
    opacity: 0.6; // Deixa o botão opaco
    cursor: not-allowed; // Muda o cursor para bloqueado

    // Estilização para a mensagem flutuante
    &::after {
      content: 'Preencha todos os campos obrigatórios'; // Mensagem flutuante
      position: absolute;
      top: -35px; // Posição acima do botão
      left: -10%;
      transform: translateX(-50%);
      background-color: rgba(0, 0, 0, 0.75); // Fundo da mensagem
      color: white; // Cor da mensagem
      padding: 5px 10px; // Espaçamento interno
      border-radius: 4px; // Bordas arredondadas
      font-size: 12px; // Tamanho da fonte
      white-space: nowrap;
      opacity: 0; // Inicialmente invisível
      transition: opacity 0.3s ease;
      pointer-events: none; // Previne que a mensagem receba cliques
    }

    &:hover::after {
      opacity: 1; // Torna a mensagem visível ao passar o mouse
    }
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
} */

.botoes {
  @apply px-4 py-1 text-white
}
</style>
