<template>
    <div>
        <div class="fixed inset-0 bg-black/60 flex items-center justify-center z-[10000]">
            <div
                class="bg-white rounded-lg shadow-lg p-6 w-[90%] sm:w-[600px] max-h-[80vh] overflow-y-auto transform transition-transform duration-300 ease-out scale-95 sm:scale-100">
                <!-- Cabeçalho do Modal -->
                <div class="flex justify-between items-center mb-4 border-b pb-2">
                    <h2 class="text-lg font-bold text-corBase">Escolha um Telefone:</h2>
                    <button @click.prevent="$emit('fecharModal')"
                        class="text-gray-500 hover:text-gray-800 p-2 rounded-full transition duration-200 ease-in-out hover:bg-gray-100">
                        <i class="fa-solid fa-x"></i>
                    </button>
                </div>

                <!-- Lista de Telefones -->
                <div>
                    <div v-for="telefone in lista_telefones" :key="telefone.id" class="border-b last:border-none py-3">
                        <!-- {{ telefone }} -->

                        <!-- Dados do Telefone -->
                        <i @click.prevent="selecionarTelefone(telefone)" class="fa-solid fa-check cursor-pointer"></i>
                        <p class="text-base text-gray-700">
                            <span class="font-semibold">Telefone:</span>
                            ({{ telefone.ddd }}) {{ telefone.telefone || 'Sem telefone' }}
                        </p>
                        <div class="text-base text-gray-700 flex">
                            <span class="font-semibold">Whatsapp:</span>
                                <i v-if="telefone.whatsapp" class="fa-solid fa-circle-check  text-ativo ml-1"></i>
                                <i v-else class="fa-solid fa-circle-xmark  text-inativo ml-1"></i>
                        </div>
                        <div class="text-base text-gray-700 flex">
                            <span class="font-semibold">Telegram:</span>
                                <i v-if="telefone.telegram" class="fa-solid fa-circle-check  text-ativo ml-1"></i>
                                <i v-else class="fa-solid fa-circle-xmark  text-inativo ml-1"></i>
                        </div>
                        <p class="text-sm text-gray-500 mt-1">
                            <span class="font-semibold">Tipo de Telefone:</span>
                            {{ telefone.tipo_telefone?.descricao || "Não especificado" }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: "ListarTelefones",
    props: ['pessoa', 'lista_telefones'],
    emits: ['fecharModal', 'telefoneSelecionado'],

    data() {
        return {
            // lista_telefones: []
        }
    },


    created() {
        console.log(this.lista_telefones);
        // this.getTelefones()
    },

    methods: {
        selecionarTelefone(telefone){
            this.$emit('telefoneSelecionado', telefone)
        }
    }
};
</script>

<style scoped></style>