<template>

    <div class="container-formulario">

        <div class="cabecalho-formulario">
        <h1 class="text-xl text-corBase font-bold">Cadastro Pessoa:</h1>
        
        <router-link to="/pessoa" class="text-xl text-corBase font-bold">
          <i class="fas fa-chevron-left"></i>
        </router-link>
      </div>

        <div class="mb-2">
            <span class="text-corBase mr-2">Selecione: </span>
            <v-select label="label" :options="lista_tipos" v-model="tipo" :reduce="tipo => tipo.value" />
        </div>

        <div v-if="tipo == 'fisica'">
            <CadastroPessoaFisica />
        </div>

        <div v-if="tipo == 'juridica'">
            <CadastroPessoaJuridica />
        </div>
    </div>

</template>

<script>
import vSelect from 'vue-select';
import CadastroPessoaFisica from '@/components/tipos_de_cadastros/CadastroPessoaFisica.vue';
import CadastroPessoaJuridica from '@/components/tipos_de_cadastros/CadastroPessoaJuridica.vue';

export default {
    name: 'CadastroPessoa',
    data() {
        return {
            lista_tipos: [
                {label: 'Pessoa Física', value: 'fisica'},
                {label: 'Pessoa Jurídica', value: 'juridica'},
            ],
            tipo: ''
        }
    },

    components: {
        vSelect,
        CadastroPessoaFisica,
        CadastroPessoaJuridica,
    }
}

</script>

<style scoped></style>