<template>

    <!-- formulario para mais de 768 pixels -->
    <section class="">
        <!-- Escopo do formulario de cadastro mobile-->
        <form @submit.prevent="alterarDadosProjeto" enctype="multipart/form-data">
            <div class="w-full mx-auto mt-8">
                <div class="space-y-4">

                    <div>
                        <div class="p-4 flex flex-col border border-corBase/60">

                            <div class="flex gap-5 pb-4">
                                <div class="w-full flex z-10">
                                    <div class="w-4/5">
                                        <label class="text-corBase">Cores de Componente Permitidas</label>
                                        <v-select v-model="tipos_componente" :options="lista_cores_componente" label="text"
                                            multiple class="w-full z-10 bg-white" />
                                    </div>

                                    <div class="w-1/5 flex flex-col text-center items-center justify-center">
                                        <label class="text-corBase">Ocultar</label>
                                        <input type="checkbox" v-model="ocultarComponente" class="w-4 h-4">
                                    </div>
                                </div>
                            </div>

                            <div class="flex gap-5 pb-4">
                                <div class="w-full flex z-5">
                                    <div class="w-4/5">
                                        <label class="text-corBase">Cores de Perfil Permitidas</label>
                                        <v-select v-model="tipos_perfil" :options="lista_cores_perfil" label="text"
                                            multiple class="w-full z-9 bg-white" />
                                    </div>

                                    <div class="w-1/5 flex flex-col text-center items-center justify-center">
                                        <label class="text-corBase">Ocultar</label>
                                        <input type="checkbox" v-model="ocultarPerfil" class="w-4 h-4">
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="flex w-full gap-8 justify-end">
                <button class="btn-enviar" type="submit">Salvar</button>
            </div>
        </form>
    </section>

    <SucessoRapido v-if="sucesso" mensagem="Dados do projeto atualizados com sucesso" />
    <listagem-erros v-if="exibirErros == true" :erros="erros" @fecharModal="toggleErros()" />
</template>


<script>
import axios from "axios";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import ListagemErros from "@/components/toasts/toast_erro/ListagemErros.vue";
import vSelect from "vue-select";

export default {
    name: 'ProjetoDadosComponent',

    components: {
        SucessoRapido,
        ListagemErros,
        vSelect,
    },

    data() {
        return {
            projeto_id: '',

            tipos_perfil: [],
            tipos_componente: [],

            lista_cores_perfil: [],
            lista_cores_componente: [],

            ocultarPerfil: false,
            ocultarComponente: false,

            sucesso: false,

            exibirErros: false,
            erros: {}

        }
    },

    props: ['dadosProjeto'],
    emits: ['atualizarLista'],

    created() {
        this.projeto_id = this.$route.params.id
        this.getListaCoresComponente()
        this.getListaCoresPerfil()
        this.defineVariaveisProjeto()
    },

    watch: {
        sucesso() {
            if (this.sucesso) {
                setTimeout(() => {
                    this.sucesso = false
                }, 2000)
            }
        },

    },

    methods: {

        defineVariaveisProjeto() {
            console.log('---------------------')
            console.log('Dados do projeto:')
            console.log(this.dadosProjeto)
            this.tipos_perfil = this.dadosProjeto.cores_perfil_permitidos?.map(cor_perfil => {
                return { id: cor_perfil.id, text: cor_perfil.nome }
            })
            this.ocultarPerfil = this.dadosProjeto.ocultar_cor_perfil



            this.tipos_componente = this.dadosProjeto.cores_componente_permitidos?.map(cor_componente => {
                return { id: cor_componente.id, text: cor_componente.nome }
            })
            this.ocultarComponente = this.dadosProjeto.ocultar_cor_componente
        },

        async alterarDadosProjeto() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                console.log(this.tipos_componente)
                console.log(this.tipos_perfil)

                const updatedData = {
                    cores_componente_permitidos: this.tipos_componente.map(tipo_componente => (tipo_componente.id)),
                    ocultar_cor_componente: this.ocultarComponente,

                    cores_perfil_permitidos: this.tipos_perfil.map(tipo_perfil => (tipo_perfil.id)),
                    ocultar_cor_perfil: this.ocultarPerfil

                }

                await axios.patch(`projeto/projeto/${this.dadosProjeto.id}/`, updatedData, {
                    headers: {
                        'Authorization': `Token ${token}`
                    },
                })
                this.sucesso = true
                this.$emit('atualizarLista')

            } catch (error) {
                console.error(error)
                if (error.response && error.response.data) {
                    this.erros = error.response.data;
                } else {
                    this.erros = { geral: ["Erro desconhecido ao salvar o projeto."] };
                }
                this.toggleErros();
                this.$store.dispatch("setLoading", false);

            }
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros
        },

        async getListaCoresComponente() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            await axios.get('produtos/cor-componente/?ativo=True&page_size=9999', {
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
                .then(response => {
                    this.lista_cores_componente = response.data.results.map(item => ({
                        id: item.id,
                        text: item.nome
                    }));

                })
                .catch(error => {
                    console.error(error)
                })
        },

        async getListaCoresPerfil() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            await axios.get('produtos/cor-perfil/?ativo=True&page_size=9999', {
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
                .then(response => {
                    this.lista_cores_perfil = response.data.results.map(item => ({
                        id: item.id,
                        text: item.nome
                    }));

                })
                .catch(error => {
                    console.error(error)
                })
        },
    }

}

</script>


<style lang="scss"></style>