<template>
    <div class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div class="bg-white p-8 rounded-lg shadow-lg w-1/3 max-w-lg">
            <div class="border-b mb-4">
                <h2 class="text-2xl font-semibold text-gray-800 text-center">Alterar Vidros</h2>
            </div>

            <div class="space-y-4">
                <div class="w-full flex gap-2">
                    <div class="text-left w-full">
                        <label for="corte" class="block text-sm font-medium text-corBase mb-1">Localização</label>
                        <v-select v-model="localizacao" :options="listaAreasVidro" label="descricao"
                            class="bg-white w-full" placeholder="Escolha uma opção" :reduce="area => area.id" />
                    </div>

                    <div class="text-left w-full">
                        <label for="posicao" class="block text-sm font-medium text-corBase mb-1">Lado</label>
                        <select class="input-campos" v-model="lado">
                            <option v-for="lado in ['A Definir', 'Externo', 'Interno']" :key="lado" :value="lado">
                                {{ lado }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="w-full flex gap-2">
                    <div class="text-left w-full">
                        <label for="fase_esquadria" class="block text-sm font-medium text-corBase mb-1">Furação</label>
                        <select class="input-campos" v-model="furacao">
                            <option v-for="furacao in ['A Definir', 'Sim', 'Não']" :key="furacao" :value="furacao">
                                {{ furacao }}
                            </option>
                        </select>
                    </div>

                    <div class="text-left w-full">
                        <label for="aplicacao" class="block text-sm font-medium text-corBase mb-1">Aplicação</label>
                        <select class="input-campos" v-model="aplicacao">
                            <option v-for="aplicacao in ['A Definir', 'Produção', 'Obra']" :key="aplicacao"
                                :value="aplicacao">
                                {{ aplicacao }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="w-full flex gap-2">
                    <div class="text-left w-full">
                        <label for="fase" class="block text-sm font-medium text-corBase mb-1">Fase</label>
                        <v-select v-model="fase" :options="fasesEsquadria" label="descricao"
                            class="bg-white w-full custom-width rounded-md shadow-sm" :reduce="cor => cor.id"
                            placeholder="Escolha uma opção" />
                    </div>
                </div>

            </div>

            <!-- Botões -->
            <div class="mt-6 flex justify-center space-x-4">
                <button class="btn-cancelar" @click.prevent="$emit('fecharModal')">
                    Fechar
                </button>
                <button class="btn-salvar" @click="salvarAlteracoes()">
                    Salvar Alterações
                </button>
            </div>
        </div>
    </div>

    <SucessoRapido v-if="sucesso === true" mensagem="Vidros atualizados com sucesso!" />
    <ErroRapido v-if="erro === true" mensagem="Ocorreu um erro ao atualizar Vidros." />
</template>

<script>
import axios from 'axios'
import vSelect from 'vue-select';
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue';

export default {
    name: 'AlterarVidros',
    data() {
        return {
            localizacao: '',
            lado: '',
            furacao: '',
            aplicacao: '',
            fase: '',

            sucesso: false,
            erro: false,
        };
    },

    components: {
        vSelect,
        SucessoRapido,
        ErroRapido,
    },

    props: ['listaAreasVidro', 'fasesEsquadria', 'vidros'],

    emits: ['fecharModal', 'atualizarLista'],

    methods: {
        async salvarAlteracoes() {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                console.log(this.vidros)
                const patchPromises = this.vidros.map(vidro => {
                    // Cria um objeto contendo apenas os campos preenchidos
                    const camposAtualizados = {};

                    if (this.localizacao) {
                        camposAtualizados.area_vidro = this.localizacao;
                        console.log(camposAtualizados.area_vidro)
                    }
                    if (this.lado) {
                        camposAtualizados.lado = this.lado;
                        console.log(camposAtualizados.lado)
                    }
                    if (this.furacao) {
                        camposAtualizados.furacao = this.furacao;
                        console.log(camposAtualizados.furacao)
                    }
                    if (this.aplicacao) {
                        camposAtualizados.aplicacao = this.aplicacao;
                        console.log(camposAtualizados.aplicacao)
                    }
                    if (this.fase) {
                        camposAtualizados.fase_esquadria = this.fase;
                        console.log(camposAtualizados.fase_esquadria)
                    }


                    if (Object.keys(camposAtualizados).length === 0) {
                        return Promise.resolve();
                    }

                    return axios.patch(
                        `projeto/projeto-vidro/${vidro.id}/`,
                        camposAtualizados,
                        {
                            headers: {
                                'Authorization': `Token ${token}`,
                            },
                        }
                    );
                });

                // Aguarda todas as requisições serem resolvidas
                await Promise.all(patchPromises);

                // Exibe mensagem de sucesso
                this.sucesso = true;
                setTimeout(() => {
                    this.sucesso = false;
                    this.$emit('fecharModal');
                    this.$emit('atualizarLista');
                }, 3000);
            } catch (error) {
                // Tratamento de erros
                console.error('Erro ao salvar alterações:', error);
                this.erro = true
                setTimeout(() => {
                    this.erro = false;
                }, 3000);
            }
        },

    },
};
</script>

<style scoped>
.input-campos {
    @apply block w-full p-2 border rounded-md text-corBase;
}
</style>