<template>

  <!-- Lista de pessoas até 768 pixels-->

  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Componente</h1>
      </div>

      <router-link to="/produtos" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <div class="w-32 pb-2 ml-2">
      <label class="text-xl text-corBase">Status:</label>
      <v-select v-model="status" class="w-full z-10" :options="lista_status" :reduce="status => status.value"
        label="nome" :clearable="false" />
    </div>

    <div class="mb-4">
      <div v-for="(filtro, index) in filtros" :key="index" class="flex items-center w-full gap-2 mb-2">
        <div class="px-2 w-1/2">
          <select v-model="filtro.campo" @change="atualizarFiltro(index)" class="input-form-chapa">
            <option value="descricao">Descrição</option>
            <option value="descricao_base">Descrição Base</option>
            <option value="tipo">Tipo</option>
            <option value="cor_base">Cor</option>
            <option value="fornecedor">Fornecedor</option>
          </select>
        </div>

        <div class="w-full">

          <v-select v-if="filtro.campo === 'tipo'" v-model="filtro.valor" :options="lista_tipo_componente" label="descricao"
            :reduce="tipo => tipo.id">
          </v-select>

          <v-select v-else-if="filtro.campo === 'cor_base'" v-model="filtro.valor" :options="lista_cor_componente"
            label="descricao" :reduce="cor => cor.id">
          </v-select>

          <v-select v-else-if="filtro.campo === 'fornecedor'" v-model="filtro.valor" :options="lista_fornecedores"
            label="nome_razao" :reduce="fornecedor => fornecedor.id"></v-select>

          <input v-else v-model="filtro.valor" type="text" class="input-form-chapa" placeholder="Insira o valor" />
        </div>

        <i class="fa-solid fa-x cursor-pointer" @click.prevent="removerFiltro(index)"></i>
      </div>

      <div class="flex w-full justify-center">
        <button class="text-ativo" @click.prevent="adicionarFiltro">
          <i class="fas fa-plus-circle"></i>
        </button>
      </div>
    </div>

    <div class="flex items-end gap-2 mb-6">
      <button
        class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase text-white hover:text-corBase py-2 px-4"
        @click.prevent="mudarPagina(1)">
        Pesquisar
      </button>

      <button @click.prevent="alterarFornecedor()"
        class="bg-orange-500 text-white border hover:bg-screenCor hover:text-orange-500 hover:border-orange-500 py-2 px-4">
        Alterar Fornecedor
      </button>

      <button @click.prevent="alterarSelecionados()"
        class="bg-blue-500 text-white border hover:bg-screenCor hover:text-blue-500 hover:border-blue-500 py-2 px-4">
        Alterar Selecionados
      </button>

      <button @click.prevent="toggleImportarComponentes()" class="bg-yellow-500 hover:bg-screenCor border border-screenCor hover:border-yellow-500
  text-white hover:text-yellow-500 py-2 px-4">Importar
      </button>
    </div>

    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
          <tr>
            <th scope="col" class="p-4">
              <div class="flex items-center">
                <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

                <label class="sr-only">checkbox</label>
              </div>
            </th>
            <th scope="col" class="px-6 py-3">
              #
            </th>
            <th class="px-6 py-3">
              Imagem
            </th>
            <th class="px-6 py-3 text-center">
              Código
            </th>
            <th class="px-6 py-3 text-center">
              Descrição
            </th>
            <th class="px-6 py-3 whitespace-nowrap text-center">
              Código Base
            </th>
            <th class="px-6 py-3 whitespace-nowrap text-center">
              Descrição Base
            </th>
            <th class="px-6 py-3 text-center">
              Tipo
            </th>
            <th scope="col" class="px-6 py-3 text-center">
              Cor
            </th>
            <th class="px-6 py-3 text-center">
              Unidade de Medida
            </th>
            <th class="px-6 py-3 text-center">
              Peso (KG)
            </th>
            <th class="px-6 py-3 text-center">
              Fornecedor
            </th>
            <th scope="col" class="px-6 py-3 text-center">
              $ Custo
            </th>
            <th scope="col" class="px-6 py-3 text-center">
              $ Custo Médio
            </th>
            <th scope="col" class="px-6 py-3 text-center">
              Itens da Embalagem
            </th>
            <th scope="col" class="px-6 py-3 text-center">
              Unidade Medida Embalagem
            </th>
            <th scope="col" class="px-6 py-3 text-center">
              Status
            </th>
            <th scope="col" class="px-6 py-3">
              Editar
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(componente, index) in lista_componentes" :key="index" class="escopo-tabela">
            <td class="w-4 p-4">
              <div class="flex items-center">
                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                  v-model="componente.selected">
                <label class="sr-only">checkbox</label>
              </div>
            </td>
            <th scope="row" class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
              {{ index + 1 }}
            </th>
            <td class="px-6 py-2 text-xs">
              <button v-if="componente.imagem && componente.imagem.url_documento"
                @click.prevent="openPreviewImage(componente.imagem.url_documento)">
                <img class="w-12" :src="componente.imagem.url_documento" :alt="componente.imagem.descricao" />
              </button>
              <img v-else class="w-12"
                src="https://managerglass.s3.amazonaws.com/media/public/b7144d8c-4a5c-4c56-a9bd-924275d6c55d.png"
                alt="Sem foto">
            </td>
            <td class="px-6 py-2 text-xs text-center">
              {{ componente.codigo || '' }}
            </td>
            <td class="px-6 py-2 text-xs text-center">
              {{ componente.descricao }}
            </td>
            <td class="px-6 py-2 text-xs text-center">
              {{ componente.componente_base?.codigo_base }}
            </td>
            <td class="px-6 py-2 text-xs text-center">
              {{ componente.componente_base?.nome_base }}
            </td>
            <td class="px-6 py-2 text-xs text-center">
              {{ componente.componente_base?.produto?.descricao || '-' }}
            </td>
            <td class="px-6 py-2 text-xs text-center">
              <!-- {{ componente.componente_base?.cor_base?.descricao || '-' }} -->
              {{ componente.cor_componentes?.descricao || '-' }}
            </td>
            <td class="px-6 py-2 text-xs text-center">
              {{ componente.componente_base?.unidade_medida?.nome || '-' }}
            </td>
            <td class="px-6 py-2 text-xs text-center">
              {{ componente.componente_base?.peso_base || '-' }}
            </td>
            <td class="px-6 py-2 text-xs text-center whitespace-nowrap">
              {{ componente.fornecedor?.nome_razao || '-' }}
            </td>
            <td class="px-6 py-2 text-xs text-center">
              {{ componente.custo || '-' }}
            </td>
            <td class="px-6 py-2 text-xs text-center">
              {{ componente.custo_medio || '-' }}
            </td>
            <td class="px-6 py-2 text-xs text-center">
              {{ componente.itens_embalagem || '-' }}
            </td>
            <td class="px-6 py-2 text-xs text-center">
              {{ componente.unidade_medida_embalagem?.nome || '-' }}
            </td>
            <td class="px-6 py-2 text-center">
              <div class="flex flex-col items-center">
                <!-- Ícone com condicional -->
                <i v-if="componente.ativo" class="fa-solid fa-circle-check text-ativo mb-1" title="Ativo"></i>
                <i v-else class="fa-solid fa-circle-xmark text-inativo mb-1"
                  :title="replaceSiglaMotivoDesativado(componente.motivo_desativado)"></i>
                <!-- Texto pequeno abaixo do ícone -->
                <span v-if="!componente.ativo" class="text-xs text-gray-500">
                  {{ replaceSiglaMotivoDesativado(componente.motivo_desativado) }}
                </span>
              </div>
            </td>

            <td class="px-6 py-2 text-xs">
              <a @click="selecionarComponenteEditar(componente.id)" href="#"
                class="font-medium text-corBase hover:underline">
                <i class="fa-solid fa-pen-to-square mr-2"></i>Editar
              </a>
            </td>

          </tr>
        </tbody>
      </table>
      <Pagination v-if="lista_componentes.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
        @change-page="mudarPagina" />

      <editar-componente-acessorio v-if="exibirModal" :dadosComponente="componenteSelecionado"
        @fecharModal="toggleModal"></editar-componente-acessorio>

      <PreviewImage v-if="preview_imagem" @closed="closed_preview_image" :imagem="preview_imagem" />

      <ModalImportacaoComponente v-if="modalImportarComponentes" @fecharModal="toggleImportarComponentes"
        @atualizarLista="mudarPagina(paginaAtual)" />
      <!-- :lista_tipo_produto="lista_tipo_Componente" :lista_cores="lista_cor_Componente"
    :lista_espessuras="lista_espessura" -->
    </div>
  </div>

</template>

<script>
import axios from "axios";
import EditarComponenteAcessorio from "@/components/midais/Editar/produtos/EditarComponenteAcessorio";
import ModalImportacaoComponente from "./ModalImportacaoComponente.vue";
import PreviewImage from "@/components/midais/ged/PreviewImage.vue";
import vSelect from "vue-select";
import Pagination from "@/utils/Pagination.vue";

export default {
  name: "ListaComponentes",

  components: {
    EditarComponenteAcessorio,
    ModalImportacaoComponente,
    PreviewImage,
    vSelect,
    Pagination
  },

  data() {
    return {

      filtros: [{ campo: "descricao", valor: "" }],

      lista_status: [
        { value: 'True', nome: "Ativo" },
        { value: 'False', nome: "Inativo" },
      ],
      lista_cor_componente: [],
      lista_tipo_componente: [],
      lista_fornecedores: [],

      status: 'True',
      checkAll: false,
      // lista usada para salvar o retorno da requisição do get
      listaPessoas: [],
      // varival que inicializa o numero de paginas
      paginaAtual: 1,
      countItens: '',

      preview_imagem: '',

      componenteSelecionado: null,
      exibirModal: false,

      modalImportarComponentes: false,

      // filtros
      lista_cor_componente: [],
      cor_componente: '',
      lista_tipo_componente: [],
      tipo_componente: '',
      lista_grupo_aplicacao_componente: [],
      grupo_aplicacao_componente: '',
      produto_base: '',


      lista_componentes: [],

      error: []
    }
  },

  created() {
    this.getListaCorComponente()
    this.getListaTipoComponente()
    this.getListaFornecedores()
  },

  computed: {
    isadmin() {
      return this.$store.state.isAdminUser
    },

    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
  },

  methods: {

    atualizarFiltro(index) {
      this.filtros[index].valor = null;
    },

    adicionarFiltro() {
      this.filtros.push({ campo: "", valor: "" });
    },
    removerFiltro(index) {
      this.filtros.splice(index, 1);
    },

    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });

      this.lista_componente.forEach((componente) => {
        componente.selected = this.checkAll;
      });
    },

    async getListaComponente(pagina) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      this.$store.dispatch("setLoading", true); // Ativar o estado de loading

      try {

        const params = this.filtros
          .filter((filtro) => filtro.campo && filtro.valor)
          .map((filtro) => `${filtro.campo}=${encodeURIComponent(filtro.valor)}`)
          .join("&");

        const response = await axios.get(`produtos/componente-acessorio/?${params}&ativo=${this.status}&page=${pagina}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        this.lista_componentes = response.data.results;
        this.countItens = response.data.count
        console.log(response.data.results);

      } catch (error) {
        console.error("Erro ao buscar componentes:", error);
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    mudarPagina(pagina) {
      this.paginaAtual = pagina;
      this.getListaComponente(pagina);
    },

    selecionarComponenteEditar(componente) {
      this.componenteSelecionado = componente
      this.toggleModal()
    },

    toggleImportarComponentes() {
      this.modalImportarComponentes = !this.modalImportarComponentes
    },

    toggleModal() {
      this.exibirModal = !this.exibirModal
    },

    // Requisição para obter a cor do componente
    async getListaCorComponente() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      try {
        const response = await axios.get('produtos/cor-produto-base/?tipo_produto=Componente&ativo=True&page_size=9999', {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        // console.log(response.data.results);
        this.lista_cor_componente = response.data.results.map(item => ({
          id: item.id,
          descricao: item.descricao
        }));

      } catch (error) {
        console.error("Erro ao buscar lista de cores de componente:", error);
      }
    },

    // Requisição para obter o tipo de componente
    async getListaTipoComponente() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      try {
        const response = await axios.get('produtos/tipo-produto-base/?produto=Componente&ativo=True&page_size=9999', {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        // console.log(response.data.results);
        this.lista_tipo_componente = response.data.results.map(item => ({
          id: item.id,
          descricao: item.descricao
        }));

      } catch (error) {
        console.error("Erro ao buscar lista de tipos de componente:", error);
      }
    },

    // Requisição para obter os fornecedores
    async getListaFornecedores() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      try {
        const response = await axios.get(`pessoa/pessoas-fisica-juridica/?produto_fornecedor=Componente&atividade=Fornecedor&page=1`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        this.lista_fornecedores = response.data.results.map(fornecedor => {
          return {
            id: fornecedor.pessoa.id,
            nome_razao: fornecedor.nome || fornecedor.razao_social,
          }
        })
        this.getSemFornecedor()
      } catch (error) {
        console.error(error)
      }
    },

    async getSemFornecedor() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')
      try {
        const nome_razao = encodeURIComponent('Sem Fornecedor')
        const response = await axios.get(`pessoas/?nome_razao=${nome_razao}&atividade=Fornecedor`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        const semFornecedor = response.data.results[0];
        if (semFornecedor) {
          // Adicionar "Sem Fornecedor" à lista de fornecedores
          this.lista_fornecedores.unshift({
            id: semFornecedor.id,
            nome_razao: semFornecedor.nome_razao
          });
        }
      } catch (error) {
        console.error(error)
      }
    },

    closed_preview_image() {
      this.preview_imagem = ''
    },

    openPreviewImage(url) {
      this.preview_imagem = url
    },
  }
}
</script>

<style lang="scss"></style>