<template>
    <details>
        <summary class="mt-4">
            <h2 class="bg-screenCor hover:bg-screenCor border border-corBase hover:border-corBase
  text-corBase hover:text-corBase py-2 px-4 ">Fornecedor
            </h2>
        </summary>


        <div class="border border-gray-200 p-2 mt-3">
            <div class="w-full flex flex-col items-center justify-center text-center">
                <label class="text-base text-corBase">Produtos:</label>
                <v-select :options="lista_produtos" v-model="dadosFornecedor.produtos_fornecedor" :multiple="true" label="nome"
                    :reduce="produto => produto.id" class="w-full" />
                <!-- Funções: {{ dadosFornecedor.produtos_fornecedor }} -->
            </div>
        </div>

    </details>


</template>

<script>
import axios from 'axios'
import vSelect from 'vue-select';
export default {
    name: 'FornecedoresCampos',
    props: ['dadosFornecedor'],
    data() {
        return {
            lista_produtos: [],
        }
    },

    components: {
        vSelect,
    },

    created() {
        this.getListaProdutos()
    },

    methods: {
        async getListaProdutos() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            try {
                const response = await axios.get(`produtos/produtos-sistema/?ativo=True&page_size=9999`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                this.lista_produtos = response.data.results
                // console.log(this.lista_produtos)
            } catch (error) {
                console.error(error)
            }
        },


    }
}
</script>

<style scoped></style>