<template>

  <div class="container-formulario">
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Importe Produtos Base</h1>
      </div>

      <router-link to="/produtos/produto-base" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>

    <div class="flex flex-col md:flex-row border w-full gap-3 p-2">
      <div class="w-44">
        <label>Produto</label>
        <v-select label="nome" :options="lista_tipo_produto" v-model="produto" @input="getTipoProdutoBase" />
      </div>
      <div class="w-44">
        <label>Tipo Produto</label>
        <v-select label="descricao" :options="lista_tipo_produto_base" v-model="tipo_produto"
          @input="getProdutoTipoBase" />
      </div>
      <div class="w-44">
        <label>Linha</label>
        <v-select label="nome" :options="Lista_linha_produto_base" v-model="linha_produto" @input="getListaLinha" />
      </div>
      <div class="w-44">
        <label>Cor</label>
        <v-select label="descricao" :options="lista_cor_produto_base" v-model="cor_base" @input="getListaCor" />
      </div>
    </div>

    <div class=" flex w-full justify-between py-2">
      <button @click.prevent="getCsvProdutosBase" class="btn-enviar">Gerar CSV</button>
    </div>
    <input type="file" @change="handleFileUpload">


    <div class=" flex w-full justify-between py-2">
      <button @click.prevent="gerarCSVLimpo" class="btn-enviar">Gerar CSV Limpo</button>
    </div>
    <input type="file" ref="fileInput" class="bg-red-500" @change="importarProdutosBase" accept=".csv" />

    <div class="mt-8">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
          <tr>
            <th class="px-6 py-3">
              COD. Base
            </th>
            <th class="px-6 py-3">
              Nome Base
            </th>
            <th class="px-6 py-3">
              Peso Base
            </th>
            <th class="px-6 py-3">
              Unidade Medida
            </th>
            <th class="px-6 py-3">
              Cor
            </th>
            <th class="px-6 py-3">
              Linha
            </th>
            <th class="px-6 py-3">
              Produto
            </th>
            <th class="px-6 py-3">
              Tipo Produto
            </th>
          </tr>
        </thead>
        <!-- {{ csv }} -->
        <tbody>
          <tr v-for="(produto, index) in csv" :key="index" class="escopo-tabela">
            PRODUTO: {{ produto }}
            <td class="px-6 py-2 text-xs">
              {{ produto.codigo }}
            </td>
            <td class="px-6 py-2 text-xs">
              {{ produto.descricao }}
            </td>
            <td class="px-6 py-2 text-xs text-center">
              {{ produto.peso }}
            </td>
            <td class="px-6 py-2 text-xs text-center">
              {{ produto.unidade_medida }}
            </td>
            <td class="px-6 py-2 text-xs text-center">
              {{ produto.cor }}
            </td>
            <td class="px-6 py-2 text-xs text-center">
              {{ produto.linha }}
            </td>
            <td class="px-6 py-2 text-xs text-center">
              {{ produto.produto }}
            </td>
            <td class="px-6 py-2 text-xs text-center">
              {{ produto.tipo }}
            </td>

          </tr>
        </tbody>
      </table>

      <button @click.prevent="postListProdutosInventario(csv)">Salvar</button>
    </div>


  </div>

  <mensagem-sucesso v-if="sucesso == true" :mensagem="'Importação realizada com Sucesso'"
    :rotas="'/produtos/produto-base'" />

  <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros" />

</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";
import ListagemErros from "@/components/toasts/toast_erro/ListagemErros.vue";

export default {
  name: "ImporteProdutoBase",

  components: {
    vSelect,
    MensagemSucesso,
    ListagemErros
  },

  data() {
    return {
      lista_tipo_produto: [],
      lista_tipo_produto_base: [],
      Lista_linha_produto_base: [],
      lista_cor_produto_base: [],
      produto: '',
      tipo_produto: '',
      linha_produto: '',
      cor_base: '',
      produtos: [],
      csv: [],
      inventario: null,
      sucesso: false,

      novoProduto: [],
      exibirErros: false,
      erros: [],
    }
  },

  created() {
    this.getListaCor()
    this.getTipoProdutoBase();
    this.getProdutoTipoBase();
    this.getListaLinha();
  },

  methods: {

    // async handleFileUpload(event) {
    //   const file = event.target.files[0];
    //   const reader = new FileReader();

    //   // Evento de conclusão de leitura do arquivo
    //   reader.onload = async (e) => {
    //     try {
    //       // Processar os dados do CSV
    //       const csvData = [];

    //       // Dividir o conteúdo do CSV em linhas
    //       const lines = e.target.result.split('\n');

    //       // Iterar sobre as linhas, começando da segunda linha (índice 1)
    //       for (let i = 1; i < lines.length - 1; i++) {
    //         // Dividir cada linha em colunas usando a vírgula como delimitador
    //         const line = lines[i].trim()
    //         const columns = line.split(',');

    //         // Verificar se há colunas suficientes
    //         if (columns.length) {
    //           // Criar o objeto com as propriedades necessárias
    //           const csvObject = {
    //             codigo_base: columns[0],
    //             nome_base: columns[1],
    //             peso_base: parseFloat(columns[2]),
    //             unidade_medida: columns[3],
    //             cor_base: columns[4],
    //             linha: columns[5],
    //             produto: columns[6],
    //             tipo_produto: columns[7]
    //           };

    //           // Adicionar o objeto ao array csvData
    //           csvData.push(csvObject);
    //         }
    //       }

    //       // Atualize this.csv com os dados do CSV processado
    //       this.csv = csvData;

    //       // Exemplo de como usar os dados processados:
    //       console.log(this.csv);
    //     } catch (error) {
    //       console.error('Erro ao processar o arquivo CSV:', error);
    //     }
    //   };

    //   // Ler o conteúdo do arquivo como texto
    //   reader.readAsText(file);
    // },

    gerarCSVLimpo() {
      const headers = [
        "Produto",
        "Código",
        "Descrição",
        "Tipo",
        "Cor",
        "Espessura",
        "Unidade Medida",
        "Peso",
        "Linha"
      ];

      // Criação do conteúdo do CSV com os cabeçalhos
      const csvContent = headers.join(",") + "\n";

      // Criar um blob para download
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

      // Criar o link de download
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "dados_limpo.csv");
      document.body.appendChild(link);

      // Iniciar o download e limpar o link criado
      link.click();
      document.body.removeChild(link);
    },

    importarProdutosBase(event) {
      const file = event.target.files[0];
      if (!file) return;

      const reader = new FileReader();
      reader.onload = async (e) => {
        const content = e.target.result;
        const rows = content
          .split("\n")
          .filter((row) => row.trim() !== "")
          .map((row) => row.split(","));

        // Remova o cabeçalho (primeira linha)
        rows.shift();

        this.erros = { erros: [] };

        // Converte os dados em objetos e realiza as buscas
        for (const [index, row] of rows.entries()) {
          const produto = {
            produto: row[0]?.trim(),
            codigo: row[1]?.trim(),
            descricao: row[2]?.trim(),
            tipo: row[3]?.trim(),
            cor: row[4]?.trim(),
            espessura: row[5]?.trim(),
            unidade_medida: row[6]?.trim(),
            peso: row[7]?.trim(),
            linha: row[8]?.trim(),
          };

          console.log(produto)

          const camposFaltando = [];
          if (!produto.produto) camposFaltando.push("Produto");
          if (!produto.descricao) camposFaltando.push("Descrição");
          if (!produto.codigo) camposFaltando.push("Código");

          console.log(camposFaltando)

          if (camposFaltando.length > 0) {
            this.erros.erros.push(`
              Linha ${index + 2}: Campos obrigatórios ausentes: ${camposFaltando.join(", ")}`
            );
            continue;
          }

          this.$store.dispatch("setLoading", true);
          this.csv = produto
          console.log('this.csv')
          console.log(this.csv)
          try {
            // Buscar ID do Produto (Vidro, Perfil, etc)
            const produtoResponse = await this.getIDProduto(produto.produto);
            produto.produto = produtoResponse?.data?.results[0]?.id || null;
            console.log('PRODUTO')
            console.log(produto.produto)

            // Buscar ID do Tipo (Laminado, etc)
            if (produto.tipo) {
              const tipoResponse = await this.getIDTipo(produto.tipo);
              produto.tipo = tipoResponse?.data?.results[0]?.id || null;
              console.log('TIPO PRODUTO')
              console.log(produto.tipo)
            } else {
              produto.tipo = null;
            }

            // Buscar ID da Cor
            if (produto.cor) {
              const corResponse = await this.getIDCor(produto.cor);
              produto.cor = corResponse?.data?.results[0]?.id || null;
              console.log('COR PRODUTO')
              console.log(produto.cor)
            } else {
              produto.cor = null;
            }

            if (produto.unidade_medida) {
              const unidadeMedidaResponse = await this.getIDUnidadeMedida(produto.unidade_medida);
              produto.unidade_medida = unidadeMedidaResponse?.data?.results[0]?.id || null;
              console.log('UNIDADE MEDIDA PRODUTO')
              console.log(produto.unidade_medida)
            } else {
              produto.unidade_medida = null;
            }

            console.log("Produto Processado:", produto);

            // Configurar this.novoPerfil
            this.novoProduto = {
              tipo_produto: produto.produto,
              codigo_base: produto.codigo,
              nome_base: produto.descricao,
              produto: produto.tipo,
              cor_base: produto.cor,
              espessura: produto.espessura,
              unidade_medida: produto.unidade_medida,
              peso_base: produto.peso,
              linha: produto.linha || [],
            };

            // this.csv = this.novoProduto
            // console.log(this.csv)

            // Executar postNovoProduto
            await this.postNovoProduto();
          } catch (error) {
            console.error(`Erro ao processar o produto na linha ${index + 2}:, error`);
            this.erros.erros.push(`Linha ${index + 2}: Erro ao buscar dados para o produto.`);
          } finally {
            this.$store.dispatch("setLoading", false);
          }
        }

        if (this.erros.erros.length > 0) {
          console.error("Erro(s) encontrado(s):", this.erros);
          this.exibirErros = true;
        } else {
          alert('sucesso')
        }
      };

      this.$refs.fileInput.value = "";
      reader.readAsText(file);
    },

    async getIDProduto(nome) {
      const token = await this.$store.dispatch("getAuthTokenCookie");
      await this.$store.dispatch("fetchURLrequest");

      return axios.get(`produtos/produtos-sistema/?nome=${nome}`, {
        headers: { Authorization: `Token ${token}` }
      });
    },

    async getIDTipo(descricao) {
      const token = await this.$store.dispatch("getAuthTokenCookie");
      await this.$store.dispatch("fetchURLrequest");

      return axios.get(`produtos/tipo-produto-base/?descricao=${descricao}`, {
        headers: { Authorization: `Token ${token}` }
      });
    },

    async getIDCor(descricao) {
      const token = await this.$store.dispatch("getAuthTokenCookie");
      await this.$store.dispatch("fetchURLrequest");

      return axios.get(`produtos/cor-produto-base/?descricao=${descricao}`, {
        headers: { Authorization: `Token ${token}` }
      });
    },

    async getIDUnidadeMedida(nome) {
      const token = await this.$store.dispatch("getAuthTokenCookie");
      await this.$store.dispatch("fetchURLrequest");

      return axios.get(`produtos/unidade-medida/?nome=${nome}`, {
        headers: { Authorization: `Token ${token}` }
      });
    },

    async postNovoProduto() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');
        this.$store.dispatch("setLoading", true);

        await axios.post('produtos/produto/', this.novoProduto, {
          headers: { Authorization: `Token ${token}` }
        });

        console.log('produto criado')
      } catch (error) {
        console.error(error);
        this.erros = error.response?.data || { geral: ["Ocorreu um erro desconhecido."] };
        this.toggleErros();
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    toggleErros(){
      this.exibirErros = !this.exibirErros
    },


    async getCsvProdutosBase() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      const response = await axios.get(`produtos/gerar-csv-produto-base?cor_base_id=${this.cor_base?.id || ''}&linha_id=${this.linha_produto?.id || ''}&produto_id=${this.tipo_produto?.id || ''}&tipo_id=${this.produto?.id || ''}`, {
        headers: {
          'Authorization': `Token ${token}`
        },
        responseType: 'blob' // Define o tipo de resposta como blob (Binary Large OBject)
      });
      console.log(response.data)
      // Cria um URL temporário para o arquivo blob
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Cria um link <a> para iniciar o download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'produtos-base.csv'); // Define o nome do arquivo
      document.body.appendChild(link);

      // Simula um clique no link para iniciar o download
      link.click();

      // Remove o link após o download
      document.body.removeChild(link);
    },

    async postListProdutosInventario(data) {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');

        const requestData = {
          produtos: data
        };

        const result = await axios.post('produtos/importar-produto-base', requestData, {
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'  // Definindo o tipo de conteúdo como JSON
          }
        });
        this.sucesso = true

        console.log(result);
      } catch (error) {
        console.error('Erro ao enviar requisição:', error);
      }
    },

    async getTipoProdutoBase() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/produtos-sistema', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          console.log(response.data)
          this.lista_tipo_produto = response.data.results
        })
    },

    async getProdutoTipoBase() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/tipo-produto-base', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          console.log(response.data)
          this.lista_tipo_produto_base = response.data.results
        })
    },

    async getListaLinha() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      try {
        const result = await axios.get(`produtos/linha-produto-base/`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })
        this.Lista_linha_produto_base = result.data.results
        console.log('Linha Produto Base')
        console.log(this.Lista_linha_produto_base)
      } catch (err) {
        console.error(err)
      }
    },

    async getListaCor() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      try {
        const result = await axios.get(`produtos/cor-produto-base/`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })
        this.lista_cor_produto_base = result.data.results
      } catch (err) {
        console.error(err)
      }
    },

  }
}
</script>


<style lang="scss"></style>
