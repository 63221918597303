<template>
    <div class="flex flex-wrap gap-4">

        <!-- Inputs à esquerda -->
        <div class="flex flex-col gap-4 w-[60%]">
            <div class="form-group">
                <label for="descricao">Descrição da Obra:</label>
                <input v-model="obra.descricao" type="text" id="descricao" placeholder="Digite aqui..."
                    class="input-campos w-full" />
            </div>

            <div class="form-group">
                <label for="cno">CNO:</label>
                <input v-model="obra.cno" type="text" id="cno" placeholder="Digite aqui..."
                    class="input-campos w-full" />
            </div>

            <div class="form-group">
                <label for="status">Status da Obra:</label>
                <v-select v-model="obra.status_obra" :options="statusOptions" label="label"
                    placeholder="Selecione o status" :reduce="status_obra => status_obra.value" class="w-full" />
            </div>

            <div>
                <div class="flex space-x-4">

                    <div class="w-1/2">
                        <label for="status">Responsável Técnico:</label>
                        <v-select v-model="obra.responsavel_tecnico" :options="listaPessoas" label="nome_razao"
                            class="w-full" />
                    </div>

                    <div class="w-1/2">
                        <label for="status">Contato Responsável Técnico:</label>
                        <input v-model="obra.contato_responsavel_tecnico" type="text" maxlength="15"
                            v-mask="'(##) #####-####'" class="w-full" />
                    </div>

                </div>

                <div class="flex space-x-4 mt-4">
                    <!-- Campo para selecionar o medidor -->
                    <div class="w-1/2">
                        <label>Medidor:</label>
                        <v-select v-model="novoMedidor.pessoa" :options="listaPessoas" label="nome_razao"
                            class="w-full" />
                    </div>

                    <!-- Campo para contato do medidor -->
                    <div class="w-1/2">
                        <label>Contato:</label>
                        <input v-model="novoMedidor.contato" type="text" maxlength="15" v-mask="'(##) #####-####'"
                            class="w-full" />
                    </div>
                </div>

                <!-- Botão para adicionar medidor -->
                <div class="flex justify-center mt-2">
                    <button class="text-ativo" @click.prevent="adicionarMedidor">
                        <i class="fa-solid fa-square-check text-2xl"></i>
                    </button>
                </div>

                <!-- Lista de medidores adicionados -->
                <div class="mt-4" v-if="obra.medidores?.length > 0">
                    <p class="font-semibold text-gray-700 mb-2">Medidores Adicionados:</p>
                    <ul class="space-y-2">
                        <li v-for="(medidor, index) in obra.medidores" :key="index"
                            class="flex items-center justify-between bg-white p-3 rounded-md border border-gray-200 shadow-sm">
                            <span class="text-gray-700 font-medium">
                                {{ medidor.pessoa?.nome_razao || medidor.medidor?.nome_razao }} - {{ medidor.contato }}
                            </span>
                            <button @click.prevent="removerMedidor(index)" class="text-red-500">
                                Remover
                            </button>
                        </li>
                    </ul>
                </div>

            </div>

        </div>

        <!-- Box de imagem à direita -->
        <div class="w-[35%] flex flex-col items-center justify-start">
            <div class="form-group w-full">
                <!-- Campo para capturar com a câmera -->
                <input type="file" id="cameraInput" ref="cameraInput" accept="image/*" capture="environment"
                    class="hidden" @change="processarImagem" />

                <!-- Campo para selecionar imagem da galeria -->
                <input type="file" id="galeriaInput" ref="galeriaInput" accept="image/*" class="hidden"
                    @change="processarImagem" />

                <!-- Título -->
                <label class="text-corBase text-xl font-bold">Imagem:</label>

                <!-- Pré-visualização da imagem -->
                <div @click.prevent="openPreviewImage(obra.imagem)" class="previewImagemObra"
                    :style="{ 'background-image': `url(${obra.imagem})` }">
                </div>

                <!-- Botões para câmera ou galeria -->
                <div class="flex gap-3 mt-4">
                    <div class="selecionar-arquivo flex justify-center">
                        <div>
                            <button @click.prevent="abrirCamera">
                                <i class="fa-solid fa-camera fa-xl"></i>
                                <p>Capturar com a Câmera</p>
                            </button>
                        </div>
                    </div>
                    <div class="selecionar-arquivo flex justify-center">
                        <div>
                            <button @click.prevent="abrirGaleria">
                                <i class="fa-solid fa-photo-film fa-xl"></i>
                                <p>Selecionar da Galeria</p>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <Galeria v-if="galeria === true" @fechar_galeria="abrir_galeria" @objetoClicado="atualizarFileInput" />
    <PreviewImage v-if="preview_imagem" @closed="closed_preview_image" :imagem="preview_imagem" />
    <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros" />
</template>



<script>
import axios from 'axios'
import vSelect from "vue-select";
import Galeria from '@/components/midais/ged/Galeria.vue';
import PreviewImage from "@/components/midais/ged/PreviewImage.vue";
import ListagemErros from '@/components/toasts/toast_erro/ListagemErros.vue';

export default {
    name: 'Obra',
    components: {
        vSelect,
        Galeria,
        PreviewImage,
        ListagemErros,
    },
    props: {
        obra: Object
    },
    data() {
        return {
            statusOptions: [
                { value: 'PE', label: 'Pendente' },
                { value: 'CA', label: 'Cancelado' },
                { value: 'PL', label: 'Planejamento' },
                { value: 'CR', label: 'Construção Inicial' },
                { value: 'AC', label: 'Acabamento' },
                { value: 'RF', label: 'Reforma' },
                { value: 'DM', label: 'Demolição' },
                { value: 'OR', label: 'Orçado' },
                { value: 'EX', label: 'Execução' },
                { value: 'CO', label: 'Concluído' },
            ],
            listaPessoas: [],

            // medidores: [],
            novoMedidor: {
                pessoa: null,
                contato: '',
            },

            exibirErros: false,
            erros: [],

            galeria: false,
            preview_imagem: null,
        };
    },

    watch: {
        'obra.responsavel_tecnico': {
            async handler(newPessoa) {
                if (newPessoa?.id) { // Só busca se o contato estiver vazio
                    try {
                        const telefone = await this.buscarTelefonePessoa(newPessoa.id);
                        if (telefone) {
                            this.obra.contato_responsavel_tecnico = `(${telefone.ddd}) ${telefone.telefone}`;
                        } else {
                            this.obra.contato_responsavel_tecnico = '';
                        }
                    } catch (error) {
                        console.error(`Erro ao buscar telefone para pessoa com ID ${newPessoa.id}:`, error);
                    }
                }
            },
            immediate: true,
            deep: true,
        },

        'novoMedidor.pessoa': {
            immediate: true,
            async handler(newPessoa) {
                if (newPessoa?.id) {
                    try {
                        const telefone = await this.buscarTelefonePessoa(newPessoa.id);
                        if (telefone) {
                            this.novoMedidor.contato = `(${telefone.ddd}) ${telefone.telefone}`;
                        } else {
                            this.novoMedidor.contato = '';
                            this.erros = { medidor: ['Este medidor não possui telefone cadastrado.'] };
                            this.toggleErros();
                        }
                    } catch (error) {
                        console.error(`Erro ao buscar telefone para pessoa com ID ${newPessoa.id}:`, error);
                    }
                } else {
                    this.novoMedidor.contato = ''; // Limpa o contato caso a pessoa seja desmarcada
                }
            },
        },
    },

    created() {
        this.getListaPessoas()
    },
    methods: {

        async getListaPessoas() {
            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.get('pessoa/pessoas-fisica-juridica/?atividade=Colaborador&ativo=True&page_size=9999', {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });
                // console.log(response.data)
                this.listaPessoas = response.data.results.map(person => ({
                    id: person.pessoa.id,
                    nome_razao: person.nome || person.razao_social,
                    // telefone: person.pessoa_fisica.telefone_pessoa[0]
                }));
            } catch (error) {
                console.error(error);
            }
        },

        adicionarMedidor() {
            if (this.novoMedidor.pessoa && this.novoMedidor.contato) {
                const medidorExistente = this.obra.medidores.some(
                    medidor => medidor.pessoa?.id === this.novoMedidor.pessoa.id || medidor.medidor?.id === this.novoMedidor.pessoa.id
                );

                if (medidorExistente) {
                    this.erros = { medidor: ["Este medidor já foi adicionado."] };
                    this.toggleErros();
                } else {
                    this.obra.medidores.push({ ...this.novoMedidor });
                    this.novoMedidor = { pessoa: null, contato: '' }; // Resetar o formulário
                }
            } else {
                this.erros = { medidor: ["Preencha os campos para adicionar."] };
                this.toggleErros();
            }
        },

        removerMedidor(index) {
            this.obra.medidores.splice(index, 1); // Remove o medidor pelo índice
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros;
        },

        async buscarTelefonePessoa(idPessoa) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.get(`pessoas/telefones/?pessoa=${idPessoa}`, {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                });
                return response.data.results[0];
            } catch (error) {
                console.error(error);
            }
        },


        // Funções relacionadas a imagem do bucket

        abrirCamera() {
            this.$refs.cameraInput.click();
        },
        abrirGaleria() {
            this.$refs.galeriaInput.click();
        },
        abrir_galeria() {
            this.galeria = !this.galeria
        },

        openPreviewImage(url) {
            this.preview_imagem = url
        },

        closed_preview_image() {
            this.preview_imagem = ''
        },

        atualizarFileInput(file) {
            this.obra.imagem = file;
        },

        // abrirCamera() {
        //     this.$refs.imagemInput.click();
        // },

        processarImagem(event) {
            const file = event.target.files[0];
            if (file) {
                // Armazena o arquivo real para envio posterior
                this.obra.imagemFile = file;

                // Cria uma URL temporária para exibição
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.obra.imagem = e.target.result;
                };
                reader.readAsDataURL(file);
            }
        },

    }
};
</script>

<style scoped>
.etapas-navegacao {
    display: flex;
    justify-content: center;
    overflow-x: auto;
}

.etapas-navegacao button {
    padding: 10px 20px;
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
    margin: 20px;
}

.etapas-navegacao .ativo {
    background-color: #1f2937;
    color: white;
}

.form-group {
    margin-bottom: 15px;
}

.previewImagemObra {
    @apply mt-2 block h-[250px] w-full cursor-pointer border border-corBase bg-white bg-contain bg-center bg-no-repeat
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

input {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
</style>