<template>
  <div>
    <div class="container-modal-editar">
      <div class="sombra-modal">
        <div class="conteudo-modal-editar">
          <form class="form-editar text-white">
            <div class="flex justify-end">
              <button @click.prevent="$emit('fecharModal')">
                <i class="fas fa-close text-2xl text-corBase"></i>
              </button>
            </div>
            <h2 class="text-lg text-corBase font-bold mb-2">Editar Dados Vidro</h2>

            <div>
              <div class="w-full flex gap-5">

                <div class="flex flex-col w-full md:w-[35%] ">
                  <label class="text-corBase text-xl">Imagem:</label>
                  <div class="imagePreviewWrapper"
                    :style="{ 'background-image': `url(${vidro.imagem?.url_documento})` }"
                    @click.prevent="toggleGaleria">
                  </div>
                  <div class="flex gap-3 mt-4">
                    <button class="selecionar-arquivo" @click.prevent="toggleGaleria">Pesquisar</button>
                  </div>

                </div>

                <div class="md:w-[65%] flex flex-col">

                  <div class="w-full flex">
                    <label class="text-xl text-corBase flex items-center w-full">
                      Descrição Base:
                      {{ vidro.vidro_base?.nome_base }}
                    </label>
                  </div>


                  <div class="w-full flex">
                    <label class="text-xl text-corBase flex items-center w-full">Tipo:
                      <span class="text-base ml-1">{{ vidro.vidro_base?.spanroduto?.descricao || '-' }}</span>
                    </label>
                  </div>

                  <div class="w-full flex items-center gap-5 space-x-4">
                    <div class="flex items-center">
                      <label class="text-xl text-corBase">Cor:</label>
                      <span class="text-base ml-1">{{ vidro.vidro_base?.cor_base.map(cor => cor.descricao).join(', ') ||
                        '-' }}
                      </span>
                    </div>

                    <div class="flex items-center">
                      <label class="text-xl text-corBase">Espessura:</label>
                      <span class="text-base ml-1">{{ vidro.vidro_base?.espessura }}mm</span>
                    </div>
                  </div>


                  <div class="w-full flex items-center gap-5 space-x-4">
                    <div class="flex items-center">
                      <label class="text-xl text-corBase">Unidade de Medida:</label>
                      <span class="text-base ml-1">{{ vidro.vidro_base?.unidade_medida?.nome || '-' }}</span>
                    </div>

                    <div class="flex items-center">
                      <label class="text-xl text-corBase">Peso:</label>
                      <span class="text-base ml-1">{{ vidro.peso || '-' }}</span>
                    </div>
                  </div>

                  <div class="w-full flex gap-2">
                    <div class="w-full">
                      <label class="text-xl text-corBase">Descrição:</label>
                      <input type="text" required class="input-campos" v-model="vidro.descricao">
                    </div>
                  </div>

                  <div class="w-full flex gap-2">
                    <div class="min-w-[70%]">
                      <label class="text-xl text-corBase">Fornecedor:</label>
                      <v-select :options="lista_fornecedores" v-model="vidro.fornecedor" label="nome_razao"
                        class="bg-white" />
                    </div>

                    <div class="min-w-[30%]">
                      <label class="text-xl text-corBase">$ Custo:</label>
                      <input type="text" v-model="vidro.custo">
                    </div>
                  </div>

                  <div class="w-full flex gap-2">
                    <div class="w-full">
                      <label class="text-xl text-corBase">Acréscimo Medida:</label>
                      <v-select :options="lista_acrescimo_tamanho" label="label" v-model="vidro.acrescimo_tamanho"
                        class="bg-white" :reduce="acrescimo => acrescimo.value" />
                    </div>

                    <div class="w-full">
                      <label class="text-xl text-corBase">Medida:</label>
                      <input v-model="vidro.medida" type="text" maxlength="100" class="bg-white w-full"
                        :disabled="vidro.acrescimo_tamanho == 'SA' || !vidro.acrescimo_tamanho">
                    </div>

                    <div class="w-full">
                      <label class="text-xl text-corBase">% Acréscimo:</label>
                      <input v-model="vidro.porcentagem_acrescimo" type="text" maxlength="100"
                        :disabled="vidro.acrescimo_tamanho == 'SA' || !vidro.acrescimo_tamanho" class="bg-white w-full">
                    </div>
                  </div>

                  <div class="w-full flex gap-2">
                    <div class="w-full">
                      <label class="text-xl text-corBase">Área Mínima Cobrança:</label>
                      <input v-model="vidro.metragem_minima" type="text" maxlength="100" class="w-full">
                    </div>


                    <div class="w-full">
                      <label class="text-xl text-corBase">Arredondamento:</label>
                      <input v-model="vidro.arredondamento" type="text" maxlength="100" class="w-full">
                    </div>
                  </div>

                  <div class="campo-chapa flex flex-col text-center items-center pt-2">
                    <label class="text-xl text-corBase">Ativo:</label>
                    <span @click.prevent="toggleAtivo" class="text-start pt-1 text-xl">
                      <i v-if="vidro.ativo === true" class="fa-solid fa-circle-check text-ativo"></i>
                      <i v-else class="fa-solid fa-circle-xmark text-inativo"></i>
                    </span>
                  </div>

                  <div class="campo-chapa flex flex-col items-center pt-2" v-if="!vidro.ativo">
                    <label class="text-xl text-corBase">Motivo da desativação:</label>
                    <v-select class="bg-white w-full" v-model="vidro.motivo_desativado"
                      :options="lista_motivos_inativacao" label="label" />
                  </div>
                </div>

              </div>
            </div>

            <!--Botão-->
            <div class="flex justify-end mt-2">
              <button class="btn-formulario-salvar" @click.prevent="putDadosEditado">
                Salvar
              </button>
            </div>
          </form>
        </div>
      </div>
      <SucessoRapido v-if="sucesso === true" mensagem="Vidro atualizado com sucesso" />
    </div>
  </div>

  <Galeria v-if="showGaleria === true" @fechar_galeria="toggleGaleria" @objetoClicado="atualizarFileInput" />
  <ListagemErros v-if="exibirErros === true" :erros="erros" @fecharModal="toggleErros" />
</template>

<script>
import axios from "axios";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import ListagemErros from "@/components/toasts/toast_erro/ListagemErros.vue";
import Galeria from "@/components/midais/ged/Galeria";
import vSelect from "vue-select";

export default {
  name: "EditarVidro",
  components: {
    SucessoRapido,
    ListagemErros,
    Galeria,
    vSelect,
  },
  data() {
    return {
      lista_fornecedores: [],
      lista_acrescimo_tamanho: [
        { label: 'Sem Acréscimo', value: 'SA' },
        { label: 'Metro Quadrado', value: 'MQ' },
        { label: 'Metro Linear', value: 'ML' },
      ],

      lista_motivos_inativacao: [
        { label: 'Descontinuado por Decisão Empresarial', value: 'DDE' },
        { label: 'Descontinuado pelo Fornecedor', value: 'DF' },
      ],

      showGaleria: false,
      exibirErros: false,
      erros: [],
      sucesso: false,

    }
  },
  props: ["vidro"],
  emits: ["fecharModal", "atualizarLista"],

  created() {
    this.getListaFornecedores()
  },

  methods: {

    async getListaFornecedores() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      try {
        const response = await axios.get(`pessoa/pessoas-fisica-juridica/?produto_fornecedor=Vidro&atividade=Fornecedor&page=1`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        this.lista_fornecedores = response.data.results.map(fornecedor => {
          return {
            id: fornecedor.pessoa.id,
            nome_razao: fornecedor.nome || fornecedor.razao_social,
          }
        })
      } catch (error) {
        console.error(error)
      }
    },

    async putDadosEditado() {

      console.log(this.vidro.acrescimo_tamanho)

      if (this.vidro.acrescimo_tamanho && this.vidro.acrescimo_tamanho !== 'SA') {
        if ((!this.vidro.medida || this.vidro.medida <= 0) || (!this.vidro.porcentagem_acrescimo || this.vidro.porcentagem_acrescimo <= 0)) {
          this.erros = {
            acrescimo_tamanho: `O tipo de acréscimo selecionado exige que medida e porcentagem de acréscimo sejam válidos e maiores que zero.`,
          };
          this.toggleErros();
          return;
        }
      }

      const dadosAtualizados = new FormData();
      dadosAtualizados.append('descricao', this.vidro.descricao)
      dadosAtualizados.append('fornecedor', this.vidro.fornecedor?.id || '')
      dadosAtualizados.append('custo', this.vidro.custo || '')
      dadosAtualizados.append(
        'acrescimo_medida',
        this.vidro.acrescimo_tamanho?.value || this.vidro.acrescimo_tamanho || ''
      );
      dadosAtualizados.append('medida', this.vidro.medida || '')
      dadosAtualizados.append('porcentagem_acrescimo', this.vidro.porcentagem_acrescimo || '')
      dadosAtualizados.append('metragem_minima', this.vidro.metragem_minima || '')
      dadosAtualizados.append('arredondamento', this.vidro.arredondamento || '')
      dadosAtualizados.append('ativo', this.vidro.ativo)
      dadosAtualizados.append('imagem', this.vidro.imagem?.id || '')

      if (!this.vidro.ativo) {
        if (this.vidro.motivo_desativado) {
          dadosAtualizados.append('motivo_desativado', this.vidro.motivo_desativado?.value)
        } else {
          this.erros = { motivo_desativado: 'Este campo é obrigatório.' }
          this.toggleErros()
          return
        }
      }

      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')
        const response = await axios.put(`produtos/vidro/${this.vidro.id}/`, dadosAtualizados, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        });
        // console.log(response);
        this.sucesso = true;
        setTimeout(() => {
          this.sucesso = false;
          // this.$emit('fecharModal')
          this.$emit('atualizarLista')
        }, 3000);

      } catch (error) {
        console.error(error)
        this.erros = error.response?.data || { geral: 'Ocorreu um erro desconhecido.' }
        this.toggleErros()
      }
    },

    toggleAtivo() {
      this.vidro.ativo = !this.vidro.ativo
    },

    toggleGaleria() {
      this.showGaleria = !this.showGaleria
    },

    atualizarFileInput(objeto) {
      this.vidro.imagem = objeto;
    },

    toggleErros() {
      this.exibirErros = !this.exibirErros
    }


  }
}
</script>

<style scoped>
input[type=text] {
  @apply w-full p-1 border border-[#ccc] rounded-md
}

input:disabled {
  @apply bg-gray-100
}
</style>