<template>
    <div>
        <!-- Campo para capturar ou selecionar imagem -->
        <div class="form-group">
            <label for="imagem" class="text-corBase pt-1">Imagem</label>
            <input type="file" id="cameraInput" ref="cameraInput" accept="image/*" capture="environment" class="hidden"
                @change="processarImagem" />

            <!-- Campo para selecionar imagem da galeria -->
            <input type="file" id="galeriaInput" ref="galeriaInput" accept="image/*" class="hidden"
                @change="processarImagem" />

            <!-- Botões para acessar câmera ou galeria -->
            <div v-if="!vao.imagem">
                <div class="flex gap-10">
                    <div class="">
                        <button @click.prevent="abrirCamera">
                            <i class="fa-solid fa-camera fa-xl"></i>
                            <p>Capturar com a Câmera</p>
                        </button>
                    </div>
                    <div class="gap-5">
                        <button @click.prevent="abrirGaleria">
                            <i class="fa-solid fa-photo-film fa-xl"></i>
                            <p>Selecionar da Galeria</p>
                        </button>
                    </div>
                </div>
            </div>
            <div v-else>
                <button @click="abrirModal">
                    <i class="fa-solid fa-image fa-xl"></i>
                    <p>Editar Imagem</p>
                </button>
            </div>

            <img v-if="this.vao.imagem" :src="this.vao.imagem" alt="Imagem do vão">
        </div>

        <!-- Modal para edição da imagem -->
        <div v-show="mostrarModal" class="modal-overlay">
            <div class="modal-content">
                <button class="" @click="fecharModal">X</button>
                <h2 class="modal-title">Editar Imagem</h2>

                <!-- Canvas para desenhar -->
                <div ref="modalContent" class="canvas-container" style="position: relative;">
                    <canvas ref="canvas" @mousedown="startDrawing" @mousemove="drawLine" @mouseup="stopDrawing"
                        @mouseleave="stopDrawing" @touchstart="startDrawing" @touchmove="drawLine"
                        @touchend="stopDrawing"></canvas>
                </div>


                <!-- Seção fixa para inputs -->
                <div class="inputs-container">
                    <div v-for="(linha, index) in linhas" :key="index" class="input-item">
                        <input type="text" v-model="linha.texto" placeholder="Digite aqui..." class="linha-input"
                            @blur="redesenharCanvas" :disabled="!vao.medidas" />
                    </div>
                </div>

                <div class="modal-actions">
                    <button class="btn-salvar md:hidden" @click.prevent="desfazerLinha">Limpar Última Linha</button>
                    <button class="btn-salvar" @click.prevent="limparCanvas">Limpar Linhas</button>
                    <button class="btn-salvar" @click.prevent="salvarImagem">Salvar Imagem</button>
                </div>
            </div>
        </div>

        <div class="flex flex-col md:flex-row gap-4 md:pb-3">
            <div class="w-full">
                <label class="text-corBase pt-2">Quantidade</label>
                <input v-model.number="vao.quantidade" type="number" min="1" placeholder="Digite aqui..."
                    class="w-full" />
            </div>
            <div class="w-full">
                <label class="text-corBase pt-2">Largura (MM)</label>
                <input v-model.number="vao.largura" type="number" min="0" placeholder="Digite aqui..." 
                class="w-full" :disabled="!vao.medidas" />
            </div>
            <div class="w-full">
                <label class="text-corBase pt-2">Altura (MM)</label>
                <input v-model.number="vao.altura" type="number" min="0" placeholder="Digite aqui..." 
                class="w-full" :disabled="!vao.medidas" />
            </div>
        </div>

        <!-- Cores lado a lado na mesma linha -->
        <h2 class="text-lg text-corBase font-bold">Cores</h2>
        <div class="flex flex-col md:flex-row gap-4 md:pb-3">

            <!-- Perfis -->
            <div class="flex flex-col w-full">
                <label class="font-bold text-corBase">Perfis</label>
                <v-select v-model="novaCombinacao.cor_perfil" :options="listaCorPerfis" label="descricao"
                    placeholder="Escolha uma opção" class="bg-white" />
                    <!-- :reduce="cor_perfil => cor_perfil.id" -->
            </div>

            <!-- Componentes -->
            <div class="flex flex-col w-full">
                <label class="font-bold text-corBase">Componentes</label>
                <v-select v-model="novaCombinacao.cor_componente" :options="listaCorComponentes" label="descricao"
                    placeholder="Escolha uma opção" class="bg-white" />
                    <!-- :reduce="cor_componente => cor_componente.id" -->
            </div>

            <!-- Vidros -->
            <div class="flex flex-col w-full">
                <label class="font-bold text-corBase">Vidros</label>
                <v-select v-model="novaCombinacao.cor_vidro" :options="listaCorVidros" label="descricao"
                    placeholder="Escolha uma opção" class="bg-white" />
                    <!-- :reduce="cor_vidro => cor_vidro.id" -->
            </div>

            <!-- Chapas/Telas -->
            <div class="flex flex-col w-full">
                <label class="font-bold text-corBase">Chapas/Telas</label>
                <v-select v-model="novaCombinacao.cor_chapa" :options="listaCorChapas" label="descricao"
                    placeholder="Escolha uma opção" class="bg-white" />
                    <!-- :reduce="cor_chapa => cor_chapa.id" -->
            </div>

        </div>
        <div class="flex justify-end mt-4">
            <button @click.prevent="adicionarCombinacao" class="btn-salvar">
                <i class="fa-solid fa-plus"></i> Adicionar Combinação
            </button>
        </div>

        <div class="mt-4">
            <h2 class="text-lg font-bold">Combinações Adicionadas</h2>
            <ul>
                <li v-for="(combinacao, index) in combinacoes_cores" :key="index">
                    Perfil: {{ combinacao.cor_perfil?.descricao }}, 
                    Componente: {{ combinacao.cor_componente?.descricao }}, 
                    Vidro: {{ combinacao.cor_vidro?.descricao }}, 
                    Chapa: {{ combinacao.cor_chapa?.descricao }}
                </li>
            </ul>
        </div>


        <div class="flex flex-col md:flex-row gap-4 md:pb-5">
            <div class="w-full">
                <label class="text-corBase pt-2">Observação</label>
                <input v-model.number="vao.observacao" type="text" placeholder="Digite aqui..." class="w-full" />
            </div>


        </div>

        <div class="flex flex-col md:flex-row gap-4 md:pb-3">
            <div class="flex flex-col w-full">
                <label class="font-bold text-corBase">Contramarco:</label>
                <v-select v-model="vao.contramarco" :options="lista_contramarco_arremate" label="label"
                    placeholder="Escolha uma opção" class="bg-white" :reduce="contramarco => contramarco.value"
                    :clearable="false" />
            </div>

            <div v-if="vao.contramarco" class="flex flex-col w-full">
                <label class="font-bold text-corBase">Medida do Contramarco:</label>
                <v-select v-model="vao.medida_contramarco" :options="lista_medida_contramarco" label="label"
                    placeholder="Escolha uma opção" class="bg-white"
                    :reduce="medida_contramarco => medida_contramarco.value" :clearable="false" />
            </div>

            <div class="flex flex-col w-full">
                <label class="font-bold text-corBase">Arremate:</label>
                <v-select v-model="vao.arremate" :options="lista_contramarco_arremate" label="label"
                    placeholder="Escolha uma opção" class="bg-white" :reduce="arremate => arremate.value"
                    :clearable="false" />
            </div>
        </div>

        <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros" />

    </div>
</template>


<script>
import vSelect from 'vue-select';
import axios from 'axios'
import ListagemErros from '@/components/toasts/toast_erro/ListagemErros.vue';

export default {
    data() {
        return {
            lista_contramarco_arremate: [
                { label: 'Sim', value: true },
                { label: 'Não', value: false }
            ],

            lista_medida_contramarco: [
                { label: 'Interna', value: 'Interna' },
                { label: 'Externa', value: 'Externa' }
            ],
            listaCorComponentes: [],
            listaCorPerfis: [],
            listaCorVidros: [],
            listaCorChapas: [],

            novaCombinacao: {
                cor_perfil: '',
                cor_componente: '',
                cor_vidro: '',
                cor_chapa: '',
            },


            exibirErros: false,
            erros: [],

            previewImage: {},
            mostrarModal: false,
            ctx: null,
            desenhando: false,
            inicioX: 0,
            inicioY: 0,
            imagemCarregada: false,
            linhas: [],
        };
    },

    props: ['vao', 'obra', 'combinacoes_cores'],

    emits: ['updateFile', 'atualizarCombinacoes'],

    components: {
        vSelect,
        ListagemErros,
    },

    created() {
        // this.getListaCorComponentes()
        // this.getListaCorPerfis()
        // this.getListaCorVidros()

        if (this.obra) {
            console.log('Obra no GeralComponent: ', this.obra.lista_cor_componente)

            this.listaCorComponentes = this.obra.lista_cor_componente
            console.log('corComponente', this.listaCorComponentes)
            this.listaCorPerfis = this.obra.lista_cor_perfil
            console.log('corPerfil', this.listaCorPerfis)
            this.listaCorVidros = this.obra.lista_vidro
            console.log('corVidro', this.listaCorVidros)
            this.listaCorChapas = this.obra.lista_cor_chapa
            console.log('corChapa', this.listaCorChapas)

            this.vao.contramarco = this.obra.contramarco
            this.vao.medida_contramarco = this.obra.medida_contramarco
            this.vao.arremate = this.obra.arremate
        }
    },

    methods: {

        adicionarCombinacao() {
            // Emitir nova combinação para o componente pai
            if (
                this.novaCombinacao.cor_perfil &&
                this.novaCombinacao.cor_componente &&
                this.novaCombinacao.cor_vidro &&
                this.novaCombinacao.cor_chapa
            ) {
                this.$emit('atualizarCombinacoes', { ...this.novaCombinacao });
                // Limpar os campos após adicionar
                this.novaCombinacao = {
                    cor_perfil: '',
                    cor_componente: '',
                    cor_vidro: '',
                    cor_chapa: '',
                };
            } else {
                this.erros = { cores: 'Adicione todos os campos para criar uma combinação.' }
                this.toggleErros()
            }
        },

        toggleErros(){
            this.exibirErros = !this.exibirErros
        },


        // Funções relacionadas à edição da imagem enviada/tirada
        abrirCamera() {
            this.$refs.cameraInput.click();
        },
        abrirGaleria() {
            this.$refs.galeriaInput.click();
        },

        processarImagem(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.vao.imagem = e.target.result;
                    this.abrirModal();
                };
                reader.readAsDataURL(file);
            }
        },

        abrirModal() {
            this.mostrarModal = true;
            this.carregarImagem();
            this.$nextTick(() => {
                this.redimensionarCanvas();
            });

            document.body.style.overflow = 'hidden';
            this.$refs.modalContent.addEventListener('touchmove', this.preventTouchScroll, { passive: false });
        },

        carregarImagem() {
            this.$nextTick(() => {
                const canvas = this.$refs.canvas;
                if (!canvas) return;

                const ctx = canvas.getContext("2d");
                const img = new Image();
                img.src = this.vao.imagem;

                img.onload = () => {
                    canvas.width = img.width;
                    canvas.height = img.height;

                    canvas.style.width = canvas.parentElement.offsetWidth + "px";
                    canvas.style.height =
                        (canvas.parentElement.offsetWidth * img.height) / img.width + "px"

                    ctx.drawImage(img, 0, 0, img.width, img.height);

                    this.ctx = ctx;
                };
            });
        },

        startDrawing(event) {
            const { x, y } = this.getCanvasCoordinates(event);
            this.inicioX = x;
            this.inicioY = y;
            this.desenhando = true;
            this.linhaAtual = { inicioX: x, inicioY: y, fimX: x, fimY: y };
            event.preventDefault();
        },

        drawLine(event) {
            if (!this.desenhando || !this.ctx) return;

            const { x, y } = this.getCanvasCoordinates(event);

            this.linhaAtual.fimX = x;
            this.linhaAtual.fimY = y;

            this.redesenharCanvas();

            // Define tamanho de linha e fonte com base na largura do canvas
            const canvas = this.$refs.canvas;
            const escala = canvas.width / 500;
            const larguraLinha = Math.max(2, 4 * escala);

            // Adiciona a linha ao canvas
            this.ctx.beginPath();
            this.ctx.moveTo(this.linhaAtual.inicioX, this.linhaAtual.inicioY);
            this.ctx.lineTo(this.linhaAtual.fimX, this.linhaAtual.fimY);
            this.ctx.strokeStyle = "red";
            this.ctx.lineWidth = larguraLinha;
            this.ctx.stroke();

            event.preventDefault()
        },

        getCanvasCoordinates(event) {
            const canvas = this.$refs.canvas;
            const rect = canvas.getBoundingClientRect();

            const scaleX = canvas.width / rect.width;
            const scaleY = canvas.height / rect.height;

            if (event.touches && event.touches[0]) {
                return {
                    x: (event.touches[0].clientX - rect.left) * scaleX,
                    y: (event.touches[0].clientY - rect.top) * scaleY,
                };
            } else {
                return {
                    x: (event.clientX - rect.left) * scaleX,
                    y: (event.clientY - rect.top) * scaleY,
                };
            }
        },

        redimensionarCanvas() {
            const canvas = this.$refs.canvas;
            const ctx = canvas.getContext("2d");

            // Ajusta o tamanho do canvas para o dispositivo
            const devicePixelRatio = window.devicePixelRatio || 1;
            canvas.width = canvas.offsetWidth * devicePixelRatio;
            canvas.height = canvas.offsetHeight * devicePixelRatio;

            ctx.scale(devicePixelRatio, devicePixelRatio);
            this.ctx = ctx;

            this.redesenharCanvas();
        },

        stopDrawing() {
            if (!this.desenhando) return;
            this.linhas.push(this.linhaAtual);
            this.linhaAtual = null;
            this.desenhando = false;
            this.redesenharCanvas();
        },

        redesenharCanvas() {
            const canvas = this.$refs.canvas;
            const ctx = this.ctx;
            if (!canvas || !ctx) return;

            const rect = canvas.getBoundingClientRect();
            this.canvasOffsetX = rect.left;
            this.canvasOffsetY = rect.top;

            const img = new Image();
            img.src = this.vao.imagem;
            img.onload = () => {
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                // Define tamanho de linha e fonte com base na largura do canvas
                const escala = canvas.width / 500; // Baseado em um tamanho padrão de 500px
                const tamanhoFonte = Math.max(16, 22 * escala); // Garante tamanho mínimo
                const larguraLinha = Math.max(2, 4 * escala); // Garante espessura mínima

                // Redesenha as linhas
                this.linhas.forEach((linha) => {
                    ctx.beginPath();
                    ctx.moveTo(linha.inicioX, linha.inicioY);
                    ctx.lineTo(linha.fimX, linha.fimY);
                    ctx.strokeStyle = "red";
                    ctx.lineWidth = larguraLinha;
                    ctx.stroke();

                    // Renderiza o texto diretamente no canvas
                    if (linha.texto) {
                        const meioX = (linha.inicioX + linha.fimX) / 2;
                        let meioY = (linha.inicioY + linha.fimY) / 2;

                        // Verifica se a linha é predominantemente horizontal
                        const isHorizontal = Math.abs(linha.fimX - linha.inicioX) > Math.abs(linha.fimY - linha.inicioY);
                        if (isHorizontal) {
                            meioY += 20 * escala; // Move o texto abaixo da linha
                            this.vao.largura = linha.texto; // Define o texto como largura
                        } else {
                            meioY -= 10 * escala; // Mantém o texto ao lado direito na vertical
                            this.vao.altura = linha.texto; // Define o texto como altura
                        }

                        ctx.font = `${tamanhoFonte}px Arial`;
                        ctx.fillStyle = "blue";
                        ctx.fillText(linha.texto, meioX, meioY);
                    }
                });
            };
        },

        limparCanvas() {
            this.linhas = [];
            this.vao.largura = ''
            this.vao.altura = ''
            this.redesenharCanvas();
        },

        salvarImagem() {
            const canvas = this.$refs.canvas;
            canvas.toBlob((blob) => {
                this.vao.imagemFile = new File([blob], "imagem-editada.png", { type: "image/png" });
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.vao.imagem = e.target.result;
                };
                reader.readAsDataURL(this.vao.imagemFile);
                this.fecharModal();
            }, "image/png", 1.0);
        },

        fecharModal() {
            this.mostrarModal = false;
            this.ctx = null;

            document.body.style.overflow = '';
            this.$refs.modalContent.removeEventListener('touchmove', this.preventTouchScroll);
        },

        preventTouchScroll(event) {
            event.preventDefault();
        },

        handleKeyPress(event) {
            // Verifica se Ctrl+Z foi pressionado
            if ((event.ctrlKey || event.metaKey) && event.key === "z") {
                this.desfazerLinha();
            }
        },

        desfazerLinha() {
            if (this.linhas.length > 0) {
                this.linhas.pop();
                this.redesenharCanvas();
            }
        },

        // Funções relacionadas à sessão de cor
        async getListaCorComponentes() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                const response = await axios.get('produtos/cor-componente/?page_size=999', {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                this.listaCorComponentes = response.data.results.map(response => {
                    return {
                        id: response.id,
                        descricao: response.descricao
                    }
                })
            } catch (error) {
                console.error(error)
            }
        },

        async getListaCorPerfis() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                const response = await axios.get('produtos/cor-perfil/?page_size=999', {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                this.listaCorPerfis = response.data.results.map(response => {
                    return {
                        id: response.id,
                        descricao: response.descricao
                    }
                })
            } catch (error) {
                console.error(error)
            }
        },

        async getListaCorVidros() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                const response = await axios.get('produtos/cor-vidro/?page_size=999', {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                this.listaCorVidros = response.data.results.map(response => {
                    return {
                        id: response.id,
                        descricao: response.descricao
                    }
                })
            } catch (error) {
                console.error(error)
            }
        },
    },
};
</script>

<style scoped>
label {
    display: block;
    font-weight: bold;
}

input[type="file"],
input[type="number"],
input[type="text"] {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.v-select {
    background-color: white;
    /* Fundo transparente para o componente principal */
    border: 1px solid #ccc;
    /* Define uma borda */
    border-radius: 5px;
}

.abas-container {
    display: flex;
    margin-top: 15px;
    @apply mt-4 p-2 border-b rounded-lg
}

.aba {
    padding: 10px;
    cursor: pointer;
    margin-right: 5px;
    font-weight: 700;
    transition: 0.3s ease-in-out;
    @apply rounded-lg bg-white text-corBase border border-white
}

.aba-selecionada {
    @apply bg-corBase text-white border border-white
}

.inputs-container {
    display: flex;
    flex-wrap: wrap;
    /* Permite quebrar linha se o espaço não for suficiente */
    gap: 10px;
    /* Espaço entre os itens */
    padding: 10px;
    justify-content: space-between;
    /* Alinha os itens uniformemente */
}

.input-item {
    flex: 1 1 auto;
    /* Permite que os itens cresçam e encolham conforme o espaço disponível */
    min-width: 150px;
    /* Define um tamanho mínimo para os inputs */
}

.linha-input {
    width: 100%;
    /* Garante que o input ocupe todo o espaço do item */
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: hidden;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    max-height: 90%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
}

.modal-title {
    margin-bottom: 10px;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
}

.modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
    gap: 5px;
}

.canvas-container canvas {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    border: 1px solid #ccc;
    /* Apenas para visualizar os limites do canvas */
}
</style>