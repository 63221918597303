<template>
    <div>
        <div class="fixed inset-0 bg-black/60 flex items-center justify-center z-[10000]">
            <div
                class="bg-white rounded-lg shadow-lg p-6 w-[90%] sm:w-[600px] max-h-[80vh] overflow-y-auto transform transition-transform duration-300 ease-out scale-95 sm:scale-100">
                <!-- Cabeçalho do Modal -->
                <div class="flex justify-between items-center mb-4 border-b pb-2">
                    <h2 class="text-lg font-bold text-red-600">Lista de Emails</h2>
                    <button @click.prevent="$emit('fecharModal')"
                        class="text-gray-500 hover:text-gray-800 p-2 rounded-full transition duration-200 ease-in-out hover:bg-gray-100">
                        <i class="fa-solid fa-x"></i>
                    </button>
                </div>

                <!-- Lista de Emails -->
                <div v-if="lista_emails.length > 0">
                    <div v-for="email in lista_emails" :key="email.id" class="border-b last:border-none py-3">
                        <!-- Dados do Email -->
                        <p class="text-base text-gray-700">
                            <span class="font-semibold">Email:</span>
                            {{ email.email_pessoa }}
                        </p>
                        <p class="text-sm text-gray-500 mt-1">
                            <span class="font-semibold">Tipo de Email:</span>
                            {{ email.tipo_email?.descricao || "Não especificado" }}
                        </p>
                    </div>
                </div>
                <div v-else>
                    <p>Sem email cadastrado.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: "ListarEnderecos",
    props: ['pessoa'],
    emits: ['fecharModal'],

    data() {
        return {
            lista_emails: []
        }
    },


    created() {
        console.log(this.pessoa);
        this.getEmails()
    },

    methods: {
        async getEmails() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            try {
                this.$store.dispatch("setLoading", true);
                const response = await axios.get(`pessoas/email/?pessoa=${this.pessoa}`, {
                    headers: {
                        'Authorization': `Token ${token}`,
                    }
                })

                this.lista_emails = response.data.results
                console.log(this.lista_emails)
            } catch (error) {
                console.error(error)
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        }
    }
};
</script>

<style scoped></style>