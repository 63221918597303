<template>
  <details>
    <summary class="mt-4">
      <h2 class="bg-screenCor hover:bg-screenCor border border-corBase hover:border-corBase
    text-corBase hover:text-corBase py-2 px-4 ">Interação Online
      </h2>
    </summary>

    <button @click.prevent="adicionarInteracao" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
    text-white hover:text-ativo py-2 px-4 mt-2 mr-2">Adicionar
    </button>

    <div class="border border-gray-200 p-2 mt-3" v-for="(interacao, index) in dadosInteracao" :key="index">

      <div class=" flex flex-end justify-end">
        <i class="fa-solid fa-x cursor-pointer" @click.prevent="removerInteracao(index)"></i>
      </div>

      <div class="container-campos ">
        <div class="campos campos-mobile campos-dasktop-select">
          <label class="text-base text-corBase mt-2">Tipo de Interação:</label>
          <div class="flex">
            <!-- <select class="input-campos" v-model="interacao.tipo_interacao">
                <option v-for="item in lista_tipo_interacao"
                        :key="item.id"
                        :value="item.id"
                >{{ item.descricao }}
                </option>
              </select> -->
            <v-select v-model="interacao.tipo_interacao" :options="lista_tipo_interacao" :reduce="item => item.id"
              label="descricao" class="bg-white w-full" placeholder="Selecione o tipo de interação" />


          </div>
        </div>
        <div class="campos campos-mobile">
          <label class="text-base text-corBase mt-2">Endereço de Interacao:</label>
          <input type="interacao" class="input-campos" placeholder="" v-model="interacao.endereco_interacao">
        </div>
      </div>

    </div>

  </details>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";

export default {
  name: "InteracaoOnlineCampos",

  props: ['dadosInteracao'],

  components: { vSelect, },

  data() {
    return {
      lista_tipo_interacao: []
    }
  },

  created() {
    this.getListaTipoInteracao()
  },

  methods: {
    adicionarInteracao() {
      this.dadosInteracao.push({
        endereco_interacao: '',
        tipo_interacao: '',
      });
    },
    removerInteracao(index) {
      this.dadosInteracao.splice(index, 1);
    },

    async getListaTipoInteracao() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('pessoas/interacoes/tipo-interacoes/?page_size=9999', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_tipo_interacao = response.data.results
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

}
</script>

<style scoped></style>