<template>
    <div class="container-modelo-tabela">
        <table class="container-tabela overflow-auto">
            <thead class="cabecalho-tabela">
                <tr class="border-b">
                    <th scope="col" class="px-2 py-2 text-center">#</th>
                    <th scope="col" class="py-2 text-center">
                        <div class="flex justify-center">
                            <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">
                            <label class="sr-only">checkbox</label>
                        </div>
                    </th>
                    <th scope="col" class="px-3 py-2 text-start w-32">Condição</th>
                    <th scope="col" class="px-6 py-2 text-start">Observação</th>
                </tr>
            </thead>

            <!-- Linhas de Validações -->
            <draggable :list="validacoes" tag="tbody" handle=".handle" itemKey="id">
                <template v-slot:item="{ element, index }">
                    <tr :key="index" class="escopo-tabela">
                        <th scope="col"
                            class="text-center font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
                            <div class="handle cursor-pointer">
                                <span>☰</span> <!-- Handle para arrastar -->
                                {{ element.index }}
                            </div>
                        </th>
                        <td scope="col" class="py-2 text-center">
                            <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                                v-model="element.selected">
                        </td>

                        <td scope="col" class="px-3 py-2 text-start text-xs">
                            <input class="input-campos" v-model="element.condicao">
                            <!-- {{ element.condicao }} -->
                        </td>

                        <td scope="col" class="px-6 py-2 text-start text-xs">
                            <input class="input-campos" v-model="element.observacao">
                            <!-- {{ element.observacao }} -->
                        </td>

                    </tr>
                </template>
            </draggable>

            <tr v-if="adicionarNovaValidacao" class="escopo-tabela">
                <td class="px-6 py-2"></td>
                <td class="px-6 py-2"></td>

                <td class="px-6 py-2 text-start">
                    <input class="input-campos" v-model="novaValidacao.condicao">
                </td>

                <td class="px-6 py-2 text-start">
                    <input class="input-campos" v-model="novaValidacao.observacao">
                </td>

            </tr>

        </table>
        <div v-if="adicionarNovaValidacao" class="flex w-full justify-end itens-center mt-6 px-4 mb-5">
            <button class="btn-cancelar" @click.prevent="toggleValidacao">
                Cancelar
            </button>
            <button class="btn-salvar" @click.prevent="postNovaValidacao">
                Adicionar
            </button>
        </div>
        <div v-else class="flex justify-center">
            <button class="text-ativo" @click.prevent="toggleValidacao">
                <i class="fas fa-plus-circle"></i>
            </button>
        </div>
    </div>

    <div class="flex flex-wrap sm:flex-nowrap w-full justify-between items-center mt-6 gap-4">
        <div class="flex flex-wrap sm:flex-nowrap items-center gap-3 w-full sm:w-auto">
            <button class="botoes bg-red-500 w-full sm:w-auto" @click.prevent="verificarExclusao()">
                Excluir Selecionados
            </button>
            <button class="botoes bg-blue-500 w-full sm:w-auto" @click.prevent="alterarSelecionados()">
                Alterar Selecionados
            </button>
            <button class="botoes bg-orange-500 w-full sm:w-auto" @click.prevent="exportarValidacoes()">
                Exportar Validações
            </button>
            <button class="botoes bg-yellow-500 w-full sm:w-auto" @click="triggerFileInput">
                Importar Validações
            </button>
        </div>

        <input type="file" ref="fileInput" class="hidden" @change="importarValidacoes" accept=".csv" />

        <div class="flex items-center w-full sm:w-auto justify-center sm:justify-end">
            <button class="btn-salvar w-full sm:w-auto" @click.prevent="atualizarValidacoes(validacoes)">
                Salvar/Atualizar
            </button>
        </div>
    </div>

    <MensagemConfirmacaoExclusao mensagem="Deseja mesmo excluir os itens selecionados?" :abrir="modal_confirmacao"
        @fecharModal="fecharModalConfirmacao" @confirmarExclusao="excluirSelecionados" />

     <alterar-validacoes v-if="modalAlterarSelecionados === true" :validacoes="validacoesSelecionadas" @fecharModal="toggleAlterarSelecionados()"
        @atualizarLista="atualizarLista()"  />

    <listagem-erros v-if="exibirErros === true" :erros="erros" @fecharModal="toggleErros()" />

    <erro-rapido v-if="erro === true" :mensagem="erroMessage" />

    <sucesso-rapido v-if="sucesso === true" :mensagem="sucessoMessage" />

</template>

<script>
import axios from 'axios'
import draggable from 'vuedraggable'
import MensagemConfirmacaoExclusao from '@/components/toasts/toast_notificacao/MensagemConfirmacaoExclusao.vue'
import AlterarValidacoes from '@/components/midais/Editar/projeto/alterar_selecionados/AlterarValidacoes.vue'
import ListagemErros from '@/components/toasts/toast_erro/ListagemErros.vue'
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue'
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue'

export default {
    name: "ValidacaoProjeto",

    props: ['validacoes', 'projeto_id'],

    emits: ['atualizarValidacoes', 'getListaValidacaoProjeto'],

    data() {
        return {
            novaValidacao: {
                projeto_id: this.projeto_id,
                condicao: '',
                observacao: '',
                index: this.validacoes.length + 1
            },

            checkAll: false,
            modal_confirmacao: false,

            modalProdutos: false,
            itemListaSelecionado: '',

            adicionarNovaValidacao: false,

            modalAlterarSelecionados: false,
            validacoesSelecionadas: [],

            exibirErros: false,
            erros: {},
            erro: false,
            erroMessage: '',

            sucesso: false,
            sucessoMessage: '',
        }
    },

    components: {
        draggable,
        MensagemConfirmacaoExclusao,
        AlterarValidacoes,
        ListagemErros,
        ErroRapido,
        SucessoRapido,
    },

    created() {

    },

    computed: {
        checkSome() {
            return this.validacoes.some(validacao => validacao.selected)
        }
    },

    methods: {

        async postNovaValidacao() {
            try {
                console.log(this.novaValidacao);
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest');
                this.$store.dispatch("setLoading", true);

                // Fazendo a requisição diretamente
                const response = await axios.post('projeto/projeto-validacao/', this.novaValidacao, {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                });

                this.sucesso = true
                this.sucessoMessage = 'Validação criada com sucesso.'
                setTimeout(() => {
                    this.sucesso = false;
                    this.sucessoMessage = ''
                }, 3000);

                console.log("Validação criada com sucesso:", response.data);

                this.adicionarNovaValidacao = false;

                this.limparCampos();
                this.$emit('getListaValidacaoProjeto')
            } catch (error) {
                console.error(error);
                if (error.response && error.response.data) {
                    this.erros = error.response.data;
                } else {
                    this.erros = { geral: ["Ocorreu um erro desconhecido."] };
                }
                this.toggleErros();
                console.log(this.exibirErros);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros
        },

        alterarSelecionados() {
            if (this.checkSome || this.checkAll) {
                this.toggleAlterarSelecionados()
                this.validacoesSelecionadas = this.validacoes.filter(validacao => validacao.selected)
                console.log(this.validacoesSelecionadas)
            } else {
                this.erro = true
                this.erroMessage = 'Selecione pelo menos uma Validação para ser alterada.'
                setTimeout(() => {
                    this.erro = false;
                    this.erroMessage = ''
                }, 3000);
            }
        },

        toggleAlterarSelecionados() {
            this.modalAlterarSelecionados = !this.modalAlterarSelecionados
        },

        selectAll() {
            // Atualiza o estado dos demais checkboxes
            const checkboxes = document.getElementsByClassName('checkbox');
            checkboxes.forEach((checkbox) => {
                checkbox.checked = this.checkAll;
            });
            this.validacoes.forEach((validacao) => {
                validacao.selected = this.checkAll;
            });
        },

        fecharModalConfirmacao() {
            this.modal_confirmacao = false
        },

        excluirSelecionados() {
            if (this.checkAll) {
                this.deletarValidacao(this.validacoes)
                this.modal_confirmacao = false
                this.checkAll = false
                return
            }

            const validacoesADeletar = this.validacoes.filter((validacao) => {
                if (validacao.selected) {
                    return validacao
                }
            })

            this.deletarValidacao(validacoesADeletar)
            this.modal_confirmacao = false
            this.$emit('atualizarValidacoes')
        },

        verificarExclusao() {
            if (this.checkSome || this.checkAll) {
                this.modal_confirmacao = true
            } else {
                this.erro = true
                this.erroMessage = 'Selecione pelo menos uma Validação para ser excluído.'
                setTimeout(() => {
                    this.erro = false;
                    this.erroMessage = ''
                }, 3000);
            }
        },


        async deletarValidacao(validacoesADeletar) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest')

            const validacoesADeletarPromisses = []

            for (const validacao of validacoesADeletar) {

                validacoesADeletarPromisses.push(
                    axios.patch(`projeto/projeto-validacao/${validacao.id}/`, { deletado: 'True' }, {
                        headers: {
                            Authorization: `Token ${token}`
                        }
                    })
                )
            }

            await Promise.all(validacoesADeletarPromisses)

            this.$emit('getListaValidacaoProjeto')
        },

        toggleValidacao() {
            this.adicionarNovaValidacao = !this.adicionarNovaValidacao
            this.limparCampos()
        },

        atualizarValidacoes(validacoesAAtualizar) {
            validacoesAAtualizar.forEach((validacao, index) => {
                validacao.index = index + 1;
            });
            this.$emit('atualizarValidacoes', validacoesAAtualizar);
        },


        limparCampos() {
            this.novaValidacao.observacao = ''
            this.novaValidacao.condicao = ''
        },

        atualizarLista() {
            // alert('atualizar')
            this.$emit('getListaValidacaoProjeto')
        },

        triggerFileInput() {
            this.$refs.fileInput.click();
        },

        exportarValidacoes() {
            alert('teste exportar')
            // const headers = [
            //     "Código",
            //     "Corte",
            //     "QTDE/Fórmula",
            //     "Medida/Fórmula",
            //     "Posição",
            //     "Condição",
            //     "Observação",
            //     "Cor",
            //     "Fase Montagem",
            //     "Aplicação",
            // ];

            // const rows = this.perfis.map((perfil) => [
            //     perfil.perfil_id?.codigo_base || "",
            //     perfil.corte || "",
            //     perfil.qtd_formula || "",
            //     perfil.medida_formula || "",
            //     perfil.posicao || "",
            //     perfil.condicao || "",
            //     perfil.observacao || "",
            //     perfil.cor?.nome || "",
            //     perfil.fase_esquadria?.descricao || "",
            //     perfil.aplicacao || "",
            // ]);

            // const csvContent = [headers, ...rows]
            //     .map((row) => row.join(","))
            //     .join("\n");

            // const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
            // const link = document.createElement("a");
            // link.href = URL.createObjectURL(blob);
            // link.download = "validacoes.csv";
            // link.click();
        },

        // Método para importar os dados
        importarValidacoes(event) {
            console.log('teste importar')
            // const file = event.target.files[0];
            // if (!file) return;

            // const reader = new FileReader();
            // reader.onload = async (e) => {
            //     const content = e.target.result;
            //     const rows = content
            //         .split("\n")
            //         .filter((row) => row.trim() !== "")
            //         .map((row) => row.split(","));

            //     // Remova o cabeçalho (primeira linha)
            //     rows.shift();

            //     this.erros = { erros: [] };

            //     // Converte os dados em objetos e realiza as buscas
            //     for (const [index, row] of rows.entries()) {
            //         const perfil = {
            //             codigo: row[0]?.trim(),
            //             corte: row[1]?.trim(),
            //             qtd_formula: row[2]?.trim(),
            //             medida_formula: row[3]?.trim(),
            //             posicao: row[4]?.trim(),
            //             condicao: row[5]?.trim(),
            //             observacao: row[6]?.trim(),
            //             cor: row[7]?.trim(),
            //             fase_esquadria: row[8]?.trim(),
            //             aplicacao: row[9]?.trim(),
            //         };

            //         const camposFaltando = [];
            //         if (!perfil.codigo) camposFaltando.push("Código");
            //         if (!perfil.corte) camposFaltando.push("Corte");
            //         if (!perfil.qtd_formula) camposFaltando.push("QTDE/Fórmula");
            //         if (!perfil.medida_formula) camposFaltando.push("Medida/Fórmula");
            //         if (!perfil.posicao) camposFaltando.push("Posição");

            //         if (camposFaltando.length > 0) {
            //             this.erros.erros.push(
            //                 `Linha ${index + 2}: Campos obrigatórios ausentes: ${camposFaltando.join(", ")}`
            //             );
            //             continue;
            //         }

            //         this.$store.dispatch("setLoading", true);
            //         try {
            //             // Buscar ID do Código
            //             const codigoResponse = await this.getIDPerfil(perfil.codigo);
            //             perfil.perfil_id = codigoResponse?.data?.results[0]?.id || null;

            //             // Buscar ID da Cor somente se ela for fornecida
            //             if (perfil.cor) {
            //                 const corResponse = await this.getIDCor(perfil.cor);
            //                 perfil.cor_id = corResponse?.data?.results[0]?.id || null;
            //             } else {
            //                 perfil.cor_id = null; // Cor ausente no CSV
            //             }

            //             // Buscar ID da Fase de Montagem somente se ela for fornecida
            //             if (perfil.fase_esquadria) {
            //                 const faseResponse = await this.getIDFaseMontagem(perfil.fase_esquadria);
            //                 perfil.fase = faseResponse?.data?.results[0]?.id || null;
            //             } else {
            //                 perfil.fase = null; // Fase ausente no CSV
            //             }

            //             console.log("Perfil Processado:", perfil);

            //             // Configurar `this.novoPerfil`
            //             this.novoPerfil = {
            //                 projeto_id: this.projeto_id,
            //                 perfil_id: perfil.perfil_id,
            //                 descricao: perfil.descricao,
            //                 peso: perfil.peso,
            //                 corte: perfil.corte,
            //                 qtd_formula: perfil.qtd_formula,
            //                 medida_formula: perfil.medida_formula,
            //                 posicao: perfil.posicao,
            //                 cor: perfil.cor_id,
            //                 condicao: perfil.condicao,
            //                 observacao: perfil.observacao,
            //                 fase_esquadria: perfil.fase,
            //                 aplicacao: perfil.aplicacao,
            //             };

            //             // Executar `postNovoPerfil`
            //             await this.postNovoPerfil();
            //             this.$store.dispatch("setLoading", false);
            //         } catch (error) {
            //             console.error(`Erro ao processar o perfil na linha ${index + 2}:`, error);
            //             this.erros.erros.push(`Linha ${index + 2}: Erro ao buscar dados para o perfil.`);
            //         }
            //     }

            //     if (this.erros.erros.length > 0) {
            //         console.error("Erro(s) encontrado(s):", this.erros);
            //         this.exibirErros = true;
            //     } else {
            //         this.sucesso = true;
            //         this.sucessoMessage = `Perfis importados e cadastrados com sucesso.`;

            //         setTimeout(() => {
            //             this.sucesso = false;
            //             this.sucessoMessage = false;
            //         }, 3000);
            //     }
            // };

            // this.$refs.fileInput.value = "";
            // reader.readAsText(file);
        },


        async getIDPerfil(codigo) {
            const token = await this.$store.dispatch("getAuthTokenCookie");
            await this.$store.dispatch("fetchURLrequest");

            return axios.get(`produtos/produto/?codigo_base=${codigo}`, {
                headers: { Authorization: `Token ${token}` },
            });
        },

    }
}
</script>

<style scoped>
.botoes {
    @apply px-4 py-1 text-white
}
</style>