<template>
    <div class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[9999]">
        <div class="bg-white p-8 rounded-lg shadow-lg w-1/3 max-w-lg">
            <div class="border-b mb-4">
                <h2 class="text-2xl font-semibold text-gray-800 text-center">Alterar Fornecedor</h2>
            </div>

            <div class="space-y-4">
                <div class="w-full flex gap-2">
                    <div class="text-left w-full">
                        <label for="corte" class="block text-sm font-medium text-corBase mb-1">Fornecedor:</label>
                        <v-select :options="lista_fornecedores" label="nome_razao" placeholder="Escolha uma opção"
                            v-model="fornecedor" :reduce="fornecedor => fornecedor.id" />
                    </div>

                </div>

            </div>

            <!-- Botões -->
            <div class="mt-6 flex justify-center space-x-4">
                <button class="btn-cancelar" @click.prevent="$emit('fecharModal')">
                    Fechar
                </button>
                <button class="btn-salvar" @click="salvarAlteracoes()">
                    Salvar Alterações
                </button>
            </div>

            <SucessoRapido v-if="sucesso" mensagem="Vidros atualizados com sucesso!" />
            <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros" />

        </div>
    </div>
</template>

<script>
import axios from 'axios'
import vSelect from 'vue-select';
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import ListagemErros from '@/components/toasts/toast_erro/ListagemErros.vue';

export default {
    name: 'AlterarSelecionadosModalImportacaoVidro',
    data() {
        return {
            fornecedor: '',

            sucesso: false,
            exibirErros: false,
            erros: [],
        };
    },

    components: {
        vSelect,
        SucessoRapido,
        ListagemErros,
    },

    props: ['vidros', 'lista_fornecedores'],

    emits: ['fecharModal', 'atualizarLista'],

    created() {
        console.log(this.lista_fornecedores)
    },

    methods: {

        toggleAtivo() {
            this.ativo = !this.ativo
        },

        async salvarAlteracoes() {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {

                const patchPromises = this.vidros.map(vidro => {

                    const camposAtualizados = {};

                    if (this.fornecedor) {
                        camposAtualizados.fornecedor = this.fornecedor;
                        console.log(camposAtualizados.fornecedor)
                    } else {
                        this.erros = { fornecedor: 'Selecione um fornecedor para atualizar vidros.' }
                        this.toggleErros()
                        return
                    }

                    if (Object.keys(camposAtualizados).length === 0) {
                        return Promise.resolve();
                    }

                    return axios.patch(
                        `produtos/vidro/${vidro.id}/`,
                        camposAtualizados,
                        {
                            headers: {
                                'Authorization': `Token ${token}`,
                            },
                        }
                    );
                });

                // Aguarda todas as requisições serem resolvidas
                await Promise.all(patchPromises);

                // Exibe mensagem de sucesso
                this.sucesso = true;
                setTimeout(() => {
                    this.sucesso = false;
                    this.$emit('fecharModal');
                    this.$emit('atualizarLista');
                }, 3000);
            } catch (error) {
                console.error('Erro ao salvar alterações:', error);
                this.erros = error.response?.data || { geral: 'Ocorreu um erro desconhecido.' }
                this.toggleErros()
            }
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros
        },

    },

};
</script>

<style scoped>
.input-campos {
    @apply block w-full p-2 border rounded-md text-corBase;
}
</style>