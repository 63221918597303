<template>

  <div class="container-formulario">


    <div class="cabecalho-dashboard">
      <h1>Projetos</h1>

      <button @click="$router.push(`/dashboard`)" class="text-2xl">
        <i class="fas fa-chevron-left mr-1 "></i>
      </button>
    </div>

    <div class="flex flex-wrap">

      <button class="escopo-dashboard-desktop" @click="$router.push(`/lista-projetista-sistemista`)">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Sistemista</h2>
            <i class="fa-solid fa-industry"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Cadastre as empresas presentes em seu sistema!</p>
          </div>
        </div>

      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push(`/linha-sistema`)">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Linhas</h2>
            <i class="fas fa-users"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Veja os relatórios em tempo real!</p>
          </div>
        </div>

      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push(`/lista-modelo-variacao`)">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Categorias</h2>
            <i class="fas fa-user-friends"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Veja os relatórios em tempo real!</p>
          </div>
        </div>

      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push(`/lista-tipologia`)">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Tipologias</h2>
            <i class="fa-solid fa-th-large"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Cadastre os tipos de pessoas em seu sistema!</p>
          </div>
        </div>

      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push(`/lista-projetos`)">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Projetos</h2>
            <i class="fas fa-user-friends"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Veja os relatórios em tempo real!</p>
          </div>
        </div>

      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push(`/fase-esquadria`)">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Fase da Esquadria</h2>
            <i class="fas fa-layer-group"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Veja os relatórios em tempo real!</p>
          </div>
        </div>

      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push(`/ged/lista-imagens`)">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Fotos Obras</h2>
            <i class="fas fa-user-friends"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Veja os relatórios em tempo real!</p>
          </div>
        </div>

      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push(`/local-vidro`)">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Localização (Vidros)</h2>
            <i class="fas fa-user-friends"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Veja os relatórios em tempo real!</p>
          </div>
        </div>

      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push(`/projeto/cadastro`)">

        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Incluir Projeto</h2>
            <i class="fas fa-user-friends"></i>
          </div>

          <div class="descricao-opcao">
            <p class="mt-6">Veja os relatórios em tempo real!</p>
          </div>
        </div>

      </button>


    </div>


  </div>


</template>

<script>

export default {
  name: "ProjetosDashboard",


}

</script>

<style lang="scss"></style>