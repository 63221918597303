<template>

    <div class="container-formulario">
  
      <!-- TILO DO FORMULARIO + BOTAO DE VOLTAR -->
      <div class="cabecalho-formulario">
        <h1 class="text-xl text-corBase font-bold">Dados Fase Esquadria:</h1>
  
        <router-link to="/fase-esquadria" class="text-xl text-corBase font-bold">
          <i class="fas fa-chevron-left"></i>
        </router-link>
      </div>
  
      <!-- Escopo do formulario de cadastro mobile-->
      <form @submit.prevent="cadastrarFaseEsquadria">
        <!--        PARTE DE DADOS DO PRODUTO-->
        <div class="flex flex-col md:flex-row gap-3 my-3 w-full ">
  
          <div class="campo-chapa">
            <label class="text-xl text-corBase font-bold">Descrição</label>
            <input type="text" v-model="descricao" class="input-form-chapa" required autofocus>
          </div>
        </div>
  
  
        <div class="flex w-full justify-end">
          <button class="btn-enviar" type="submit">Salvar</button>
        </div>
  
      </form>
    </div>
    <MensagemSucesso v-if="sucesso === true" rotas="/fase-esquadria"
      mensagem="Fase da Esquadria cadastrado com Sucesso!" />
    <MenssagemErro v-if="erro === true" rotas="/fase-esquadria/cadastro" :mensagem="erroMessage" />
  
  </template>
  
  <script>
  import axios from "axios";
  import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";
  import MenssagemErro from "@/components/toasts/toast_erro/MenssagemErro.vue";
  import vSelect from "vue-select";
  
  export default {
    name: "CadastroProjetistaSistemista",
  
    components: {
      MensagemSucesso,
      MenssagemErro,
      vSelect
    },
  
  
    data() {
      return {
        sucesso: false,
        erro: false,
        erroMessage: '',
        descricao: '',
      }
    },
  
    created() {
      
    },
  
    computed: {
      minhaEmpresa() {
        return this.$store.state.minhaEmpresa;
      },
      isadmin() {
        return this.$store.state.isAdminUser
      },
    },
  
    methods: {
  
  
      async cadastrarFaseEsquadria() {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')
  
        const data = {
          ativo: true,
          descricao: this.descricao,
        }
  
        this.sucesso = false
        this.erro = false
        this.$store.dispatch("setLoading", true);
        await axios.post('projeto/fase-esquadria/', data, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })
          .then(response => {
            console.log(response);
            this.descricao = ''
            this.sucesso = true
            this.$store.dispatch("setLoading", false);
          })
          .catch(error => {
            console.error(error);
            this.$store.dispatch("setLoading", false);
            this.erro = true
            this.erroMessage = 'Ocorreu um erro ao salvar.'
          })
  
      },

  
  
    }
  
  }
  </script>
  
  <style lang="scss"></style>