<template>

  <preview-image v-if="preview_imagem" @closed="closed_preview_image" :imagem="preview_imagem" />
  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Projetos</h1>
      </div>

      <router-link to="/projetos" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>

    <form @submit.prevent="getListaProjetos">
      <div class="flex gap-4 mb-6 items-end w-[100%]">
        <div class="w-full">
          <label class="text-corBase font-bold">Gestor</label>
          <v-select v-model="gestor" :options="lista_gestor" label="text" :reduce="(option) => option.text">
          </v-select>
        </div>
        <div class="w-full">
          <label class="text-corBase">Sistemista</label>
          <v-select v-model="sistemista" :options="lista_sistemista" label="text" :reduce="(option) => option.id">
            <template #no-options>
              <span>
                {{ gestor ? 'Nenhum sistemista encontrado' : 'Selecionar Gestor' }}
              </span>
            </template>
          </v-select>
        </div>
        <div class="w-full">
          <label class="text-corBase">Linha</label>
          <v-select v-model="linha" :options="lista_linha" label="text" :reduce="(option) => option.id">
            <template #no-options>
              <span>
                {{ sistemista ? 'Nenhuma linha encontrada para este sistemista.' : 'Selecionar Sistemista' }}
              </span>
            </template>
          </v-select>
        </div>
        <div class="w-full">
          <label class="text-corBase">Modelo</label>
          <v-select v-model="modelo" :options="lista_modelo" label="text" :reduce="(option) => option.id"
            @open="fetchModelos">
            <!-- @open="checkPreconditions" -->
            <template #no-options>
              <span>
                {{ sistemista && linha ? 'Nenhum modelo encontrado.' : 'Selecionar Sistemista e Linha' }}
              </span>
            </template>
          </v-select>
        </div>
        <div class="w-full">
          <label class="text-corBase">Status</label>
          <v-select v-model="status" :options="lista_status" label="text" :reduce="(option) => option.id">
            <template #no-options>
              <span>
                {{ gestor ? 'Nenhum status encontrado' : 'Selecionar Gestor' }}
              </span>
            </template>
          </v-select>
        </div>
        <div class="w-full">
          <label class="text-corBase">Tipologia</label>
          <!-- <input type="text" class="input-form-chapa" v-model="filtro_base"> -->
          <v-select v-model="tipologia" :options="lista_tipologia" label="nome" :reduce="(option) => option.id">
            <template #no-options>
              <span>
                {{ gestor ? 'Nenhuma tipologia encontrada' : 'Selecionar Gestor' }}
              </span>
            </template>
          </v-select>
        </div>
      </div>
      <div class="flex items-center justify-between w-full">
        <!-- Campo Tipologia -->
        <div class="flex-1 mr-4">
          <label class="text-corBase block">Código ou descrição</label>
          <div class="w-48 pb-2">
            <input type="text" class="input-form-chapa" v-model="filtro_base">

          </div>
        </div>

        <!-- Botões -->
        <div class="flex gap-4">
          <div>
            <button class="btn-add-fisica" @keyup.enter="submit">
              Pesquisar
            </button>

          </div>
          <button
            class="border border-gray-200 bg-yellow-500 text-white px-4 py-2 hover:bg-screenCor hover:text-yellow-500 hover:border-yellow-500"
            @click.prevent="selectAll()">
            Marcar/Desmarcar Projetos
          </button>
          <button v-if="lista_projetos.length > 0"
            class="border border-gray-200 bg-orange-500 text-white px-4 py-2 hover:bg-screenCor hover:text-orange-500 hover:border-orange-500"
            @click.prevent="ferramentas()">
            Ferramentas
          </button>
          <button
            class="border border-gray-200 bg-gray-500 text-white px-4 py-2 hover:bg-screenCor hover:text-gray-500 hover:border-gray-500"
            @click.prevent="parametrizar()">
            Parametrizar
          </button>
          <button @click.prevent="$router.push(`/projeto/cadastro`)"
            class="border border-gray-200 bg-ativo text-white px-4 py-2 hover:bg-screenCor hover:text-ativo hover:border-ativo">
            Criar Projeto
          </button>
        </div>
      </div>

    </form>


    <!-- Container da exibiçao da lista de pessoas -->
    <div
      class="w-full flex flex-wrap rounded-sm border border-corBase/10 h-[48vh] overflow-y-scroll scrollbar-thin scrollbar-thumb-corBase/20">

      <div class="container-cards-produtos" v-for="(projeto, index) in lista_projetos" :key="index">
        <div class="flex">
          <div class="w-52 border border-corBase/40">
            <div class="flex justify-between px-4 py-1 border border-b-corBase/40">
              <input type="checkbox" name="" id="" class=" w-6">
              <p class="text-xs font-bold">{{ projeto.gestor?.name.toUpperCase() || 'MANAGERGLASS' }}</p>
              <div class="flex bg-corBase divide-x rounded">
                <button><i class="fas fa-wrench text-sm text-white px-2 py-1"></i></button>
                <button @click.prevent="togglePrivado(projeto)">
                  <i :class="{
                    'fas fa-lock text-sm text-white px-2 py-1': projeto.privado,
                    'fas fa-unlock text-sm text-white px-2 py-1': !projeto.privado
                  }"></i>
                </button>

              </div>
            </div>

            <div class="px-4 py-1">
              <h4 class="font-bold text-sm">{{ projeto.codigo }}</h4>
            </div>

            <div class="flex justify-center items-center">
              <div class="text-center">
                <div class="flex flex-col items-center p-4 rounded-lg">
                  <img :src="projeto.imagem?.url_documento" v-if="projeto.imagem?.url_documento" alt="Imagem de Capa"
                    class="w-full mb-2 rounded-md cursor-pointer"
                    @click.prevent="openPreviewImage(`${projeto.imagem?.url_documento}`)" />
                  <img v-else
                    src="https://managerglass.s3.amazonaws.com/media/public/b7144d8c-4a5c-4c56-a9bd-924275d6c55d.png"
                    alt="Sem foto" class="w-full mb-2 rounded-md">
                </div>
                <p class="text-xs text-gray-500">Imagem teste</p>
              </div>
            </div>

            <div class="text-center text-sm py-2">
              <p>Nome: {{ projeto.descricao_fixa }}</p>
              <p class="">Modelo: {{ projeto.modelo_variacao?.descricao || 'Sem modelo' }}</p>
              <p class="">Sistemista: {{ projeto.sistemista?.nome || 'Sem Sistemista' }}</p>
              <p class="">Linha: {{ projeto.linha_sistema?.linha || 'Sem Linha' }}</p>
              <p class="">Tipologia: {{ projeto.tipologia?.nome || 'Sem tipologia' }}</p>
              <p class="">Status: {{ projeto.status_projeto?.descricao || 'Sem status' }}</p>
              <p>
                Privado:
                <span v-if="projeto.privado" class="text-xs">
                  <i class="fa-solid fa-circle-check text-ativo ml-3"></i>
                </span>
                <span v-else class="text-xs">
                  <i class="fa-solid fa-circle-xmark text-inativo ml-3"></i>
                </span>
              </p>

            </div>

            <div class="flex flex-wrap gap-3 justify-center px-4 mb-2">
              <router-link :to="'/alterar-projeto/' + projeto.id"
                class="bg-corBarraLateral text-white text-xs px-2 py-1">{{
                  projeto.tipologia?.nome || 'Sem tipologia' }}
              </router-link>
            </div>

          </div>
        </div>
      </div>

      <!-- <div class="container-cards-produtos" v-for="(projeto, index) in listaProjetosPublic" :key="index">
        <div class="flex">
          <div class="w-52 border border-corBase/40">
            <div class="flex justify-between px-4 py-1 border border-b-corBase/40">
              <input type="checkbox" name="" id="" class=" w-6">
              <p class="text-xs font-bold">MANAGERGLASS</p>
              <div class="flex bg-corBase divide-x rounded">
                <button><i class="fas fa-wrench text-sm text-white px-2 py-1"></i></button>
                <button><i class="fas fa-lock text-sm text-white px-2 py-1"></i></button>
              </div>
            </div>

            <div class="px-4 py-1">
              <h4 class="font-bold text-sm">{{ projeto.codigo }}</h4>
            </div>

            <div>
              <button @click.prevent="openPreviewImage(projeto.tipologia.imagem.url_documento)">
                <img :src="projeto.tipologia.imagem.url_documento">
              </button>
            </div>

            <div class="text-center text-sm py-2">
              <p>Nome: {{ projeto.descricao_fixa }}</p>
              <p class="">Modelo: {{ projeto.modelo_variacao?.descricao || 'Sem modelo' }}</p>
              <p class="">Tipologia: {{ projeto.tipologia?.nome || 'Sem tipologia' }}</p>
              <p class="">Linha: {{ projeto.linha_sistema?.linha || 'Sem linha' }}</p>
              <p class="">Status: {{ projeto.status_projeto?.descricao || 'Sem status' }}</p>
            </div>

            <div class="flex flex-wrap gap-3 justify-center px-4 mb-2">
              <router-link :to="'/alterar-projeto/' + projeto.id"
                class="bg-corBarraLateral text-white text-xs px-2 py-1">{{
                  projeto.tipologia.nome }}
              </router-link>
            </div>

          </div>
        </div>
      </div> -->

    </div>
  </div>

  <div v-if="showModal" class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <div class="bg-white p-6 rounded-lg shadow-lg w-1/3 text-center">
      <p class="text-gray-700">OBRIGATÓRIO ESCOLHA DO GESTOR</p>
      <div class="mt-6">
        <button
          class="bg-red-500 border text-white px-4 py-2 rounded hover:bg-screenCor hover:text-red-500 hover:border-red-500"
          @click="showModal = false">
          Fechar
        </button>
      </div>
    </div>
  </div>

  <ParametrizarProjetos v-if="parametrizacao === true" @fecharModal="parametrizar()" />

</template>

<script>
import axios from "axios";
import PreviewImage from "@/components/midais/ged/PreviewImage";
import ParametrizarProjetos from "./ParametrizarProjetos.vue";
import vSelect from "vue-select";

export default {
  name: "ListaProjetos",

  components: {
    PreviewImage,
    ParametrizarProjetos,
    vSelect
  },

  data() {
    return {
      projeto: '',

      allChecked: false,
      // lista usada para salvar o retorno da requisição do get
      listaProjetos: [],
      listaProjetosPublic: [],
      lista_projetos: [],
      // lista usada para salvar esse retorno depois de dividido
      listaProjetosPaginada: [],
      // varival que inicializa o numero de paginas
      paginaAtual: 1,

      // filtros
      lista_gestor: [],
      lista_sistemista: [],
      lista_linha: [],
      lista_modelo: [],
      lista_status: [],
      lista_tipologia: [],
      gestor: '',
      sistemista: '',
      linha: '',
      modelo: '',
      status: '',
      filtro_base: '',
      tipologia: '',
      preview_imagem: '',

      showModal: false,
      parametrizacao: false,

      error: [],
    }
  },

  watch: {
    sistemista(newValue) {
      if (newValue) {
        this.getListaLinha(newValue)
        this.linha = ''
        this.tipologia = ''
      }

      if (!newValue) {
        this.linha = ''
        this.lista_linha = []
        this.sistemista = ''
        this.modelo = ''
        this.lista_modelo = []
      }
    },

    gestor(newValue) {
      if (newValue) {
        this.getListaSistemista()
        this.getListaStatus()
        this.getListaTipologia()
      }

      if (!newValue) {
        this.lista_sistemista = []
        this.sistemista = ''
        this.lista_linha = []
        this.linha = ''
        this.lista_modelo = []
        this.modelo = ''
        this.lista_status = []
        this.status = ''
        this.lista_tipologia = []
        this.tipologia = ''
      }
    },

    modelo(newValue) {
      newValue ? this.modelo = newValue : this.modelo = ''
    },

    linha(newValue) {
      newValue ? this.linha = newValue : this.linha = ''
    },

    status(newValue) {
      newValue ? this.status = newValue : this.status = ''
    },

    tipologia(newValue) {
      if (newValue) {
        this.sistemista = ''
        this.linha = ''
        this.modelo = ''
      }
    },
  },

  computed: {
    isadmin() {
      return this.$store.state.isAdminUser
    },

    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },


  },

  created() {
    this.getListaGestor()
    // this.getListaModelos()
    // this.getListaStatus()
    // this.getListaSistemista()
    // this.getListaTipologia()

    const gestor = window.location.hostname.split('.')[0]
    if (gestor === 'admin') {
      this.gestor = 'ManagerGlass'
      return
    }
    this.gestor = gestor;
  },

  methods: {
    closed_preview_image() {
      this.preview_imagem = ''
    },
    openPreviewImage(url) {
      this.preview_imagem = url
    },

    selectAll() {
      this.allChecked = !this.allChecked; // Alterna o estado interno

      // Seleciona todos os checkboxes no container e atualiza seu estado
      const checkboxes = document.querySelectorAll('.container-cards-produtos input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.allChecked;
      });
    },

    // busca a lista de projetos
    async getListaProjetos() {
      if (!this.gestor) {
        this.showModal = true
        return
      }
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')


      this.$store.dispatch("setLoading", true);
      await axios.get(
        `/projeto/projeto/?sistemista=${this.sistemista || ''}&modelo=${this.modelo || ''}&linha=${this.linha || ''}&status=${this.status || ''}&filtro_base=${this.filtro_base}&tipologia=${this.tipologia || ''}&gestor=${this.gestor || ''}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          // this.listaProjetos = response.data.current_tenant
          // this.listaProjetosPublic = response.data.public_tenant
          console.log(response.data.results)
          this.lista_projetos = response.data.results
          this.$store.dispatch("setLoading", false);
        })
        .catch(error => {
          console.error(error)
          this.$store.dispatch("setLoading", false);
        })
    },

    async fetchModelos() {
      // console.log('abriu campo modelo')
      // Verifica se todos os campos necessários estão preenchidos
      if (!this.sistemista || !this.linha) {
        this.lista_modelo = []; // Garante que o dropdown ficará vazio
        return;
      }

      this.getListaModelos()
    },

    // busca a lista de modelos do sistema
    async getListaModelos() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`projeto/modelo-variacao/?projeto_vinculado=True&linha_id=${this.linha}&page_size=999`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_modelo = response.data.results.map(item => ({
            id: item.id,
            text: item.descricao
          }));
        })
        .catch(error => {
          console.error(error)
        })
    },

    // busca a lista de linhas do sistema
    async getListaLinha(sistemistaId) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`projeto/linha-sistema/?projetista_sistemista_id=${sistemistaId}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_linha = response.data.results.map(item => ({
            id: item.id,
            text: item.linha
          }));
        })
        .catch(error => {
          console.error(error)
        })
    },

    // busca a lista de gestores do sistema
    async getListaGestor() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('tenant/client/', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_gestor = response.data.results.map(item => ({
            id: item.id,
            text: item.name
          }));

          // Adicionar ManagerGlass na primeira posição
          this.lista_gestor.unshift({
            id: 0,
            text: 'ManagerGlass'
          });
        })
        .catch(error => {
          console.error(error)
        })
    },

    // busca a lista de status do sistema
    async getListaStatus() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('projeto/status-projeto/?page_size=999', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_status = response.data.results.map(item => ({
            id: item.id,
            text: item.descricao
          }));
        })
        .catch(error => {
          console.error(error)
        })
    },

    // busca a lista de sistemistas do sistema
    async getListaSistemista() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('projeto/projetista-sistemista/?page_size=999', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_sistemista = response.data.results.map(item => ({
            id: item.id,
            text: item.nome
          }));
        })
        .catch(error => {
          console.error(error)
        })
    },

    // busca a lista de tipologias do sistema
    async getListaTipologia() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')
      try {
        const response = await axios.get(`projeto/tipologia/?page_size=9999`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        // console.log(response.data.results)
        this.lista_tipologia = response.data.results
      } catch (error) {
        console.error(error)
      }
    },

    ferramentas() {
      alert('ferramentas')
    },

    parametrizar() {
      this.parametrizacao = !this.parametrizacao
    },

    async togglePrivado(projeto) {
      console.log(projeto)
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      console.log(projeto.privado)
      projeto.privado = !projeto.privado
      console.log(projeto.privado)

      try {
        const response = await axios.patch(`projeto/projeto/${projeto.id}/`, { privado: projeto.privado }, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        console.log(response)

      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style lang="scss">
.container-cards-produtos {
  @apply flex flex-wrap px-3 py-3
}
</style>