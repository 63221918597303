<template>
  <div>
    <div class="container-modal-editar">
      <div class="sombra-modal">
        <div class="conteudo-modal-editar">
          <form class="form-editar text-white" @submit.prevent="putDadosEditado">
            <div class="flex justify-end">
              <button @click.prevent="$emit('fecharModal')">
                <i class="fas fa-close text-2xl text-corBase"></i>
              </button>
            </div>
            <h2 class="text-lg text-corBase font-bold mb-2">Editar Dados Tipo Contato</h2>
            <!--Campos da Pessoa-->
            <div>
              <div class="md:flex md:gap-12">
                <div class="flex flex-col mt-2 w-full justify-start">
                  <label class="text-base text-corBase font-bold mt-2">Nome:</label>
                  <input type="text" required class="input-campos text-base" v-model="tipo.descricao">
                </div>


                <div class="flex flex-col mt-2 w-full justify-start ">
                  <label class="text-base text-corBase font-bold mt-2">Ativo:</label>

                  <span @click.prevent="toggleAtivo" class="text-start pt-1 text-xl">
                    <i v-if="tipo.ativo === true" class="fa-solid fa-circle-check text-ativo"></i>
                    <i v-else class="fa-solid fa-circle-xmark text-inativo"></i>
                  </span>
                </div>


              </div>

            </div>

            <!--Botão-->
            <div class="btn-formulario-modal">
              <button class="btn-formulario-salvar" type="submit">
                Salvar
              </button>
            </div>
          </form>
        </div>
      </div>
      <SucessoRapido v-if="sucesso" mensagem="Tipo de Contato atualizado com sucesso!" />
    </div>
    <toast-error v-if="erroNome === true" mensagem="Projetista/Sistemista com Esse Nome já Existe"/>
  </div>
</template>

<script>
import axios from "axios";
import ToastError from "@/components/toasts/toast_erro/ToastError";
import Select2 from "vue3-select2-component";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";

export default {
  name: "EditarTipoEmail",
  components: {
    ToastError,
    Select2,
    SucessoRapido,
  },
  data() {
    return {
      statusModal: false,
      sucesso: false,
      erroNome: false,

    }
  },
  props: ["tipo"],
  emits: ["fecharModal", "atualizarLista"],

  methods: {
    toggleAtivo() {
      this.tipo.ativo = !this.tipo.ativo
    },

    async putDadosEditado() {
      const dadosAtualizados = new FormData();
      dadosAtualizados.append('ativo', this.tipo.ativo)
      dadosAtualizados.append('descricao', this.tipo.descricao)

      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')
        const response = await axios.put(`pessoas/email/tipo-email/${this.tipo.id}/`, dadosAtualizados, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        });
        console.log(response);
        this.sucesso = true
        setTimeout(() => {
          this.sucesso = false;
          this.$emit('fecharModal')
          this.$emit('atualizarLista')
        }, 3000);

      } catch (error) {
        console.error(error)
      }
    },


  }
}
</script>

<style lang="scss">

</style>