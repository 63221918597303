<template>

  <label class="text-base text-corBase font-bold">Relacionamento:</label>
  <div class="flex items-center mb-4">
    <v-select :options="lista_atividades" v-model="dadosPessoaFisica.atividade" label="nome" :multiple="true"
      class="w-full z-10" :reduce="atividade => atividade.id" />
  </div>

  <h1 class="text-xl text-corBase font-bold">Dados Pessoais:</h1>

  <form @submit.prevent="cadastrarPessoa">

    <pessoa-fisica-dados :dadosPessoaFisica="dadosPessoaFisica" />

    <enderecos-campos :dadosEndereco="dadosEndereco" />

    <telefones-campos :dadosTelefone="dadosTelefone" />

    <emails-campos :dadosEmail="dadosEmail" />

    <interacao-online-campos :dadosInteracao="dadosInteracao" />

    <stakeholders-campos v-if="exibirStakeholders" :dadosStakeholders="dadosStakeholders" />

    <fornecedores-campos v-if="exibirFornecedores" :dadosFornecedor="dadosFornecedor" />

    <div class="flex w-full justify-end">
      <button class="btn-enviar" type="submit">Cadastrar</button>
    </div>

  </form>

  <mensagem-sucesso v-if="sucesso === true" mensagem="Pessoa Fisíca Cadastrada com Sucesso!" rotas="/pessoa" />

  <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros()" />
</template>

<script>
import axios from "axios";
import EnderecosCampos from "@/components/campos_cadastro/EnderecosCampos";
import PessoasCampos from "@/components/campos_cadastro/PessoasCampos";
import TelefonesCampos from "@/components/campos_cadastro/TelefonesCampos";
import PessoaFisicaDados from "@/components/campos_cadastro/PessoaFisicaDados";
import EmailsCampos from "@/components/campos_cadastro/EmailsCampos";
import InteracaoOnlineCampos from "@/components/campos_cadastro/InteracaoOnlineCampos.vue";
import StakeholdersCampos from "@/components/campos_cadastro/StakeholdersCampos.vue";
import FornecedoresCampos from "@/components/campos_cadastro/FornecedoresCampos.vue";
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";
import ListagemErros from "@/components/toasts/toast_erro/ListagemErros.vue";
import vSelect from "vue-select";

export default {
  name: 'CadastroPessoaFisica',

  components: {
    TelefonesCampos,
    EnderecosCampos,
    PessoasCampos,
    PessoaFisicaDados,
    EmailsCampos,
    InteracaoOnlineCampos,
    StakeholdersCampos,
    FornecedoresCampos,
    MensagemSucesso,
    ListagemErros,
    vSelect,

  },

  data() {
    return {

      dadosPessoaFisica: {
        nome_razao: '',
        nome_social: '',
        atividade: [],
        cpf: '',
        rg: '',
        orgao_expeditor: '',
        nascimento: '',
      },

      dadosEndereco: [
        {
          logadouro: '',
          numero: '',
          complemento: '',
          cep: '',
          bairro: '',
          cidade: '',
          uf: '',
          referencia: '',
          tipo_endereco: '',
          cidades: []
        }
      ],

      dadosTelefone: [
        {
          ddd: '',
          telefone: '',
          tipo_telefone: '',
          whatsapp: false,
          telegram: false,
        }
      ],

      dadosEmail: [{
        email_pessoa: '',
        tipo_email: '',
      }],

      dadosInteracao: [{
        endereco_interacao: '',
        tipo_interacao: '',
      }],

      dadosStakeholders: [{
        funcao: [],
      }],

      dadosFornecedor: [{
        produtos_fornecedor: [],
      }],

      id_stakeholders: '',
      exibirStakeholders: false,

      id_fornecedor: '',
      exibirFornecedores: false,

      lista_atividades: [],

      sucesso: false,

      exibirErros: false,
      erros: [],
    }
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
    isadmin() {
      return this.$store.state.isAdminUser
    },
  },

  watch: {
    'dadosPessoaFisica.atividade': {
      handler(newValue) {
        console.log(newValue)
        if(newValue.includes(this.id_stakeholders)){
          this.exibirStakeholders = true
        } else{
          this.exibirStakeholders = false
        }

        if(newValue.includes(this.id_fornecedor)){
          this.exibirFornecedores = true
        } else{
          this.exibirFornecedores = false
        }
      },
      immediate: false
    }
  },

  created() {
    this.getListaAtividades()
  },

  methods: {

    async getListaAtividades() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      try {
        const response = await axios.get(`/pessoas/atividade/`, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        })

        const nomesDesejados = ['Cliente', 'Fornecedor', 'Stakeholders', 'Sócio da Empresa', 'Colaborador', 'Revendedor'];
        this.lista_atividades = response.data.results.filter(atividade =>
          nomesDesejados.includes(atividade.nome)
        );

        this.id_stakeholders = this.lista_atividades.filter(atividade =>
          atividade.nome == 'Stakeholders'
        )[0].id;

        this.id_fornecedor = this.lista_atividades.filter(atividade =>
          atividade.nome == 'Fornecedor'
        )[0].id;

        console.log(this.lista_atividades)
      } catch (error) {
        console.error(error)
      }
    },

    async cadastrarPessoa() {

      if (this.dadosPessoaFisica.atividade.length === 0) {
        this.erros = { relacionamento: ['Este campo não pode ser vazio.'] }
        this.toggleErros()
        return
      }

      if (this.dadosTelefone[0].telefone.length < 9) {
        this.erros = { telefone: ['Adicione pelo menos um telefone.'] }
        this.toggleErros()
        return
      }

      try {
        const data = {
          nome_razao: this.dadosPessoaFisica.nome_razao,
          atividade: this.dadosPessoaFisica.atividade,
          identificacao: this.dadosPessoaFisica.cpf || '',
          enderecos: this.dadosEndereco,
          telefones: this.dadosTelefone,
          emails: this.dadosEmail,
          interacoes: this.dadosInteracao,
        };

        console.log(data)

        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.post('pessoas/', data, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        });
        console.log(response)
        this.sucesso = true
        await this.cadastrarPessoaFisica(response.data.id)
      } catch (error) {
        console.error('Erro ao cadastrar', error);

        if (error.response?.data) {
          // Verifica se error.response.data contém erroView
          if (error.response.data.erroView) {
            this.erros = {
              nome: ["Este campo não pode ser em branco."]
            };
          } else {
            // Define this.erros para error.response.data normalmente
            this.erros = error.response.data;
          }
        } else {
          this.erros = { geral: ["Ocorreu um erro desconhecido."] };
        }

        this.toggleErros();
      }
    },

    async cadastrarPessoaFisica(idPessoa) {
      try {
        const data = {
          pessoa: idPessoa,
          nome: this.dadosPessoaFisica.nome_razao,
          nome_social: this.dadosPessoaFisica.nome_social,
          cpf: this.dadosPessoaFisica.cpf ? this.dadosPessoaFisica.cpf.replace(/[^0-9]/g, '') : null,
          rg: this.dadosPessoaFisica.rg ? this.dadosPessoaFisica.rg.replace(/[^0-9]/g, '') : null,
          orgao_expeditor: this.dadosPessoaFisica.orgao_expeditor || '',
          nascimento: this.dadosPessoaFisica.nascimento || '',
          funcao: this.dadosStakeholders.funcao ? this.dadosStakeholders.funcao.map(item => item.id || item) : [],
          produtos_fornecedor: this.dadosFornecedor.produtos_fornecedor
        };

        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.post('pessoa/pessoa-fisica/', data, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        });
        console.log(response)
        this.sucesso = true
      } catch (error) {
        console.error('Erro ao cadastrar', error);
        this.erros = error.response?.data || { geral: ["Ocorreu um erro desconhecido."] };
        this.toggleErros();
      }
    },

    toggleErros() {
      this.exibirErros = !this.exibirErros;
    },

  }

}
</script>

<style lang="scss">
//estilo sessao
.sessao-cadastro {
  @apply flex justify-center md:justify-end md:mr-10
}

//estilo btn cadastrar
.btn-enviar {
  @apply bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo text-white hover:text-ativo py-2 px-4 mt-2
}

// cabeçalho estilo
.cabecalho-formulario {
  @apply flex justify-between mb-2
}

.titulo-cadastro {
  @apply text-xl text-corBase font-bold
}

// estilo formulario
.container-formulario {
  @apply flex flex-col bg-white p-4 mt-4 rounded-3xl w-[94%] mx-auto md:w-[89%] md:ml-20 md:mt-24 md:overflow-y-scroll md:h-[80vh] md:mb-10 lg:w-[92%] lg:overflow-y-scroll lg:h-[80vh] lg:left-[80px] lg:top-20 scrollbar-thin scrollbar-thumb-corBase/40
}

.container-campos {
  @apply md:flex md:gap-3
}

.campos-mobile {
  @apply flex flex-col mt-2 w-full
}

.campos-dasktop-select {
  @apply flex flex-col mt-2 w-80
}

.label-campos {
  @apply text-base text-corBase font-bold mt-2
}

.input-campos {
  @apply w-full bg-white border-2 border-corBase/5 border-b-corBase/20 p-2 text-corBase outline-none
}
</style>