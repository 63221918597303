<template>
    <div class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div class="bg-white p-8 rounded-lg shadow-lg w-1/3 max-w-lg">
            <div class="border-b mb-4">
                <h2 class="text-2xl font-semibold text-gray-800 text-center">Alterar Cor Base</h2>
            </div>

            <div class="space-y-4">


                <div class="w-full flex gap-2">
                    <div class="text-center flex flex-col items-center w-full">
                        <label for="fase_esquadria" class="block text-sm font-medium text-corBase mb-1">Status</label>
                        <div class="cursor-pointer flex items-center justify-center w-6 h-6"
                            @click.prevent="toggleAtivo">
                            <i :class="ativo ? 'fa-solid fa-circle-check text-ativo' : 'fa-solid fa-circle-xmark text-inativo'"
                                class="text-2xl"></i>
                        </div>
                    </div>
                </div>

            </div>

            <!-- Botões -->
            <div class="mt-6 flex justify-center space-x-4">
                <button class="btn-cancelar" @click.prevent="$emit('fecharModal')">
                    Fechar
                </button>
                <button class="btn-salvar" @click="salvarAlteracoes()">
                    Salvar Alterações
                </button>
            </div>
        </div>
    </div>

    <SucessoRapido v-if="sucesso === true" mensagem="Cores atualizadas com sucesso!" />
    <ErroRapido v-if="erro === true" mensagem="Ocorreu um erro ao atualizar Cores." />
</template>

<script>
import axios from 'axios'
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue';

export default {
    name: 'AlterarCores',
    data() {
        return {
            ativo: true,

            sucesso: false,
            erro: false,
        };
    },

    components: {
        SucessoRapido,
        ErroRapido,
    },

    props: ['cores'],

    emits: ['fecharModal', 'atualizarLista'],

    methods: {
        toggleAtivo() {
            this.ativo = !this.ativo
        },

        async salvarAlteracoes() {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');

            try {
                // Filtra apenas as cores que possuem alterações
                const patchPromises = this.cores
                    .filter(cor => cor.ativo !== undefined) // Verifica se o campo ativo está definido
                    .map(cor => {
                        const camposAtualizados = {
                            ativo: this.ativo,
                        };

                        return axios.patch(
                            `produtos/cor-produto-base/${cor.id}/`,
                            camposAtualizados,
                            {
                                headers: {
                                    'Authorization': `Token ${token}`,
                                },
                            }
                        );
                    });

                // Aguarda todas as requisições serem resolvidas
                if (patchPromises.length > 0) {
                    await Promise.all(patchPromises);
                }

                // Exibe mensagem de sucesso
                this.sucesso = true;
                setTimeout(() => {
                    this.sucesso = false;
                    this.$emit('fecharModal');
                    this.$emit('atualizarLista');
                }, 3000);
            } catch (error) {
                // Tratamento de erros
                console.error('Erro ao salvar alterações:', error);
                this.erro = true;
                setTimeout(() => {
                    this.erro = false;
                }, 3000);
            }
        },

    },
};
</script>

<style scoped>
.input-campos {
    @apply block w-full p-2 border rounded-md text-corBase;
}
</style>