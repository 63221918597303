<template>
  <section class=" flex justify-center md:block md:justify-end md:mr-10">
    <div class="container-formulario">

      <form @submit.prevent="cadastrarItemOrcamento"> <!--  @submit.prevent="cadastrarItemOrcamento" -->

        <ModalVariaveisOrcamento v-if="modalMensagemSucesso" :id="idNovoOrcamento" rotaVoltar="/orcamentos" />

        <MenssagemErro v-if="modalMensagemErro" :mensagem="mensagemErro" rotas="/orcamentos" />

        <!-- <MensagemSucesso
          v-if="modalMensagemSucesso"
          mensagem="Deu certo"
          rotas="/orcamentos"
        /> -->

        <div class="w-full mx-auto mt-8">
          <div class="space-y-4">
            <div>
              <button @click.prevent="toggleSection(1)"
                class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
                Detalhes do Projeto
              </button>

              <div v-if="activeSection === 1" class="dados-orcamento">
                <div class="flex gap-6">
                  <div class="flex flex-col w-full">
                    <label class="font-bold text-corBase text-xs">Descrição</label>
                    <input type="text" class="input-campos text-xs" v-model="descricaoItemOrcamento"> <!-- required -->
                  </div>
                </div>

                <div class="flex gap-6">
                  <div class="flex flex-col w-full">
                    <label class="font-bold text-corBase text-xs">Quantidade</label>
                    <input type="text" class="input-campos text-xs" v-model="quantidade"> <!-- required -->
                  </div>

                  <div class="flex flex-col w-full">
                    <label class="font-bold text-corBase text-xs">Largura (MM)</label>
                    <input type="text" class="input-campos text-xs" v-model="largura"> <!-- required -->
                  </div>

                  <div class="flex flex-col w-full">
                    <label class="font-bold text-corBase text-xs">Altura (MM)</label>
                    <input type="text" class="input-campos text-xs" v-model="altura"> <!-- required -->
                  </div>
                </div>

                <div class="flex gap-6">

                  <div class="flex flex-col w-full">
                    <label class="font-bold text-corBase">Cor Componentes</label>
                    <Select2 v-model="corComponentes" class="w-full text-xs z-10" :options="listaCorComponentes"
                      :settings="{ width: '100%' }" />
                  </div>

                  <div class="flex flex-col w-full">
                    <label class="font-bold text-corBase">Cor Perfis</label>
                    <Select2 v-model="corPerfis" class="w-full text-xs z-10" :options="listaCorPerfis"
                      :settings="{ width: '100%' }" />
                  </div>

                  <div class="flex flex-col w-full">
                    <label class="font-bold text-corBase">Cor Vidros</label>
                    <Select2 v-model="corVidros" class="w-full text-xs z-10" :options="listaCorVidros"
                      :settings="{ width: '100%' }" />
                  </div>
                </div>

                <div class="flex gap-6">
                  <div class="flex flex-col w-full">
                    <label class="font-bold text-corBase text-xs">Ambiente</label>
                    <input type="text" class="input-campos text-xs" v-model="ambiente">
                  </div>

                  <div class="flex flex-col w-full">
                    <label class="font-bold text-corBase text-xs">Tipo</label>
                    <input type="text" class="input-campos text-xs" v-model="tipo">
                  </div>

                  <div class="flex flex-col w-full">
                    <label class="font-bold text-corBase text-xs">Data Entrega</label>
                    <input type="date" class="input-campos text-xs" v-model="dataEntrega">
                  </div>
                </div>

                <div class="flex gap-6">
                  <div class="flex flex-col w-full">
                    <label class="font-bold text-corBase text-xs">Obs. do Projeto para o cliente</label>
                    <input type="text" class="input-campos text-xs" v-model="obsProjetoParaCliente">
                  </div>

                  <div class="flex flex-col w-full">
                    <label class="font-bold text-corBase text-xs">Obs. para tempera</label>
                    <input type="text" class="input-campos text-xs" v-model="obsParaTempera">
                  </div>

                  <div class="flex flex-col w-full">
                    <label class="font-bold text-corBase text-xs">Obs. para Produção</label>
                    <input type="text" class="input-campos text-xs" v-model="obsParaProd">
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="w-full mx-auto mt-8">
          <div class="space-y-4">
            <div>
              <button @click.prevent="toggleSection(2)"
                class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
                Outras Informações
              </button>

              <div v-if="activeSection === 2" class="dados-orcamento">
                <div class="flex gap-6">
                  <div class="flex flex-col w-full">
                    <label class="font-bold text-corBase text-xs">Status das Medidas</label>
                    <select name="status-medidas" class="input-campos text-xs" v-model="statusMedidas">
                      <option value="1" selected>1 - Medidas Informadas pelo cliente(a medir)</option>
                      <option value="2">2 - Medidas Informadas pelo cliente(vão acabado)</option>
                      <option value="3">3 - Medidas do vão sem acabamento</option>
                      <option value="4">4 - Medidas do vão acabado</option>
                    </select>
                  </div>

                  <div class="flex flex-col w-full">
                    <label class="font-bold text-corBase text-xs">Enviar para Tempera</label>
                    <input type="checkbox" v-model="enviarParaTempera">
                  </div>

                  <div class="flex flex-col w-full">
                    <label class="font-bold text-corBase text-xs">Medida Final Tempera</label>
                    <input type="checkbox" v-model="medidaFinalTempera">
                  </div>
                </div>
              </div>
            </div>

            <div class="flex w-full gap-8 justify-end">
              <button class="btn-enviar !bg-inativo" @click.prevent="resetaOsCampos">Cancelar</button>
              <button class="btn-enviar" type="submit">Salvar</button>
            </div>
          </div>
        </div>

      </form>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
import MenssagemErro from '../toasts/toast_erro/MenssagemErro.vue'
import MensagemSucesso from '../toasts/toast_sucesso/MensagemSucesso.vue'
import ModalVariaveisOrcamento from './ModalVariaveisOrcamento.vue'
import Select2 from "vue3-select2-component"

export default {
  data() {
    return {
      quantidade: '',
      largura: '',
      altura: '',
      corComponentes: '',
      corPerfis: '',
      corVidros: '',
      ambiente: '',
      tipo: '',
      dataEntrega: '',
      obsProjetoParaCliente: '',
      obsParaTempera: '',
      obsParaProd: '',
      statusMedidas: 1,
      enviarParaTempera: false,
      medidaFinalTempera: false,

      listaCorComponentes: [],
      listaCorPerfis: [],
      listaCorVidros: [],

      novoItemOrcamento: {},
      idNovoOrcamento: '',

      activeSection: 1,
      mensagemSucesso: '',
      modalMensagemSucesso: false,
      mensagemErro: '',
      modalMensagemErro: false,
    }
  },

  components: {
    MenssagemErro,
    MensagemSucesso,
    Select2,
    ModalVariaveisOrcamento
  },

  created() {
    this.getlistaCorComponentes()
    this.getlistaCorPerfis()
    this.getlistaCorVidros()
  },

  computed: {
    projetoId() {
      return this.$store.state.projetoIdAtual
    },

    orcamentoId() {
      return this.$store.state.orcamentoIdAtual
    },

    descricaoItemOrcamento() {
      return this.$store.state.descricaoItemOrcamento
    },
  },

  methods: {
    abrirModal() {
      this.modalMensagemSucesso = true
    },

    toggleSection(sectionNumber) {
      this.activeSection = this.activeSection === sectionNumber ? null : sectionNumber;
    },

    resetaOsCampos() {
      this.quantidade = ''
      this.largura = ''
      this.altura = ''
      this.corComponentes = ''
      this.corPerfis = ''
      this.corVidros = ''
      this.ambiente = ''
      this.tipo = ''
      this.dataEntrega = ''
      this.obsProjetoParaCliente = ''
      this.obsParaTempera = ''
      this.obsParaProd = ''
      this.statusMedidas = ''
      this.enviarParaTempera = false
      this.medidaFinalTempera = false
    },

    async getlistaCorComponentes() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get('produtos/cor-componente/?page_size=999', {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        this.listaCorComponentes = response.data.results.map(response => {
          return {
            id: response.id,
            text: response.descricao
          }
        })
      } catch (error) {
        console.error(error)
      }
    },

    async getlistaCorPerfis() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get('produtos/cor-perfil/?page_size=999', {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        this.listaCorPerfis = response.data.results.map(response => {
          return {
            id: response.id,
            text: response.descricao
          }
        })
      } catch (error) {
        console.error(error)
      }
    },

    async getlistaCorVidros() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get('produtos/cor-vidro/?page_size=999', {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        this.listaCorVidros = response.data.results.map(response => {
          return {
            id: response.id,
            text: response.descricao
          }
        })
      } catch (error) {
        console.error(error)
      }
    },

    async cadastrarOrcamento() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.post('orcamentos/', {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

      } catch (error) {
        this.mensagemErro = error.message
        this.modalMensagemErro = true
      }
    },

    // ESSE AQUI
    async cadastrarItemOrcamento() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const data = {
          orcamento: this.orcamentoId,
          projeto: this.projetoId,
          descricao: this.descricaoItemOrcamento,
          quantidade: this.quantidade,
          largura: this.largura,
          altura: this.altura,
          cor_componentes: this.corComponentes,
          cor_perfis: this.corPerfis,
          cor_vidros: this.corVidros,
          ambiente: this.ambiente,
          tipo: this.tipo,
          obs_projeto_para_cliente: this.obsProjetoParaCliente,
          obs_para_tempera: this.obsParaTempera,
          obs_para_prod: this.obsParaProd,
          status_medidas: this.statusMedidas,
          enviar_para_tempera: this.enviarParaTempera,
          medida_final_tempera: this.medidaFinalTempera
        }

        this.dataEntrega ? data.data_entrega = this.dataEntrega : ''

        const response = await axios.post('orcamentos/item-orcamento/', data, {
          headers: {
            Authorization: `Token ${token}`
          }
        })

        this.novoItemOrcamento = response.data

        this.idNovoOrcamento = this.novoItemOrcamento.id

        this.mensagemSucesso = 'Item do orçamento criado com sucesso'
        this.modalMensagemSucesso = true

      } catch (error) {
        this.mensagemErro = error.message
        console.error(error)
        this.modalMensagemErro = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dados-orcamento {
  @apply p-4 flex flex-col gap-5 border border-corBase
}
</style>
