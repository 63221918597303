<template>
  <div class="flex flex-col gap-3 md:flex md:flex-row md:gap-3">
    <div class="flex flex-col mt-2 w-full">
      <label class="label-campos">CNPJ:</label>
      <input type="text" class="input-campos" placeholder="xx.xxx.xxx/xxxx-xx" v-mask="'##.###.###/####-##'"
        v-model="dadosPessoaJuridica.cnpj" @input="buscarCnpjAutomaticamente">
    </div>

    <div class="flex flex-col mt-2 w-full">
      <label class="label-campos">Inscrição Estadual:</label>
      <input type="text" class="input-campos" placeholder="" v-model="dadosPessoaJuridica.inscricao_estadual">
    </div>

    <div class="flex flex-col mt-2 w-full">
      <label class="label-campos">Suframa:</label>
      <input type="text" class="input-campos" placeholder="" v-model="dadosPessoaJuridica.suframa">
    </div>

    <div class="flex flex-col mt-2 w-full">
      <label class="label-campos">Situação Fiscal:</label>
      <select class="input-campos" v-model="dadosPessoaJuridica.situacao_fiscal">
        <option value="LR">Lucro Real</option>
        <option value="LP">Lucro Presumido</option>
        <option value="SN">Simples Nacional</option>
        <option value="SE">Simples Nacional, excesso sublimite de receita bruta</option>
      </select>
    </div>

    <div class="flex flex-col mt-2 w-full">
      <label class="label-campos">Data de Constituição:</label>
      <input type="date" class="input-campos" v-model="dadosPessoaJuridica.constituicao">
    </div>
  </div>

  <div class="flex flex-col gap-3 md:flex md:flex-row md:gap-3">

    <!-- Linha 2 -->
    <div class="flex flex-col mt-2 w-full">
      <label class="label-campos">Razão Social:</label>
      <input type="text" class="input-campos" placeholder="" v-model="dadosPessoaJuridica.razao_social">
    </div>

    <div class="flex flex-col mt-2 w-full">
      <label class="label-campos">Nome Fantasia:</label>
      <input type="text" class="input-campos" placeholder="" v-model="dadosPessoaJuridica.nome_fantasia">
    </div>
  </div>

  <div class="flex flex-col gap-3 md:flex md:flex-row md:gap-3">

    <!-- Linha 3 -->
    <div class="flex flex-col gap-3 w-full">
      <div class="flex flex-col mt-2 w-full">
        <label class="label-campos">Responsável:</label>
        <div class="flex items-center">
          <div class="w-2/3">
            <v-select :options="lista_responsaveis" v-model="novoResponsavel" label="nome_razao" class="w-full z-10"
              :reduce="responsavel => responsavel.id" />
          </div>
          <div class="w-1/3 flex ml-3">
            <button @click.prevent="adicionarResponsavel" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
            text-white hover:text-ativo py-2 px-4 ">
              <i class="fa-solid fa-check"></i>
            </button>
          </div>
        </div>

        <div v-if="dadosPessoaJuridica.responsavel.length > 0" class="mt-4">
          <p class="font-semibold text-gray-700 mb-2">Lista de responsáveis:</p>
          <ul class="space-y-2">
            <li v-for="(responsavel, index) in dadosPessoaJuridica.responsavel" :key="responsavel.id"
              class="flex items-center justify-between bg-white p-3 rounded-md border border-gray-200 shadow-sm">
              <span class="text-gray-700 font-medium">{{ responsavel.nome_razao }}</span>
              <button @click="removerResponsavel(index)" class="text-red-500 hover:text-red-700 font-semibold">
                Remover
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>

  </div>

  <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros()" />
</template>


<script>
import TipoPessoaGeralModal from "@/components/midais/modaisCadastro/TipoPessoaGeralModal";
import PessoaFisicaModal from "@/components/midais/modaisCadastro/PessoaFisicaModal";
import ListagemErros from "@/components/toasts/toast_erro/ListagemErros.vue";
import axios from "axios";
import vSelect from "vue-select";
import debounce from "lodash.debounce";

export default {
  name: "PessoaJuridicaCampos",


  components: {
    TipoPessoaGeralModal,
    PessoaFisicaModal,
    ListagemErros,
    vSelect,
  },

  props: ['dadosPessoaJuridica'],

  data() {
    return {
      lista_responsaveis: [],
      novoResponsavel: null,

      exibirErros: false,
      erros: [],
    }
  },

  computed: {
    isadmin() {
      return this.$store.state.isAdminUser
    },
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
  },

  created() {
    this.getResponsaveis()
  },

  methods: {

    // async getResponsaveis() {

    //   const token = await this.$store.dispatch('getAuthTokenCookie')
    //   await this.$store.dispatch('fetchURLrequest')

    //   await axios.get('pessoas/?atividade=Sócio&page_size=9999', {
    //     headers: {
    //       'Authorization': `Token ${token}`
    //     }
    //   })
    //     .then(response => {
    //       this.lista_responsaveis = response.data.results
    //       console.log(this.lista_responsaveis)
    //     })
    //     .catch(error => {
    //       console.error(error)
    //     })
    // },

    async getResponsaveis() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      try {
        const response = await axios.get('pessoas/?atividade=Sócio&page_size=9999', {
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        // console.log(response.data.results)
        this.lista_responsaveis = response.data.results
      } catch (error) {
        console.error(error)
      }
    },

    adicionarResponsavel() {
      if (
        this.novoResponsavel &&
        !this.dadosPessoaJuridica.responsavel.some(
          (responsavel) => responsavel.id === this.novoResponsavel
        )
      ) {
        const responsavel = this.lista_responsaveis.find(
          (item) => item.id === this.novoResponsavel
        );
        this.dadosPessoaJuridica.responsavel.push(responsavel);
        this.novoResponsavel = null;
      } else if (this.novoResponsavel) {
        this.erros = { responsavel: ['Este responsável já foi adicionado.'] }
        this.toggleErros()
      } else {
        this.erros = { geral: ['Ocorreu um erro ao adicionar responsável.'] }
        this.toggleErros()
      }
    },

    removerResponsavel(index) {
      this.dadosPessoaJuridica.responsavel.splice(index, 1);
    },

    buscarCnpjAutomaticamente: debounce(async function () {
      if (this.dadosPessoaJuridica.cnpj.length === 18) {
        await this.buscarCnpj(this.dadosPessoaJuridica.cnpj);
      }
    }, 500),

    async buscarCnpj(cnpj) {
      const token = await this.$store.state.token
      await this.$store.dispatch('fetchURLrequest')

      const cnpjSemMascara = cnpj.replace(/[^0-9]/g, '')
      await axios.get(`cnpj/${cnpjSemMascara}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.dadosPessoaJuridica.nome_fantasia = response.data.fantasia
          this.dadosPessoaJuridica.razao_social = response.data.nome

        })
        .catch(error => {
          console.error('Erro ao buscar CNPJ:', error);
        });
    },

    toggleErros() {
      this.exibirErros = !this.exibirErros;
    },

  }
}
</script>

<style scoped></style>