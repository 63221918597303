<template>
  <div>
    <div class="container-modal-editar">
      <div class="sombra-modal">
        <div class="conteudo-modal-editar">
          <form class="form-editar text-white">
            <div class="flex justify-end">
              <button @click.prevent="$emit('fecharModal')">
                <i class="fas fa-close text-2xl text-corBase"></i>
              </button>
            </div>
            <h2 class="text-lg text-corBase font-bold mb-2">Editar Dados - Pessoa Jurídica</h2>

            <label class="text-base text-corBase font-bold">Relacionamento:</label>
            <div class="flex items-center mb-4">
              <v-select :options="lista_atividades" v-model="dadosPessoaJuridica.atividade" label="nome"
                :multiple="true" class="w-full z-10" />
            </div>


            <pessoa-juridica-campos :dadosPessoaJuridica="dadosPessoaJuridica" />

            <enderecos-campos :dadosEndereco="dadosEndereco" />

            <telefones-campos :dadosTelefone="dadosTelefone" />

            <emails-campos :dadosEmail="dadosEmail" />

            <interacao-online-campos :dadosInteracao="dadosInteracao" />

            <stakeholders-campos v-if="exibirStakeholders" :dadosStakeholders="dadosStakeholders" />

            <fornecedores-campos v-if="exibirFornecedores" :dadosFornecedor="dadosFornecedor" />

            <!--Botão-->
            <div class="btn-formulario-modal mt-4 flex justify-end">
              <button class="btn-formulario-salvar" @click.prevent="putDadosEditado">
                Salvar
              </button>
            </div>
          </form>
        </div>
      </div>
      <SucessoRapido v-if="sucesso === true" mensagem="Pessoa Jurídica atualizada com sucesso!" />
    </div>
  </div>
</template>

<script>
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import vSelect from "vue-select";
import axios from "axios";
import ListagemErros from "@/components/toasts/toast_erro/ListagemErros.vue";
import TipoEnderecoModal from "@/components/midais/modaisCadastro/TipoEnderecoModal";
import TipoTelefoneModal from "@/components/midais/modaisCadastro/TipoTelefoneModal";
import SucessoCadastroPessoaJuridica from "@/components/toasts/toast_sucesso/SucessoCadastroPessoaJuridica";
import EnderecosCampos from "@/components/campos_cadastro/EnderecosCampos";
import TelefonesCampos from "@/components/campos_cadastro/TelefonesCampos";
import PessoaJuridicaCampos from "@/components/campos_cadastro/PessoaJuridicaCampos";
import EmailsCampos from "@/components/campos_cadastro/EmailsCampos";
import InteracaoOnlineCampos from "@/components/campos_cadastro/InteracaoOnlineCampos.vue";
import StakeholdersCampos from "@/components/campos_cadastro/StakeholdersCampos.vue";
import FornecedoresCampos from "@/components/campos_cadastro/FornecedoresCampos.vue";
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";

export default {
  name: "EditarDadosPessoaJuridica",
  components: {
    SucessoRapido,
    vSelect,
    ListagemErros,
    SucessoCadastroPessoaJuridica,
    TipoTelefoneModal,
    TipoEnderecoModal,
    EnderecosCampos,
    TelefonesCampos,
    PessoaJuridicaCampos,
    EmailsCampos,
    InteracaoOnlineCampos,
    StakeholdersCampos,
    FornecedoresCampos,
    MensagemSucesso
  },
  data() {
    return {
      listaSituacaoFiscal: [
        { descricao: "Lucro Real", value: "LR" },
        { descricao: "Lucro Presumido", value: "LP" },
        { descricao: "Simples Nacional", value: "SN" },
        { descricao: "Simples Nacional, excesso de receita", value: "SE" },
      ],
      listaUF: [
        "AC", "AL", "AP", "AM", "BA", "CE", "DF", "ES", "GO", "MA",
        "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN",
        "RS", "RO", "RR", "SC", "SP", "SE", "TO",
      ],

      lista_atividades: [],

      dadosPessoaJuridica: {
        cnpj: '',
        atividade: [],
        razao_social: '',
        nome_fantasia: '',
        responsavel: [],
        constituicao: '',
        inscricao_estadual: '',
        suframa: '',
        situacao_fiscal: ''
      },

      dadosEndereco: [
        {
          logadouro: '',
          numero: '',
          complemento: '',
          cep: '',
          bairro: '',
          cidade: '',
          uf: '',
          referencia: '',
          tipo_endereco: '',
          cidades: []
        }
      ],

      dadosTelefone: [
        {
          ddd: '',
          telefone: '',
          whatsapp: false,
          tipo_telefone: '',
        }
      ],

      dadosEmail: [{
        email_pessoa: '',
        tipo_email: '',
      }],

      dadosInteracao: [{
        endereco_interacao: '',
        tipo_interacao: '',
      }],

      dadosStakeholders: [{
        funcao: [],
      }],

      dadosFornecedor: [{
        produtos_fornecedor: [],
      }],


      exibirStakeholders: false,

      exibirFornecedores: false,


      tipo_endereco: [],
      tipo_pessoa: [],
      tipo_email: [],
      tipo_telefone: [],

      sucesso: false,

      dados: {
        pessoa_juridica: [],
        endereco: [],
        telefone: [],
        email: [],
        responsavel: [],
      }
    }
  },

  props: ["dadosPessoa"],
  emits: ["fecharModal", "atualizarLista"],

  watch: {
    'dadosPessoaJuridica.atividade': {
      handler(newValue) {
        console.log('newValue: ', newValue)
        if (newValue.some(atividade => atividade.nome === 'Stakeholders')) {
          this.exibirStakeholders = true
          console.log('exibir stakeholders true')
        } else {
          this.exibirStakeholders = false
          console.log('exibir stakeholders true')
        }

        if (newValue.some(atividade => atividade.nome === 'Fornecedor')) {
          this.exibirFornecedores = true
          console.log('exibir fornecedor true')
        } else {
          this.exibirFornecedores = false
          console.log('exibir fornecedor false')
        }

        console.log('exibirstakeholders:', this.exibirStakeholders)
        console.log('exibirfornecedores:', this.exibirFornecedores)
      },
      immediate: false,
      deep: true,
    },
  },

  created() {
    console.log(this.dadosPessoa)
    // this.dados.pessoa_juridica = this.dadosPessoa.pessoa_juridica || []
    // this.dados.endereco = this.dadosPessoa.pessoa_juridica.endereco_pessoa[0] || []
    // this.dados.telefone = this.dadosPessoa.pessoa_juridica.telefone_pessoa[0] || []
    // this.dados.email = this.dadosPessoa.pessoa_juridica.email_pessoa[0] || []
    // this.dados.responsavel = this.dadosPessoa.pessoa_juridica.responsavel || []
    // this.dados.tipo_pessoa = this.dadosPessoa.tipo_pessoa_geral_id


    this.dadosPessoaJuridica.razao_social = this.dadosPessoa.razao_social
    this.dadosPessoaJuridica.nome_fantasia = this.dadosPessoa.nome_fantasia
    this.dadosPessoaJuridica.atividade = this.dadosPessoa.pessoa.atividade
    this.dadosPessoaJuridica.responsavel = this.dadosPessoa.responsavel
    this.dadosPessoaJuridica.cnpj = this.dadosPessoa.cnpj
    this.dadosPessoaJuridica.inscricao_estadual = this.dadosPessoa.inscricao_estadual
    this.dadosPessoaJuridica.constituicao = this.dadosPessoa.constituicao
    this.dadosPessoaJuridica.orgao_expeditor = this.dadosPessoa.orgao_expeditor
    this.dadosPessoaJuridica.situacao_fiscal = this.dadosPessoa.situacao_fiscal
    this.dadosPessoaJuridica.suframa = this.dadosPessoa.suframa
    this.dadosPessoaJuridica.pessoa = this.dadosPessoa.pessoa.id

    if (this.dadosPessoaJuridica.atividade &&
      this.dadosPessoaJuridica.atividade.some(atividade => atividade.nome === 'Stakeholders')) {
        this.dadosStakeholders.funcao = this.dadosPessoa.funcao
      this.exibirStakeholders = true;
    } else {
      this.exibirStakeholders = false;
    }

    if (this.dadosPessoaJuridica.atividade &&
      this.dadosPessoaJuridica.atividade.some(atividade => atividade.nome === 'Fornecedor')) {
      this.dadosFornecedor.produtos_fornecedor = this.dadosPessoa.produtos_fornecedor;
      this.exibirFornecedores = true;
    } else {
      this.exibirFornecedores = false;
    }

    this.getListaAtividades()

    this.getEnderecos(this.dadosPessoaJuridica.pessoa)
    this.getTelefones(this.dadosPessoaJuridica.pessoa)
    this.getEmails(this.dadosPessoaJuridica.pessoa)
    this.getInteracoes(this.dadosPessoaJuridica.pessoa)

    this.getTipoPessoa()
    // this.getListaPessoas()
    this.getTipoEndereco()
    this.getTipoEmail()
    this.getTipoTelefone()
  },
  methods: {

    async getEnderecos(pessoa) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      try {
        this.$store.dispatch("setLoading", true);
        const response = await axios.get(`pessoas/enderecos/?pessoa=${pessoa}`, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        })

        this.dadosEndereco = response.data.results
        console.log(this.dadosEndereco)
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    async getTelefones(pessoa) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      try {
        this.$store.dispatch("setLoading", true);
        const response = await axios.get(`pessoas/telefones/?pessoa=${pessoa}`, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        })

        this.dadosTelefone = response.data.results
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    async getEmails(pessoa) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      try {
        this.$store.dispatch("setLoading", true);
        const response = await axios.get(`pessoas/email/?pessoa=${pessoa}`, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        })

        this.dadosEmail = response.data.results
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    async getInteracoes(pessoa) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      try {
        this.$store.dispatch("setLoading", true);
        const response = await axios.get(`pessoas/interacoes/?pessoa=${pessoa}`, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        })

        this.dadosInteracao = response.data.results
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },


    async getListaAtividades() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      try {
        const response = await axios.get(`/pessoas/atividade/`, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        })

        const nomesDesejados = ['Cliente', 'Fornecedor', 'Stakeholders', 'Revendedor'];
        this.lista_atividades = response.data.results.filter(atividade =>
          nomesDesejados.includes(atividade.nome)
        );
        console.log(this.lista_atividades)

        this.id_stakeholders = this.lista_atividades.filter(atividade =>
          atividade.nome == 'Stakeholders'
        )[0].id;
        console.log(`ID Stakeholder:`)
        console.log(this.id_stakeholders)
      } catch (error) {
        console.error(error)
      }
    },


    async putDadosEditado() {
      const data = {
          nome_razao: this.dadosPessoaJuridica.razao_social,
          atividade: this.dadosPessoaJuridica.atividade.map(atividade => atividade.id),
          identificacao: this.dadosPessoaJuridica.cnpj || '',
          enderecos: this.dadosEndereco,
          telefones: this.dadosTelefone,
          emails: this.dadosEmail,
          interacoes: this.dadosInteracao,
        };

      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')
        const response = await axios.patch(`pessoas/update/${this.dadosPessoaJuridica.pessoa}/`, data, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        });
        console.log(response.data);
        // this.sucesso = true;
        await this.atualizarPessoaJuridica(response.data.id)
        // setTimeout(() => {
        //   this.sucesso = false;
        //   // this.$emit('fecharModal')
        // }, 3000);

      } catch (error) {
        console.error(error)
      }
    },

    async atualizarPessoaJuridica(idPessoa) {
      try {
        const data = {
          pessoa: idPessoa,
          razao_social: this.dadosPessoaJuridica.razao_social,
          nome_fantasia: this.dadosPessoaJuridica.nome_fantasia,
          cnpj: this.dadosPessoaJuridica.cnpj ? this.dadosPessoaJuridica.cnpj.replace(/[^0-9]/g, '') : null,
          inscricao_estadual: this.dadosPessoaJuridica.inscricao_estadual,
          suframa: this.dadosPessoaJuridica.suframa,
          // responsavel: this.dadosPessoaJuridica.responsavel,
          responsavel: this.dadosPessoaJuridica.responsavel.map(responsavel => responsavel.id),
          situacao_fiscal: this.dadosPessoaJuridica.situacao_fiscal,
          funcao: this.dadosStakeholders.funcao ? this.dadosStakeholders.funcao.map(item => item.id || item) : [],
          produtos_fornecedor: this.dadosFornecedor.produtos_fornecedor ? this.dadosFornecedor.produtos_fornecedor.map(item => item.id || item) : []
        
        };

        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.patch(`pessoa/pessoa-juridica/${this.dadosPessoa.id}/`, data, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        });
        console.log(response)
        this.sucesso = true
        setTimeout(() => {
          this.sucesso = false;
          this.$emit('fecharModal')
          this.$emit('atualizarLista')
        }, 3000);
      } catch (error) {
        console.error('Erro ao cadastrar', error);
        this.erros = error.response?.data || { geral: ["Ocorreu um erro desconhecido."] };
        this.toggleErros();
      }
    },

    async getTipoPessoa() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('pessoas/tipo-pessoas', {
        headers: {
          'Authorization': `Token ${token}` // Define o cabeçalho Authorization com o token
        }
      })
        .then(response => {
          this.dados.tipo_pessoa = response.data
        })
        .catch(error => {
          console.error(error)
        })
    },

    async getTipoEndereco() {
      const token = await this.$store.dispatch('getAuthTokenCookie'); // Obtém o token do localStorage
      await this.$store.dispatch('fetchURLrequest')
      await axios.get('pessoas/enderecos/tipo-enderecos', {
        headers: {
          'Authorization': `Token ${token}` // Define o cabeçalho Authorization com o token
        }
      })
        .then(response => {
          this.dados.tipo_endereco = response.data
        })
        .catch(error => {
          console.error(error)
        })
    },

    async getTipoEmail() {
      const token = await this.$store.dispatch('getAuthTokenCookie'); // Obtém o token do localStorage
      await this.$store.dispatch('fetchURLrequest')
      await axios.get('pessoas/email/tipo-email', {
        headers: {
          'Authorization': `Token ${token}` // Define o cabeçalho Authorization com o token
        }
      })
        .then(response => {
          this.dados.tipo_email = response.data
        })
        .catch(error => {
          console.error(error)
        })
    },

    async getTipoTelefone() {
      const token = await this.$store.dispatch('getAuthTokenCookie'); // Obtém o token do localStorage
      await this.$store.dispatch('fetchURLrequest')
      await axios.get('pessoas/telefones/tipo-telefones', {
        headers: {
          'Authorization': `Token ${token}` // Define o cabeçalho Authorization com o token
        }
      })
        .then(response => {
          this.dados.tipo_telefone = response.data
        })
        .catch(error => {
          console.error(error)
        })
    },

  }
}
</script>

<style>
.container-modal-editar {
  @apply fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full
}

.conteudo-modal-editar {
  @apply bg-screenCor p-6 w-[75%] shadow-xl shadow-screenCor/30 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-[80vh] overflow-y-scroll
}

.form-editar {
  @apply text-corBase
}

.titulo-cadastro {
  @apply text-corBase
}

.btn-formulario-salvar {
  @apply bg-ativo py-2 px-4 rounded-sm text-screenCor
}
</style>