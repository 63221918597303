<template>
    <div>
        <div class="container-modal-editar">
            <div class="sombra-modal">
                <div class="conteudo-modal-editar">
                    <form class="form-editar text-white" @submit.prevent="putDadosEditado">
                        <div class="flex justify-end">
                            <button @click.prevent="$emit('fecharModal')">
                                <i class="fas fa-close text-2xl text-corBase"></i>
                            </button>
                        </div>
                        <h2 class="text-lg text-corBase font-bold mb-2">Editar Fase Esquadria</h2>
                        <!--Campos da Pessoa-->
                        <div>
                            <div class="md:flex md:gap-12">
                                <div class="flex flex-col mt-2 w-full justify-start">
                                    <label class="text-base text-corBase font-bold mt-2">Descricao:</label>
                                    <input type="text" required class="input-campos" v-model="fase_esquadria.descricao">
                                </div>

                                <div class="flex flex-col mt-2 w-full justify-start ">
                                    <label class="text-base text-corBase font-bold mt-2">Ativo:</label>

                                    <span @click.prevent="toggleAtivo" class="text-start pt-1 text-xl">
                                        <i v-if="fase_esquadria.ativo === true"
                                            class="fa-solid fa-circle-check text-ativo"></i>
                                        <i v-else class="fa-solid fa-circle-xmark text-inativo"></i>
                                    </span>
                                </div>


                            </div>

                        </div>

                        <!--Botão-->
                        <div class="btn-formulario-modal">
                            <button class="btn-formulario-salvar" type="submit">
                                Salvar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <SucessoRapido v-if="sucesso === true" mensagem="Fase atualizada com sucesso." />
            <ErroRapido v-if="erro === true" mensagem="Ocorreu um erro ao atualizar Fase." />
        </div>
    </div>
</template>

<script>
import axios from "axios";
import ErroRapido from "@/components/toasts/toast_erro/ErroRapido";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";

export default {
    name: "EditarFasesEsquadria",
    components: {
        ErroRapido,
        SucessoRapido
    },
    data() {
        return {
            sucesso: false,
            erro: false,

        }
    },
    props: ["fase_esquadria", "paginaAtual"],
    emits: ["fecharModal", "atualizarLista"],

    methods: {
        toggleAtivo(event) {
            event.preventDefault()
            this.fase_esquadria.ativo = !this.fase_esquadria.ativo
        },

        async putDadosEditado() {
            const dadosAtualizados = new FormData();
            dadosAtualizados.append('ativo', this.fase_esquadria.ativo)
            dadosAtualizados.append('descricao', this.fase_esquadria.descricao)


            try {
                const token = await this.$store.dispatch('getAuthTokenCookie');
                await this.$store.dispatch('fetchURLrequest')
                const response = await axios.put(`projeto/fase-esquadria/${this.fase_esquadria.id}/`, dadosAtualizados, {
                    headers: {
                        'Authorization': `Token ${token}`,
                    }
                });

                this.sucesso = true
                setTimeout(() => {
                    this.sucesso = false;
                    this.$emit('atualizarLista')
                    this.$emit('fecharModal')
                }, 3000);

            } catch (error) {
                console.error(error)
                this.erro = true
                setTimeout(() => {
                    this.erro = false
                }, 3000);
            }
        },

    }
}
</script>

<style>
.container-modal-editar {
    @apply fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full
}

.conteudo-modal-editar {
    @apply bg-screenCor p-6 w-[75%] shadow-xl shadow-screenCor/30 fixed top-[55%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-[80vh] overflow-y-scroll scrollbar-thin scrollbar-thumb-screenCor/40
}

.form-editar {
    @apply text-corBase
}
</style>