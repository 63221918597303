<template>
    <div>
        <div class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div class="bg-white p-6 rounded-lg shadow-lg w-1/3 text-center">
                <form class="form-editar text-white" @submit.prevent="putDadosEditado">
                    <div class="flex justify-end">
                        <button @click.prevent="$emit('fecharModal')">
                            <i class="fas fa-close text-2xl text-corBase"></i>
                        </button>
                    </div>
                    <h2 class="text-lg text-corBase font-bold mb-2">Clonar Item - {{ produto }}</h2>

                    <!-- {{ itemParaCopiar.index }} -->

                    <div class="text-center">
                        <p class="text-sm font-bold mb-4">Deseja que o item clonado fique acima ou abaixo do
                            original?</p>
                        <div class="flex items-center justify-center gap-6">
                            <button type="button"
                                class="flex items-center justify-center bg-gray-700 hover:bg-gray-600 text-white p-3 rounded-full"
                                @click="posicaoClone = 'acima'">
                                <i class="fas fa-arrow-up text-xl"></i>
                            </button>
                            <span class="text-sm text-corBase font-bold">{{ posicaoClone === 'acima' ? 'Acima' :
                                posicaoClone === 'abaixo' ? 'Abaixo' : 'Escolha uma posição' }}</span>
                            <button type="button"
                                class="flex items-center justify-center bg-gray-700 hover:bg-gray-600 text-white p-3 rounded-full"
                                @click="posicaoClone = 'abaixo'">
                                <i class="fas fa-arrow-down text-xl"></i>
                            </button>
                        </div>
                    </div>

                    <!-- Botão -->
                    <div class="flex justify-center mt-6">
                        <button class="btn-formulario-salvar" type="submit">
                            Salvar
                        </button>
                    </div>
                </form>
            </div>
            <SucessoRapido v-if="sucesso === true" :mensagem="`${produto} clonado com sucesso!`" />
            <ErroRapido v-if="erro === true" :mensagem="erroMessage" />
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue';

export default {
    name: "ClonarItemModal",

    data() {
        return {
            posicaoClone: null,
            sucesso: false,
            erro: false,
            erroMessage: ''
        };
    },

    props: ['itemParaCopiar', 'produto'],

    emits: ['fecharModal', 'atualizarLista'],

    components: {
        SucessoRapido,
        ErroRapido
    },

    created() {
        console.log(this.itemParaCopiar)
    },

    methods: {
        async putDadosEditado() {
            if (!this.posicaoClone) {
                this.erro = true;
                this.erroMessage = 'Defina uma posição para o item clonado.'
                return;
            }

            if (this.posicaoClone === 'abaixo') {
                this.itemParaCopiar.index = (this.itemParaCopiar.index || 0);
            } else if (this.posicaoClone === 'acima') {
                this.itemParaCopiar.index = Math.max((this.itemParaCopiar.index || 0) - 1, 0);
            } else {
                console.error("Posição inválida: ", this.posicaoClone);
            }


            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')
            try {
                const response = await axios.post(
                    `projeto/projeto-${this.produto.toLowerCase()}-clone/${this.itemParaCopiar.id}/`,
                    this.itemParaCopiar,
                    {
                        headers: {
                            Authorization: `Token ${token}`
                        }
                    }
                );

                this.sucesso = true;
                setTimeout(() => {
                    this.sucesso = false;
                    this.$emit("fecharModal");
                    this.$emit('atualizarLista')
                }, 3000);
            } catch (error) {
                console.error("Erro ao clonar item:", error);
                this.erro = true;
                this.erroMessage = `Erro ao clonar ${this.produto}.`
                setTimeout(() => {
                    this.erro = false;
                    this.erroMessage = ''
                }, 3000);
            }
        },
    },
};
</script>

<style scoped></style>