<template>


  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Tipos do Produto</h1>
      </div>

      <router-link to="/configuracoes-produto" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>
    <div class="flex items-center gap-3 mb-10">

      <div class="block">

        <div class="flex gap-2">
          <div class="w-full">
            <label class="text-xl text-corBase">Produto:</label>
            <v-select class="" v-model="tipo_produto"
              :options="['Perfil', 'Chapa', 'Vidro', 'Uso Consumo', 'Componente', 'Serviço']"></v-select>
          </div>

          <div class="w-full">
            <label class="text-xl text-corBase">Código:</label>
            <input type="text" class="input-form-chapa" v-model="codigo">
          </div>

          <div class="w-full">
            <label class="text-xl text-corBase">Descrição:</label>
            <input type="text" class="input-form-chapa" v-model="descricao">
          </div>

          <div class="w-full">
            <label class="text-xl text-corBase">Status:</label>
            <v-select :options="lista_status" label="nome" v-model="status" :reduce="status => status.value" />
          </div>
        </div>

      </div>

      <div class="flex gap-2">

        <div>
          <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
  text-white hover:text-corBase py-2 px-4" @click="getListaTipoProdutoBase(1)">Pesquisar
          </button>
        </div>

        <div v-if="isadmin === true">
          <button class="bg-blue-500 hover:bg-screenCor border border-screenCor hover:border-blue-500
        text-white hover:text-blue-500 py-2 px-4" @click.prevent="alterarSelecionados()">
            Alterar Status Selecionados
          </button>
        </div>
        <div v-if="isadmin === true">
          <button @click="$router.push(`/tipo-produto-base/cadastro`)" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-2 px-4 ">Adicionar
          </button>
        </div>
      </div>
    </div>

    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
          <tr>
            <th scope="col" class="p-4">
              <div class="flex items-center">
                <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

                <label class="sr-only">checkbox</label>
              </div>
            </th>
            <th scope="col" class="px-6 py-3">
              #
            </th>
            <th class="px-6 py-3">
              Descrição
            </th>
            <th class="px-3 py-3">
              Código
            </th>
            <th class="px-6 py-3 text-center">
              NCM
            </th>
            <th class="px-6 w-full py-3">
              Produtos
            </th>
            <th scope="col" class="px-6 py-3">
              Status
            </th>
            <th scope="col" class="px-10 py-3" v-if="isadmin === true">
              Ações
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(tipo_produto, index) in lista_tipos_produto_base" :key="index" class="escopo-tabela">

            <td class="w-4 p-4">
              <div class="flex items-center">
                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                  v-model="tipo_produto.selected">
                <label class="sr-only">checkbox</label>
              </div>
            </td>
            <th scope="row" class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
              {{ index + 1 }}
            </th>
            <td class="px-6 py-2 text-xs">
              {{ tipo_produto.descricao }}
            </td>
            <td class="px-3 py-2 text-xs">
              {{ tipo_produto.codigo }}
            </td>
            <td class="px-6 py-2 text-xs text-center">
              {{ tipo_produto.ncm || '-' }}
            </td>
            <td class="px-6 py-2 text-xs">
              <ul>
                <li class="list-disc" v-for="(produto_aplicado, i) in tipo_produto.produto_aplicado" :key="i">
                  {{ produto_aplicado.nome }}
                </li>
              </ul>
            </td>
            <template v-if="tipo_produto.ativo === true">
              <td class="px-1 sm:px-6  py-1 sm:py-4 text-center">
                <i class="fa-solid fa-circle-check  text-ativo"></i>
              </td>
            </template>
            <template v-if="tipo_produto.ativo === false">
              <td class="px-1 sm:px-6  py-1 sm:py-4 text-center">
                <i class="fa-solid fa-circle-xmark text-inativo"></i>
              </td>
            </template>

            <td class="px-6 py-2 text-xs">
              <a @click="selecionarTipoProdutoEditar(tipo_produto)" href="#"
                class="font-medium text-corBase hover:underline">
                <i class="fa-solid fa-pen-to-square mr-2"></i>Editar
              </a>
            </td>


          </tr>
        </tbody>
      </table>

      <Pagination v-if="lista_tipos_produto_base.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
        @change-page="mudarPagina" />
    </div>
  </div>

  <div v-if="erroModal" class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <div class="bg-white p-6 rounded-lg shadow-lg w-1/3 text-center">
      <p class="text-gray-700">{{ erroMessage }}</p>
      <div class="mt-6">
        <button
          class="bg-red-500 border text-white px-4 py-2 rounded hover:bg-screenCor hover:text-red-500 hover:border-red-500"
          @click="fecharErroModal()">
          Fechar
        </button>
      </div>
    </div>
  </div>

  <EditarTipoProdutoBase v-if="modalEditar" :tipo_produto="tipoProdutoSelecionado" @fecharModal="toggleModalEditar"
    @atualizarLista="getListaTipoProdutoBase(paginaAtual)" />

  <AlterarSelecionadosTipoProdutoBase v-if="modalAlterarSelecionados === true"
    @fecharModal="toggleAlterarSelecionados()" @atualizarLista="getListaTipoProdutoBase(paginaAtual)"
    :produtos="produtosSelecionados" />
</template>

<script>
import axios from "axios";
import EditarTipoProdutoBase from "@/components/midais/Editar/tipo/EditarTipoProdutoBase.vue";
import AlterarSelecionadosTipoProdutoBase from "@/components/midais/Editar/tipo/AlterarSelecionadosTipoProdutoBase.vue";
import Pagination from "@/utils/Pagination.vue";
import vSelect from "vue-select";

export default {
  name: "ListaTipoProdutoBase.vue",

  components: {
    EditarTipoProdutoBase,
    AlterarSelecionadosTipoProdutoBase,
    Pagination,
    vSelect,
  },

  data() {
    return {
      codigo: '',
      descricao: '',
      lista_status: [
        { value: 'True', nome: "Ativo" },
        { value: 'False', nome: "Inativo" },
      ],

      status: '',
      tipo_produto: '',

      lista_tipos_produto_base: [],

      produtosSelecionados: [],
      modalAlterarSelecionados: false,

      checkAll: false,
      // varival que inicializa o numero de paginas
      paginaAtual: 1,
      countItens: '',

      modalEditar: false,
      tipoProdutoSelecionado: [],

      erroModal: false,
      erroMessage: '',

      error: [],
    }
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },

    isadmin() {
      return this.$store.state.isAdminUser
    },

    checkSome() {
      return this.lista_tipos_produto_base.some(cor => cor.selected)
    }
  },


  methods: {

    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });

      this.lista_tipos_produto_base.forEach((produto_base) => {
        produto_base.selected = this.checkAll;
      });
    },


    async getListaTipoProdutoBase(pagina) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      this.$store.dispatch("setLoading", true);

      try {
        const response = await axios.get(`/produtos/tipo-produto-base/?produto=${this.tipo_produto || ''}&codigo=${this.codigo || ''}&descricao=${this.descricao || ''}&ativo=${this.status || ''}&page=${pagina}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        this.lista_tipos_produto_base = response.data.results;
        this.countItens = response.data.count;
        console.log(this.lista_tipos_produto_base);

      } catch (error) {
        console.error("Erro ao buscar tipos de produto base:", error);
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    selecionarTipoProdutoEditar(tipo_produto) {
      this.tipoProdutoSelecionado = tipo_produto
      this.toggleModalEditar()
    },

    toggleModalEditar() {
      this.modalEditar = !this.modalEditar
    },

    alterarSelecionados() {
      if (this.checkSome || this.checkAll) {
        this.toggleAlterarSelecionados();
        this.produtosSelecionados = this.lista_tipos_produto_base.filter(cor => cor.selected);
        console.log(this.produtosSelecionados)
      } else {
        this.erroModal = true;
        this.erroMessage = 'Selecione pelo menos um Produto para ser alterado.';
      }
    },

    toggleAlterarSelecionados() {
      this.modalAlterarSelecionados = !this.modalAlterarSelecionados;
    },

    fecharErroModal() {
      this.erroModal = false;
    },

    mudarPagina(pagina) {
      this.paginaAtual = pagina;
      this.getListaTipoProdutoBase(pagina);
    },


  }
}
</script>

<style lang="scss"></style>