<template>
    <div class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div class="bg-white p-8 rounded-lg shadow-lg w-1/3 max-w-lg">
            <div class="border-b mb-4">
                <h2 class="text-2xl font-semibold text-gray-800 text-center">Alterar Validações</h2>
            </div>

            <div class="space-y-4">
                <div class="w-full flex gap-2">
                    <div class="text-left w-full">
                        <label for="corte" class="block text-sm font-medium text-corBase mb-1">Condição</label>
                        <input class="input-campos" v-model="condicao">
                    </div>

                    <div class="text-left w-full">
                        <label for="posicao" class="block text-sm font-medium text-corBase mb-1">Observação</label>
                        <input class="input-campos" v-model="observacao">
                    </div>
                </div>
            </div>

            <!-- Botões -->
            <div class="mt-6 flex justify-center space-x-4">
                <button class="btn-cancelar" @click.prevent="$emit('fecharModal')">
                    Fechar
                </button>
                <button class="btn-salvar" @click="salvarAlteracoes()">
                    Salvar Alterações
                </button>
            </div>
        </div>
    </div>

    <SucessoRapido v-if="sucesso === true" mensagem="Vidros atualizados com sucesso!" />
    <ErroRapido v-if="erro === true" mensagem="Ocorreu um erro ao atualizar Vidros." />
</template>

<script>
import axios from 'axios'
import vSelect from 'vue-select';
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue';

export default {
    name: 'AlterarValidacoes',
    data() {
        return {
            condicao: '',
            observacao: '',

            sucesso: false,
            erro: false,
        };
    },

    components: {
        vSelect,
        SucessoRapido,
        ErroRapido,
    },

    props: ['validacoes'],

    emits: ['fecharModal', 'atualizarLista'],

    methods: {
        async salvarAlteracoes() {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                console.log(this.validacoes)
                const patchPromises = this.validacoes.map(validacao => {
                    // Cria um objeto contendo apenas os campos preenchidos
                    const camposAtualizados = {};

                    if (this.condicao) {
                        camposAtualizados.condicao = this.condicao;
                        // console.log(camposAtualizados.condicao)
                    }
                    if (this.observacao) {
                        camposAtualizados.observacao = this.observacao;
                        // console.log(camposAtualizados.observacao)
                    }


                    if (Object.keys(camposAtualizados).length === 0) {
                        return Promise.resolve();
                    }

                    return axios.patch(
                        `projeto/projeto-validacao/${validacao.id}/`,
                        camposAtualizados,
                        {
                            headers: {
                                'Authorization': `Token ${token}`,
                            },
                        }
                    );
                });

                await Promise.all(patchPromises);

                this.sucesso = true;
                setTimeout(() => {
                    this.sucesso = false;
                    this.$emit('fecharModal');
                    this.$emit('atualizarLista');
                }, 3000);
            } catch (error) {
                console.error('Erro ao salvar alterações:', error);
                this.erro = true
                setTimeout(() => {
                    this.erro = false;
                }, 3000);
            }
        },

    },
};
</script>

<style scoped>
.input-campos {
    @apply block w-full p-2 border rounded-md text-corBase;
}
</style>