<template>

  <div class="mt-6 block md:hidden">
    <h1 class="text-corBase mb-2 text-xl">Navegar pelas</h1>
    <div class="relative" @click="dropdownDados = !dropdownDados">
      <button class="bg-screenCor px-3 py-1 text-corBase font-bold" v-if="!dropdownDados">
        Opções
      </button>
      <button class="bg-screenCor px-3 py-1 text-corBase font-bold" v-if="dropdownDados">
        Opções
      </button>

      <div class="absolute left-0 mt-1 w-40 bg-white border border-gray-300 shadow-lg z-[9999]" v-if="dropdownDados">
        <!-- Conteúdo do dropdown -->
        <button class="block w-full px-4 py-2 hover:bg-gray-100" @click.prevent="abrirSessaoDireto('dados')">
          Dados Iniciais
        </button>
        <button class="block w-full px-4 py-2 hover:bg-gray-100" @click.prevent="abrirSessao('configuracoes')">
          Configurações
        </button>
        <button class="block w-full px-4 py-2 hover:bg-gray-100" @click.prevent="abrirSessao('calculos')">
          Cálculos
        </button>
        <button class="block w-full px-4 py-2 hover:bg-gray-100" @click.prevent="abrirSessao('permissoes')">
          Permissões
        </button>
        <button class="block w-full px-4 py-2 hover:bg-gray-100" @click.prevent="abrirSessao('imagens')">
          Imagens
        </button>
        <button class="block w-full px-4 py-2 hover:bg-gray-100" @click.prevent="abrirSessao('produtos')">
          Produtos
        </button>
        <button class="block w-full px-4 py-2 hover:bg-gray-100" @click.prevent="abrirSessaoDireto('ensaio')">
          Ensaio
        </button>

      </div>
    </div>
  </div>


  <!-- NAVEGAÇÃO PRINCIPAL DESKTOP -->
  <div class="nav-principal-projetos">

    <div class="w-full block justify-center">
      <div class="flex flex-wrap gap-2 justify-center">
        <button :class="['py-3 px-6 rounded-lg font-semibold shadow-md transition-all',
          sessoesAtivas.includes('dados')
            ? 'bg-screenCor text-corBase border border-corBase'
            : 'bg-corBase text-white border-corBase hover:bg-screenCor hover:text-corBase']"
          @click.prevent="abrirSessaoDireto('dados')">
          Dados Iniciais
        </button>
        <button :class="['py-3 px-6 rounded-lg font-semibold shadow-md transition-all',
          sessoesAtivas.includes('configuracoes')
            ? 'bg-screenCor text-corBase border border-corBase'
            : 'bg-corBase text-white border-corBase hover:bg-screenCor hover:text-corBase']"
          @click.prevent="abrirSessao('configuracoes')">
          Configurações
        </button>
        <button :class="['py-3 px-6 rounded-lg font-semibold shadow-md transition-all',
          sessoesAtivas.includes('calculos'
          ) ? 'bg-screenCor text-corBase border border-corBase'
            : 'bg-corBase text-white border-corBase hover:bg-screenCor hover:text-corBase']"
          @click.prevent="abrirSessao('calculos')">
          Cálculos
        </button>
        <button :class="['py-3 px-6 rounded-lg font-semibold shadow-md transition-all',
          sessoesAtivas.includes('permissoes'
          ) ? 'bg-screenCor text-corBase border border-corBase'
            : 'bg-corBase text-white border-corBase hover:bg-screenCor hover:text-corBase']"
          @click.prevent="abrirSessao('permissoes')">
          Permissões
        </button>
        <button :class="['py-3 px-6 rounded-lg font-semibold shadow-md transition-all',
          sessoesAtivas.includes('imagens'
          ) ? 'bg-screenCor text-corBase border border-corBase'
            : 'bg-corBase text-white border-corBase hover:bg-screenCor hover:text-corBase']"
          @click.prevent="abrirSessao('imagens')">
          Imagens
        </button>
        <button :class="['py-3 px-6 rounded-lg font-semibold shadow-md transition-all',
          sessoesAtivas.includes('produtos'
          ) ? 'bg-screenCor text-corBase border border-corBase'
            : 'bg-corBase text-white border-corBase hover:bg-screenCor hover:text-corBase']"
          @click.prevent="abrirSessao('produtos')">
          Produtos
        </button>
        <button :class="['py-3 px-6 rounded-lg font-semibold shadow-md transition-all',
          sessoesAtivas.includes('ensaio'
          ) ? 'bg-screenCor text-corBase border border-corBase'
            : 'bg-corBase text-white border-corBase hover:bg-screenCor hover:text-corBase']"
          @click.prevent="abrirSessaoDireto('ensaio')">
          Ensaio
        </button>

      </div>
    </div>
  </div>

  <div class="flex flex-wrap justify-center items-center w-full p-2">

    <!-- Configurações -->
    <div v-if="sessoesAtivas.includes('configuracoes')" class="flex flex-wrap gap-2 justify-center">
      <button :class="opcoes.vendas ? 'btn-nav-projeto-ativo' : 'btn-nav-projeto-inativo'"
        @click.prevent="emitEvent('vendas')">
        Vendas
      </button>
      <button :class="opcoes.producao ? 'btn-nav-projeto-ativo' : 'btn-nav-projeto-inativo'"
        @click.prevent="emitEvent('producao')">
        Produção
      </button>
      <button :class="opcoes.nota_fiscal ? 'btn-nav-projeto-ativo' : 'btn-nav-projeto-inativo'"
        @click.prevent="emitEvent('nota_fiscal')">
        Nota Fiscal
      </button>
      <button :class="opcoes.instalacao ? 'btn-nav-projeto-ativo' : 'btn-nav-projeto-inativo'"
        @click.prevent="emitEvent('instalacao')">
        Instalação
      </button>
    </div>

    <!-- Cálculos -->
    <div v-if="sessoesAtivas.includes('calculos')" class="flex flex-wrap gap-2 justify-center">
      <button :class="opcoes.estruturas ? 'btn-nav-projeto-ativo' : 'btn-nav-projeto-inativo'"
        @click.prevent="emitEvent('estruturas')">
        Estruturas
      </button>
      <button :class="opcoes.formulas ? 'btn-nav-projeto-ativo' : 'btn-nav-projeto-inativo'"
        @click.prevent="emitEvent('formulas')">
        Fórmulas
      </button>
      <button :class="opcoes.variaveis ? 'btn-nav-projeto-ativo' : 'btn-nav-projeto-inativo'"
        @click.prevent="emitEvent('variaveis')">
        Variáveis
      </button>
    </div>

    <!-- Produtos -->
    <div v-if="sessoesAtivas.includes('produtos')" class="flex flex-wrap gap-2 justify-center">
      <button :class="opcoes.perfis ? 'btn-nav-projeto-ativo' : 'btn-nav-projeto-inativo'"
        @click.prevent="emitEvent('perfis')">
        Perfis
      </button>
      <button :class="opcoes.componentes ? 'btn-nav-projeto-ativo' : 'btn-nav-projeto-inativo'"
        @click.prevent="emitEvent('componentes')">
        Componentes
      </button>
      <button :class="opcoes.vidros ? 'btn-nav-projeto-ativo' : 'btn-nav-projeto-inativo'"
        @click.prevent="emitEvent('vidros')">
        Vidros
      </button>
      <button :class="opcoes.chapas_telas ? 'btn-nav-projeto-ativo' : 'btn-nav-projeto-inativo'"
        @click.prevent="emitEvent('chapas_telas')">
        Chapas/Telas
      </button>
      <button :class="opcoes.insumos ? 'btn-nav-projeto-ativo' : 'btn-nav-projeto-inativo'"
        @click.prevent="emitEvent('insumos')">
        Insumos
      </button>
      <button :class="opcoes.servicos ? 'btn-nav-projeto-ativo' : 'btn-nav-projeto-inativo'"
        @click.prevent="emitEvent('servicos')">
        Serviços
      </button>
    </div>

    <!-- Imagens -->
    <div v-if="sessoesAtivas.includes('imagens')" class="flex flex-wrap gap-2 justify-center">
      <button :class="opcoes.imagem_capa ? 'btn-nav-projeto-ativo' : 'btn-nav-projeto-inativo'"
        @click.prevent="emitEvent('imagem_capa')">
        Imagem Capa
      </button>
      <button :class="opcoes.imagens_variaveis ? 'btn-nav-projeto-ativo' : 'btn-nav-projeto-inativo'"
        @click.prevent="emitEvent('imagens_variaveis')">
        Imagens Variáveis
      </button>
    </div>

    <!-- Permissões -->
    <div v-if="sessoesAtivas.includes('permissoes')" class="flex flex-wrap gap-2 justify-center">
      <button :class="opcoes.validacoes ? 'btn-nav-projeto-ativo' : 'btn-nav-projeto-inativo'"
        @click.prevent="emitEvent('validacoes')">
        Validações
      </button>

      <button :class="opcoes.medidas ? 'btn-nav-projeto-ativo' : 'btn-nav-projeto-inativo'"
        @click.prevent="emitEvent('medidas')">
        Medidas
      </button>

      <button :class="opcoes.cores ? 'btn-nav-projeto-ativo' : 'btn-nav-projeto-inativo'"
        @click.prevent="emitEvent('cores')">
        Cores
      </button>

      <button :class="opcoes.tipo_vidro ? 'btn-nav-projeto-ativo' : 'btn-nav-projeto-inativo'"
        @click.prevent="emitEvent('tipo_vidro')">
        Tipos de Vidro
      </button>

      <button :class="opcoes.espessuras ? 'btn-nav-projeto-ativo' : 'btn-nav-projeto-inativo'"
        @click.prevent="emitEvent('espessuras')">
        Espessuras
      </button>
    </div>

  </div>



</template>

<script>

import axios from "axios";

export default {

  name: "NavgecacaoProjeto",

  props: ['dadosProjeto'],

  emits: ['navegar-opcoes', 'fecharTodasAbas'],

  data() {

    return {

      opcoes: [],
      dropdownDados: false,

      sessoesAtivas: [],

      formulas: false,

    }

  },

  created() {

    // cria as opções com valores de false

    this.opcoes = {

      vendas: false,
      producao: false,
      nota_fiscal: false,
      instalacao: false,

      estruturas: false,
      formulas: false,
      variaveis: false,

      validacoes: false,
      medidas: false,
      cores: false,
      tipo_vidro: false,
      espessuras: false,

      imagem_capa: false,
      imagens_variaveis: false,

      perfis: false,
      componentes: false,
      vidros: false,
      chapas_telas: false,
      insumos: false,
      servicos: false,

      ensaio: false,

      projeto: {},
    };

    this.abrirSessaoDireto('dados')
    // setTimeout(() => {
    //   this.abrirSessao('imagens')
    //   this.emitEvent('imagens_variaveis')
    // }, 500);


  },

  methods: {

    abrirSessao(sessao) {
      this.sessoesAtivas = []
      this.$emit('fecharTodasAbas')
      this.fecharOpcoesFilhos()
      this.sessoesAtivas.push(sessao)
    },

    abrirSessaoDireto(sessao) {
      this.sessoesAtivas = []
      this.$emit('fecharTodasAbas')
      this.fecharOpcoesFilhos()
      this.sessoesAtivas.push(sessao)
      this.emitEvent(sessao)
    },

    fecharOpcoesFilhos() {
      Object.keys(this.opcoes).forEach((key) => {
        this.opcoes[key] = false;
      });
    },


    // função para navegar pelos botoes
    navegarOpcoes(nomeVariavel) {
      for (const key in this.opcoes) {
        if (key !== nomeVariavel) {
          this.opcoes[key] = false; // Define todas as opções como false
        }
      }
      this.opcoes[nomeVariavel] = !this.opcoes[nomeVariavel]; // Alterna a opção selecionada
    },

    // busca a lista de perfis relacionados ao projeto
    async getListaPerfis() {
      this.navegarOpcoes('perfis')
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`projeto/projeto-perfil/?projeto_id=${this.projeto_id}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_perfis = response.data
          console.log(this.lista_perfis)
        })
        .catch(error => {
          console.log(error)
        })
    },

    // busa a lista de vidros relacionados ao projeto
    async getListaVidros() {
      this.navegarOpcoes('vidros')
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`projeto/projeto-vidro/?projeto_id=${this.projeto_id}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_vidros = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    // busca a lista de componentes relacionados ao projeto
    async getListaComponentes() {
      this.navegarOpcoes('componentes')
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`projeto/projeto-componente/?projeto_id=${this.projeto_id}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_componentes = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    // busca a lista de serviços relacionados ao projeto
    async getListaServicos() {
      this.navegarOpcoes('serviços')
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`projeto/projeto-servico/?projeto_id=${this.projeto_id}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_servicos = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    // chamar evento no pai para trocar de aba
    emitEvent(opcao) {
      // this.sessoesAtivas = []
      this.$emit('navegar-opcoes', opcao);
      this.navegarOpcoes(opcao)
      console.log('Clicado com ' + opcao)
    }

  }

}
</script>

<style lang="scss" scoped>
.nav-principal-projetos {
  @apply hidden md:block md:w-full justify-center
}

.btn-nav-projeto-inativo {
  @apply flex items-center py-2 text-sm w-full sm:w-40 text-center justify-center bg-corBase text-screenCor rounded-md shadow-sm hover:bg-screenCor hover:text-corBase hover:shadow-md transition-colors;
}

.btn-nav-projeto-ativo {
  @apply flex items-center py-2 border border-corBase text-sm w-full sm:w-40 text-center justify-center bg-screenCor text-corBase font-semibold rounded-md shadow-md;
}
</style>