<template>
  <div class="container-formulario">

    <div class="cabecalho-formulario">
      <h1 class="text-xl text-corBase font-bold">Dados do Orçamento:</h1>

      <router-link to="/orcamentos" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <form @submit.prevent="cadastrarOrcamento">

      <MenssagemErro
        v-if="modalMensagemErro"
        :mensagem="mensagemErro"
        rotas="/orcamentos"
      />

      <MensagemSucesso
        v-if="modalMensagemSucesso"
        mensagem="Deu certo"
        rotas="/orcamentos"
      />

      <div class="w-full mx-auto mt-8">
        <div class="space-y-4">
          <div>
            <button @click.prevent="toggleSection(1)"
                    class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
              Dados do Orçamento
            </button>

            <div v-if="activeSection === 1" class="dados-orcamento">

              <div class="flex gap-12 w-full">
                <div class="w-full">
                  <label class="font-bold text-corBase">Cliente</label>

                  <v-select :options="listaClientes" label="text" v-model="cliente" />

                  <!-- só aparece as opções da lista quando seleciona o que esta escrito no input 
                   (quando seleciono é emitido um Event, deve ter coneção)) -->
                  <!-- <Search
                    v-model="cliente"
                    :options="listaClientes"
                    valueKey="id"
                    labelKey="nome_razao"
                    @select="onSelect"
                    @shouldSearch="getClientes"
                    class="w-full"
                  /> -->
                </div>

                <div class="w-full">
                  <label class="font-bold text-corBase">Vendedor</label>
                  <Select2
                    v-model="vendedor"
                    class="w-full"
                    :options="listaVendedores"
                    :settings="{ width: '100%'}"
                  />
                </div>
              </div>

              <div class="flex gap-12 w-full">
                <div class="w-full">
                  <label class="font-bold text-corBase">Referência</label>
                  <input type="text" class="input-campos" v-model="referencia">
                </div>

                <div class="w-full">
                  <label class="font-bold text-corBase">Tipo Medida Contramarco</label>
                  <Select2
                    v-model="tipoMedidaContramarco"
                    class="w-full"
                    :options="listaTiposMedidas"
                    :settings="{ width: '100%' }"
                  />
                </div>
              </div>

              <div class="flex w-full justify-around">
                
                <div class="check-box">
                  <label class="font-bold text-corBase">Contramarco</label>
                  <input 
                    type="checkbox" 
                    class="p-1 text-corBase border border-corBase/40 w-5 h-5"
                    v-model="contramarco"
                  >
                </div>

                <div class="check-box">
                  <label class="font-bold text-corBase">Arremate</label>
                  <input 
                    type="checkbox" 
                    class="p-1 text-corBase border border-corBase/40 w-5 h-5"
                    v-model="arremate"
                  >
                </div>

                <div class="check-box">
                  <label class="font-bold text-corBase">Arremate Piso</label>
                  <input 
                    type="checkbox" 
                    class="p-1 text-corBase border border-corBase/40 w-5 h-5"
                    v-model="arrematePiso"
                  >
                </div>
              </div>

            </div>
          </div>

          <div class="flex w-full gap-8 justify-end">
            <button class="btn-enviar !bg-inativo" @click.prevent="resetaOsCampos">Cancelar</button>
            <button class="btn-enviar" type="submit">Salvar</button>
          </div>
        </div>
      </div>
    </form>

  </div>
</template>

<script>
import axios from 'axios'
import Select2 from 'vue3-select2-component'
import vSelect from 'vue-select'
import Search from '@/utils/Search.vue'
import MenssagemErro from '../toasts/toast_erro/MenssagemErro.vue'
import MensagemSucesso from '../toasts/toast_sucesso/MensagemSucesso.vue'

export default {
  name: 'CadastroOrcamentos',

  data() {
    return {
      // variaveis formulario
      cliente: '',
      vendedor: '',
      referencia: '',
      contramarco: false,
      arremate: false,
      arrematePiso: false,
      tipoMedidaContramarco: '',
      // listas de opções para os campos de seleção
      listaClientes: [],
      listaVendedores: [],
      listaTiposMedidas: ['Externa', 'Interna'],
      // guarda o response quando o orçamento for criado
      novoOrcamento: {},
      // controle de exibição
      activeSection: 1,
      modalMensagemErro: false,
      mensagemErro: '',
      modalMensagemSucesso: false,
      mensagemSucesso: ''
    }
  },

  components: {
    vSelect,
    Select2,
    Search,
    MenssagemErro,
    MensagemSucesso
  },

  created() {
    this.getVendedores()
    this.getListaClientes()
  },

  methods: {
    async cadastrarOrcamento() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const data = {
          cliente: this.cliente.id,
          vendedor: this.vendedor,
          referencia: this.referencia,
          contramarco: this.contramarco,
          arremate: this.arremate,
          arremate_piso: this.arrematePiso,
          tipo_medida_contramarco: this.tipoMedidaContramarco === 'Externa' ? 'E' : 'I'
        }

        const response = await axios.post('orcamentos/', data, {
          headers: {
            Authorization: `Token ${token}`
          }
        })

        this.novoOrcamento = response.data

        this.mensagemSucesso = 'Orçamento criado com sucesso'
        this.modalMensagemSucesso = true

      }catch(error) {
        this.mensagemErro = error.message
        this.modalMensagemErro = true
      }
    },

    async getVendedores() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get('empresa/vendedor/', {
          headers: {
            Authorization: `Token ${token}`
          }
        })

        this.listaVendedores = response.data.results.map(item => ({
          id: item.id,
          text: item.pessoa.nome_razao
        }))

      }catch(error) {
        console.error(error)
      }
    },

    async getListaClientes() {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.get(`pessoa/pessoas-fisica-juridica/?atividade=Cliente&ativo=True&page_size=9999`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                this.listaClientes = response.data.results.map(person => ({
                    id: person.pessoa.id,
                    text: person.nome || person.razao_social,
                }))
                console.log(this.listaClientes)
            } catch (error) {
                console.error(error)
            }
        },

    async getClientes(pesquisa) {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get(`pessoas/?razao_social=${pesquisa}`, {
          headers: {
            Authorization: `Token ${token}`
          }
        })

        this.listaClientes = response.data.results
      }catch(error) {
        console.error(error)
      }
    },

    toggleSection(sectionNumber) {
      this.activeSection = sectionNumber
    },

    resetaOsCampos() {
      this.cliente = ''
      this.listaClientes = []
      this.vendedor = ''
      this.referencia = ''
      this.tipoMedidaContramarco = ''
      this.contramarco = false
      this.arremate = false
      this.arrematePiso = false
    },

    onSelect(cliente) {
      this.cliente = cliente
    }
  }
}
</script>

<style scoped lang="scss">
.dados-orcamento {
  @apply p-4 flex flex-col gap-5 border border-corBase
}

.check-box {
  @apply flex justify-center gap-3
}
</style>
