<template>

  <details>
    <summary class="mt-4">
      <h2 class="bg-screenCor hover:bg-screenCor border border-corBase hover:border-corBase
  text-corBase hover:text-corBase py-2 px-4 ">Telefone
      </h2>
    </summary>

    <button @click.prevent="adicionarTelefone" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-2 px-4 mt-2 mr-2">Adicionar
    </button>

    <div class="border border-gray-200 p-2 mt-3" v-for="(telefone, index) in dadosTelefone" :key="index">

      <div class=" flex flex-end justify-end">
        <i class="fa-solid fa-x cursor-pointer" @click.prevent="removerTelefone(index)"></i>
      </div>

      <div class="flex gap-2">
        <div class="w-full">
          <label class="text-base text-corBase mt-2">Tipo de Telefone:</label>
          <div class="flex">
            <!-- <select class="input-campos" v-model="telefone.tipo_telefone">
              <option v-for="item in lista_tipo_telefone" :key="item.id" :value="item.id">{{ item.descricao }}
              </option>
            </select> -->
            <v-select v-model="telefone.tipo_telefone" :options="lista_tipo_telefone" :reduce="item => item.id"
              label="descricao" class="bg-white w-full" placeholder="Selecione o tipo de telefone" />

          </div>
        </div>
        <div class="w-full">
          <label class="text-base text-corBase mt-2">DDD:</label>
          <input type="text" maxlength="3" class="input-campos" placeholder="" v-model="telefone.ddd">
        </div>

        <div class="w-full">
          <label class="text-base text-corBase mt-2">Telefone:</label>
          <input ref="telefoneInput" type="text" class="input-campos" placeholder="" v-model="telefone.telefone"
            @input="aplicarMascara(index)">
        </div>

        <div class="w-full flex items-center justify-center text-center">
          <input type="checkbox" value="whatsapp" class="mr-3" v-model="telefone.whatsapp">
          <label class="text-base text-corBase">Whatsapp</label>
        </div>

        <div class="w-full flex items-center justify-center text-center">
          <input type="checkbox" value="telegram" class="mr-3" v-model="telefone.telegram">
          <label class="text-base text-corBase">Telegram</label>
        </div>

      </div>

    </div>

  </details>


</template>
<script>
import TipoTelefoneModal from "@/components/midais/modaisCadastro/TipoTelefoneModal";
import TipoEmailModal from "@/components/midais/modaisCadastro/TipoEmailModal";
import vSelect from "vue-select";
import axios from "axios";

export default {
  name: "TelefonesCampos",

  components: {
    TipoTelefoneModal,
    TipoEmailModal,
    vSelect,
  },
  data() {
    return {
      lista_tipo_telefone: [],
      whatsapp: false,
    }
  },
  computed: {
    isadmin() {
      return this.$store.state.isAdminUser
    },
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
  },
  props: ['dadosTelefone'],

  created() {
    this.getListaTipoTelefone()
  },

  methods: {
    adicionarTelefone() {
      this.dadosTelefone.push({
        ddd: '',
        telefone: '',
        tipo_telefone: '',
        email_pessoa: '',
        tipo_email: '',
        whatsapp: false,
        telegram: false,
      });
    },
    removerTelefone(index) {
      this.dadosTelefone.splice(index, 1);
    },

    async getListaTipoTelefone() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('pessoas/telefones/tipo-telefones', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_tipo_telefone = response.data.results;
        })
        .catch(error => {
          console.error(error)
        })
    },

    aplicarMascara(index) {
      const telefone = this.dadosTelefone[index].telefone;
      const mask = telefone.length === 10 ? "#####-####" : "####-####";

      this.$refs.telefoneInput[index].value = this.aplicarMascaraTexto(telefone, mask);
      this.dadosTelefone[index].telefone = this.$refs.telefoneInput[index].value;
    },

    aplicarMascaraTexto(texto, mascara) {
      const cleanText = texto.replace(/\D/g, '');
      let resultado = '';
      let indexMascara = 0;

      for (let i = 0; i < cleanText.length && indexMascara < mascara.length; i++) {
        if (mascara[indexMascara] === '-') {
          resultado += '-';
          indexMascara++;
        }
        resultado += cleanText[i];
        indexMascara++;
      }

      return resultado;
    }
  }

}
</script>

<style scoped></style>