<template>


  <div class="container-formulario">

    <div class="cabecalho-dashboard">
      <h1>Cadastro de Produtos</h1>

      <button @click="$router.push(`/cadastros`)" class="text-2xl">
        <i class="fas fa-chevron-left mr-1 "></i>
      </button>
    </div>

    <div class="flex flex-wrap">

      <button class="escopo-dashboard-desktop" @click="$router.push('/produtos/produto-base')" v-if="isadmin === true">
        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Produtos Base</h2>
            <i class="fas fa-user"></i>
          </div>
          <div class="descricao-opcao">
            <p class="mt-6">Cadastre os Produtos Bases!</p>
          </div>
        </div>
      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push('/produtos/chapas')">
        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Chapas</h2>
            <i class="fa-solid fa-layer-group"></i>
          </div>
          <div class="descricao-opcao">
            <p class="mt-6">Cadastre as Chapas presentes em seu sistema!</p>
          </div>
        </div>
      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push('/produtos/componente-acessorio')">
        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Componentes/Acessórios</h2>
            <i class="fa-solid fa-gears"></i>
          </div>
          <div class="descricao-opcao">
            <p class="mt-6">Cadastre os Componentes/Acessórios em seu sistema!</p>
          </div>
        </div>
      </button>

      <!-- <button class="escopo-dashboard-desktop" @click="$router.push('produtos/espessuras')">
        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Espessuras</h2>
            <i class="fa-solid fa-ruler-horizontal"></i>
          </div>
          <div class="descricao-opcao">
            <p class="mt-6">Cadastre as Espessuras presentes em seu sistema!</p>
          </div>
        </div>
      </button> -->

      <!-- <button class="escopo-dashboard-desktop" @click="$router.push('produtos/linha-aplicacao')">
        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Linhas de Aplicação de Perfil</h2>
            <i class="fa-solid fa-object-group"></i>
          </div>
          <div class="descricao-opcao">
            <p class="mt-6">Cadastre as Linhas de Aplicação presentes em seu sistema!</p>
          </div>
        </div>
      </button> -->

      <!-- <button class="escopo-dashboard-desktop" @click="$router.push('produtos/linha-produto-base')">
        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Linhas de Produto Base</h2>
            <i class="fa-solid fa-object-group"></i>
          </div>
          <div class="descricao-opcao">
            <p class="mt-6">Cadastre as Linhas de Produto Base presentes em seu sistema!</p>
          </div>
        </div>
      </button> -->

      <button class="escopo-dashboard-desktop" @click="$router.push('produtos/perfil')">
        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Perfis</h2>
            <i class="fa-solid fa-ruler-combined"></i>
          </div>
          <div class="descricao-opcao">
            <p class="mt-6">Cadastre os Perfis presentes em seu sistema!</p>
          </div>
        </div>
      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push('/produtos/produto-por-fornecedor')">
        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Produtos por Fornecedor</h2>
            <i class="fa-solid fa-truck-field"></i>
          </div>
          <div class="descricao-opcao">
            <p class="mt-6">Cadastre os Produtos Por Fornecedor presentes em seu sistema!</p>
          </div>
        </div>
      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push('produtos/servico')">
        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Serviços</h2>
            <i class="fa-solid fa-hammer"></i>
          </div>
          <div class="descricao-opcao">
            <p class="mt-6">Cadastre os Serviços presentes em seu sistema!</p>
          </div>
        </div>
      </button>

      <!-- <button class="escopo-dashboard-desktop" @click="$router.push('produtos/unidade-medida')">
        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Unidades de Medida</h2>
            <i class="fa-solid fa-weight-hanging"></i>
          </div>
          <div class="descricao-opcao">
            <p class="mt-6">Cadastre as Unidades de Medida presentes em seu sistema!</p>
          </div>
        </div>
      </button> -->

      <button class="escopo-dashboard-desktop" @click="$router.push('produtos/uso-consumo')">
        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Usos de Consumos</h2>
            <i class="fa-solid fa-box-open"></i>
          </div>
          <div class="descricao-opcao">
            <p class="mt-6">Cadastre as Usos Consumo presentes em seu sistema!</p>
          </div>
        </div>
      </button>

      <button class="escopo-dashboard-desktop" @click="$router.push('produtos/vidro')">
        <div class="opcao-dashboard">
          <div class="cacebalho-opcao">
            <h2 class="titulo-opcao">Vidros</h2>
            <i class="fas fa-user-friends"></i>
          </div>
          <div class="descricao-opcao">
            <p class="mt-6">Cadastre os Vidros presentes em seu sistema!</p>
          </div>
        </div>
      </button>

    </div>


  </div>



</template>

<script>
export default {
  name: "ProdutosDashboard",

  computed: {
    isadmin() {
      return this.$store.state.isAdminUser
    },
  }
}
</script>

<style lang="scss"></style>