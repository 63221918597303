<template>
    <div>
        <div class="fixed inset-0 bg-black/60 flex items-center justify-center z-[9999]">
            <div class="bg-white rounded-lg max-w-[90%] shadow-lg p-6 max-h-[80vh] overflow-y-auto ">
                <!-- Cabeçalho do modal -->
                <div class="flex justify-between items-center mb-4 border-b pb-2">
                    <h2 class="text-lg font-bold text-green-600">Selecionar Fornecedor</h2>
                    <button @click.prevent="$emit('fecharModal')"
                        class="text-gray-500 hover:text-gray-800 p-2 rounded-full transition duration-200 ease-in-out hover:bg-gray-100">
                        <i class="fa-solid fa-x"></i>
                    </button>
                </div>

                <div class="container-modelo-tabela">
                    <table class="container-tabela">
                        <thead class="cabecalho-tabela">
                            <tr class="border-b">
                                <th scope="col" class="px-4 py-3">
                                    Selecionar
                                </th>
                                <!-- <th scope="col" class="px-6 py-3 text-start">
                                    #
                                </th> -->
                                <th scope="col" class="px-6 py-3 text-center">
                                    Pessoa
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    CPF/CNPJ
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Nome/Razão Social
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Nome Social/Nome Fantasia
                                </th>
                                <th scope="col" class="px-6 py-3 text-center">
                                    Endereço(s)
                                </th>
                                <th scope="col" class="px-6 py-3 text-center">
                                    Telefone(s)
                                </th>
                                <th scope="col" class="px-6 py-3 text-center">
                                    Email(s)
                                </th>
                                <th scope="col" class="px-6 py-3 text-center">
                                    Interação(ções)
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(pessoa, index) in lista_fornecedores" :key="index" class="escopo-tabela">
                                <td class="px-4 py-3">
                                    <div class="flex justify-center items-center">
                                        <i class="fa-solid fa-check cursor-pointer text-xl"
                                            @click.prevent="selecionarFornecedor(pessoa)"></i>
                                    </div>
                                </td>
                                <!-- <th scope="row"
                                    class="px-6 py-3 text-start font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {{ index + 1 }}
                                </th> -->
                                <td class="px-6 py-3 text-center">
                                    <div class="flex justify-center items-center">
                                        <i v-if="pessoa.fisica" class="fa-solid fa-person"></i>
                                        <i v-else class="fa-solid fa-building"></i>
                                    </div>
                                </td>
                                <td class="px-6 py-3">
                                    {{ formatarCpfCnpj(pessoa.cpf || pessoa.cnpj) || '-' }}
                                </td>
                                <td class="px-6 py-3">
                                    {{ pessoa.nome || pessoa.razao_social || '-' }}
                                </td>
                                <td class="px-6 py-3">
                                    {{ pessoa.nome_social || pessoa.nome_fantasia || '-' }}
                                </td>
                                <td class="px-6 py-3 text-center">
                                    <p @click.prevent="listarEnderecos(pessoa.pessoa.id)"
                                        class="cursor-pointer text-blue-500">Ver lista</p>
                                </td>
                                <td class="px-6 py-3 text-center">
                                    <p @click.prevent="listarTelefones(pessoa.pessoa.id)"
                                        class="cursor-pointer text-blue-500">Ver lista</p>
                                </td>
                                <td class="px-6 py-3 text-center">
                                    <p @click.prevent="listarEmails(pessoa.pessoa.id)"
                                        class="cursor-pointer text-blue-500">Ver lista</p>
                                </td>
                                <td class="px-6 py-3 text-center">
                                    <p @click.prevent="listarInteracoes(pessoa.pessoa.id)"
                                        class="cursor-pointer text-blue-500">Ver lista</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <Pagination v-if="lista_fornecedores.length > 0" :offset="paginaAtual" :total="countItens"
                        :limit="20" @change-page="mudarPagina" />
                </div>
                <div class="flex m-2">
                    <i class="fa-solid fa-check cursor-pointer text-xl mr-2"
                        @click.prevent="selecionarFornecedor(sem_fornecedor)"></i>Sem Fornecedor
                </div>
                <!-- <input type="checkbox" @click.prevent> Sem Fornecedor -->
            </div>

        </div>
        <!-- <SucessoRapido v-if="sucesso" :mensagem="sucessoMessage" /> -->
    </div>

    <ListarEnderecos v-if="mostrarEnderecos" :pessoa="pessoaSelecionada" @fecharModal="toggleEnderecos()" />
    <ListarTelefones v-if="mostrarTelefones" :pessoa="pessoaSelecionada" @fecharModal="toggleTelefones()" />
    <ListarEmails v-if="mostrarEmails" :pessoa="pessoaSelecionada" @fecharModal="toggleEmails()" />
    <ListarInteracoes v-if="mostrarInteracoes" :pessoa="pessoaSelecionada" @fecharModal="toggleInteracoes()" />

    <!-- <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros" /> -->
</template>

<script>
import axios from "axios"
import ListarEnderecos from "../cadastros_pessoa/ListarEnderecos.vue";
import ListarTelefones from "../cadastros_pessoa/ListarTelefones.vue";
import ListarEmails from "../cadastros_pessoa/ListarEmails.vue";
import ListarInteracoes from "../cadastros_pessoa/ListarInteracoes.vue";
import Pagination from "@/utils/Pagination.vue";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import ListagemErros from "@/components/toasts/toast_erro/ListagemErros.vue";

export default {
    name: 'ModalSelecionarFornecedor',

    props: ['produto'],

    emits: ['selecionarFornecedor', 'fecharModal'],

    data() {
        return {
            lista_fornecedores: [],
            sem_fornecedor: [],

            paginaAtual: 1,
            countItens: '',

            pessoaSelecionada: [],
            mostrarEnderecos: false,
            mostrarTelefones: false,
            mostrarEmails: false,
            mostrarInteracoes: false,
        }
    },

    components: {
        Pagination,
        SucessoRapido,
        ListagemErros,

        ListarEnderecos,
        ListarTelefones,
        ListarEmails,
        ListarInteracoes,
    },

    created() {
        this.getListaFornecedores()
        this.getSemFornecedor()
    },

    methods: {
        async getListaFornecedores() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            try {
                const response = await axios.get(`pessoa/pessoas-fisica-juridica/?produto_fornecedor=${this.produto}&atividade=Fornecedor&page=1`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                this.lista_fornecedores = response.data.results
                this.countItens = response.data.count
            } catch (error) {
                console.error(error)
            }
        },

        async getSemFornecedor() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')
            try {
                const nome_razao = encodeURIComponent('Sem Fornecedor')
                const response = await axios.get(`pessoas/?nome_razao=${nome_razao}&atividade=Fornecedor`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                console.log(response.data.results)
                this.sem_fornecedor = response.data.results[0]
            } catch (error) {
                console.error(error)
            }
        },

        selecionarFornecedor(fornecedor) {
            this.$emit('selecionarFornecedor', fornecedor)
            this.$emit('fecharModal')
        },

        mudarPagina(pagina) {
            this.paginaAtual = pagina;
            this.buscarPorTipoDePessoa(pagina);
        },

        formatarCpfCnpj(valor) {
            if (!valor) return '';

            // Remover quaisquer caracteres não numéricos
            const apenasNumeros = valor.replace(/\D/g, '');

            // Verificar o comprimento para decidir se é CPF ou CNPJ
            if (apenasNumeros.length === 11) {
                // Formatar como CPF (###.###.###-##)
                return apenasNumeros.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
            } else if (apenasNumeros.length === 14) {
                // Formatar como CNPJ (##.###.###/####-##)
                return apenasNumeros.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
            }

            // Retornar o valor original caso não seja nem CPF nem CNPJ válido
            return valor;
        },

        listarEnderecos(pessoaId) {
            this.pessoaSelecionada = pessoaId
            this.toggleEnderecos()
        },

        toggleEnderecos() {
            this.mostrarEnderecos = !this.mostrarEnderecos
        },

        listarTelefones(pessoaId) {
            this.pessoaSelecionada = pessoaId
            this.toggleTelefones()
        },

        toggleTelefones() {
            this.mostrarTelefones = !this.mostrarTelefones
        },

        listarEmails(pessoaId) {
            this.pessoaSelecionada = pessoaId
            this.toggleEmails()
        },

        toggleEmails() {
            this.mostrarEmails = !this.mostrarEmails
        },

        listarInteracoes(pessoaId) {
            this.pessoaSelecionada = pessoaId
            this.toggleInteracoes()
        },

        toggleInteracoes() {
            this.mostrarInteracoes = !this.mostrarInteracoes
        },
    }

}
</script>

<style scoped></style>