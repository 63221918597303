<template>


  <div class="container-formulario">

    <!-- TILO DO FORMULARIO + BOTAO DE VOLTAR -->
    <div class="cabecalho-formulario">
      <h1 class="text-xl text-corBase">Dados Tipo Produto:</h1>

      <router-link to="/tipo-produto-base" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <!-- Escopo do formulario de cadastro mobile-->
    <form @submit.prevent="cadastrarTipoBase">
      <!--        PARTE DE DADOS DO PRODUTO-->
      <div class="div-produtos">

        <div class="campo-chapa">
          <label class="font-bold text-corBase">Código</label>
          <input type="text" v-model="codigo" class="input-form-chapa" v-on:input="codigo = codigo.toUpperCase()" autofocus>
        </div>

        <div class="campo-chapa">
          <label class="font-bold text-corBase">Descrição</label>
          <input type="text" v-model="descricao" class="input-form-chapa" autofocus>
        </div>

        <div class="campo-chapa">
          <label class="font-bold text-corBase">NCM</label>
          <input type="text" v-model="ncm" class="input-form-chapa" maxlength="8" autofocus>
        </div>

        <div class="campo-chapa">
          <label class="font-bold text-corBase">Produtos</label>
          <v-select v-model="produto_aplicado" class="w-full z-10" :options="lista_produto_aplicado" label="text"
            :reduce="produto => produto.id" multiple />
        </div>

        <div class="campo-chapa">
          <label class="font-bold text-corBase">Status</label>
          <div class="cursor-pointer flex items-center justify-center w-6 h-6" @click.prevent="toggleAtivo">
            <i :class="ativo ? 'fa-solid fa-circle-check text-ativo' : 'fa-solid fa-circle-xmark text-inativo'"
              class="text-2xl"></i>
          </div>
        </div>

      </div>

      <div class="flex w-full justify-end">
        <button class="btn-enviar" type="submit">Cadastrar</button>
      </div>
    </form>

  </div>
  <MensagemSucesso v-if="sucesso == true" rotas="/tipo-produto-base"
    mensagem="Tipo produto base cadastrado com sucesso!" />

  <ListagemErros v-if="exibirErros === true" :erros="erros" @fecharModal="toggleErros()" />

</template>

<script>
import axios from "axios";
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";
import ListagemErros from "@/components/toasts/toast_erro/ListagemErros.vue";
import vSelect from "vue-select";

export default {
  name: "TipoBase",


  data() {
    return {
      sucesso: false,
      codigo: '',
      descricao: '',
      ncm: '',
      produto_aplicado: [],
      ativo: true,
      lista_produto_aplicado: [],

      erros: [],
      exibirErros: false,
    }
  },

  components: {
    MensagemSucesso,
    ListagemErros,
    vSelect
  },

  created() {
    this.getListProdutoSistema()
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
    isadmin() {
      return this.$store.state.isAdminUser
    },
  },

  methods: {

    toggleAtivo(){
      this.ativo = !this.ativo
    },

    async getListProdutoSistema() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/produtos-sistema/', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          console.log(response.data);
          this.lista_produto_aplicado = response.data.results.map(item => ({
            id: item.id,
            text: item.nome
          }))
        })
        .catch(error => {
          console.log(error);
        })
    },

    async cadastrarTipoBase() {
      const token = await this.$store.dispatch('getAuthTokenCookie')

      await this.$store.dispatch('fetchURLrequest')

      const data = {
        'ativo': this.ativo,
        'codigo': this.codigo,
        'descricao': this.descricao,
        'ncm': this.ncm,
        'produto_aplicado': this.produto_aplicado
      }

      this.sucesso = false
      await axios.post('produtos/tipo-produto-base/', data, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          console.log(response.data.results);
          this.sucesso = true
        })
        .catch(error => {
          console.error(error)
          this.erros = error.response?.data || { geral: ["Ocorreu um erro desconhecido."] };
          this.toggleErros();
        })

    },

    toggleErros() {
      this.exibirErros = !this.exibirErros;
    },


  }

}
</script>

<style lang="scss"></style>