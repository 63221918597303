<template>

  <label class="text-base text-corBase font-bold">Relacionamento:</label>
  <div class="flex items-center mb-4">
    <v-select :options="lista_atividades" v-model="dadosPessoaJuridica.atividade" label="nome" :multiple="true"
      class="w-full z-10" :reduce="atividade => atividade.id" />
  </div>
  <!-- {{ dadosPessoaJuridica.atividade }} -->

  <h1 class="titulo-cadastro">Dados Empresa:</h1>

  <form @submit.prevent="cadastroPessoaJuridica">

    <pessoa-juridica-campos :dadosPessoaJuridica="dadosPessoaJuridica" />

    <enderecos-campos :dadosEndereco="dadosEndereco" />

    <telefones-campos :dadosTelefone="dadosTelefone" />

    <emails-campos :dadosEmail="dadosEmail" />

    <interacao-online-campos :dadosInteracao="dadosInteracao" />

    <stakeholders-campos v-if="exibirStakeholders" :dadosStakeholders="dadosStakeholders" />

    <fornecedores-campos v-if="exibirFornecedores" :dadosFornecedor="dadosFornecedor" />

    <div class="flex w-full justify-end">
      <button class="btn-enviar" type="submit">Cadastrar</button>
    </div>

  </form>

  <mensagem-sucesso v-if="sucesso === true" mensagem="Pessoa Juridica Cadastrada com Sucesso!" rotas="/pessoa" />

  <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros()" />

</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import ListagemErros from "../toasts/toast_erro/ListagemErros.vue";
import TipoEnderecoModal from "@/components/midais/modaisCadastro/TipoEnderecoModal";
import TipoTelefoneModal from "@/components/midais/modaisCadastro/TipoTelefoneModal";
import SucessoCadastroPessoaJuridica from "@/components/toasts/toast_sucesso/SucessoCadastroPessoaJuridica";
import EnderecosCampos from "@/components/campos_cadastro/EnderecosCampos";
import TelefonesCampos from "@/components/campos_cadastro/TelefonesCampos";
import PessoaJuridicaCampos from "@/components/campos_cadastro/PessoaJuridicaCampos";
import EmailsCampos from "@/components/campos_cadastro/EmailsCampos";
import InteracaoOnlineCampos from "@/components/campos_cadastro/InteracaoOnlineCampos.vue";
import StakeholdersCampos from "@/components/campos_cadastro/StakeholdersCampos.vue";
import FornecedoresCampos from "@/components/campos_cadastro/FornecedoresCampos.vue";
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";

export default {
  name: 'CadastroPessoaJuridica',
  components: {
    vSelect,
    ListagemErros,
    SucessoCadastroPessoaJuridica,
    TipoTelefoneModal,
    TipoEnderecoModal,
    EnderecosCampos,
    TelefonesCampos,
    PessoaJuridicaCampos,
    EmailsCampos,
    InteracaoOnlineCampos,
    StakeholdersCampos,
    FornecedoresCampos,
    MensagemSucesso
  },

  data() {
    return {
      lista_atividades: [],

      dadosPessoaJuridica: {
        cnpj: '',
        atividade: [],
        razao_social: '',
        nome_fantasia: '',
        responsavel: [],
        funcao: [],
        constituicao: '',
        inscricao_estadual: '',
        suframa: '',
        situacao_fiscal: ''
      },

      dadosEndereco: [
        {
          logadouro: '',
          numero: '',
          complemento: '',
          cep: '',
          bairro: '',
          cidade: '',
          uf: '',
          referencia: '',
          tipo_endereco: '',
          cidades: []
        }
      ],

      dadosTelefone: [
        {
          ddd: '',
          telefone: '',
          whatsapp: false,
          tipo_telefone: '',
        }
      ],

      dadosEmail: [{
        email_pessoa: '',
        tipo_email: '',
      }],

      dadosInteracao: [{
        endereco_interacao: '',
        tipo_interacao: '',
      }],

      dadosStakeholders: [{
        funcao: [],
      }],

      dadosFornecedor: [{
        produtos_fornecedor: [],
      }],

      sucesso: false,

      id_stakeholders: '',
      exibirStakeholders: false,

      id_fornecedor: '',
      exibirFornecedores: false,

      exibirErros: false,
      erros: [],
    }
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
    isadmin() {
      return this.$store.state.isAdminUser
    },
  },

  watch: {
    'dadosPessoaJuridica.atividade': {
      handler(newValue) {
        console.log(newValue)
        if(newValue.includes(this.id_stakeholders)){
          this.exibirStakeholders = true
        } else{
          this.exibirStakeholders = false
        }

        if(newValue.includes(this.id_fornecedor)){
          this.exibirFornecedores = true
        } else{
          this.exibirFornecedores = false
        }
      },
      immediate: false
    }
  },

  created() {
    this.getListaAtividades()
  },

  methods: {

    async getListaAtividades() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      try {
        const response = await axios.get(`/pessoas/atividade/`, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        })

        const nomesDesejados = ['Cliente', 'Fornecedor', 'Stakeholders', 'Revendedor'];
        this.lista_atividades = response.data.results.filter(atividade =>
          nomesDesejados.includes(atividade.nome)
        );
        console.log(this.lista_atividades)

        this.id_stakeholders = this.lista_atividades.filter(atividade =>
          atividade.nome == 'Stakeholders'
        )[0].id;

        this.id_fornecedor = this.lista_atividades.filter(atividade =>
          atividade.nome == 'Fornecedor'
        )[0].id;

      } catch (error) {
        console.error(error)
      }
    },

    async cadastroPessoaJuridica() {

      if (this.dadosPessoaJuridica.atividade.length === 0) {
        this.erros = { relacionamento: ['Este campo não pode ser vazio.'] }
        this.toggleErros()
        return
      }

      if (this.dadosPessoaJuridica.cnpj.length < 18) {
        this.erros = { cnpj: ['Adicione um CNPJ.'] }
        this.toggleErros()
        return
      }

      try {
        const data = {
          nome_razao: this.dadosPessoaJuridica.razao_social,
          atividade: this.dadosPessoaJuridica.atividade,
          identificacao: this.dadosPessoaJuridica.cnpj || '',
          enderecos: this.dadosEndereco,
          telefones: this.dadosTelefone,
          emails: this.dadosEmail,
          interacoes: this.dadosInteracao,
        };

        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.post('pessoas/', data, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        });
        console.log(response)
        this.sucesso = true
        await this.cadastrarPessoaJuridica(response.data.id)
      } catch (error) {
        console.error('Erro ao cadastrar', error);

        if (error.response?.data) {
          // Verifica se error.response.data contém erroView
          if (error.response.data.erroView) {
            this.erros = {
              razao_social: ["Este campo não pode ser em branco."]
            };
          } else {
            // Define this.erros para error.response.data normalmente
            this.erros = error.response.data;
          }
        } else {
          this.erros = { geral: ["Ocorreu um erro desconhecido."] };
        }

        this.toggleErros();

      }
    },

    async cadastrarPessoaJuridica(idPessoa) {
      try {
        const data = {
          pessoa: idPessoa,
          razao_social: this.dadosPessoaJuridica.razao_social,
          nome_fantasia: this.dadosPessoaJuridica.nome_fantasia,
          cnpj: this.dadosPessoaJuridica.cnpj ? this.dadosPessoaJuridica.cnpj.replace(/[^0-9]/g, '') : null,
          inscricao_estadual: this.dadosPessoaJuridica.inscricao_estadual,
          suframa: this.dadosPessoaJuridica.suframa,
          funcao: this.dadosPessoaJuridica.funcao,
          responsavel: this.dadosPessoaJuridica.responsavel.map(responsavel => responsavel.id),
          situacao_fiscal: this.dadosPessoaJuridica.situacao_fiscal,
          funcao: this.dadosStakeholders.funcao ? this.dadosStakeholders.funcao.map(item => item.id || item) : [],
          produtos_fornecedor: this.dadosFornecedor.produtos_fornecedor
        };

        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.post('pessoa/pessoa-juridica/', data, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        });
        console.log(response)
        this.sucesso = true
      } catch (error) {
        console.error('Erro ao cadastrar', error);
        this.erros = error.response?.data || { geral: ["Ocorreu um erro desconhecido."] };
        this.toggleErros();
      }
    },

    toggleErros() {
      this.exibirErros = !this.exibirErros;
    },

  }

}
</script>

<style lang="scss"></style>
