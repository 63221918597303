<template>
  <div class="z-[20005]">
    <div class="container-modal-editar">
      <div class="sombra-modal">
        <galeria v-if="showGaleria === true" @fechar_galeria="abrir_galeria" @objetoClicado="atualizarFileInput" />
        <modal-add-imagem v-if="ged === true" @fechar_ged="addImageGed" @objetoClicado="atualizarFileInput" />

        <div class="conteudo-modal-editar">
          <form class="form-editar text-white">
            <div class="flex justify-end">
              <button @click.prevent="$emit('fecharModal')">
                <i class="fas fa-close text-2xl text-corBase"></i>
              </button>
            </div>
            <h2 class="text-lg text-corBase font-bold mb-2">Editar Produto</h2>

            <div class="flex flex-row gap-4">
              <div class="flex flex-col w-full md:w-[35%] mt-6">
                <label class="text-corBase text-xl font-bold">Adicione uma imagem:</label>
                <div class="imagePreviewWrapper"
                  :style="{ 'background-image': `url(${produto.imagem?.url_documento})` }"
                  @click.prevent="abrir_galeria">
                </div>
                <div class="flex gap-3 mt-4">
                  <button class="selecionar-arquivo" @click.prevent="addImageGed">Adicionar</button>
                  <button class="selecionar-arquivo" @click.prevent="abrir_galeria">Pesquisar</button>
                </div>

              </div>

              <div class="flex flex-col w-full gap-[14px] ml-4 mr-1">
                <div class="flex items-center gap-3 w-full mt-3">

                  <div class="campo-chapa">
                    <label class="text-xl text-corBase font-bold">Produto:</label>
                    <v-select label="nome" class="bg-white" :options="lista_produtos_sistema" v-model="tipo_produto" />
                  </div>
                </div>

                <div>

                  <div class="div-produtos">
                    <div class="w-1/4">
                      <label class="text-xl text-corBase font-bold">Código:</label>
                      <input type="text" v-model="produto.codigo_base" class="input-form-chapa"
                        v-on:input="codigo_base = codigo_base.toUpperCase()" autofocus>
                    </div>

                    <div class="w-full">
                      <label class="text-xl text-corBase font-bold">Descrição:</label>
                      <input type="text" v-model="produto.nome_base" class="input-form-chapa" autofocus>
                    </div>

                  </div>


                  <div class="div-produtos">

                    <div class="campo-chapa">
                      <label class="text-xl text-corBase">Tipo:</label>
                      <v-select class="bg-white" :options="lista_tipo_produto_base" v-model="produto.produto"
                        label="descricao">
                        <template #no-options>
                          <span>
                            {{ tipo_produto ? 'Nenhum tipo encontrado para este produto.' : 'Selecionar Produto' }}
                          </span>
                        </template>
                      </v-select>
                    </div>

                    <div class="campo-chapa">
                      <label class="text-xl text-corBase">Cor:</label>
                      <v-select class="bg-white" label="descricao" :options="lista_cores" v-model="produto.cor_base"
                        :multiple="true">
                        <template #no-options>
                          <span>
                            {{ tipo_produto ? 'Nenhuma cor encontrada para este produto.' : 'Selecionar Produto' }}
                          </span>
                        </template>
                      </v-select>
                    </div>

                    <div class="campo-chapa">
                      <label class="text-xl text-corBase">Espessura:</label>
                      <v-select class="bg-white" label="espessura" :options="lista_espessuras"
                        v-model="produto.espessura" />
                    </div>

                    <div class="campo-chapa">
                      <label class="text-xl text-corBase">Unidade Medida:</label>
                      <v-select class="bg-white" label="nome" :options="lista_unidade_medida"
                        v-model="produto.unidade_medida">
                        <template #no-options>
                          <span>
                            {{ tipo_produto ?
                              'Nenhuma Unidade de Medida encontrada para este produto.'
                              :
                              'SelecionarProduto'
                            }}
                          </span>
                        </template>
                      </v-select>
                    </div>

                    <div class="campo-chapa">
                      <label class="text-xl text-corBase">Peso:</label>
                      <input type="text" v-model="produto.peso_base" class="input-form-chapa" autofocus>
                    </div>

                  </div>

                  <div class="campo-chapa">
                    <label class="text-xl text-corBase">Linha:</label>
                    <v-select class="bg-white" label="nome" :options="lista_linhas" v-model="produto.linha"
                      :multiple="true" />
                  </div>

                  <div class="campo-chapa flex flex-col text-center items-center pt-2">
                    <label class="text-xl text-corBase">Ativo:</label>
                    <span @click.prevent="toggleAtivo" class="text-start pt-1 text-xl">
                      <i v-if="produto.ativo === true" class="fa-solid fa-circle-check text-ativo"></i>
                      <i v-else class="fa-solid fa-circle-xmark text-inativo"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>


            <!--Botão-->
            <div class="flex justify-end">
              <button class="btn-formulario-salvar" @click.prevent="putDadosEditado()">
                Salvar
              </button>
            </div>
          </form>
        </div>
      </div>
      <ErroRapido v-if="erro === true" :mensagem="erroMessage" />
      <SucessoRapido v-if="sucesso === true" :mensagem="sucessoMessage" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import Galeria from "@/components/midais/ged/Galeria";
import ModalAddImagem from "@/components/ged/ModalAddImagem";
import ErroRapido from "@/components/toasts/toast_erro/ErroRapido.vue";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";

export default {
  name: "EditarProdutoBase",

  components: {
    vSelect,
    Galeria,
    ModalAddImagem,
    ErroRapido,
    SucessoRapido,
  },
  data() {
    return {
      primeira_mudanca: true,
      showGaleria: false,
      ged: false,
      lista_unidade_medida: [],
      lista_produtos_sistema: [],
      lista_cores: [],
      lista_espessuras: [],
      lista_tipo_produto_base: [],
      lista_linhas: [],

      tipo_produto: [],

      erro: false,
      erroMessage: '',

      sucesso: false,
      sucessoMessage: ''

    }
  },
  props: ["produto"],
  emits: ["fecharModal", "atualizarLista"],

  created() {
    // this.getListaUnidadeMedida()
    // this.getListaCores()
    // this.getListaTipoProduto()
    // this.getListaTipoProdutoBase()
    // this.getListaLinhaProdutoBase()
    this.getListaProdutoSistema()
    this.getListaLinhas()

    this.tipo_produto = this.produto.tipo_produto
    setTimeout(() => {
      this.primeira_mudanca = false
    }, 1000);

  },

  watch: {
    tipo_produto: {
      handler(newVal) {
        if (newVal) {
          console.log(newVal);

          // Verificar se o tipo é Vidro ou Chapa para buscar as espessuras
          if (newVal.nome === 'Vidro' || newVal.nome === 'Chapa') {
            this.getListaEspessura();
            // alert('buscou as espessuras')
          } else {
            this.espessura = '';
          }

          // Buscar outras listas relacionadas
          this.getListaCores();
          this.getListaTiposProduto();
          this.getListaUnidadeMedida();

          // Limpar os campos relacionados na primeira mudança
          if (!this.primeira_mudanca) {
            this.lista_espessuras = [];
            this.produto.cor_base = '';
            this.produto.produto = '';
            this.produto.unidade_medida = '';
          }
        } else {
          console.log('sem newVal');
        }
      },
      immediate: false,
    },
  },

  methods: {
    toggleAtivo(event) {
      event.preventDefault()
      this.produto.ativo = !this.produto.ativo
    },

    atualizarFileInput(objeto) {
      this.produto.imagem = objeto;
    },

    addImageGed() {
      this.ged = !this.ged
    },

    abrir_galeria() {
      this.showGaleria = !this.showGaleria
    },


    async putDadosEditado() {
      const dadosAtualizados = {
        ativo: this.produto.ativo,
        nome_base: this.produto.nome_base,
        codigo_base: this.produto.codigo_base,
        peso_base: this.produto.peso_base || '',
      };

      // Verifica e adiciona espessura
      if (this.produto.espessura) {
        if (this.produto.espessura.espessura) {
          dadosAtualizados.espessura = this.produto.espessura.espessura;
        } else {
          dadosAtualizados.espessura = this.produto.espessura;
        }
      }

      // Verifica e adiciona tipo_produto
      if (this.tipo_produto) {
        if (this.tipo_produto.id) {
          dadosAtualizados.tipo_produto = this.tipo_produto.id;
        } else {
          dadosAtualizados.tipo_produto = this.tipo_produto;
        }
      }

      // Verifica e adiciona cor_base (múltiplo)
      if (this.produto.cor_base && this.produto.cor_base.length > 0) {
        const corIds = this.produto.cor_base.map(cor => cor.id || cor);
        dadosAtualizados.cor_base = corIds;
      }

      // Verifica e adiciona produto
      if (this.produto.produto) {
        if (this.produto.produto.id) {
          dadosAtualizados.produto = this.produto.produto.id;
        } else {
          dadosAtualizados.produto = this.produto.produto;
        }
      }

      // Verifica e adiciona unidade_medida
      if (this.produto.unidade_medida) {
        if (this.produto.unidade_medida.id) {
          dadosAtualizados.unidade_medida = this.produto.unidade_medida.id;
        } else {
          dadosAtualizados.unidade_medida = this.produto.unidade_medida;
        }
      }

      // Verifica e adiciona imagem apenas se definida
      if (this.produto.imagem) {
        if (this.produto.imagem.id) {
          dadosAtualizados.imagem = this.produto.imagem.id;
        } else {
          dadosAtualizados.imagem = this.produto.imagem;
        }
      }

      // Processa as linhas e adiciona ao objeto
      console.log(this.produto.linha);
      const linhaIds = await this.processarLinhas(this.produto.linha);
      dadosAtualizados.linha = linhaIds;

      // Exibe o objeto final
      console.log(dadosAtualizados);


      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')
        const response = await axios.patch(`produtos/produto/${this.produto.id}/`, dadosAtualizados, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        });
        console.log(response);
        this.sucesso = true
        this.sucessoMessage = `Produto editado com sucesso!`
        setTimeout(() => {
          this.sucesso = false;
          this.sucessoMessage = ''
          this.$emit('fecharModal')
          this.$emit('atualizarLista')
        }, 3000);

      } catch (error) {
        console.error(error)
        this.erro = true
        this.erroMessage = `Ocorreu um erro ao salvar produto. Verifique todos os campos.`
        setTimeout(() => {
          this.erro = false;
          this.erroMessage = ''
        }, 3000);
      }
    },

    async processarLinhas(linhas) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')
      const linhaIds = [];

      for (const linha of linhas) {
        console.log(linha)
        const nome = linha.nome;
        try {
          // Verificar se a linha existe
          const response = await axios.get(`produtos/linha-produto-base/?nome=${nome}`, {
            headers: {
              'Authorization': `Token ${token}`,
            },
          });

          if (response.data.results.length > 0) {
            // Se a linha já existe, pega o ID
            linhaIds.push(response.data.results[0].id);
          } else {
            // Caso contrário, cria a linha e pega o ID
            const createdResponse = await axios.post(
              '/produtos/linha-produto-base/',
              { nome },
              {
                headers: {
                  'Authorization': `Token ${token}`,
                },
              }
            );
            linhaIds.push(createdResponse.data.id);
          }
        } catch (error) {
          console.error(`Erro ao processar a linha ${nome}:`, error);
        }
      }

      console.log(linhaIds)
      return linhaIds;
    },

    async getListaUnidadeMedida() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');
      try {
        const response = await axios.get(`produtos/unidade-medida/?page_size=9999`, {
          headers: {
            'Authorization': `Token ${token}`,
          },
        });
        console.log('unidade medida:')
        console.log(response.data.results)
        this.lista_unidade_medida = response.data.results.map(item => ({
          id: item.id,
          nome: item.nome,
        }));
      } catch (error) {
        console.error(error);
      }
    },

    async getListaCores() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      axios.get(`produtos/cor-produto-base/?tipo_produto=${this.tipo_produto?.nome || ''}&page_size=9999`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          console.log('Cores')
          console.log(response.data.results);
          this.lista_cores = response.data.results;
        })
        .catch(error => {
          console.error(error);
        })
    },

    async getListaLinhaProdutoBase() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');
      try {
        const response = await axios.get(`produtos/linha-produto-base/?ativo=True&page_size=9999`, {
          headers: {
            'Authorization': `Token ${token}`,
          },
        });
        console.log(response.data.results)
        this.lista_linha_produto_base = response.data.results.map(item => ({
          id: item.id,
          nome: item.nome,
        }));
      } catch (error) {
        console.error(error);
      }
    },

    async getListaProdutoSistema() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      axios.get('produtos/produtos-sistema', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          console.log('produtos sistema:')
          console.log(response.data.results);
          this.lista_produtos_sistema = response.data.results.map(item => ({
            id: item.id,
            nome: item.nome
          }));

        })
        .catch(error => {
          console.error(error);
        });

    },

    async getListaTiposProduto() {
      // alert('buscou')
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      axios.get(`produtos/tipo-produto-base/?produto=${this.tipo_produto?.nome || ''}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          console.log('Tipos');
          console.log(response.data.results);
          this.lista_tipo_produto_base = response.data.results.map(item => ({
            id: item.id,
            descricao: item.descricao
          }));
        })
        .catch(error => {
          console.error(error);
        });
    },

    async getListaEspessura() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      axios.get(`produtos/espessura/?page_size=9999`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          console.log(response.data.results);
          this.lista_espessuras = response.data.results;
        })
        .catch(error => {
          console.error(error);
        })

    },

    async getListaUnidadeMedida() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      axios.get(`produtos/unidade-medida/?produto=${this.tipo_produto?.nome || ''}&page_size=9999`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          console.log(response.data);
          this.lista_unidade_medida = response.data.results;
        })
        .catch(error => {
          console.error(error);
        });

    },

    async getListaLinhas() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      axios.get('projeto/linha-sistema/?ativo=True&page_size=9999', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_linhas = response.data.results.map(item => ({
            id: item.id,
            nome: item.linha
          }));
          console.log(this.lista_linhas)


          const linhaNomes = this.produto.linha.map(item => item.nome);
          console.log('Nomes em produto.linha:', linhaNomes);

          const linhasCorrespondentes = linhaNomes
            .map(nome => {
              const linhaEncontrada = this.lista_linhas.find(linha => linha.nome === nome);
              if (linhaEncontrada) {
                console.log(`Correspondência encontrada para "${nome}":`, linhaEncontrada);
                return linhaEncontrada;
              } else {
                console.error(`Nenhuma correspondência encontrada para "${nome}"`);
                return null;
              }
            })
            .filter(obj => obj !== null);

          // Atualizar this.produto.linha com os IDs encontrados
          this.produto.linha = linhasCorrespondentes;
          console.log('Atualizado this.produto.linha:', this.produto.linha);

        })
        .catch(error => {
          console.error(error);
        });

    },

  }
}

</script>

<style>
.container-modal-editar {
  @apply fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full
}

.conteudo-modal-editar {
  @apply bg-screenCor p-6 w-[75%] shadow-xl shadow-screenCor/30 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-[80vh] overflow-y-scroll scrollbar-thin scrollbar-thumb-screenCor/40
}

.form-editar {
  @apply text-corBase
}
</style>