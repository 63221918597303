<template>
    <div>
        <!-- Cabeçalho -->
        <div class="shadow">
            <table class="container-tabela overflow-auto">
                <thead class="cabecalho-tabela">
                    <tr class="border-b">
                        <th class="py-2 px-4 bg-gray-200">Ações</th>
                        <th class="py-2 px-4 bg-gray-200 whitespace-nowrap">
                            <div class="flex items-center">
                                <p class="mr-2">Atividade</p>
                            </div>
                        </th>
                        <th class="py-2 px-4 bg-gray-200">
                            <div class="flex items-center">
                                <p class="mr-2">Pessoa</p>
                                <button class="text-ativo" @click.prevent="toggleModalPessoa">
                                    <i class="fas fa-plus-circle"></i>
                                </button>
                            </div>
                        </th>
                        <th class="py-2 px-4 bg-gray-200">Contato</th>
                    </tr>
                </thead>
                <tbody>

                    <!-- Criação de Stakeholders Obrigatórios se ainda estiverem visíveis -->
                    <template v-if="stakeholdersObrigatoriosVisiveis">
                        <tr v-for="(stakeholder, index) in stakeholdersObrigatorios" :key="`obrigatorio-${index}`"
                            class="escopo-tabela border-b">
                            <td class="py-2 px-4"></td>
                            <td class="py-2 px-4">{{ stakeholder.funcao }}</td>
                            <td class="py-2 px-4 w-96">
                                <v-select v-model="stakeholder.pessoa"
                                    :options="stakeholder.funcao === 'Cliente' ? listaClientes : listaPessoas"
                                    label="text" class="w-full" :clearable="false" />
                            </td>
                            <td class="py-2 px-4">
                                <input v-model="stakeholder.contato" type="text" class="input-form"
                                    placeholder="DDD + Telefone (apenas números)" v-mask="'(##) #####-####'"
                                    minlength="15" maxlength="15" />
                            </td>
                        </tr>
                    </template>


                    <!-- Exibir Stakeholders -->
                    <template v-for="(stakeholder, index) in stakeholdersFiltrados" :key="index">
                        <tr class="escopo-tabela">
                            <td class="py-2 px-4 flex gap-2">
                                <!-- Botão para excluir stakeholders não obrigatórios -->
                                <button
                                    v-if="!['Cliente', 'Patrocinador', 'Responsável de Obra'].includes(stakeholder.funcao)"
                                    @click.prevent="excluirStakeholder(stakeholder)" class="text-gray-500"
                                    title="Excluir stakeholder">
                                    <i class="fas fa-times"></i>
                                </button>

                                <!-- Botão para editar stakeholders obrigatórios -->
                                <button
                                    v-if="['Cliente', 'Patrocinador', 'Responsável de Obra'].includes(stakeholder.funcao)"
                                    @click.prevent="editarStakeholder(stakeholder, index)" class="text-gray-500"
                                    title="Editar stakeholder">
                                    <i class="fas fa-edit"></i>
                                </button>
                            </td>
                            <td class="py-2 px-4 border-b">
                                {{ stakeholder.responsabilidade?.descricao || stakeholder.funcao.text ||
                                    stakeholder.funcao }}
                            </td>
                            <td class="py-2 px-4 border-b">
                                <!-- Exibir pessoa -->
                                <template v-if="stakeholder.editando">
                                    <v-select v-model="stakeholder.pessoa"
                                        :options="stakeholder.funcao === 'Cliente' ? listaClientes : listaPessoas"
                                        label="text" class="w-full" :clearable="false">

                                        <template v-slot:option="option">
                                            <div @click.prevent="buscarTelefonePessoa(stakeholder.pessoa.id, stakeholder)">
                                                <span>
                                                    {{ option.text }}
                                                </span>
                                            </div>
                                        </template>

                                    </v-select>
                                </template>
                                <template v-else>
                                    {{ stakeholder.pessoa?.nome_razao || stakeholder.pessoa.text || stakeholder.pessoa
                                    }}
                                </template>
                            </td>
                            <td class="py-2 px-4 border-b">
                                <!-- Exibir contato -->
                                <template v-if="stakeholder.editando">
                                    <input v-model="stakeholder.contato" type="text" class="input-form"
                                        placeholder="DDD + Telefone (apenas números)" v-mask="'(##) #####-####'"
                                        minlength="15" maxlength="15" />
                                </template>
                                <template v-else>
                                    {{ stakeholder.contato }}
                                </template>
                            </td>
                        </tr>
                        <p v-if="stakeholder.editando">Clique novamente no ícone para salvar.</p>
                    </template>


                    <!-- Linha de input para adicionar novo stakeholder -->
                    <tr v-if="addStakeholder" class="border-b">
                        <td class="py-2 px-4 flex gap-2"></td>
                        <td class="py-2 px-4">
                            <v-select v-model="novoStakeholder.funcao" :options="listaResponsabilidades" label="text"
                                class="w-full" />
                        </td>
                        <td class="py-2 px-4 w-96">
                            <v-select v-model="novoStakeholder.pessoa" :options="listaPessoasFuncao" label="text"
                                class="w-full">
                                <template #no-options>
                                    <span>
                                        {{ novoStakeholder.funcao ? 'Nenhum stakeholder com esta atividade encontrado.'
                                            : 'Selecione uma atividade.' }}
                                    </span>
                                </template>
                            </v-select>
                        </td>
                        <td class="py-2 px-4">
                            <input v-model="novoStakeholder.contato" type="text" class="input-form"
                                placeholder="DDD + Telefone (apenas números)" v-mask="'(##) #####-####'" maxlength="15">
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- Botão de Salvar Stakeholders Obrigatórios -->
        <div class="flex justify-end mt-4" v-if="stakeholdersObrigatoriosVisiveis">
            <button class="btn-salvar" @click.prevent="salvarStakeholdersObrigatorios"
                :disabled="!obrigatoriosPreenchidos">Adicionar Stakeholders Obrigatórios</button>
        </div>

        <!-- Botão de Adicionar Stakeholder -->
        <div class="flex justify-end mt-4 gap-2" v-if="addStakeholder">

            <button class="btn-cancelar-obra" @click.prevent="cancelarStakeholder">
                Cancelar
            </button>
            <button class="btn-salvar-obra" @click.prevent="adicionarStakeholder"
                :disabled="!stakeholderPreenchido">Adicionar
                Stakeholder
            </button>
        </div>

        <!-- Botão para adicionar novo stakeholder -->
        <div class="flex justify-center mt-4" v-if="!addStakeholder && !stakeholdersObrigatoriosVisiveis">
            <button class="text-ativo" @click.prevent="modalAdicionarStakeholder">
                <i class="fas fa-plus-circle"></i>
            </button>
        </div>

        <!-- Botão para avançar ou salvar formulário
        <div class="flex w-full justify-end itens-center mt-6">
            <button type="submit" @click="$emit('salvarObra')" class="btn-salvar">Salvar Stakeholders</button>
        </div> -->
    </div>

    <ModalCriacaoPessoa v-if="mostrarModalPessoa" @atualizarLista="getListaPessoasFisicaJuridica"
        @fecharModal="toggleModalPessoa" />
    <ModalListagemTelefone v-if="exibirTelefones" :lista_telefones="telefonesPessoa"
        @telefoneSelecionado="definirTelefone" @fecharModal="toggleModalTelefones" />

    <!-- <SucessoRapido v-if="sucesso === true" mensagem="Função criada com sucesso." /> -->
    <!-- <ErroRapido v-if="erro === true" mensagem="Ocorreu um erro ao criar a função." /> -->

</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import ModalCriacaoPessoa from "@/utils/ModalCriacaoPessoa.vue";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import ErroRapido from "@/components/toasts/toast_erro/ErroRapido.vue";
import FormatarTelefonePessoa from "@/utils/FormatarTelefonePessoa";
import ModalListagemTelefone from "./Modais/ModalListagemTelefone.vue";

export default {
    name: "ListarStakeholders",
    components: {
        vSelect,
        ModalCriacaoPessoa,
        SucessoRapido,
        ErroRapido,
        FormatarTelefonePessoa,
        ModalListagemTelefone
    },
    emits: ['atualizarStakeholders', 'atualizarStakeholdersObrigatorios', 'salvarStakeholdersObrigatorios', 'clienteEditado', 'atualizarDados'],
    data() {
        return {
            sucesso: false,
            erro: false,

            mostrarModalPessoa: false,

            telefonesPessoa: [],
            exibirTelefones: false,
            stakeholderEmEdicao: null,

            stakeholdersObrigatoriosVisiveis: true,
            stakeholdersObrigatorios: [
                { funcao: 'Cliente', pessoa: '', contato: '' },
                { funcao: 'Patrocinador', pessoa: '', contato: '' },
                { funcao: 'Responsável de Obra', pessoa: '', contato: '' },
            ],
            stakeholders: [],
            addStakeholder: false,
            novoStakeholder: {
                pessoa: '',
                funcao: '',
                contato: '',
            },
            listaPessoas: [],
            listaClientes: [],
            listaPessoasFuncao: [],
            listaResponsabilidades: [],
        };
    },
    props: {
        obra: Object,
    },

    watch: {
        'stakeholdersObrigatorios': {
            async handler(newStakeholders) {

                for (const stakeholder of newStakeholders) {
                    if (stakeholder.pessoa && !stakeholder.contato) {
                        try {
                            const telefone = await this.buscarTelefonePessoa(stakeholder.pessoa.id, stakeholder);
                            if (telefone) {
                                stakeholder.contato = `(${telefone.ddd}) ${telefone.telefone}`;
                            } else {
                                stakeholder.contato = '';
                            }
                        } catch (error) {
                            console.error(`Erro ao buscar telefone para pessoa com ID ${stakeholder.pessoa.id}:`, error);
                        }
                    }
                }
            },
            deep: true,
        },

        'novoStakeholder.funcao': {
            handler(newFuncao) {
                // Reseta os campos de pessoa e contato quando a função é alterada
                this.novoStakeholder.pessoa = '';
                this.novoStakeholder.contato = '';

                // Carrega as pessoas associadas à nova função
                this.carregarPessoasPorFuncao(newFuncao);
            },
            immediate: true,
        },


        'novoStakeholder.pessoa': {
            async handler(newPessoa) {
                if (newPessoa?.id && !this.novoStakeholder.contato) { // Só busca se o contato estiver vazio
                    try {
                        const telefone = await this.buscarTelefonePessoa(newPessoa.id);
                        if (telefone) {
                            this.novoStakeholder.contato = `(${telefone.ddd}) ${telefone.telefone}`;
                        } else {
                            this.novoStakeholder.contato = '';
                        }
                    } catch (error) {
                        console.error(`Erro ao buscar telefone para pessoa com ID ${newPessoa.id}:`, error);
                    }
                }
            },
            immediate: true,
            deep: true,
        },

        // stakeholders: {
        //     async handler(newStakeholders) {
        //         for (const stakeholder of newStakeholders) {
        //             // Quando estiver editando e o ID da pessoa for válido
        //             if (stakeholder.editando && stakeholder.pessoa?.id) {
        //                 try {
        //                     const telefone = await this.buscarTelefonePessoa(stakeholder.pessoa.id);
        //                     if (telefone) {
        //                         stakeholder.contato = `(${telefone.ddd}) ${telefone.telefone}`;
        //                     } else {
        //                         stakeholder.contato = '';
        //                     }
        //                 } catch (error) {
        //                     console.error(`Erro ao buscar telefone para pessoa com ID ${stakeholder.pessoa.id}:`, error);
        //                 }
        //             }
        //         }
        //     },
        //     deep: true,
        // },
    },

    computed: {
        isadmin() {
            return this.$store.state.isAdminUser;
        },

        stakeholderContato() {
            return function (stakeholder) {
                return stakeholder[`contato_${stakeholder.funcao.toLowerCase()}`] || '';
            };
        },

        obrigatoriosPreenchidos() {
            return this.stakeholdersObrigatorios.every(
                stakeholder => stakeholder.pessoa !== ''
                // && stakeholder.contato !== ''
            );
        },

        stakeholderPreenchido() {
            return (
                this.novoStakeholder.pessoa !== '' &&
                this.novoStakeholder.funcao !== ''
                // &&
                // this.novoStakeholder.contato !== ''
            );
        },

        stakeholdersFiltrados() {
            const funcoesUnicas = ["Cliente", "Patrocinador", "Responsável de Obra"]; // Lista de funções únicas
            const funcoesUnicasMap = new Map(); // Para garantir unicidade das funções específicas
            const stakeholdersNaoUnicos = []; // Lista para outras funções

            // Iterar pelos stakeholders para separar únicos e não únicos
            this.stakeholders.forEach((stakeholder) => {
                if (funcoesUnicas.includes(stakeholder.funcao)) {
                    // Se a função for única, verifica se já foi adicionada
                    if (!funcoesUnicasMap.has(stakeholder.funcao)) {
                        funcoesUnicasMap.set(stakeholder.funcao, stakeholder);
                    }
                } else {
                    // Adicionar diretamente ao array de não únicos
                    stakeholdersNaoUnicos.push(stakeholder);
                }
            });

            // Combina os stakeholders únicos com os não únicos
            return [...funcoesUnicasMap.values(), ...stakeholdersNaoUnicos];
        },
    },

    created() {
        // if (this.stakeholders.length == 0 && this.obra.stakeholders.length >= 1) {
        this.stakeholders = [...this.obra.stakeholders];
        this.ajustarStakeholders();
        // }
        this.getListaPessoasFisicaJuridica();
        this.getListaClientes();
        this.getListaFuncoesStakeholder();

        if (this.stakeholders.some(stakeholder => stakeholder.funcao !== 'Cliente')) {
            this.stakeholdersObrigatoriosVisiveis = false;
        }
    },

    methods: {

        formataTelefonePessoa(ddd, telefone) {
            return FormatarTelefonePessoa(ddd, telefone)
        },

        ajustarStakeholders() {
            // Stakeholders Cliente, Patrocinador e Responsável de Obra
            if (this.obra.cliente?.text || this.obra.cliente?.nome_razao) {
                this.stakeholders = [
                    {
                        id: this.obra?.cliente.id || '',
                        funcao: 'Cliente',
                        pessoa: this.obra?.cliente?.text || this.obra?.cliente?.nome_razao || '',
                        contato: this.obra?.contato_cliente || ''
                    },
                    {
                        id: this.obra?.patrocinador.id || '',
                        funcao: 'Patrocinador',
                        pessoa: this.obra?.patrocinador?.text || this.obra?.patrocinador?.nome_razao || '',
                        contato: this.obra?.contato_patrocinador || ''
                    },
                    {
                        id: this.obra?.responsavel.id || '',
                        funcao: 'Responsável de Obra',
                        pessoa: this.obra?.responsavel?.text || this.obra?.responsavel?.nome_razao || '',
                        contato: this.obra?.contato_responsavel || ''
                    }
                ];
                // Caso seja uma edição, adicionar os stakeholders existentes
                if (this.obra.stakeholders?.length > 0) {
                    this.stakeholders.push(...this.obra.stakeholders);
                }
            }
        },


        toggleModalPessoa() {
            alert('era p fechar')
            this.mostrarModalPessoa = !this.mostrarModalPessoa
        },

        modalAdicionarStakeholder() {
            this.addStakeholder = true;
        },

        adicionarStakeholder() {
            if (this.stakeholderPreenchido) {
                this.stakeholders.push(this.novoStakeholder);
                this.novoStakeholder = {
                    pessoa: '',
                    funcao: '',
                    contato: '',
                };
                // console.log(this.stakeholders)
                this.addStakeholder = false;
                // Emitir para garantir que a lista seja atualizada corretamente
                this.$emit('atualizarStakeholders', this.stakeholders);
            }
        },

        cancelarStakeholder() {
            this.addStakeholder = false;
            this.novoStakeholder = {
                pessoa: '',
                funcao: '',
                contato: '',
            };
        },

        async excluirStakeholder(stakeholder) {
            if (stakeholder.id) {
                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')
                const response = await axios.patch(`obra/pessoa-alocada/${stakeholder.id}/`, { ativo: 'False' }, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                })
                // console.log(response)
            }
            const index = this.stakeholders.indexOf(stakeholder);
            if (index !== -1) {
                this.stakeholders.splice(index, 1); // Remove da lista
                this.$emit('atualizarStakeholders', this.stakeholders); // Atualiza no componente pai
            }
        },

        async editarStakeholder(stakeholder, index) {
            if (stakeholder.id) {
                // Alterna o modo de edição
                this.stakeholders[index].editando = !this.stakeholders[index].editando;

                if (!this.stakeholders[index].editando) {
                    try {
                        const token = await this.$store.dispatch('getAuthTokenCookie');
                        await this.$store.dispatch('fetchURLrequest');

                        // Mapeamento direto no método
                        const funcaoMap = {
                            'Cliente': 'cliente',
                            'Patrocinador': 'patrocinador',
                            'Responsável de Obra': 'responsavel',
                        };

                        const backendFuncao = funcaoMap[stakeholder.funcao] || stakeholder.funcao.toLowerCase();

                        // Valores originais
                        const originalPessoaId = this.obra[backendFuncao].id;
                        console.log('pessoaOriginalID')
                        console.log(typeof originalPessoaId)
                        console.log(typeof stakeholder.pessoa)
                        console.log(originalPessoaId)
                        console.log(stakeholder)
                        const originalContato = this.obra[`contato_${backendFuncao}`];

                        // console.log('originalContato')
                        // console.log(originalContato)
                        console.log(stakeholder.contato)

                        // Verifica se houve alteração
                        const hasChanges = stakeholder.pessoa ? stakeholder.pessoa.id : stakeholder.pessoa
                        console.log(hasChanges)

                        if (hasChanges) {
                            const payload = {
                                [backendFuncao]: stakeholder.pessoa.id,
                                [`contato_${backendFuncao}`]: stakeholder.contato,
                            };

                            const response = await axios.patch(
                                `obra/obra/${this.obra.id}/`,
                                payload,
                                {
                                    headers: {
                                        Authorization: `Token ${token}`,
                                    },
                                }
                            );

                            this.$emit('atualizarDados')

                            if (stakeholder.funcao === 'Cliente') {
                                this.$emit('clienteEditado', stakeholder);
                            }

                            console.log('Stakeholder atualizado com sucesso!', response.data);
                        } else {
                            console.log('Nenhuma alteração detectada. Nenhuma requisição enviada.');
                        }
                    } catch (error) {
                        console.error('Erro ao atualizar stakeholder na API:', error);
                    }
                }
            } else {
                // Caso não tenha ID, ativa/desativa o modo de edição local
                this.stakeholders[index].editando = !this.stakeholders[index].editando;

                // Ao desativar o modo de edição, salva as alterações localmente
                if (!this.stakeholders[index].editando) {
                    this.salvarStakeholdersObrigatorios();

                    if (stakeholder.funcao === 'Cliente') {
                        this.$emit('clienteEditado', stakeholder);
                    }
                }
            }
        },


        salvarStakeholdersObrigatorios() {
            const newStakeholdersObrigatorios = {
                cliente: this.stakeholdersObrigatorios.find(stakeholder => stakeholder.funcao === 'Cliente'),
                patrocinador: this.stakeholdersObrigatorios.find(stakeholder => stakeholder.funcao === 'Patrocinador'),
                responsavel: this.stakeholdersObrigatorios.find(stakeholder => stakeholder.funcao === 'Responsável de Obra'),
            };
            this.$emit('salvarStakeholdersObrigatorios', newStakeholdersObrigatorios);

            this.stakeholders.push(...this.stakeholdersObrigatorios);
            this.stakeholdersObrigatoriosVisiveis = false;
        },

        async buscarTelefonePessoa(idPessoa, stakeholder = null) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');

            try {
                this.$store.dispatch("setLoading", true);
                const response = await axios.get(`pessoas/telefones/?pessoa=${idPessoa}`, {
                    headers: {
                        'Authorization': `Token ${token}`,
                    },
                });

                if (response.data.count === 1) {
                    // Atualiza diretamente quando há apenas um telefone
                    const telefone = response.data.results[0];
                    if (stakeholder) {
                        stakeholder.contato = `(${telefone.ddd}) ${telefone.telefone}`;
                    } else if (this.novoStakeholder.pessoa) {
                        this.novoStakeholder.contato = `(${telefone.ddd}) ${telefone.telefone}`;
                    }
                    return telefone;
                } else if (response.data.count > 1) {
                    // Salva o stakeholder em edição e exibe o modal
                    this.stakeholderEmEdicao = stakeholder || null;
                    console.log('STAKEHOLDER EM EDIÇÃO')
                    console.log(this.stakeholderEmEdicao)
                    this.telefonesPessoa = response.data.results;
                    this.toggleModalTelefones();
                } else {
                    // Nenhum telefone encontrado
                    // this.erros = { telefone: ["A pessoa selecionada não possui um telefone cadastrado."] };
                    // this.toggleErros();
                }
            } catch (error) {
                console.error(error);
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        definirTelefone(telefone) {
            if (this.stakeholderEmEdicao) {
                // Atualiza o contato do stakeholder em edição
                this.stakeholderEmEdicao.contato = `(${telefone.ddd}) ${telefone.telefone}`;
            } else if (this.novoStakeholder.pessoa) {
                // Atualiza o contato do novo stakeholder
                this.novoStakeholder.contato = `(${telefone.ddd}) ${telefone.telefone}`;
            }
            this.toggleModalTelefones();
        },

        toggleModalTelefones() {
            this.exibirTelefones = !this.exibirTelefones;
        },

        validatePhone(event) {
            event.target.value = event.target.value.replace(/\D/g, '');
        },

        async getListaPessoasFisicaJuridica() {
            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.get('pessoa/pessoas-fisica-juridica/?atividade=Colaborador&ativo=True&page_size=9999', {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });
                // console.log(response.data)
                this.listaPessoas = response.data.results.map(person => ({
                    id: person.pessoa.id,
                    text: person.nome || person.razao_social,
                    // telefone: person.pessoa_fisica.telefone_pessoa[0]
                }));
            } catch (error) {
                console.error(error);
            }
        },

        async carregarPessoasPorFuncao() {
            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.get(`pessoa/pessoas-fisica-juridica/?funcao=${this.novoStakeholder.funcao.text}&ativo=True&page_size=9999`, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });
                // console.log(response.data)
                this.listaPessoasFuncao = response.data.results.map(person => ({
                    id: person.pessoa.id,
                    text: person.nome || person.razao_social,
                    // telefone: person.pessoa_fisica.telefone_pessoa[0]
                }));
            } catch (error) {
                console.error(error);
            }
        },

        async getListaClientes() {
            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.get(`pessoa/pessoas-fisica-juridica/?atividade=Cliente&ativo=True&page_size=9999`, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });
                // console.log(response.data)
                this.listaClientes = response.data.results.map(person => ({
                    id: person.pessoa.id,
                    text: person.nome || person.razao_social,
                    // telefone: person.pessoa_fisica.telefone_pessoa[0]
                }));
            } catch (error) {
                console.error(error);
            }
        },

        async getListaFuncoesStakeholder() {
            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.get('pessoas/tipo-pessoas/?obrigatorio_obra=False&ativo=True&page_size=9999', {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                })

                this.listaResponsabilidades = response.data.results.map(responsabilidade => ({
                    id: responsabilidade.id,
                    text: responsabilidade.descricao
                }));
            } catch (error) {
                console.error(error)
            }
        }
    }
};
</script>

<style scoped>
.input-form {
    border: 1px solid #ccc;
    padding: 8px;
    width: 100%;
}

.btn-cancelar-obra {
    @apply px-4 py-1 cursor-pointer text-white bg-inativo border hover:bg-screenCor hover:text-inativo hover:border-inativo
}

.btn-salvar-obra {
    @apply px-4 py-1 cursor-pointer text-white bg-ativo border hover:bg-screenCor hover:text-ativo hover:border-ativo
}

/* Para o Google Chrome, Safari, Edge e Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Para o Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
</style>
