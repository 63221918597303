<template>

  <div class="container-formulario">

    <!-- TILO DO FORMULARIO + BOTAO DE VOLTAR -->
    <div class="cabecalho-formulario">
      <h1 class="text-xl text-corBase">Dados Stakeholders:</h1>

      <router-link to="/tipo-pessoa" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <!-- Escopo do formulario de cadastro mobile-->
    <form @submit.prevent="cadastrarTipoPessoa">
      <!--        PARTE DE DADOS DO PRODUTO-->
      <div class="div-produtos">

        <div class="campo-chapa">
          <label class="font-bold text-corBase">Descrição:</label>
          <input type="text" v-model="descricao" class="input-form-chapa">
        </div>

        <div class="campo-chapa">
          <label class="font-bold text-corBase">Sigla:</label>
          <input type="text" maxlength="3" v-model="sigla" @input="sigla = sigla.toUpperCase()"
            class="input-form-chapa">
        </div>

        <div class="campo-chapa">
          <label class="font-bold text-corBase">Ativo:</label>
          <span class="text-start pt-1 text-xl">
            <i @click.prevent="toggleAtivo" v-if="ativo === true" class="fa-solid fa-circle-check text-ativo cursor-pointer"></i>
            <i @click.prevent="toggleAtivo" v-else class="fa-solid fa-circle-xmark text-inativo cursor-pointer"></i>
          </span>
        </div>

        <div class="campo-chapa">
          <label class="font-bold text-corBase">Obrigatório na Obra:</label>
          <span class="text-start pt-1 text-xl">
            <i @click.prevent="toggleObrigatorio" v-if="obrigatorio_obra === true" class="fa-solid fa-circle-check text-ativo cursor-pointer"></i>
            <i @click.prevent="toggleObrigatorio" v-else class="fa-solid fa-circle-xmark text-inativo cursor-pointer"></i>
          </span>
        </div>
      </div>


      <div class="flex w-full justify-end">
        <button class="btn-enviar" type="submit">Cadastrar</button>
      </div>
    </form>

  </div>
  <MensagemSucesso v-if="sucesso == true" rotas="/tipo-pessoa" mensagem="Stakeholder cadastrado com sucesso!" />
  <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros()" />

</template>

<script>
import axios from "axios";
import ListagemErros from "@/components/toasts/toast_erro/ListagemErros.vue";
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";

export default {
  name: "TipoPessoa",

  components: {
    ListagemErros,
    MensagemSucesso,
  },

  data() {
    return {
      sucesso: false,
      descricao: '',
      sigla: '',
      ativo: true,
      obrigatorio_obra: false,

      exibirErros: false,
      erros: [],
    }
  },

  created() {
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
    isadmin() {
      return this.$store.state.isAdminUser
    },
  },

  methods: {

    toggleAtivo(){
      this.ativo = !this.ativo
    },

    toggleObrigatorio(){
      this.obrigatorio_obra = !this.obrigatorio_obra
    },

    async cadastrarTipoPessoa() {
      const token = await this.$store.dispatch('getAuthTokenCookie')

      await this.$store.dispatch('fetchURLrequest')

      const data = new FormData();
      data.append('descricao', this.descricao)
      data.append('sigla', this.sigla)
      data.append('ativo', this.ativo)
      data.append('obrigatorio_obra', this.obrigatorio_obra)
      this.sucesso = false
      await axios.post('pessoas/tipo-pessoas/', data, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          console.log(response.data);
          this.sucesso = true
        })
        .catch(error => {
          console.error('Erro ao cadastrar', error);
          this.erros = error.response?.data || { geral: ["Ocorreu um erro desconhecido."] };
          this.toggleErros();
        })
    },

    toggleErros() {
      this.exibirErros = !this.exibirErros;
    },


  }

}
</script>

<style lang="scss"></style>