<template>

  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Cores</h1>
      </div>

      <router-link to="/cores" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>

    <div class="flex items-center gap-3 mb-10">

      <div class="block">

        <div class="flex gap-2">
          <div class="w-full">
            <label class="text-xl text-corBase">Produto:</label>
            <v-select class="" v-model="tipo_produto"
              :options="['Perfil', 'Chapa', 'Vidro', 'Uso Consumo', 'Componente', 'Serviço']"></v-select>
          </div>

          <div class="w-full">
            <label class="text-xl text-corBase">Código:</label>
            <input type="text" class="input-form-chapa" v-model="codigo">
          </div>

          <div class="w-full">
            <label class="text-xl text-corBase">Descrição:</label>
            <input type="text" class="input-form-chapa" v-model="descricao">
          </div>

          <div class="w-full">
            <label class="text-xl text-corBase">Status:</label>
            <v-select :options="lista_status" label="nome" v-model="status" :reduce="status => status.value" />
          </div>
        </div>

      </div>

      <div class="flex gap-8">
        <div>
          <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
  text-white hover:text-corBase py-2 px-4" @click="getListaCorPadrao(1)">Pesquisar
          </button>
        </div>
      </div>

      <div>
        <button class="bg-blue-500 hover:bg-screenCor border border-screenCor hover:border-blue-500
        text-white hover:text-blue-500 py-2 px-4" @click.prevent="alterarSelecionados()">
          Alterar Status Selecionados
        </button>
      </div>

      <!-- v-if="isadmin === true" -->
      <div>
        <button @click="$router.push(`/cadastro-cores-padrao`)" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-2 px-4">Adicionar
        </button>
      </div>
      
    </div>

    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
          <tr>
            <th scope="col" class="p-4">
              <div class="flex items-center">
                <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

                <label class="sr-only">checkbox</label>
              </div>
            </th>
            <th scope="col" class="px-6 py-3">
              #
            </th>
            <th class="px-6 py-3 text-start">
              Produtos
            </th>
            <th class="px-6 py-3">
              Descrição
            </th>
            <th class="px-6 py-3 text-center">
              Código
            </th>
            <th scope="col" class="px-6 py-3 text-center">
              Status
            </th>
            <th scope="col" class="px-10 py-3">
              <!-- v-if="isadmin === true" -->
              Ação
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(cor, index) in lista_cores_padrao" :key="index" class="escopo-tabela">

            <td class="w-4 p-4">
              <div class="flex items-center">
                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox" v-model="cor.selected">
                <label class="sr-only">checkbox</label>
              </div>
            </td>
            <th scope="row" class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
              {{ index + 1 }}
            </th>

            <td class="">
              <ul class="list-disc">
                <li v-for="(produto, index) in cor.produto_aplicado" :key="index">
                  {{ produto.nome }}
                </li>
              </ul>
            </td>

            <td class="px-6 py-2 text-xs">
              {{ cor.descricao }}
            </td>

            <td class="px-6 py-2 text-xs text-center">
              {{ cor.codigo || '-' }}
            </td>

            <template v-if="cor.ativo === true">
              <td class="px-1 sm:px-6  py-1 sm:py-4 text-center">
                <i class="fa-solid fa-circle-check  text-ativo"></i>
              </td>
            </template>
            <template v-if="cor.ativo === false">
              <td class="px-1 sm:px-6  py-1 sm:py-4 text-center">
                <i class="fa-solid fa-circle-xmark text-inativo"></i>
              </td>
            </template>

            <td class="px-6 py-2 text-xs">
              <a @click="selecionarCorEditar(cor)" href="#" class="font-medium text-corBase pl-2 hover:underline">
                <i class="fa-solid fa-pen-to-square mr-2"></i>Editar
              </a>
            </td>

          </tr>
        </tbody>
      </table>

      <Pagination v-if="lista_cores_padrao.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
        @change-page="mudarPagina" />
    </div>

  </div>

  <div v-if="erroModal" class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <div class="bg-white p-6 rounded-lg shadow-lg w-1/3 text-center">
      <p class="text-gray-700">{{ erroMessage }}</p>
      <div class="mt-6">
        <button
          class="bg-red-500 border text-white px-4 py-2 rounded hover:bg-screenCor hover:text-red-500 hover:border-red-500"
          @click="fecharErroModal()">
          Fechar
        </button>
      </div>
    </div>
  </div>

  <EditarCorBase v-if="mostrarModal" :cor="corSelecionada" @fecharModal="toggleModalEditar"
    @atualizarLista="getListaCorPadrao(paginaAtual)" />

    <AlterarSelecionadosCorBase v-if="modalAlterarSelecionados === true" @fecharModal="toggleAlterarSelecionados()"
    @atualizarLista="getListaCorPadrao(paginaAtual)" :cores="coresSelecionadas" />
</template>

<script>
import axios from "axios";
import Pagination from "@/utils/Pagination.vue";
import EditarCorBase from "@/components/midais/Editar/cores/EditarCorBase.vue";
import AlterarSelecionadosCorBase from "@/components/midais/Editar/cores/AlterarSelecionadosCorBase.vue";
import vSelect from "vue-select";

export default {
  name: "ListaCoresPadraoSistema",

  components: {
    Pagination,
    EditarCorBase,
    vSelect,
    AlterarSelecionadosCorBase,
  },

  data() {
    return {
      lista_cores_padrao: [],
      lista_produto_base: [],
      lista_status: [
        { value: 'True', nome: "Ativo" },
        { value: 'False', nome: "Inativo" },
      ],

      tipo_produto: '',
      codigo: '',
      descricao: '',
      status: '',

      checkAll: false,
      // varival que inicializa o numero de paginas
      paginaAtual: 1,
      countItens: '',

      corSelecionada: [],
      mostrarModal: false,

      coresSelecionadas: [],
      modalAlterarSelecionados: false,

      error: [],
      erroModal: false,
      erroMessage: '',

      sucesso: false,
    }
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },

    isadmin() {
      return this.$store.state.isAdminUser
    },

    checkSome() {
      return this.lista_cores_padrao.some(cor => cor.selected)
    }
  },

  created() {

  },

  methods: {

    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });

      this.lista_cores_padrao.forEach((cor) => {
        cor.selected = this.checkAll;
      });
    },

    async getListaCorPadrao(pagina) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      try {
        const response = await axios.get(`/produtos/cor-produto-base/?tipo_produto=${this.tipo_produto || ''}&codigo=${this.codigo || ''}&descricao=${this.descricao || ''}&ativo=${this.status || ''}&page=${pagina}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        this.lista_cores_padrao = response.data.results;
        this.countItens = response.data.count;
        console.log(this.lista_cores_padrao);

      } catch (error) {
        console.error("Erro ao buscar lista de cores padrão:", error);
      }
    },

    alterarSelecionados() {
      if (this.checkSome || this.checkAll) {
        this.toggleAlterarSelecionados();
        this.coresSelecionadas = this.lista_cores_padrao.filter(cor => cor.selected);
        console.log(this.coresSelecionadas)
      } else {
        this.erroModal = true;
        this.erroMessage = 'Selecione pelo menos uma Cor para ser alterada.';
      }
    },

    toggleAlterarSelecionados() {
      this.modalAlterarSelecionados = !this.modalAlterarSelecionados;
    },

    fecharErroModal(){
      this.erroModal = false;
    },

    mudarPagina(pagina) {
      this.paginaAtual = pagina;
      this.getListaCorPadrao(pagina);
    },

    selecionarCorEditar(cor) {
      this.corSelecionada = { ...cor }
      this.toggleModalEditar()
    },

    toggleModalEditar() {
      this.mostrarModal = !this.mostrarModal
    },

  }
}
</script>

<style lang="scss"></style>