<template>
    <div>
        <div class="container-modal-editar">
            <div class="sombra-modal">
                <div class="conteudo-modal-editar">
                    <form class="form-editar text-white" @submit.prevent="putDadosEditado">
                        <div class="flex justify-end">
                            <button @click.prevent="$emit('fecharModal')">
                                <i class="fas fa-close text-2xl text-corBase"></i>
                            </button>
                        </div>
                        <h2 class="text-lg text-corBase font-bold mb-2">Editar Dados Cor Base</h2>

                        <div>
                            <div class="md:flex md:gap-12">

                                <div class="flex flex-col mt-2 w-full justify-start">
                                    <label class="text-base text-corBase font-bold mt-2">Código:</label>
                                    <input type="text" required class="input-campos text-base" v-model="codigo"
                                        v-on:input="codigo = codigo.toUpperCase()">
                                </div>

                                <div class="flex flex-col mt-2 w-full justify-start">
                                    <label class="text-base text-corBase font-bold mt-2">Descrição:</label>
                                    <input type="text" required class="input-campos text-base" v-model="descricao">
                                </div>

                                <div class="flex flex-col mt-2 w-full justify-start">
                                    <label class="text-base text-corBase font-bold mt-2">Produtos:</label>
                                    <v-select v-model="produtos_selecionados" :options="lista_produtos_sistema"
                                        label="nome" multiple class="w-full" />
                                </div>


                                <div class="flex flex-col mt-2 w-full justify-start ">
                                    <label class="text-base text-corBase font-bold mt-2">Ativo:</label>

                                    <span @click.prevent="toggleAtivo" class="text-start pt-1 text-xl">
                                        <i v-if="cor.ativo === true" class="fa-solid fa-circle-check text-ativo"></i>
                                        <i v-else class="fa-solid fa-circle-xmark text-inativo"></i>
                                    </span>
                                </div>

                            </div>

                        </div>

                        <!--Botão-->
                        <div class="btn-formulario-modal">
                            <button class="btn-formulario-salvar" type="submit">
                                Salvar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <SucessoRapido v-if="sucesso" mensagem="Cor atualizada com sucesso!" />
            <ErroRapido v-if="erro" mensagem="Ocorreu um erro ao atualizar Cor." />
            <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros()" />
        </div>
    </div>
</template>

<script>
import axios from "axios";
import ErroRapido from "@/components/toasts/toast_erro/ErroRapido.vue";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import ListagemErros from "@/components/toasts/toast_erro/ListagemErros.vue";
import vSelect from "vue-select";

export default {
    name: "EditarCorBase",
    components: {
        ErroRapido,
        SucessoRapido,
        ListagemErros,
        vSelect,
    },
    data() {
        return {
            statusModal: false,
            sucesso: false,
            erro: false,

            erros: [],
            exibirErros: false,

            lista_produtos_sistema: [],
            produtos_selecionados: [],
            codigo: '',
            descricao: ''

        }
    },

    props: ["cor"],

    emits: ["fecharModal", "atualizarLista"],

    created() {
        console.log(this.cor)
        this.produtos_selecionados = this.cor.produto_aplicado?.map(cor => {
            return { id: cor.id, nome: cor.nome }
        })
        this.codigo = this.cor.codigo
        this.descricao = this.cor.descricao
        this.getProdutosSistema()
    },

    methods: {

        async getProdutosSistema() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            await axios.get('produtos/produtos-sistema', {
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
                .then(response => {
                    console.log(response.data)
                    this.lista_produtos_sistema = response.data.results.map(item => ({
                        id: item.id,
                        nome: item.nome
                    }))
                })
                .catch(err => {
                    console.log(err)
                })
        },

        toggleAtivo() {
            this.cor.ativo = !this.cor.ativo
        },

        async putDadosEditado() {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');

            // Mapeia os IDs dos produtos selecionados
            const produtoIds = Array.isArray(this.produtos_selecionados)
                ? this.produtos_selecionados.map(produto => produto.id)
                : [];

            // Dados para atualizar a cor base
            const dadosAtualizados = {
                ativo: this.cor.ativo,
                codigo: this.codigo,
                descricao: this.descricao,
                produto_aplicado: produtoIds
            };

            try {
                // Atualiza os dados na tabela base
                const response = await axios.patch(`produtos/cor-produto-base/${this.cor.id}/`, dadosAtualizados, {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                });
                console.log('Atualização na cor base:', response.data);

                // // Exibe sucesso
                this.sucesso = true;
                setTimeout(() => {
                    this.sucesso = false;
                    this.$emit("fecharModal");
                    this.$emit("atualizarLista");
                }, 3000);
            } catch (error) {
                console.error('Erro ao atualizar a cor base:', error);
                this.erros = error.response?.data || { geral: ["Ocorreu um erro desconhecido."] };
                this.toggleErros();

            }
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros;
        },

    }
}
</script>

<style lang="scss"></style>