<template>

    <!-- formulario para mais de 768 pixels -->
    <section class="">
        <!-- Escopo do formulario de cadastro mobile-->
        <form @submit.prevent="alterarDadosProjeto" enctype="multipart/form-data">
            <div class="w-full mx-auto mt-8">
                <div class="space-y-4">
                    <div class="p-4 flex flex-col border border-corBase/60">
                        <div class="flex gap-5 pb-4">

                            <div class="w-full">
                                <label class="font-bold text-corBase">Largura Mínima</label>
                                <input type="text" class="input-campos" v-model="largura_minima">
                            </div>

                            <div class="w-full">
                                <label class="font-bold text-corBase">Largura Máxima</label>
                                <input type="text" class="input-campos" v-model="largura_maxima">
                            </div>

                            <div class="w-full">
                                <label class="font-bold text-corBase">Altura Mínima</label>
                                <input type="text" class="input-campos" v-model="altura_minima">
                            </div>

                            <div class="w-full">
                                <label class="font-bold text-corBase">Altura Máxima</label>
                                <input type="text" class="input-campos" v-model="altura_maxima">
                            </div>

                        </div>

                        <div class="flex gap-5 pb-4">

                            <div class="w-full text-center">
                                <label class="font-bold text-corBase">Bloquear Orçamento</label>
                                <input type="checkbox" class="input-campos" v-model="bloquear_orcamento">
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <div class="flex w-full gap-8 justify-end">
                <button class="btn-enviar" type="submit">Salvar</button>
            </div>
        </form>
    </section>

    <SucessoRapido v-if="sucesso" mensagem="Dados do projeto atualizados com sucesso" />
    <listagem-erros v-if="exibirErros == true" :erros="erros" @fecharModal="toggleErros()" />
</template>


<script>
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";
import axios from "axios";
import Select2 from "vue3-select2-component";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import ListagemErros from "@/components/toasts/toast_erro/ListagemErros.vue";
import vSelect from "vue-select";
import Search from "@/utils/Search.vue";

export default {
    name: 'MedidasProjeto',

    components: {
        MensagemSucesso,
        Select2,
        SucessoRapido,
        ListagemErros,
        vSelect,
        Search
    },

    data() {
        return {
            projeto_id: '',

            altura_minima: '',
            altura_maxima: '',
            largura_minima: '',
            largura_maxima: '',
            bloquear_orcamento: false,

            sucesso: false,

            exibirErros: false,
            erros: {}

        }
    },

    props: ['dadosProjeto'],

    emits: ['atualizarLista'],

    created() {
        this.projeto_id = this.$route.params.id
        this.defineVariaveisProjeto()
    },

    watch: {
        sucesso() {
            if (this.sucesso) {
                setTimeout(() => {
                    this.sucesso = false
                }, 2000)
            }
        },
    },

    methods: {

        defineVariaveisProjeto() {
            this.altura_maxima = this.dadosProjeto.altura_maxima
            this.altura_minima = this.dadosProjeto.altura_minima
            this.largura_maxima = this.dadosProjeto.largura_maxima
            this.largura_minima = this.dadosProjeto.largura_minima
            this.bloquear_orcamento = this.dadosProjeto.bloquear_orcamento
        },

        async alterarDadosProjeto() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                // console.log(this.sistemista)

                const updatedData = {
                    altura_minima: this.altura_minima,
                    altura_maxima: this.altura_maxima,
                    largura_minima: this.largura_minima,
                    largura_maxima: this.largura_maxima,
                    bloquear_orcamento: this.bloquear_orcamento,
                }

                await axios.patch(`projeto/projeto/${this.dadosProjeto.id}/`, updatedData, {
                    headers: {
                        'Authorization': `Token ${token}`
                    },
                })
                this.sucesso = true
                this.$emit('atualizarLista')

            } catch (error) {
                console.error(error)
                if (error.response && error.response.data) {
                    this.erros = error.response.data;
                } else {
                    this.erros = { geral: ["Erro desconhecido ao salvar o projeto."] };
                }
                this.toggleErros();
                this.$store.dispatch("setLoading", false);

            }
        },

        toggleErros() {
            this.exibirErros = !this.exibirErros
        },

    }

}

</script>
