<template>


  <div class="container-formulario">

    <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Stakeholders</h1>
      </div>

      <router-link to="/configuracoes-relacionamento" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>


    <div class="flex items-end gap-8 mb-6">

      <div class="w-1/3">
        <label class="text-xl text-corBase font-bold">Descrição:</label>
        <input type="text" v-model="descricao" class="input-form-chapa" />
      </div>

      <div class="w-1/3">
        <label class="text-xl text-corBase font-bold">Obrigatório na Obra:</label>
        <v-select v-model="obrigatorio_obra" :options="[
          { value: 'True', text: 'Sim' },
          { value: 'False', text: 'Não' },
        ]" label="text" :reduce="option => option.value" class="w-full" />
      </div>

      <div class="w-1/3">
        <label class="text-xl text-corBase font-bold">Status:</label>
        <v-select v-model="status" :options="[
          { value: 'True', text: 'Ativo' },
          { value: 'False', text: 'Inativo' },
        ]" label="text" :reduce="option => option.value" class="w-full" />
      </div>

    </div>

    <div class="flex items-center gap-3 mb-10">

      <div class="flex gap-8">
        <div>
          <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
  text-white hover:text-corBase py-2 px-4" @click="getListaTipoPessoa(1)">Pesquisar
          </button>
        </div>
      </div>
      <div v-if="isadmin === true">
        <button @click="$router.push(`/tipo-pessoa/cadastro`)" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-2 px-4 ">Adicionar
        </button>
      </div>
    </div>

    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
          <tr>
            <th scope="col" class="p-4">
              <div class="flex items-center">
                <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

                <label class="sr-only">checkbox</label>
              </div>
            </th>
            <th scope="col" class="px-6 py-3">
              #
            </th>
            <th class="px-6 py-3">
              Descrição
            </th>
            <th class="px-6 py-3 text-center">
              Sigla
            </th>
            <th class="px-6 py-3 text-center">
              Obrigatório na Obra
            </th>
            <th scope="col" class="px-6 py-3 text-center">
              Status
            </th>
            <th scope="col" class="px-10 py-3" v-if="isadmin === true">
              Ações
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(tipo_pessoa, index) in lista_tipos_pessoa" :key="index" class="escopo-tabela">

            <td class="w-4 p-4">
              <div class="flex items-center">
                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                  v-model="tipo_pessoa.selected">
                <label class="sr-only">checkbox</label>
              </div>
            </td>
            <th scope="row" class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
              {{ index + 1 }}
            </th>
            <td class="px-6 py-2 text-xs">
              {{ tipo_pessoa.descricao }}
            </td>
            <td class="px-6 py-2 text-xs text-center">
              {{ tipo_pessoa.sigla }}
            </td>
            <template v-if="tipo_pessoa.obrigatorio_obra === true">
              <td class="px-6 py-3 text-center">
                <i class="fa-solid fa-circle-check  text-ativo"></i>
              </td>
            </template>
            <template v-if="tipo_pessoa.obrigatorio_obra === false">
              <td class="px-6 py-3 text-center">
                <i class="fa-solid fa-circle-xmark text-inativo"></i>
              </td>
            </template>
            <template v-if="tipo_pessoa.ativo === true">
              <td class="px-6 py-3 text-center">
                <i class="fa-solid fa-circle-check  text-ativo"></i>
              </td>
            </template>
            <template v-if="tipo_pessoa.ativo === false">
              <td class="px-6 py-3 text-center">
                <i class="fa-solid fa-circle-xmark text-inativo"></i>
              </td>
            </template>

            <td class="px-6 py-2 text-xs" v-if="isadmin === true">
              <a @click="selecionarTipoEditar(tipo_pessoa)" href="#"
                class="font-medium text-corBase pl-2 hover:underline">
                <i class="fa-solid fa-pen-to-square mr-2"></i>Editar
              </a>
            </td>


          </tr>
        </tbody>
      </table>

      <editar-tipo-pessoa v-if="exibirModal" :tipoPessoa="tipoSelecionado" @fecharModal="toggleModal()"
        @atualizarLista="mudarPagina(paginaAtual)" />
      <Pagination v-if="lista_tipos_pessoa.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
        @change-page="mudarPagina" />
    </div>
  </div>

</template>

<script>
import axios from "axios";
import EditarTipoPessoa from "@/components/midais/Editar/tipo/EditarTipoPessoa";
import Pagination from "@/utils/Pagination.vue";
import vSelect from "vue-select";

export default {
  name: "ListaTipoPessoa.vue",

  components: {
    EditarTipoPessoa,
    Pagination,
    vSelect,
  },

  data() {
    return {
      lista_tipos_pessoa: [],
      tipo_pessoa: '',

      descricao: '',
      obrigatorio_obra: '',
      status: '',

      checkAll: false,
      // varival que inicializa o numero de paginas
      paginaAtual: 1,
      countItens: '',

      tipoSelecionado: [],
      exibirModal: false,

      error: [],
    }
  },

  computed: {

    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },

    isadmin() {
      return this.$store.state.isAdminUser
    },
  },


  methods: {

    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },


    async getListaTipoPessoa(pagina) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest');

      this.$store.dispatch("setLoading", true);

      try {
        const response = await axios.get(`/pessoas/tipo-pessoas/?descricao=${this.descricao || ''}&obrigatorio_obra=${this.obrigatorio_obra || ''}&ativo=${this.status || ''}&page=${pagina}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });

        this.lista_tipos_pessoa = response.data.results;
        this.countItens = response.data.count;
        console.log(this.lista_tipos_pessoa);

      } catch (error) {
        console.error("Erro ao buscar tipos de pessoa:", error);
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    mudarPagina(pagina) {
      this.paginaAtual = pagina;
      this.getListaTipoPessoa(pagina);
    },

    selecionarTipoEditar(tipo) {
      this.tipoSelecionado = { ...tipo }
      this.toggleModal()
    },

    toggleModal() {
      this.exibirModal = !this.exibirModal
    },

  }
}
</script>

<style lang="scss"></style>