<template>
  <div>
    <div class="container-modal-editar">
      <div class="sombra-modal">
        <div class="conteudo-modal-editar">
          <form class="form-editar text-white">
            <div class="flex justify-end">
              <button @click.prevent="$emit('fecharModal')">
                <i class="fas fa-close text-2xl text-corBase"></i>
              </button>
            </div>
            <h2 class="text-lg text-corBase font-bold mb-2">Editar Dados - Pessoa Física</h2>
            <!--Campos da Pessoa-->

            <label class="text-base text-corBase font-bold">Relacionamento:</label>
            <div class="flex items-center mb-4">
              <v-select :options="lista_atividades" v-model="dadosPessoaFisica.atividade" label="nome" :multiple="true"
                class="w-full z-10 bg-white" />
            </div>

            <pessoa-fisica-dados :dadosPessoaFisica="dadosPessoaFisica" />

            <enderecos-campos :dadosEndereco="dadosEndereco" />

            <telefones-campos :dadosTelefone="dadosTelefone" />

            <emails-campos :dadosEmail="dadosEmail" />

            <interacao-online-campos :dadosInteracao="dadosInteracao" />

            <stakeholders-campos v-if="exibirStakeholders" :dadosStakeholders="dadosStakeholders" />

            <fornecedores-campos v-if="exibirFornecedores" :dadosFornecedor="dadosFornecedor" />

            <!--Botão-->
            <div class="btn-formulario-modal mt-4 flex justify-end">
              <button class="btn-formulario-salvar" @click.prevent="putDadosEditado">
                Salvar
              </button>
            </div>
          </form>
        </div>
      </div>

      <SucessoRapido v-if="sucesso" mensagem="Pessoa Física atualizada com sucesso!" />
      <ListagemErros v-if="exibirErros" :erros="erros" @fecharModal="toggleErros()" />

    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import debounce from "lodash.debounce";
import SucessoRapido from "@/components/toasts/toast_sucesso/SucessoRapido.vue";
import EnderecosCampos from "@/components/campos_cadastro/EnderecosCampos";
import PessoasCampos from "@/components/campos_cadastro/PessoasCampos";
import TelefonesCampos from "@/components/campos_cadastro/TelefonesCampos";
import PessoaFisicaDados from "@/components/campos_cadastro/PessoaFisicaDados";
import EmailsCampos from "@/components/campos_cadastro/EmailsCampos";
import InteracaoOnlineCampos from "@/components/campos_cadastro/InteracaoOnlineCampos.vue";
import StakeholdersCampos from "@/components/campos_cadastro/StakeholdersCampos.vue";
import FornecedoresCampos from "@/components/campos_cadastro/FornecedoresCampos.vue";
import MensagemSucesso from "@/components/toasts/toast_sucesso/MensagemSucesso";
import ListagemErros from "@/components/toasts/toast_erro/ListagemErros.vue";

export default {
  name: "EditarDados",

  components: {
    vSelect,
    SucessoRapido,
    TelefonesCampos,
    EnderecosCampos,
    PessoasCampos,
    PessoaFisicaDados,
    EmailsCampos,
    InteracaoOnlineCampos,
    StakeholdersCampos,
    FornecedoresCampos,
    MensagemSucesso,
    ListagemErros,
    vSelect,
  },

  data() {
    return {

      tipo_pessoa: [],
      tipo_endereco: [],
      tipo_email: [],
      tipo_telefone: [],

      exibirErros: false,
      erros: [],

      sucesso: false,

      lista_atividades: [],

      dados: {
        pessoais: {},
        endereco: {},
        telefone: {},
        email: {},
        tipo_pessoa_geral: '',
      },

      dadosPessoaFisica: {
        nome_razao: '',
        nome_social: '',
        atividade: [],
        cpf: '',
        rg: '',
        orgao_expeditor: '',
        nascimento: '',
      },

      dadosEndereco: [
        {
          logradouro: '',
          numero: '',
          complemento: '',
          cep: '',
          bairro: '',
          cidade: '',
          uf: '',
          referencia: '',
          tipo_endereco: '',
          cidades: []
        }
      ],

      dadosTelefone: [
        {
          ddd: '',
          telefone: '',
          tipo_telefone: '',
          whatsapp: false,
          telegram: false,
        }
      ],

      dadosEmail: [{
        email_pessoa: '',
        tipo_email: '',
      }],

      dadosInteracao: [{
        endereco_interacao: '',
        tipo_interacao: '',
      }],

      dadosStakeholders: [{
        funcao: [],
      }],

      dadosFornecedor: [{
        produtos_fornecedor: [],
      }],


      exibirStakeholders: false,

      exibirFornecedores: false,
    }
  },
  props: ["dadosPessoa"],
  emits: ["fecharModal", "atualizarLista"],

  watch: {
    'dadosPessoaFisica.atividade': {
      handler(newValue) {
        console.log('newValue: ', newValue)
        if (newValue.some(atividade => atividade.nome === 'Stakeholders')) {
          this.exibirStakeholders = true
          console.log('exibir stakeholders true')
        } else {
          this.exibirStakeholders = false
          console.log('exibir stakeholders true')
        }

        if (newValue.some(atividade => atividade.nome === 'Fornecedor')) {
          this.exibirFornecedores = true
          console.log('exibir fornecedor true')
        } else {
          this.exibirFornecedores = false
          console.log('exibir fornecedor false')
        }

        console.log('exibirstakeholders:', this.exibirStakeholders)
        console.log('exibirfornecedores:', this.exibirFornecedores)
      },
      immediate: false,
      deep: true,
    },
  },

  created() {
    console.log(this.dadosPessoa)
    // this.dadosPessoaFisica = this.dadosPessoa

    this.dadosPessoaFisica.nome_razao = this.dadosPessoa.nome
    this.dadosPessoaFisica.nome_social = this.dadosPessoa.nome_social
    this.dadosPessoaFisica.atividade = this.dadosPessoa.pessoa.atividade
    this.dadosPessoaFisica.cpf = this.dadosPessoa.cpf
    this.dadosPessoaFisica.rg = this.dadosPessoa.rg
    this.dadosPessoaFisica.orgao_expeditor = this.dadosPessoa.orgao_expeditor
    this.dadosPessoaFisica.nascimento = this.dadosPessoa.nascimento
    this.dadosPessoaFisica.pessoa = this.dadosPessoa.pessoa.id

    if (this.dadosPessoaFisica.atividade &&
      this.dadosPessoaFisica.atividade.some(atividade => atividade.nome === 'Stakeholders')) {
      this.dadosStakeholders.funcao = this.dadosPessoa.funcao
      this.exibirStakeholders = true;
    } else {
      this.exibirStakeholders = false;
    }

    if (this.dadosPessoaFisica.atividade &&
      this.dadosPessoaFisica.atividade.some(atividade => atividade.nome === 'Fornecedor')) {
      this.dadosFornecedor.produtos_fornecedor = this.dadosPessoa.produtos_fornecedor;
      this.exibirFornecedores = true;
    } else {
      this.exibirFornecedores = false;
    }


    this.getListaAtividades()
    // this.getTipoPessoa()
    // this.getTipoEndereco()
    // this.getTipoEmail()
    // this.getTipoTelefone()

    this.getEnderecos(this.dadosPessoaFisica.pessoa)
    this.getTelefones(this.dadosPessoaFisica.pessoa)
    this.getEmails(this.dadosPessoaFisica.pessoa)
    this.getInteracoes(this.dadosPessoaFisica.pessoa)


    // console.log('dadospessoafisica')
    // console.log(this.dadosPessoaFisica)
  },
  methods: {

    async putDadosEditado() {

      try {
        const data = {
          nome_razao: this.dadosPessoaFisica.nome_razao,
          // atividade: this.dadosPessoaFisica.atividade,
          atividade: this.dadosPessoaFisica.atividade.map(atividade => atividade.id),
          identificacao: this.dadosPessoaFisica.cpf || '',
          enderecos: this.dadosEndereco,
          telefones: this.dadosTelefone,
          emails: this.dadosEmail,
          interacoes: this.dadosInteracao,
        };

        // console.log(data)

        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.patch(`pessoas/update/${this.dadosPessoaFisica.pessoa}/`, data, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        });
        // console.log(response)
        // this.sucesso = true

        await this.atualizarPessoaFisica(response.data.id)
      } catch (error) {
        console.error('Erro ao cadastrar', error);

        if (error.response?.data) {
          // Verifica se error.response.data contém erroView
          if (error.response.data.erroView) {
            this.erros = {
              nome: ["Este campo não pode ser em branco."]
            };
          } else {
            // Define this.erros para error.response.data normalmente
            this.erros = error.response.data;
          }
        } else {
          this.erros = { geral: ["Ocorreu um erro desconhecido."] };
        }

        this.toggleErros();
      }

    },

    async atualizarPessoaFisica(idPessoa) {
      try {
        const data = {
          pessoa: idPessoa,
          nome: this.dadosPessoaFisica.nome_razao,
          nome_social: this.dadosPessoaFisica.nome_social,
          cpf: this.dadosPessoaFisica.cpf ? this.dadosPessoaFisica.cpf.replace(/[^0-9]/g, '') : null,
          rg: this.dadosPessoaFisica.rg ? this.dadosPessoaFisica.rg.replace(/[^0-9]/g, '') : null,
          orgao_expeditor: this.dadosPessoaFisica.orgao_expeditor || '',
          nascimento: this.dadosPessoaFisica.nascimento || '',
          funcao: this.dadosStakeholders.funcao ? this.dadosStakeholders.funcao.map(item => item.id || item) : [],
          produtos_fornecedor: this.dadosFornecedor.produtos_fornecedor ? this.dadosFornecedor.produtos_fornecedor.map(item => item.id || item) : []
        };

        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.patch(`pessoa/pessoa-fisica/${this.dadosPessoa.id}/`, data, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        });
        // console.log(response)
        this.sucesso = true
        setTimeout(() => {
          this.sucesso = false;
          this.$emit('fecharModal')
          this.$emit('atualizarLista')
        }, 3000);
      } catch (error) {
        console.error('Erro ao cadastrar', error);
        this.erros = error.response?.data || { geral: ["Ocorreu um erro desconhecido."] };
        this.toggleErros();
      }
    },





    async getEnderecos(pessoa) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      try {
        this.$store.dispatch("setLoading", true);
        const response = await axios.get(`pessoas/enderecos/?pessoa=${pessoa}`, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        })

        this.dadosEndereco = response.data.results
        // console.log(this.dadosEndereco)
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    async getTelefones(pessoa) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      try {
        this.$store.dispatch("setLoading", true);
        const response = await axios.get(`pessoas/telefones/?pessoa=${pessoa}`, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        })

        this.dadosTelefone = response.data.results
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    async getEmails(pessoa) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      try {
        this.$store.dispatch("setLoading", true);
        const response = await axios.get(`pessoas/email/?pessoa=${pessoa}`, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        })

        this.dadosEmail = response.data.results
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    async getInteracoes(pessoa) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      try {
        this.$store.dispatch("setLoading", true);
        const response = await axios.get(`pessoas/interacoes/?pessoa=${pessoa}`, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        })

        this.dadosInteracao = response.data.results
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },

    async getListaAtividades() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      try {
        const response = await axios.get(`/pessoas/atividade/`, {
          headers: {
            'Authorization': `Token ${token}`,
          }
        })

        const nomesDesejados = ['Cliente', 'Fornecedor', 'Stakeholders', 'Sócio da Empresa', 'Colaborador', 'Revendedor'];
        this.lista_atividades = response.data.results.filter(atividade =>
          nomesDesejados.includes(atividade.nome)
        );
        // console.log(this.lista_atividades)
      } catch (error) {
        console.error(error)
      }
    },

    async getTipoPessoa() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')
      await axios.get('pessoas/tipo-pessoas', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.tipo_pessoa = response.data.results
        })
        .catch(error => {
          console.error(error)
        })
    },

    async getTipoEndereco() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')
      await axios.get('pessoas/enderecos/tipo-enderecos', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.tipo_endereco = response.data.results
        })
        .catch(error => {
          console.error(error)
        })
    },
    async getTipoEmail() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')
      await axios.get('pessoas/email/tipo-email', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.tipo_email = response.data.results
        })
        .catch(error => {
          console.error(error)
        })
    },
    async getTipoTelefone() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')
      await axios.get('pessoas/telefones/tipo-telefones', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.tipo_telefone = response.data.results
        })
        .catch(error => {
          console.error(error)
        })
    },


    buscarCepAutomaticamente: debounce(async function (endereco) {
      if (endereco.cep && endereco.cep.length === 9) {
        await this.buscarCep(endereco);
      }
    }, 500),

    async buscarCep(endereco) {
      const cepSemMascara = endereco.cep.replace(/[^0-9]/g, '');
      const token = await this.$store.state.token;

      this.$store.dispatch('fetchURLrequest');
      try {
        const response = await axios.get(`cep/${cepSemMascara}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        });
        // console.log('Resposta da busca de CEP:', response.data);
        endereco.logradouro = response.data.logradouro;
        endereco.bairro = response.data.bairro;
        endereco.cidade = response.data.localidade;
        endereco.uf = response.data.uf;
      } catch (error) {
        console.error('Erro ao buscar CEP:', error);
      }
    },

    toggleErros() {
      this.exibirErros = !this.exibirErros;
    },

  }
}
</script>

<style>
.container-modal-editar {
  @apply fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full
}

.conteudo-modal-editar {
  @apply bg-screenCor p-6 w-[75%] shadow-xl shadow-screenCor/30 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-[80vh] overflow-y-scroll scrollbar-thin scrollbar-thumb-screenCor/40
}

.form-editar {
  @apply text-corBase
}
</style>