<template>

    <div class="container-formulario">

        <!-- Cabeçalho lista de pessoas depois de 768 pixels-->
        <div class="container-cabecalho flex justify-between mb-4">
            <div>
                <h1 class="text-xl text-corBase font-bold">Fases da Esquadria</h1>
            </div>

            <router-link to="/projetos" class="text-xl text-corBase font-bold">
                <i class="fas fa-chevron-left"></i>
            </router-link>

        </div>
        <div class="flex gap-8 items-end mb-6 w-[80%]">
            <!-- <div class="w-full">
                <label class="text-corBase">Nome</label>
                <input type="text" class="input-form-chapa" v-model="linha">
            </div>
            <div class="w-full">
                <label class="text-corBase">Sistemista</label>
                <v-select v-model="projetista" :options="projetistas_sistemistas" :reduce="projetista => projetista.id"
                    label="text" />
            </div>
            <div class="w-full">
                <label class="text-corBase">Bitola</label>
                <input type="text" class="input-form-chapa" v-model="bitola">
            </div> -->
            <div>
                <button class="btn-add-fisica" @click="getListaFasesEsquadria(paginaAtual)">Pesquisar</button>
            </div>
            <div v-if="isadmin === true">
                <button @click="$router.push(`/fase-esquadria/cadastro`)" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
    text-white hover:text-ativo py-1 rounded-sm px-4 mt-2 mr-2">Adicionar
                </button>
            </div>
        </div>


        <!-- Container da exibiçao da lista de pessoas -->
        <div class="container-modelo-tabela">
            <table class="container-tabela overflow-auto">
                <thead class="cabecalho-tabela">
                    <tr>
                        <th scope="col" class="p-4">
                            <div class="flex items-center">
                                <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">
                                <label class="sr-only">checkbox</label>
                            </div>
                        </th>
                        <th scope="col" class="px-6 py-3">#</th>
                        <th class="px-6 text-start w-full">Descrição</th>
                        <th scope="col" class="text-center px-10">Status</th>
                        <th v-if="isadmin === true" scope="col" class="px-10 text-start">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(fase_esquadria, index) in lista_fase_esquadria" :key="index" class="escopo-tabela">
                        <td class="w-4 p-4">
                            <div class="flex items-center">
                                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                                    v-model="fase_esquadria.selected">
                                <label class="sr-only">checkbox</label>
                            </div>
                        </td>
                        <th scope="row"
                            class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
                            {{ index + 1 }}
                        </th>
                        <td class="px-6 py-2 text-xs">{{ fase_esquadria.descricao }}</td>
                        <td>
                            <div class="px-1 sm:px-6 py-1 sm:py-4 text-center">
                                <i v-if="fase_esquadria.ativo" class="fa-solid fa-circle-check text-ativo"></i>
                                <i v-else class="fa-solid fa-circle-xmark text-inativo"></i>
                            </div>
                        </td>

                        <!-- Ações -->
                        <td class="px-6 py-2 text-xs" v-if="isadmin === true">
                            <a @click="selecionarFaseEditar(fase_esquadria)" href="#"
                                class="font-medium text-corBase pl-2 hover:underline">
                                <i class="fa-solid fa-pen-to-square mr-2"></i>Editar
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <Pagination v-if="lista_fase_esquadria.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
                @change-page="mudarPagina" />
            <editar-fases-esquadria v-if="mostrarModal === true" :fase_esquadria="faseEsquadriaSelecionada"
                @fecharModal="toggleModal()"
                @atualizarLista="getListaFasesEsquadria(paginaAtual)" />
        </div>
    </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import EditarFasesEsquadria from "@/components/midais/Editar/projeto/EditarFasesEsquadria.vue";
import Pagination from "@/utils/Pagination.vue";

export default {
    name: "ListaLinhasSistemistas",

    components: {
        vSelect,
        EditarFasesEsquadria,
        Pagination
    },

    data() {
        return {
            lista_fase_esquadria: [],
            projetistas_sistemistas: [],

            checkAll: false,
            // varival que inicializa o numero de paginas
            paginaAtual: 1,
            countItens: 0,

            // modal
            faseEsquadriaSelecionada: null,
            mostrarModal: false,

            error: [],
        }
    },

    computed: {
        minhaEmpresa() {
            return this.$store.state.minhaEmpresa;
        },

        isadmin() {
            return this.$store.state.isAdminUser
        },
    },

    created() {
        // this.getListaProjetistaSistemista()
    },

    methods: {

        selectAll() {
            // Atualiza o estado dos demais checkboxes
            const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
            checkboxes.forEach((checkbox) => {
                checkbox.checked = this.checkAll;
            });
        },

        async getListaFasesEsquadria(pagina) {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            await axios.get(`/projeto/fase-esquadria/?page=${pagina}`, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
                .then(response => {
                    this.lista_fase_esquadria = response.data.results
                    this.countItens = response.data.count
                })
                .catch(error => {
                    console.error(error)
                })
        },

        mudarPagina(pagina) {
            this.paginaAtual = pagina
            this.getListaFasesEsquadria(pagina)
        },

        selecionarFaseEditar(fase) {
            this.faseEsquadriaSelecionada = { ...fase }
            this.toggleModal()
        },

        toggleModal() {
            this.mostrarModal = !this.mostrarModal
        }

    }
}
</script>

<style lang="scss"></style>