<template>
    <details>
        <summary class="mt-4">
            <h2 class="bg-screenCor hover:bg-screenCor border border-corBase hover:border-corBase
  text-corBase hover:text-corBase py-2 px-4 ">Stakeholders
            </h2>
        </summary>


        <div class="border border-gray-200 p-2 mt-3">
            <div class="w-full flex flex-col items-center justify-center text-center">
                <label class="text-base text-corBase">Função:</label>
                <v-select :options="lista_cargos" v-model="dadosStakeholders.funcao" :multiple="true" label="descricao"
                    class="w-full" />
                <!-- Funções: {{ dadosStakeholders.funcao }} -->
            </div>
        </div>

    </details>


</template>

<script>
import axios from 'axios'
import vSelect from 'vue-select';
export default {
    name: 'StakeholdersCampos',
    props: ['dadosStakeholders'],
    data() {
        return {
            lista_cargos: [],
        }
    },

    components: {
        vSelect,
    },

    created() {
        this.getListaCargos()
    },

    methods: {
        async getListaCargos() {
            const token = await this.$store.dispatch('getAuthTokenCookie')
            await this.$store.dispatch('fetchURLrequest')

            try {
                const response = await axios.get(`pessoas/tipo-pessoas/?ativo=True&page_size=9999`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                this.lista_cargos = response.data.results
                // console.log(this.lista_cargos)
            } catch (error) {
                console.error(error)
            }
        },


    }
}
</script>

<style scoped></style>