<template>

  <div class="flex flex-col gap-3 md:flex md:flex-row md:gap-3">
    <div class="flex flex-col mt-2 w-full">
      <label class="text-base text-corBase mt-2">CPF:</label>
      <input type="text" class="input-campos" placeholder="xxx.xxx.xxx-xx" v-mask="'###.###.###-##'"
        v-model="dadosPessoaFisica.cpf" @input="emitirDados">
    </div>

    <div class="flex flex-col mt-2 w-full">
      <label class="text-base text-corBase mt-2">RG:</label>
      <input type="text" class="input-campos" v-mask="'##.###.###-#'" v-model="dadosPessoaFisica.rg">
    </div>

    <div class="flex flex-col mt-2 w-full">
      <label class="text-base text-corBase mt-2">Órgão Expeditor:</label>
      <input type="text" class="input-campos" v-model="dadosPessoaFisica.orgao_expeditor">
    </div>

    <div class="flex flex-col mt-2 w-full">
      <label class="text-base text-corBase mt-2">Data de Nascimento:</label>
      <input type="date" class="input-campos" v-model="dadosPessoaFisica.nascimento">
    </div>
  </div>

  <div class="flex flex-col gap-3 md:flex md:flex-row md:gap-3 mb-6">
    <div class="flex flex-col mt-2 w-full">
      <label class="text-base text-corBase mt-2 font-bold">Nome:</label>
      <input type="text" class="input-campos"
      v-model="dadosPessoaFisica.nome_razao">
    </div>
    <!-- v-on:input="dadosPessoaFisica.nome_razao = dadosPessoaFisica.nome_razao.toUpperCase()" -->
    
    <div class="flex flex-col mt-2 w-full">
      <label class="text-base text-corBase mt-2">Nome Social:</label>
      <input type="text" class="input-campos"
      v-model="dadosPessoaFisica.nome_social">
    </div>
    <!-- v-on:input="dadosPessoaFisica.nome_razao = dadosPessoaFisica.nome_razao.toUpperCase()" -->

  </div>

  <hr>

  <!--  <div class="flex flex-col gap-3 md:flex md:flex-row md:gap-3 mt-6">-->
  <!--    <div class="flex flex-col mt-2 w-full">-->
  <!--      <h2 class="text-xl text-corBase font-bold">Enderecos:</h2>-->
  <!--    </div>-->
  <!--  </div>-->

  <!--  <div class="flex flex-col gap-3 md:flex md:flex-row md:gap-3">-->
  <!--    <div class="flex">-->
  <!--      <enderecos  @salvar-dados="receberDados" />-->
  <!--    </div>-->
  <!--  </div>-->


</template>

<script>
import axios from "axios";
import Select2 from "vue3-select2-component";
import vSelect from "vue-select";
// import TipoPessoaGeralModal from "@/components/midais/modaisCadastro/TipoPessoaGeralModal";
import Enderecos from "@/components/midais/modaisCadastro/Enderecos";

export default {
  name: "PessoaFisicaDados",

  components: {
    vSelect,
    Select2,
    // TipoPessoaGeralModal,
    Enderecos
  },

  data() {
    return {
      lista_atividades: [
        { label: "Cliente", value: "cliente" },
        { label: "Fornecedor", value: "fornecedor" },
        { label: "Stakeholder", value: "stakeholder" },
        { label: "Colaborador", value: "colaborador" },
      ],

      lista_tipo_pessoa: [],

    }
  },

  created() {
    this.getListaTipoPessoaGeral()
    this.getTipoEmail()
    this.getTipoEndereco()
    this.getTipoTelefone()
  },

  props: ["dadosPessoaFisica"],

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
    isadmin() {
      return this.$store.state.isAdminUser
    },
  },

  methods: {

    async getListaTipoPessoaGeral() {
      const token = await this.$store.state.token

      await this.$store.dispatch('fetchURLrequest')
      await axios.get('pessoas/tipo-pessoas', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_tipo_pessoa = response.data.results.map(item => ({
            id: item.id,
            text: item.descricao
          }))
        })
        .catch(error => {
          if (error.response) {
            for (const property in error.response.data) {
              this.errors.push(`${property}: ${error.response.results.data[property]}`)
            }
          } else if (error.message) {
            this.errors.push('Something went wrong. Please try again!')
          }
        })
    },

    async getTipoEndereco() {
      const token = await this.$store.state.token

      await this.$store.dispatch('fetchURLrequest')

      await axios.get('pessoas/enderecos/tipo-enderecos', {
        headers: {
          'Authorization': `Token ${token}` // Define o cabeçalho Authorization com o token
        }
      })
        .then(response => {
          this.tipo_endereco = response.data.results
          // console.log(response.data.results)
        })
        .catch(error => {
          if (error.response) {
            for (const property in error.response.data.results) {
              this.errors.push(`${property}: ${error.response.data.results[property]}`)
            }
          } else if (error.message) {
            this.errors.push('Something went wrong. Please try again!')
          }
        })
    },

    async getTipoTelefone() {
      const token = await this.$store.state.token

      await this.$store.dispatch('fetchURLrequest')

      await axios.get('pessoas/telefones/tipo-telefones', {
        headers: {
          'Authorization': `Token ${token}` // Define o cabeçalho Authorization com o token
        }
      })
        .then(response => {
          this.tipo_telefone = response.data.results
          // console.log(response.data.results)
        })
        .catch(error => {
          if (error.response) {
            for (const property in error.response.data.results) {
              this.errors.push(`${property}: ${error.response.data.results[property]}`)
            }
          } else if (error.message) {
            this.errors.push('Something went wrong. Please try again!')
          }
        })
    },

    async getTipoEmail() {
      const token = await this.$store.state.token

      await this.$store.dispatch('fetchURLrequest')

      await axios.get('pessoas/email/tipo-email', {
        headers: {
          'Authorization': `Token ${token}` // Define o cabeçalho Authorization com o token
        }
      })
        .then(response => {
          this.tipo_email = response.data.results
          // console.log(response.data.results)
        })
        .catch(error => {
          if (error.response) {
            for (const property in error.response.data.results) {
              this.errors.push(`${property}: ${error.response.data.results[property]}`)
            }
          } else if (error.message) {
            this.errors.push('Something went wrong. Please try again!')
          }
        })
    },
  }
}
</script>

<style scoped></style>