<template>
    <div>
        <div class="fixed inset-0 bg-black/60 flex items-center justify-center z-[10000]">
            <div
                class="bg-white rounded-lg shadow-lg p-6 w-[90%] sm:w-[600px] max-h-[80vh] overflow-y-auto transform transition-transform duration-300 ease-out scale-95 sm:scale-100">
                <!-- Cabeçalho do Modal -->
                <div class="flex justify-between items-center mb-4 border-b pb-2">
                    <h2 class="text-lg font-bold text-corBase">Escolha um Endereço:</h2>
                    <button @click.prevent="$emit('fecharModal')"
                        class="text-gray-500 hover:text-gray-800 p-2 rounded-full transition duration-200 ease-in-out hover:bg-gray-100">
                        <i class="fa-solid fa-x"></i>
                    </button>
                </div>

                <!-- Lista de Endereços -->
                <div v-if="lista_enderecos.length > 0">
                    <div v-for="endereco in lista_enderecos" :key="endereco.id" class="border-b last:border-none py-3">
                        <!-- Dados do Endereço -->
                        <i @click.prevent="selecionarEndereco(endereco)" class="fa-solid fa-check cursor-pointer"></i>
                        <p class="text-base text-gray-700">
                            <span class="font-semibold">Endereço:</span>
                            {{ endereco.logadouro }}, nº {{ endereco.numero }}
                            <span v-if="endereco.complemento">, {{ endereco.complemento }}</span>,
                            bairro {{ endereco.bairro }}, {{ endereco.cidade }} - {{ endereco.uf }}
                        </p>
                        <p class="text-sm text-gray-500 mt-1">
                            <span class="font-semibold">Tipo de Endereço:</span>
                            {{ endereco.tipo_endereco?.descricao || "Não especificado" }}
                        </p>
                    </div>
                </div>
                <div v-else>
                    <p>Sem endereço cadastrado.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: "ListarEnderecos",
    props: ['pessoa', 'lista_enderecos'],
    emits: ['fecharModal', 'enderecoSelecionado'],

    data() {
        return {
            // lista_enderecos: []
        }
    },


    created() {
        console.log(this.lista_enderecos);
        // this.getEnderecos()
    },

    methods: {
        selecionarEndereco(endereco){
            this.$emit('enderecoSelecionado', endereco)
            this.$emit('fecharModal')
        }
    }
};
</script>

<style scoped></style>