<template>
  <div class="container-modal-editar">
    <div class="sombra-modal">
      <div class="conteudo-modal-editar">
        <div class="flex justify-end">
          <button @click.prevent="this.$emit('fechar')">
            <i class="fas fa-close text-2xl text-corBase"></i>
          </button>
        </div>

        <div>
          <div class="cabecalho-formulario">
            <h1 class="text-xl text-corBase font-bold">Valores da Variável</h1>
          </div>

          <div class="container-modelo-tabela">
            <table class="container-tabela overflow-auto">
              <thead class="cabecalho-tabela">
                <tr class="border-b">
                  <th scope="col" class="px-2 py-2 text-center">#</th>
                  <th scope="col" class="px-6 py-2 text-center">
                    <div class="flex items-center">
                      <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">
                      <label class="sr-only">checkbox</label>
                    </div>
                  </th>
                  <th scope="col" class="px-3 py-2 text-center">Opções</th>
                  <th scope="col" class="px-6 py-2 text-center">Variável</th>
                  <th scope="col" class="px-20 py-2 text-center">Descrição</th>
                  <th scope="col" class="px-12 py-2 text-center">Valor</th>
                  <th scope="col" class="px-24 py-2 text-center">Descrição</th>
                  <th scope="col" class="px-6 py-2 text-center">Imagem</th>
                  <th scope="col" class="px-24 py-2 text-center">Condição</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(opcao, index) in opcoesVariavel" :key="index" class="escopo-tabela">
                  <th scope="col" class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
                    {{ index + 1 }}
                  </th>
                  <td class="text-center">
                    <div class="selec">
                      <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox-opcao"
                        v-model="opcao.selected">
                      <label class="sr-only">checkbox</label>
                    </div>
                  </td>
                  <td class="text-center">
                    <button @click.prevent="opcao.edit = !opcao.edit">
                      <i class="fa-solid fa-pen-to-square"></i>
                    </button>
                  </td>
                  <td scope="col" class="text-center text-xs">
                    {{ opcao.variavel.variavel }}
                  </td>
                  <td scope="col" class="text-center text-xs">
                    {{ opcao.variavel.descricao }}
                  </td>
                  <td scope="col" class="text-center text-xs" v-if="!opcao.edit">
                    {{ opcao.valor }}
                  </td>
                  <td scope="col" class="text-center text-xs" v-else>
                    <input type="text" class="input-campos" v-model="opcao.valor">
                  </td>
                  <td scope="col" class="text-center text-xs" v-if="!opcao.edit">
                    {{ opcao.descricao }}
                  </td>
                  <td scope="col" class="text-center text-xs" v-else>
                    <input type="text" class="input-campos"
                      v-model="opcao.descricao">
                  </td>
                  <td scope="col" class="text-center text-xs">
                    <div class="flex justify-center text-center gap-6">
                      <!-- <button 
                        @click.prevent="abrir_galeria"
                        v-if="opcao.edit"
                      >
                        <i class="fa-solid fa-image fa-xl"></i>
                        Escolha uma Imagem
                      </button> -->

                      <img v-if="opcao.imagem" :src="opcao.imagem.url_documento" alt="Imagem da opção" class="w-10 cursor-pointer"
                        @click="openPreviewImage(opcao.imagem.url_documento)">

                      <img v-else
                        src="https://managerglass.s3.amazonaws.com/media/public/b7144d8c-4a5c-4c56-a9bd-924275d6c55d.png"
                        alt="Sem imagem" class="w-10">
                    </div>
                  </td>
                  <td scope="col" class="text-center text-xs" v-if="!opcao.edit">
                    {{ opcao.condicao }}
                  </td>
                  <td scope="col" class="text-center text-xs" v-else>
                    <input type="text" class="input-campos"
                      v-model="opcao.condicao">
                  </td>
                </tr>
              </tbody>

              <tbody>
                <tr v-if="adicionarNovaOpcao" class="escopo-tabela text-center">
                  <td class=""></td>
                  <td class=""></td>
                  <td class=""></td>
                  <td class="text-center">
                    {{ variavel.variavel }}
                  </td>
                  <td class="text-center">
                    {{ variavel.descricao }}
                  </td>
                  <td class="text-center">
                    <input type="text" class="input-campos"
                      v-model="novaOpcao.valor">
                  </td>
                  <td class="w-full text-center">
                    <input type="text" class="input-campos"
                      v-model="novaOpcao.descricao">
                  </td>
                  <td class="w-full flex text-center justify-center" v-if="!novaOpcao.imagem">
                    <button @click.prevent="abrir_galeria">
                      <i class="fa-solid fa-image fa-xl"></i>
                      Escolha uma Imagem
                    </button>
                  </td>
                  <td class="w-full flex text-center justify-center items-center" v-else>
                    <img :src="novaOpcao.imagem.url_documento" alt="Imagem da Nova Opção" class="w-10 h-10 cursor-pointer"
                      @click.prevent="openPreviewImage(novaOpcao.imagem.url_documento)">
                  </td>
                  <td class="w-full text-center">
                    <input type="text" class="input-campos"
                      v-model="novaOpcao.condicao">
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="flex w-full justify-end itens-center mt-6 px-4 mb-5" v-if="adicionarNovaOpcao">
              <button class="btn-cancelar" @click.prevent="toggleAdicionarOpcao">
                Cancelar
              </button>
              <button class="btn-salvar" @click.prevent="postNovaOpcao">
                Adicionar
              </button>
            </div>

            <div class="flex justify-center" v-if="!adicionarNovaOpcao">
              <button class="text-ativo" @click.prevent="toggleAdicionarOpcao">
                <i class="fas fa-plus-circle"></i>
              </button>
            </div>
          </div>

          <div class="flex flex-wrap sm:flex-nowrap w-full justify-between items-center mt-6 gap-4">
            <div class="flex flex-wrap sm:flex-nowrap items-center gap-3 w-full sm:w-auto">
              <button class="botoes bg-red-500 w-full sm:w-auto" @click.prevent="verificarExclusao()">
                Excluir Selecionados
              </button>
              <!-- {{ modal_confirmacao_opcao_variavel }} -->
              <!-- <button class="botoes bg-blue-500 w-full sm:w-auto" @click.prevent="importarVariaveisBase()">
                Importar Variáveis da Base
              </button>
              <button class="botoes bg-yellow-500 w-full sm:w-auto" @click="importarVariaveisProjeto()">
                Importar Variáveis Projeto
              </button> -->
            </div>

            <input type="file" ref="fileInput" class="hidden" @change="importarPerfis" accept=".csv" />

            <div class="flex items-center w-full sm:w-auto justify-center sm:justify-end">
              <button class="btn-salvar w-full sm:w-auto" @click.prevent="atualizarOpcoesVariavel(opcoesVariavel)">
                Salvar/Atualizar
              </button>
            </div>
          </div>

          <!-- <div class="flex justify-end mt-5">
            <button class="btn-cancelar" @click.prevent="modal_confirmacao_opcao_variavel = true">
              Excluir Selecionados
            </button>
            <button class="btn-salvar"
              @click.prevent="atualizarOpcoesVariavel(opcoesVariavel)">Salvar/Atualizar</button>
          </div> -->
        </div>
      </div>
    </div>
  </div>

  <div v-if="erroModal" class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[10000]">
    <div class="bg-white p-6 rounded-lg shadow-lg w-1/3 text-center">
      <p class="text-gray-700">{{ erroMessage }}</p>
      <div class="mt-6">
        <button
          class="bg-red-500 border text-white px-4 py-2 rounded hover:bg-screenCor hover:text-red-500 hover:border-red-500"
          @click="fecharErroModal()">
          Fechar
        </button>
      </div>
    </div>
  </div>

  <MensagemConfirmacaoExclusao mensagem="Deseja mesmo excluir os itens selecionados?"
    :abrir="modal_confirmacao_opcao_variavel" @feharModal="feharModalConfirmacao"
    @confirmarExclusao="excluirSelecionados" />

  <preview-image v-if="preview_imagem" @closed="closed_preview_image" :imagem="preview_imagem" />

  <listagem-erros v-if="exibirErros === true" :erros="erros" @fecharModal="toggleErros()" />

  <Galeria v-if="galeria === true" @fechar_galeria="abrir_galeria" @objetoClicado="atualizarFileInput" />
</template>

<script>
import axios from 'axios'
import Galeria from '../ged/Galeria.vue';
import PreviewImage from '@/components/midais/ged/PreviewImage.vue'
import MensagemConfirmacaoExclusao from '@/components/toasts/toast_notificacao/MensagemConfirmacaoExclusao.vue';
import ListagemErros from '@/components/toasts/toast_erro/ListagemErros.vue';

export default {
  name: 'ModalOpcoesVariaveis',

  data() {
    return {
      novasOpcoes: [],
      galeria: false,
      previewImage: {},
      preview_imagem: '',

      novaOpcao: {
        variavel: this.variavel.id,
        valor: '',
        descricao: '',
        imagem: '',
        condicao: '',
        index: this.opcoesVariavel.length + 1
      },

      adicionarNovaOpcao: false,

      erros: {},
      exibirErros: false,
      erroModal: false,

      checkAll: false,
      modal_confirmacao_opcao_variavel: false,
    }
  },

  props: ['variavel', 'opcoesVariavel'],

  emits: ['fechar', 'getListaOpcoesVariavel', 'atualizarOpcoesVariavel'],

  components: {
    Galeria,
    PreviewImage,
    MensagemConfirmacaoExclusao,
    ListagemErros
  },

  computed: {

    checkSome() {
      return this.opcoesVariavel.some(opcao => opcao.selected)
    },
  },

  methods: {
    // atualizarOpcoesVariavel(opcoesVariavelAAtualizar) {
    //   this.$emit('atualizarOpcoesVariavel', opcoesVariavelAAtualizar)
    // },

    atualizarOpcoesVariavel(opcoesVariavelAAtualizar) {
      opcoesVariavelAAtualizar.forEach((variavel, index) => {
        variavel.index = index + 1
      })

      this.$emit('atualizarOpcoesVariavel', opcoesVariavelAAtualizar)
    },

    feharModalConfirmacao() {
      this.modal_confirmacao_opcao_variavel = false
    },

    async deletarOpcaoVariavel(opcaoVariavelADeletar) {
      console.log(opcaoVariavelADeletar)
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')

        const opcaoVariaveisADeletarPromisses = []

        for (const opcaoVariavel of opcaoVariavelADeletar) {

          opcaoVariaveisADeletarPromisses.push(
            axios.patch(`projeto/opcao-variavel-local/${opcaoVariavel.id}/`, { deletado: 'True' }, {
              headers: {
                Authorization: `Token ${token}`
              }
            })
          )
        }

        await Promise.all(opcaoVariaveisADeletarPromisses)

      } catch (error) {
        console.error(error)
      }
    },

    async excluirSelecionados() {
      if (this.checkAll) {
        await this.deletarOpcaoVariavel(this.opcoesVariavel)
        this.modal_confirmacao_opcao_variavel = false
        this.checkAll = false
        this.atualizarLista()
        return
      }
      const opcoesVariavelADeletar = this.opcoesVariavel.filter((opcao) => {
        if (opcao.selected) {
          return opcao
        }
      })

      await this.deletarOpcaoVariavel(opcoesVariavelADeletar)
      this.modal_confirmacao_opcao_variavel = false
      this.atualizarLista()
    },

    abrirModal(variavelSelecionada) {
      this.variavelAtual = variavelSelecionada
      this.modal_opcoes = true
    },

    forcarPrefixo(variavel) {
      const prefixo = this.valorPadrao;
      if (!variavel.variavel.startsWith(prefixo)) {
        variavel.variavel = prefixo + variavel.variavel.slice(prefixo.length);
      }
      // Converte o restante para maiúsculas
      variavel.variavel = variavel.variavel.toUpperCase();
    },


    selectAll() {
      const checkboxes = document.getElementsByClassName('checkbox-opcao');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },

    closed_preview_image() {
      this.preview_imagem = ''
    },

    openPreviewImage(url) {
      this.preview_imagem = url
    },

    atualizarFileInput(objeto) {
      this.previewImage = objeto;
      this.novaOpcao.imagem = objeto
    },

    abrir_galeria() {
      this.galeria = !this.galeria
    },

    // fechar() {
    //   this.novasOpcoes = []
    //   this.checkAll = false
    //   this.opcoesFiltradas =
    //     this.opcoesFiltradas.map(opcao => {
    //       opcao.selected = false
    //       return opcao
    //     })
    //   this.$emit("fechar")
    // },

    verificarExclusao() {
      console.log(this.checkSome, this.checkAll)
      if (this.checkSome || this.checkAll) {
        this.modal_confirmacao_opcao_variavel = true;
      } else {
        this.erroModal = true;
        this.erroMessage = 'Selecione pelo menos uma Variável para ser excluída.';
      }
    },

    fecharErroModal() {
      this.erroModal = false
    },

    toggleAdicionarOpcao() {
      this.adicionarNovaOpcao = !this.adicionarNovaOpcao
    },

    limparCampos() {
      this.novaOpcao.valor = ''
      this.novaOpcao.descricao = ''
      this.novaOpcao.imagem = ''
      this.novaOpcao.condicao = ''
    },

    async postNovaOpcao() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest');
        
        // this.novaOpcao.imagem?.id ? this.novaOpcao.imagem.id : ''
        // vidro.vidro_id ? vidro.vidro_id = vidro.vidro_id.id : ''
        this.novaOpcao.imagem ? this.novaOpcao.imagem = this.novaOpcao.imagem.id : ''

        await axios.post('projeto/opcao-variavel-local/', this.novaOpcao, {
          headers: {
            Authorization: `Token ${token}`
          }
        });

        this.limparCampos()
        this.atualizarLista();
        this.toggleAdicionarOpcao();

      } catch (error) {
        console.error(error)
        if (error.response && error.response.data) {
          if (typeof error.response.data === 'string' && error.response.data.includes('<!DOCTYPE html>')) {
            this.erros = {
              geral: ["Já existe uma opção com este valor nesta variável."],
            };
          } else if (Array.isArray(error.response.data)) {
            this.erros = { detalhes: error.response.data };
          } else if (typeof error.response.data === 'object') {
            this.erros = error.response.data;
          } else {
            this.erros = { geral: ["Erro desconhecido ao salvar a opção da variável."] };
          }
        } else {
          this.erros = { geral: ["Erro desconhecido ao salvar a opção da variável."] };
        }
        this.toggleErros();
        this.$store.dispatch("setLoading", false);
      }
    },

    atualizarLista() {
      this.$emit('getListaOpcoesVariavel')
    },

    toggleErros() {
      this.exibirErros = !this.exibirErros
    }
  }
}

</script>

<style scoped lang="scss">
.botoes {
  @apply px-4 py-1 text-white
}
</style>
