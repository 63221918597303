<template>
    <div class="container-modelo-tabela">
      <!-- Tabela -->
      <table class="container-tabela overflow-auto">
        <!-- Cabeçalho -->
        <thead class="cabecalho-tabela">
          <tr class="border-b">
            <th scope="col" class="px-6 py-2 text-center">#</th>
            <th scope="col" class="px-6 py-2 text-center">
              <div class="flex items-center">
                <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll" />
                <label class="sr-only">checkbox</label>
              </div>
            </th>
            <th scope="col" class="px-6 py-2 text-start">Local</th>
            <th scope="col" class="px-6 py-2 text-start">Largura</th>
            <th scope="col" class="px-6 py-2 text-start">Altura</th>
            <th scope="col" class="px-6 py-2 text-start">Quantidade</th>
            <th scope="col" class="px-6 py-2 text-start">Condição</th>
            <th scope="col" class="px-6 py-2 text-start">Posição</th>
            <th scope="col" class="px-10 py-2 text-start">Lado</th>
            <th scope="col" class="px-10 py-2 text-start">Furação</th>
            <th scope="col" class="px-10 py-2 text-start">Aplicação</th>
            <th scope="col" class="px-6 py-2 text-start whitespace-nowrap">Acréscimo (%)</th>
            <th scope="col" class="px-6 py-2 text-start whitespace-nowrap">Acréscimo (R$)</th>
            <th scope="col" class="px-6 py-2 text-start">Observação</th>
          </tr>
        </thead>
  
        <!-- Linhas de Vidros -->
        <draggable :list="vidros" tag="tbody" handle=".handle" itemKey="id">
          <template v-slot:item="{ element, index }">
            <tr :key="index" class="escopo-tabela">
              <th scope="row" class="text-center font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
                <div class="handle cursor-pointer">
                  <span>☰</span> <!-- Handle para arrastar -->
                  {{ element.index }}
                </div>
              </th>
              <td scope="col" class="text-center">
                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                  v-model="element.selected" />
              </td>
              <td scope="col" class="text-start text-xs">
                <v-select v-model="element.area_vidro" :options="listaAreasVidro" label="descricao"
                  class="bg-white w-full custom-width" placeholder="Escolha uma opção" />
              </td>
              <td scope="col" class="text-start text-xs">
                <input class="input-campos" v-model="element.largura" />
              </td>
              <td scope="col" class="text-start text-xs">
                <input class="input-campos" v-model="element.altura" />
              </td>
              <td scope="col" class="text-start text-xs">
                <input class="input-campos" v-model="element.qtd_formula" />
              </td>
              <td scope="col" class="text-start text-xs">
                <input class="input-campos" v-model="element.condicao" />
              </td>
              <td scope="col" class="text-start text-xs">
                <input class="input-campos" v-model="element.posicao" />
              </td>
              <td scope="col" class="text-start text-xs">
                <select class="input-campos" v-model="element.lado">
                  <option v-for="lado in ['A Definir', 'Externo', 'Interno']" :key="lado" :value="lado">
                {{ lado }}
              </option>
                </select>
              </td>
              <td scope="col" class="text-start text-xs">
                <select class="input-campos" v-model="element.furacao">
                  <option v-for="furacao in ['A Definir', 'Sim', 'Não']" :key="furacao" :value="furacao">
                {{ furacao }}
              </option>
                </select>
              </td>
              <td scope="col" class="text-start text-xs">
                <select class="input-campos" v-model="element.aplicacao">
                  <option v-for="aplicacao in ['A Definir', 'Produção', 'Obra']" :key="aplicacao" :value="aplicacao">
                    {{ aplicacao }}
                  </option>
                </select>
              </td>
              <td scope="col" class="text-start text-xs">
                <input class="input-campos" v-model="element.acrescimoPorcentagem" />
              </td>
              <td scope="col" class="text-start text-xs">
                <input class="input-campos" v-model="element.acrescimoReais" />
              </td>
              <td scope="col" class="text-start text-xs">
                <input class="input-campos" v-model="element.observacao" />
              </td>
            </tr>
          </template>
        </draggable>
  
        <!-- Adicionar Novo Vidro -->
        <tr v-if="adicionarNovoVidro" class="escopo-tabela">
          <td></td>
          <td></td>
          <td>
            <!-- <v-select v-model="novoVidro.area_vidro" :options="listaAreasVidro" label="descricao" class="bg-white w-full"
              placeholder="Escolha uma opção" :reduce="area => area.id" /> -->
              INPUT BUSCANDO CHAPAS NO PRODUTOBASE
          </td>
          <td><input class="input-campos" v-model="novoVidro.largura" /></td>
          <td><input class="input-campos" v-model="novoVidro.altura" /></td>
          <td><input class="input-campos" v-model="novoVidro.qtd_formula" /></td>
          <td><input class="input-campos" v-model="novoVidro.condicao" /></td>
          <td><input class="input-campos" v-model="novoVidro.posicao" /></td>
          <td>
            <select class="input-campos" v-model="novoVidro.lado">
              <option v-for="lado in ['A Definir', 'Externo', 'Interno']" :key="lado" :value="lado">
                {{ lado }}
              </option>
            </select>
          </td>
          <td>
            <select class="input-campos" v-model="novoVidro.furacao">
              <option v-for="furacao in ['A Definir', 'Sim', 'Não']" :key="furacao" :value="furacao">
                {{ furacao }}
              </option>
            </select>
          </td>
          <td>
            <select class="input-campos" v-model="novoVidro.aplicacao">
              <option v-for="aplicacao in ['A Definir', 'Produção', 'Obra']" :key="aplicacao" :value="aplicacao">
                {{ aplicacao }}
              </option>
            </select>
          </td>
          <td><input type="number" min="0" class="input-campos" v-model="novoVidro.acrescimoPorcentagem" /></td>
          <td><input type="number" min="0" class="input-campos" v-model="novoVidro.acrescimoReais" /></td>
          <td><input class="input-campos" v-model="novoVidro.observacao" /></td>
        </tr>
      </table>
  
      <!-- Botões de Ação -->
      <div v-if="adicionarNovoVidro" class="flex justify-end items-center gap-4 mt-6 px-4 mb-5">
        <button class="btn-cancelar" @click.prevent="toggleVidro">Cancelar</button>
        <button class="btn-salvar" @click.prevent="postNovoVidro">Adicionar</button>
      </div>
      <div v-else class="flex justify-center">
        <button class="text-ativo" @click.prevent="toggleVidro">
          <i class="fas fa-plus-circle"></i>
        </button>
      </div>
  
    </div>
  
    <!-- Botões Gerais -->
    <div class="flex w-full justify-end itens-center mt-6">
      <button class="btn-cancelar" @click.prevent="modal_confirmacao = true" v-if="checkSome || checkAll">
        Excluir Selecionados
      </button>
      <!-- <button class="btn-cancelar" @click.prevent="getListaVidros">Cancelar</button> -->
      <button class="btn-salvar" @click.prevent="atualizarVidros(vidros)">Salvar</button>
    </div>
  
    <!-- Mensagem de Confirmação -->
    <MensagemConfirmacaoExclusao mensagem="Deseja mesmo excluir os itens selecionados?" :abrir="modal_confirmacao"
      @confirmarExclusao="excluirSelecionados" />
  </template>
  
  
  <script>
  import Search from '@/utils/Search.vue'
  import axios from 'axios'
  import MensagemConfirmacaoExclusao from '@/components/toasts/toast_notificacao/MensagemConfirmacaoExclusao.vue'
  import draggable from 'vuedraggable'
  import Search2 from '@/utils/Search2.vue';
  import vSelect from 'vue-select';
  
  export default {
    name: "VidroProjeto",
  
    props: ['vidros', 'projeto_id'],
  
    emits: ['atualizarVidros', 'getListaVidrosProjeto', 'atualizarUnicoVidro'],
  
    data() {
      return {
        abrir: Boolean,
        novosVidros: [],
        vidroSelecionado: [],
        vidrosPesquisados: [],
  
        checkAll: false,
        modal_confirmacao: false,
  
        listaAreasVidro: [],
  
        vidroBase: '',
  
        novoVidro: {
          projeto_id: this.projeto_id,
          vidro_id: '',
          qtd_formula: '',
          furacao: 'A Definir',
          aplicacao: 'A Definir',
          condicao: '',
          posicao: '',
          lado: 'A Definir',
          acrescimoPorcentagem: '',
          acrescimoReais: '',
          observacao: '',
          index: this.vidros.length + 1
        },
        adicionarNovoVidro: false
      }
    },
  
    components: {
      Search,
      MensagemConfirmacaoExclusao,
      draggable,
      Search2,
      vSelect
    },
  
    computed: {
      checkSome() {
        return this.vidros.some(vidro => vidro.selected)
      }
    },
  
    created() {
      this.getListaAreaVidros()
      this.getVidroBase()
    },
  
  
    methods: {
  
      async getListaAreaVidros() {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')
  
        try {
          const response = await axios.get(`projeto/area-vidro/?ativo=True&page_size=9999`, {
            headers: {
              'Authorization': `Token ${token}`
            }
          })
  
          // console.log(response.data.results)
          this.listaAreasVidro = response.data.results
        } catch (error) {
          console.error(error)
        }
      },
  
      async getVidroBase() {
        const token = await this.$store.dispatch('getAuthTokenCookie')
        await this.$store.dispatch('fetchURLrequest')
  
        try {
          const response = await axios.get(`produtos/produto/?tipo_produto=Vidro&ativo=True`, {
            headers: {
              'Authorization': `Token ${token}`
            }
          })
  
          // console.log(response.data.results)
          this.vidroBase = response.data.results[0].id
          this.novoVidro.vidro_id = this.vidroBase
          console.log(this.novoVidro.vidro_id)
        } catch (error) {
          console.error(error)
        }
      },
  
      atualizarUnicoVidro(vidro, index) {
        const vidroProjetoAAtualizarId = this.vidros[index - 1].id
  
        this.vidrosPesquisados = []
        this.$emit('atualizarUnicoVidro', vidro.id, vidroProjetoAAtualizarId)
  
      },
  
      atualizarVidros(vidrosAAtualizar) {
        vidrosAAtualizar.forEach((vidro, index) => {
          vidro.index = index + 1
        })
  
        this.$emit('atualizarVidros', vidrosAAtualizar)
      },
  
      // getLabelKey(option) {
      //   return option.codigo_base ? option.codigo_base : ''
      // },
  
      // getNameKey(option) {
      //   return option.nome_base ? option.nome_base : ''
      // },
  
      // onSelect(vidro) {
      //   this.vidroSelecionado = vidro
      // },
  
      selectAll() {
        // Atualiza o estado dos demais checkboxes
        const checkboxes = document.getElementsByClassName('checkbox');
        checkboxes.forEach((checkbox) => {
          checkbox.checked = this.checkAll;
        });
      },
  
      excluirSelecionados() {
        if (this.checkAll) {
          this.deletarVidros(this.vidros)
          this.modal_confirmacao = false
          this.checkAll = false
          return
        }
        const vidrosADeletar = this.vidros.filter((vidro) => {
          if (vidro.selected) {
            return vidro
          }
        })
  
        const vidrosAAtualizar = this.vidros.filter(vidro =>
          !vidrosADeletar.some(deletar => deletar.id === vidro.id)
        )
  
        this.deletarVidros(vidrosADeletar)
        this.modal_confirmacao = false
        this.atualizarVidros(vidrosAAtualizar)
      },
  
      async deletarVidros(vidrosADeletar) {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')
  
        const vidrosADeletarPromisses = []
  
        for (const vidro of vidrosADeletar) {
  
          vidrosADeletarPromisses.push(
            axios.patch(`projeto/projeto-vidro/${vidro.id}/`, { deletado: 'True' }, {
              headers: {
                Authorization: `Token ${token}`
              }
            })
          )
        }
  
        await Promise.all(vidrosADeletarPromisses)
  
        this.$emit('getListaVidrosProjeto')
      },
  
      // async buscarVidros(searchQuery) {
      //   if (searchQuery) {
      //     try {
      //       const token = await this.$store.dispatch('getAuthTokenCookie');
      //       await this.$store.dispatch('fetchURLrequest')
  
      //       const response = await axios.get(`produtos/produto/?tipo_produto=Vidro&filtro_base=${searchQuery}&page_size=9999`, {
      //         headers: {
      //           Authorization: `Token ${token}`
      //         }
      //       })
  
      //       this.vidrosPesquisados = response.data.results
      //     } catch (error) {
      //       console.error(error)
      //     }
      //   } else {
      //     this.vidrosPesquisados = []
      //   }
      // },
  
      toggleVidro() {
        this.adicionarNovoVidro = !this.adicionarNovoVidro
        this.limparCampos()
        // this.novosVidros.push({
        //   projeto_id: this.projeto_id,
        //   vidro_id: '',
        //   qtd_formula: '',
        //   furacao: 'A DEFINIR',
        //   aplicacao: 'A DEFINIR',
        //   condicao: '',
        //   posicao: '',
        //   lado: 'A DEFINIR',
        //   acrescimoPorcentagem: '',
        //   acrescimoReais: '',
        //   observacao: '',
        //   index: this.vidros.length + 1
        // })
      },
  
      async postNovoVidro() {
        console.log(this.novoVidro)
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')
  
        const novosVidrosPromisses = []
  
        novosVidrosPromisses.push(
          axios.post('projeto/projeto-vidro/', this.novoVidro, {
            headers: {
              Authorization: `Token ${token}`
            }
          })
        )
        // }
  
        await Promise.all(novosVidrosPromisses)
        this.adicionarNovoVidro = false
  
        this.novosVidros = []
        this.$emit('getListaVidrosProjeto')
  
        this.limparCampos()
  
      },
  
      limparCampos() {
        this.novoVidro.largura = '',
          this.novoVidro.altura = '',
          this.novoVidro.qtd_formula = '',
          this.novoVidro.condicao = '',
          this.novoVidro.posicao = '',
          this.novoVidro.acrescimoPorcentagem = '',
          this.novoVidro.acrescimoReais = '',
          this.novoVidro.observacao = ''
      }
    }
  
  }
  </script>
  
  <style scoped>
  .input-campos {
    @apply w-full
  }
  
  .custom-width {
    width: 200px;
  }
  </style>